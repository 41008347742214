import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Chart } from 'chart.js';
import { DataService } from '../../../../shared/services/data.service';
import { Type } from '../../../models/type';
// import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';
// import * as moment from 'moment';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import {
  ReactiveFormsModule,
  UntypedFormGroup,
  FormControl,
  Validators,
  UntypedFormBuilder
} from '@angular/forms';
// import * as $ from 'jquery';
import $ from 'jquery';
import { LoaderService } from '../../../../shared/services/loader.service';

@Component({
  selector: 'wtaf-test-suite-execution-time-trend',
  templateUrl: './test-suite-execution-time-trend.component.html',
  styleUrls: ['./test-suite-execution-time-trend.component.css']
})
export class TestSuiteExecutionTimeTrendComponent
  implements OnInit, AfterViewInit {
  chart: any;
  regionList: any = [];
  testCaseList: any = [];
  projectTypeList: any = [];
  platformList: any = [];
  browserList: any = [];
  projectNameList: any = [];
  brandList: any = [];
  testSuiteList: any = [];
  userList: any = [];
  chartData: any = [];
  chartLabelData: any = [];
  chartTimeData: any = [];
  chartMeanTimeData: any = [];
  environmentList = ['STG', 'Dev', 'Prod', 'QA'];
  toDate: any;
  fromDate: any;
  brandId: any;
  regionId: any;
  projecttypeId: any;
  testSuiteName: any;
  regionName: any;
  testSuiteid: any;
  widgetFilterform: UntypedFormGroup;
  isSubmitted = false;
  error: any = { isError: false, errorMessage: '' };
  isValidDate: any;

  dropdownSettings = {};
  dropdownSettingsTestSuite = {};
  dropdownList = [];
  selectedItems = [];
  selectedItemsSuite = [];
  projectName = '';
  constructor(
    private dataservice: DataService,
    public toastr: ToastrService,
    public datepipe: DatePipe,
    public fb: UntypedFormBuilder,
    public loaderservice:LoaderService
  ) {
    let nowDate = new Date();
    this.toDate = this.datepipe.transform(nowDate, 'yyyy-MM-dd');
    this.fromDate = moment()
      .subtract('days', 7)
      .format('YYYY-MM-DD');
    this.widgetFilterform = this.fb.group({
      projectTypeId: ['', [Validators.required]],
      regionId: ['', [Validators.required]],
      brandId: ['', [Validators.required]],
      testSuiteId: ['', [Validators.required]],
      testSuiteName: ['', [Validators.required]],
      productName: ['', [Validators.required]],
      profileId: ['', [Validators.required]],
      platformId: ['', [Validators.required]],
      environment: ['STG', [Validators.required]],
      startDate: [this.fromDate, [Validators.required]],
      endDate: [this.toDate, [Validators.required]],
      projectName: []
    });
  }

  ngOnInit() {

    this.loaderservice.show()

    setTimeout(()=>{
        this.loaderservice.hide()
  
      },15000)

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'projectName',
      textField: 'projectName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
      // closeDropDownOnSelection: this.closeDropdownSelection
    };

    this.dropdownSettingsTestSuite = {
      singleSelection: true,
      idField: 'testSuiteName',
      textField: 'testSuiteName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
      // closeDropDownOnSelection: this.closeDropdownSelection
    };

    $('#testSuiteExecutionTimeTrendFromDate').attr('max', this.toDate);
    $('#testSuiteExecutionTimeTrendToDate').attr('max', this.toDate);
    this.getAllProjectType();
    // this.getAllRegion(1);
    // this.getBrandsByRegionId(1);
    // this.getAllProjectName(0, 0, 0);
    // this.getAlltestsuites();
    this.getAllUser();
    let InLine = document.getElementById('InLine');
    // this.chart = new Chart(InLine, {
    //   type: 'line',
    //   data: {
    //     labels: [
    //       '2016-12-25',
    //       '2016-12-26',
    //       '2016-12-27',
    //       '2016-12-28',
    //       '2016-12-29'
    //     ],
    //     datasets: [
    //       {
    //         data: [
    //           { x: '2016-12-25', y: 18 },
    //           { x: '2016-12-26', y: 10 },
    //           { x: '2016-12-27', y: 30 },
    //           { x: '2016-12-28', y: 17 },
    //           { x: '2016-12-29', y: 21 }
    //         ],
    //         label: ['Time-in-min'],
    //         borderColor: 'green',
    //         backgroundColor: '',
    //         lineTension: 0,
    //         fill: false,
    //         stacked: true
    //       },
    //       {
    //         data: [
    //           { x: '2016-12-25', y: 19 },
    //           { x: '2016-12-26', y: 19 },
    //           { x: '2016-12-27', y: 19 },
    //           { x: '2016-12-28', y: 19 },
    //           { x: '2016-12-29', y: 19 }
    //         ],
    //         label: ['Mean time'],
    //         borderColor: '#328efe',
    //         backgroundColor: '',
    //         lineTension: 0,
    //         fill: false,
    //         borderDash: [10, 5],
    //         stacked: true
    //       }
    //     ]
    //   },
    //   options: {
    //     legend: {
    //       display: true
    //     },
    //     scales: {
    //       xAxes: [
    //         {
    //           display: true,
    //           ticks: { min: 1, max: 31 }
    //         }
    //       ],
    //       yAxes: [
    //         {
    //           display: true,
    //           ticks: { min: 1, max: 60 }
    //         }
    //       ]
    //     }
    //   }
    // });
  }

  onItemSelectProductName(item: any) {
    console.log(item);
    console.log(this.selectedItems);
    // this.dataservice.isfromWidget = false;
    this.projectName = item.projectName;
    // this.widgetFilterform.controls['productName'].setValue(item.projectName);
    this.dataservice.isfromWidget = false;
    this.widgetFilterform.controls['testSuiteId'].setValue('');
    this.widgetFilterform.controls['testSuiteName'].setValue('');
    this.testSuiteList = [];
    this.brandId = this.widgetFilterform.get('brandId').value;
    this.regionId = this.widgetFilterform.get('regionId').value;
    this.projecttypeId = this.widgetFilterform.get('projectTypeId').value;

    this.getAllTestSuite(
      this.regionId,
      this.brandId,
      this.projecttypeId,
      this.projectName
    );
  }

  onItemSelectSuite(item: any) {
     
    console.log(item);
    console.log(this.selectedItemsSuite);
    this.testSuiteName = item.testSuiteName;
    let index = this.testSuiteList.findIndex(
      el => el.testSuiteName == this.testSuiteName
    );

    this.testSuiteid = this.testSuiteList[index].testSuiteId;
    this.widgetFilterform.controls['testSuiteId'].setValue(this.testSuiteid);
    console.log(this.testSuiteList[index].testSuiteId);
    // this.dataservice.isfromWidget = false;
    // this.projectName = item.projectName;
    // this.widgetFilterform.controls['productName'].setValue(item.projectName);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
    this.projectName = '';
    delete item.projectName;
    this.widgetFilterform.controls['productName'].setValue('');
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }
  getAllRegion(Projecttypeid) {
    this.dataservice
      .getAllRegionByProjectType(Projecttypeid)
      .subscribe(async (response: {}) => {
        this.regionList = response;
        this.regionList.unshift(
          this.regionList.splice(
            this.regionList.findIndex(item => item.regionName === 'NAR'),
            1
          )[0]
        );
        if (response != null) {
          this.widgetFilterform.controls['regionId'].setValue(
            this.regionList[0].regionId
          );
          this.regionName = this.regionList[0].regionName;
          await this.getBrandsByRegionId(
            this.widgetFilterform.get('regionId').value
          );
        }
      });
  }
  async getAllProjectName(regionId, brandId, projecttypeId) {
    await this.dataservice
      .getProjectNameByTypeId(brandId, projecttypeId, regionId)
      .subscribe(allProjNameRes => {
        this.projectNameList = allProjNameRes;
        console.log('all product names', this.projectNameList);
      });
  }
  async getBrandsByRegionId(regionId) {
    await this.dataservice
      .getBrandsByRegionId(regionId)
      .toPromise()
      .then((response: {}) => {
        this.brandList = response;
        if (response != null) {
          if (this.brandList) {
            this.regionId = this.widgetFilterform.get('regionId').value;
            this.projecttypeId = this.widgetFilterform.get(
              'projectTypeId'
            ).value;

            this.brandList.unshift(
              this.brandList.splice(
                this.brandList.findIndex(
                  item => item.brandName.toLowerCase() === 'whirlpool'
                ),
                1
              )[0]
            );
            this.widgetFilterform.controls['brandId'].setValue(
              this.brandList[0].brandId
            );
            this.brandId = this.brandList[0].brandId;
          }
          this.getAllProjectName(
            this.regionId,
            this.brandId,
            this.projecttypeId
          );
        } else {
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }
  async getAllProjectType() {
    await this.dataservice
      .getAllProjectType()
      .toPromise()
      .then(async response => {
        // Success
        this.projectTypeList = response;
        console.log('this.projectTypeList.length');
        console.log(this.projectTypeList.length);
        if (response != null) {
          this.widgetFilterform.controls['projectTypeId'].setValue(
            this.projectTypeList[0].projectTypeId
          );
          await this.getPlatformByProjectTypeId(
            this.projectTypeList[0].projectTypeId
          );
          await this.getAllRegion(this.projectTypeList[0].projectTypeId);
        }
      });
  }
  async getAlltestsuites() {
    this.dataservice
      .getTestSuiteNameByProjectName('multiple test suite')
      .subscribe((response: {}) => {
        console.log(response);
        this.testSuiteList = response;
        if (response != null) {
          if (this.testSuiteList.length == 1) {
          } else {
          }
        }
      });
  }
  async getPlatformByProjectTypeId(projectTypeId) {
    await this.dataservice.getPlatformByProjectTypeId(projectTypeId).subscribe(
      (response: {}) => {
        this.platformList = response;
        console.log(this.platformList);
        if (this.platformList != null) {
          if (this.platformList.length == 1) {
            this.widgetFilterform.controls['platformId'].setValue(
              this.platformList[0].platformId
            );
          } else {
            this.widgetFilterform.controls['platformId'].setValue(
              this.platformList[1].platformId
            );
          }
        }
      },
      error => {
        console.log(error.message);
      }
    );
  }
  async getAllTestSuite(regionId, brandId, projectTypeId, projectName) {
    let TestSuiteName = Type.TestSuiteName;
    let asc = Type.ascending;
    await this.dataservice
      .getAllTestSuite(
        TestSuiteName,
        asc,
        regionId,
        brandId,
        projectTypeId,
        projectName,
        0,
        0
      )
      .toPromise()
      .then(async (response: {}) => {
        this.testSuiteList = response;
        console.log('getAllTestSuite----response', response);
      });
  }
  getAllUser() {
    this.dataservice.getAllUser().subscribe((response: {}) => {
      this.userList = response;
      this.widgetFilterform.controls['profileId'].setValue(0);
      console.log('response', response);
      // this.dataservice.customFilter['userName'] = '';
    });
  }
  changeProjectType(e) {
    this.dataservice.isfromWidget = false;
    this.projecttypeId = e.target.value;
    this.regionList = [];
    this.brandList = [];
    this.brandId = 0;
    this.projectNameList = [];
    this.testSuiteList = [];
    this.widgetFilterform.controls['regionId'].setValue('');
    this.widgetFilterform.controls['testSuiteId'].setValue('');
    this.widgetFilterform.controls['projectName'].setValue('');
    this.getPlatformByProjectTypeId(parseInt(e.target.value));
    this.getAllRegion(parseInt(e.target.value));
  }
  changeRegion(e) {
    this.dataservice.isfromWidget = false;
    this.projectNameList = [];
    this.testSuiteList = [];
    this.widgetFilterform.controls['testSuiteId'].setValue('');
    this.widgetFilterform.controls['projectName'].setValue('');
    this.getBrandsByRegionId(parseInt(e.target.value));
  }
  changeBrand(e) {
    this.dataservice.isfromWidget = false;
    this.widgetFilterform.controls['testSuiteId'].setValue('');
    this.widgetFilterform.controls['testSuiteName'].setValue('');
    this.widgetFilterform.controls['projectName'].setValue('');
    this.brandId = parseInt(e.target.value);
    this.regionId = this.widgetFilterform.get('regionId').value;
    this.projecttypeId = this.widgetFilterform.get('projectTypeId').value;

    this.getAllProjectName(this.regionId, this.brandId, this.projecttypeId);
  }
  changeProjectName(e) {
    this.dataservice.isfromWidget = false;
    this.widgetFilterform.controls['testSuiteId'].setValue('');
    this.widgetFilterform.controls['testSuiteName'].setValue('');
    this.testSuiteList = [];
    this.brandId = this.widgetFilterform.get('brandId').value;
    this.regionId = this.widgetFilterform.get('regionId').value;
    this.projecttypeId = this.widgetFilterform.get('projectTypeId').value;

    this.getAllTestSuite(
      this.regionId,
      this.brandId,
      this.projecttypeId,
      e.target.value
    );
  }
  changeTestsuite(e) {
    this.dataservice.isfromWidget = false;
    let index = this.testSuiteList.findIndex(
      el => el.testSuiteName == e.target.value
    );
    this.testSuiteName = e.target.value;
    this.testSuiteid = this.testSuiteList[index].testSuiteId;
    this.widgetFilterform.controls['testSuiteId'].setValue(this.testSuiteid);
    console.log(this.testSuiteList[index].testSuiteId);
    // constForm.testSuiteId= this.testSuiteid;
    // l this.widgetFilterform.controls['testSuiteId'].setValue(this.testSuiteid);
  }
  changeUser(e) {
    let userId = parseInt(e.target.value);
    this.widgetFilterform.controls['profileId'].setValue(
      parseInt(e.target.value)
    );
    console.log('userId', userId);
  }
  onFromDateChange(e) {
    let fromDate = this.datepipe.transform(e.target.value, 'yyyy-MM-dd');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(fromDate).getMonth();
    let currentyear1 = new Date(fromDate).getFullYear();
    if (
      new Date(this.datepipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ===
      new Date(this.datepipe.transform(fromDate, 'yyyy-MM-dd')).getTime()
    ) {
      let maxDate = fromDate;
      fromDate = moment(fromDate)
        .subtract(1, 'months')
        .format('YYYY-MM-DD');
      $('#testSuiteExecutionTimeTrendToDate').attr(
        'min',
        this.datepipe.transform(fromDate, 'yyyy-MM-dd')
      );
      $('#testSuiteExecutionTimeTrendToDate').attr(
        'max',
        this.datepipe.transform(maxDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        let maxDate = fromDate;
        fromDate = moment(fromDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let toDate = moment(
          this.datepipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#testSuiteExecutionTimeTrendToDate').attr(
          'min',
          this.datepipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#testSuiteExecutionTimeTrendToDate').attr(
          'max',
          this.datepipe.transform(currentDate, 'yyyy-MM-dd')
        );
      } else {
        // fromDate = moment(fromDate)
        // .subtract(1, 'months')
        // .format('YYYY-MM-DD');
        let toDate = moment(
          this.datepipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');

        let date_1 = new Date(
          this.datepipe.transform(currentDate, 'yyyy-MM-dd')
        ).getTime();
        let date_2 = new Date(
          this.datepipe.transform(toDate, 'yyyy-MM-dd')
        ).getTime();

        if (date_2 > date_1) {
          toDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
        }
        $('#testSuiteExecutionTimeTrendToDate').attr(
          'min',
          this.datepipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#testSuiteExecutionTimeTrendToDate').attr(
          'max',
          this.datepipe.transform(toDate, 'yyyy-MM-dd')
        );
        this.widgetFilterform.controls['endDate'].setValue(toDate);
      }
    }
  }
  onToDateChange(e) {
    let toDate = this.datepipe.transform(e.target.value, 'yyyy-MM-dd');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(toDate).getMonth();
    let currentyear1 = new Date(toDate).getFullYear();
    if (
      new Date(this.datepipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ===
      new Date(this.datepipe.transform(toDate, 'yyyy-MM-dd')).getTime()
    ) {
      toDate = moment(toDate)
        .subtract(1, 'months')
        .format('YYYY-MM-DD');
      $('#testSuiteExecutionTimeTrendFromDate').attr(
        'min',
        this.datepipe.transform(toDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        toDate = moment(toDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let fromDate = moment(
          this.datepipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#testSuiteExecutionTimeTrendFromDate').attr(
          'min',
          this.datepipe.transform(toDate, 'yyyy-MM-dd')
        );
        $('#testSuiteExecutionTimeTrendFromDate').attr(
          'max',
          this.datepipe.transform(currentDate, 'yyyy-MM-dd')
        );
      } else {
        toDate = moment(toDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let fromDate = moment(
          this.datepipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#testSuiteExecutionTimeTrendFromDate').attr(
          'min',
          this.datepipe.transform(toDate, 'yyyy-MM-dd')
        );
        $('#testSuiteExecutionTimeTrendFromDate').attr(
          'max',
          this.datepipe.transform(fromDate, 'yyyy-MM-dd')
        );
      }
    }
  }
  onFormSubmit() {
    this.isSubmitted = true;
    this.widgetFilterform.controls['projectName'].setValue(this.projectName);
    this.isValidDate = this.validateDates(
      this.datepipe.transform(
        this.widgetFilterform.controls['startDate'].value,
        'yyyy-MM-dd'
      ),
      this.datepipe.transform(
        this.widgetFilterform.controls['endDate'].value,
        'yyyy-MM-dd'
      )
    );
    if (!this.isValidDate || this.widgetFilterform.invalid) {
      return;
    } else {
      this.dataservice.testExeTimeTrend(this.widgetFilterform.value).subscribe(
        (res: any) => {
          console.log('res', res);
          let mindate = moment(this.toDate)
            .subtract(24, 'months')
            .format('YYYY-MM-DD');
          $('#testSuiteExecutionTimeTrendFromDate').attr('max', this.toDate);
          $('#testSuiteExecutionTimeTrendToDate').attr('max', this.toDate);
          $('#testSuiteExecutionTimeTrendFromDate').attr('min', mindate);
          $('#testSuiteExecutionTimeTrendToDate').attr('min', mindate);
          this.error = { isError: false, errorMessage: '' };
          this.chartMeanTimeData = [];
          this.chartLabelData = [];
          this.chartTimeData = [];
          if (res.length === 0) {
            this.toastr.error('No records found');
          }
          if (res != null) {
            res.map(element => {
              this.chartLabelData.push(element.date);
              this.chartTimeData.push({
                x: element.date,
                y: Math.round(element.executionTime / 60)
              });
              this.chartMeanTimeData.push({
                x: element.date,
                y: Math.round(element.meanExecutionTime / 60)
              });
            });
            // let meansum = this.chartMeanTimeData[0].y;
            for (let means in this.chartMeanTimeData) {
              if (+means != 0) {
                this.chartMeanTimeData[means].y = this.chartMeanTimeData[0].y;
              }
            }

            let minmaxY = Math.max.apply(
              Math,
              this.chartTimeData.map(function(o) {
                return o.y;
              })
            );
            let meanmaxY = Math.max.apply(
              Math,
              this.chartMeanTimeData.map(function(o) {
                return o.y;
              })
            );
            let maxYpoint = Math.max(minmaxY, meanmaxY);
            this.chart.destroy();
            document.getElementById('InLinechart').innerHTML = '&nbsp;';
            document.getElementById('InLinechart').innerHTML =
              '<canvas id="InLine" ></canvas>';
            let Bar = document.getElementById('Bar');
            let InLine = document.getElementById('InLine');
            // this.chart = new Chart(InLine, {
            //   type: 'line',
            //   data: {
            //     labels: this.chartLabelData,
            //     datasets: [
            //       {
            //         data: this.chartTimeData,
            //         label: ['Time-in-min'],
            //         borderColor: 'green',
            //         backgroundColor: '',
            //         lineTension: 0,
            //         fill: false
            //       },
            //       {
            //         data: this.chartMeanTimeData,
            //         label: ['Mean time'],
            //         borderColor: '#328efe',
            //         backgroundColor: '',
            //         lineTension: 0,
            //         fill: false,
            //         borderDash: [10, 5]
            //       }
            //     ]
            //   },
            //   options: {
            //     legend: {
            //       display: true
            //     },
            //     scales: {
            //       xAxes: [
            //         {
            //           display: true,
            //           ticks: { min: 1, max: maxYpoint }
            //         }
            //       ],
            //       yAxes: [
            //         {
            //           display: true,
            //           ticks: { min: 0, max: maxYpoint }
            //         }
            //       ]
            //     }
            //   }
            // });
          }
        },
        error => {
          $('#testSuiteExecutionTimeTrendFromDate').attr('max', this.toDate);
          $('#testSuiteExecutionTimeTrendToDate').attr('max', this.toDate);
          // this.widgetFilterform.controls['startDate'].setValue(this.fromDate);
          // this.widgetFilterform.controls['endDate'].setValue(this.toDate);
          this.error = { isError: false, errorMessage: '' };
        }
      );
      // alert('if not true')
    }
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if (sDate == null || eDate == null) {
      this.error = {
        isError: true,
        errorMessage: 'Start date and end date are required.'
      };
      this.isValidDate = false;
    }

    if (sDate != null && eDate != null && eDate < sDate) {
      this.error = {
        isError: true,
        errorMessage: 'To date should be greater than from date.'
      };
      this.isValidDate = false;
    }
    return this.isValidDate;
  }
  ngAfterViewInit() {}
}
