<div class="row col-md-12 pr-0 no-gutters">

  <div class="col-md-12">
    <select *ngIf='this.totalRecords> 0' class="form-control txtControlCss recordRangCss  col-md-8 ml-4"
    (change)="getrecordsPerPage($event)" [(ngModel)]='recordsPerPage'>
    <option *ngFor ='let rangeArr of recordRangeArr' [value]="rangeArr.value">{{rangeArr.name}}</option>
  </select>
    <nav aria-label="page navigation example" class="mainNav">
      <ul class="pagination" *ngIf='this.totalPage > 0'>
        <li class="page-item" [ngClass]="{'disabled': activePage === 1}" (click)="onClickPage(1)"><a class="page-link"
            href="javascript:void(0);">
            First</a></li>

        <li class="page-item" [ngClass]="{'disabled': activePage === 1}" (click)="onClickPage(activePage - 1)"><a
            class="page-link" href="javascript:void(0);">«
            Previous</a></li>

        <li class="page-item" [ngClass]="{'active': activePage === item}" *ngFor="let item of pagesRange"
          (click)="onClickPage(item)"><a class="page-link" href="javascript:void(0);">{{item}}</a></li>

        <li class="page-item" *ngIf="(this.totalPage - this.startPage) > 5 " (click)="onClickPage(this.startPage + 5)">
          <a class="page-link" href="javascript:void(0);">...
          </a>
        </li>

        <li class="page-item" *ngIf="(this.totalPage - this.startPage) > 5 " (click)="onClickPage(this.totalPage)">
          <a class="page-link" href="javascript:void(0);">{{this.totalPage}}
          </a>
        </li>

        <li class="page-item" (click)="onClickPage(activePage + 1)"
          [ngClass]="{'disabled':activePage === this.totalPage}"><a class="page-link"
            href="javascript:void(0);">Next
            »</a></li>

        <li class="page-item" [ngClass]="{'disabled':activePage === this.totalPage}"
          (click)="onClickPage(this.totalPage)"><a class="page-link" href="javascript:void(0);">Last</a></li>
      </ul>
    </nav>

  </div>

</div>
