<ng-container>
    <div class="row mt-2">
        <div class="col-md-6 txt-color" style="font-weight: 500;">
        </div>
        <div class="col-md-6 txt-color" style="font-weight: 500;">
            Jobs
        </div>
        <div class="chart-container  col-md-12" id="barchart">
        </div>

        <div class="d-flex overlay " *ngIf="this.load==true">
            <div class="monthlyloading">Loading&#8230;</div>
        </div>

        <div class="chart-container  col-md-12">
            <div id="container"></div>
            <highcharts-chart [(update)]="updateFlag" [Highcharts]="highcharts" [options]="chartOptions"
                style="width: 100%; height: 350px; display: block;">
            </highcharts-chart>
        </div>
    </div>
</ng-container>