import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class LoaderService {
    isLoading = new Subject<boolean>();
    show() {
        // setTimeout(() => {
            this.isLoading.next(true);
            // console.log("show noooooooooo");
		// }, 1000);
    }
    hide() {
        setTimeout(() => {
            this.isLoading.next(false);
            // console.log("show noooooooooo");
		}, 500);
    }
}