<ng-container>
  <div style="width: 100% !important; overflow:auto;">
    <form [formGroup]="AppReportStatusform" (ngSubmit)="AppReportStatusFormSubmit()">
      <div class="row">
        <div class="col-md-3 txt-color mt-4" style="font-weight: 500;size: 20;">
          <h5>App Based Report{{selectedDate1}} {{selectedDate2}}</h5>
        </div>
        <div class="col-md-2" style="margin-left: 50px;">
          <label for="ReportType" class="txtControlLblCss">Test Type<span>*</span></label>
          <!-- <input type="text" (input)="GetAppVersion($event)" [(ngModel)]='AppVersionName'
                [disabled]="this.isFromHistory == true" placeholder="Enter Version (Mobile App/Browser)"
                class="form-control col-md-7 txtControlCss" id="AppVersionName" name="AppVersionName" /> -->
          <div class="exmp-wrp ">
            <div class="input-group btn-wrp">
              <select class="input-group btn-clck" id="DataType" name="DataType" formControlName="DataType" (change)="changeReport($event)">
                <!-- <option value="default-version">Default-Version</option> -->
                <option value="Normal">All</option>
                <option value="Functional">Functional</option>
              </select>
              <span class="input-group-btn ">
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <label for="startDate" class="txtControlLblCss">Select StartDate<span>*</span></label>
          <div class="exmp-wrp " style="margin-left:2px;">
            <div class="input-group btn-wrp">
              <input type="date" class="btn-clck" formControlName="startDate" (change)="onFromDateChange($event)"
                id="exe_StartDate" name="widget_From_date" placeholder="From" [min]="getMinDate()" [max]="getMaxDate()">
            </div>
            <span class="input-group-btn ">
              <button class="btn1  " type="text"><span class="paddingLefiRightCalender"><img
                    src="assets/images/calendarr.png"></span></button>
            </span>
          </div>
        </div>
        <div class="col-md-2">
          <label for="endDate" class="txtControlLblCss">Select EndDate<span>*</span></label>

          <div class="exmp-wrp " style="margin-left:2px;">
            <div class="input-group btn-wrp">
              <input type="date" class="btn-clck " formControlName="endDate" id="toDate"
                (change)="onToDateChange($event)" name="endDate" placeholder="To" [min]="getMinDate()"
                [max]="getMaxDate()">
            </div>
            <span class="input-group-btn ">
              <button class="btn1  " type="text"><span class="paddingLefiRightCalender"><img
                    src="assets/images/calendarr.png"></span></button>
            </span>
          </div>
        </div>
        <div class="download">
          <button type="submit" class="btn  btn-primary" style="margin-right: 15px;width: 100px;">Run</button>
          <button type="button" (click)="exportTableData()" class="btn btn-primary">Download Report</button>
        </div>
      </div>
    </form>

    <table class="table  table-bordered myTable table-sticky">
      <thead class="thead-light">
        <tr>
          <th style=" vertical-align : middle;text-align:center;background-color: brown; color: white;" scope="col"
            (click)="sortTable('brandName')">Brand&nbsp;
            <div class="inlineImages">
              <img src="assets/images/up.png">
              <img src="assets/images/down.png">

            </div>
          </th>
          <th style="width: 25%; vertical-align : middle;text-align:center;background-color: brown; color: white;"
            scope="col" (click)="sortTable('appVersion')">App Version&nbsp;
            <div class="inlineImages">
              <img src="assets/images/up.png">
              <img src="assets/images/down.png">

            </div>

          </th>
          <th style=" width: 8%; vertical-align : middle;text-align:center;background-color: brown; color: white;"
            scope="col" (click)="sortTable('testLevel')">Test Level&nbsp;
            <div class="inlineImages">
              <img src="assets/images/up.png">
              <img src="assets/images/down.png">

            </div>

          </th>
          <th style=" width: 8%; vertical-align : middle;text-align:center;background-color: brown; color: white;"
            scope="col" (click)="sortTable('environment')">Environment&nbsp;
            <div class="inlineImages">
              <img src="assets/images/up.png">
              <img src="assets/images/down.png">

            </div>
          </th>
          <th style=" vertical-align : middle;text-align:center;background-color: brown; color: white;" scope="col"
            (click)="sortTable('platformName')">Platform Name&nbsp;
            <div class="inlineImages">
              <img src="assets/images/up.png">
              <img src="assets/images/down.png">

            </div>
          </th>
          <th style=" vertical-align : middle;text-align:center;background-color: brown; color: white;" scope="col"
            >
            Total Suites</th>
          <th style=" vertical-align : middle;text-align:center;background-color: brown; color: white;" scope="col" *ngIf="this.isNormal">
            Suites Executed</th>
          <th style=" vertical-align : middle;text-align:center;background-color: brown; color: white;" scope="col">TS
            Pass</th>
          <th style=" vertical-align : middle;text-align:center;background-color: brown; color: white;" scope="col">TS
            Fail</th>
            <th style=" vertical-align : middle;text-align:center;background-color: brown; color: white;" scope="col" *ngIf="!this.isNormal">TS Skipped</th>
          <th style=" vertical-align : middle;text-align:center;background-color: brown; color: white;" scope="col">TS
            Pass %</th>
          <th style=" vertical-align : middle;text-align:center;background-color: brown; color: white;" scope="col">TS
            Fail %</th>
            <th style=" vertical-align : middle;text-align:center;background-color: brown; color: white;" scope="col" *ngIf="!this.isNormal">TS Skipped %</th>
          <th style=" vertical-align : middle;text-align:center;background-color: brown; color: white;" scope="col"
            *ngIf="this.isNormal">
            Total Cases</th>
          <th style=" vertical-align : middle;text-align:center;background-color: brown; color: white;" scope="col">
            Cases Executed</th>
          <th style=" vertical-align : middle;text-align:center;background-color: brown; color: white;" scope="col">TC
            Pass</th>
          <th style=" vertical-align : middle;text-align:center;background-color: brown; color: white;" scope="col">TC
            Fail</th>
          <th style=" vertical-align : middle;text-align:center;background-color: brown; color: white;" scope="col">TC
            Pass %</th>
          <th style=" vertical-align : middle;text-align:center;background-color: brown; color: white;" scope="col">TC
            Fail %</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let alldata of allDetailsCount'>

          <td style="width: 100%; vertical-align : middle;text-align:left;">{{alldata.brandName}}</td>

          <td style="width: 100%; vertical-align : middle;text-align:left;">{{alldata.appVersion}}</td>

          <td style="width: 100%; vertical-align : middle;text-align:left;">{{alldata.testLevel}}</td>

          <td style="width: 100%; vertical-align : middle;text-align:left;">{{alldata.environment}}</td>
          <td style="width: 100%; vertical-align : middle;text-align:left;">{{alldata.platformName}}</td>
          <td style="width: 100%; vertical-align : middle;text-align:right;" >
            {{alldata.developedSuiteCount}}</td>
          <td style="width: 100%; vertical-align : middle;text-align:right;" *ngIf="this.isNormal">{{alldata.testSuitePassed +
            alldata.testSuiteFailed}}</td>
          <td style="width: 100%; vertical-align : middle;text-align:right;color: green;" *ngIf="this.isNormal">{{alldata.testSuitePassed}}
          </td>
          <td style="width: 100%; vertical-align : middle;text-align:right;color: green;" *ngIf="!this.isNormal">{{alldata.functionalPass}}
          </td>
          <td style="width: 100%; vertical-align : middle;text-align:right; color: red;" *ngIf="this.isNormal">{{alldata.testSuiteFailed}}
          </td>
          <td style="width: 100%; vertical-align : middle;text-align:right;color: red;" *ngIf="!this.isNormal">{{alldata.functionalFail}}
          </td>
          <td style="width: 100%; vertical-align : middle;text-align:right;" *ngIf="!this.isNormal">{{alldata.developedSuiteCount-(alldata.functionalPass+alldata.functionalFail)}}</td>
          <td *ngIf="alldata.testSuitePassed!=0 && this.isNormal"
            style="width: 100%; vertical-align : middle;text-align:right;color: green;">
            {{alldata.testSuitePassed/(alldata.testSuitePassed + alldata.testSuiteFailed+alldata.skippedTestSuiteCount)*100 | number: '1.2-2' }}</td>
            <td *ngIf="alldata.testSuitePassed!=0 && !this.isNormal"
            style="width: 100%; vertical-align : middle;text-align:right;color: green;">
            {{(alldata.functionalPass/(alldata.developedSuiteCount))*100 | number: '1.2-2' }}</td>
          <td *ngIf="alldata.testSuitePassed==0"
            style="width: 100%; vertical-align : middle;text-align:right;color: green;">0</td>
          <td *ngIf="alldata.testSuiteFailed!=0 && this.isNormal"
            style="width: 100%; vertical-align : middle;text-align:right;color: red;">
            {{alldata.testSuiteFailed/(alldata.testSuitePassed + alldata.testSuiteFailed + alldata.skippedTestSuiteCount)*100 | number: '1.2-2' }}</td>
            <td *ngIf="alldata.testSuiteFailed!=0 && !this.isNormal"
            style="width: 100%; vertical-align : middle;text-align:right;color: red;">
            {{(alldata.functionalFail/(alldata.developedSuiteCount))*100 | number: '1.2-2' }}</td>
          <td *ngIf="alldata.testSuiteFailed==0"
            style="width: 100%; vertical-align : middle;text-align:right;color: red;">0</td>
          <td *ngIf="!this.isNormal" style="width: 100%; vertical-align : middle;text-align:right;">
            {{((alldata.developedSuiteCount-(alldata.functionalPass+alldata.functionalFail))/alldata.developedSuiteCount)*100 | number: '1.2-2' }}</td>
          <td style="width: 100%; vertical-align : middle;text-align:right;" *ngIf="this.isNormal">
            {{alldata.developedTestCaseCount}}</td>
          <td style="width: 100%; vertical-align : middle;text-align:right;">{{alldata.testCasePassed +
            alldata.testCaseFailed}}</td>
          <td style="width: 100%; vertical-align : middle;text-align:right;color: green;">{{alldata.testCasePassed}}
          </td>
          <td style="width: 100%; vertical-align : middle;text-align:right;color: red;">{{alldata.testCaseFailed}}</td>
          <td *ngIf="alldata.testCasePassed!=0"
            style="width: 100%; vertical-align : middle;text-align:right;color: green;">
            {{alldata.testCasePassed/(alldata.testCasePassed + alldata.testCaseFailed)*100 | number: '1.2-2' || 0 }}
          </td>
          <td *ngIf="alldata.testCasePassed==0"
            style="width: 100%; vertical-align : middle;text-align:right;color: green;">0</td>
          <td *ngIf="alldata.testCaseFailed!=0"
            style="width: 100%; vertical-align : middle;text-align:right;color: red;">
            {{alldata.testCaseFailed/(alldata.testCasePassed + alldata.testCaseFailed)*100 | number: '1.2-2' || 0 }}
          </td>
          <td *ngIf="alldata.testCaseFailed==0"
            style="width: 100%; vertical-align : middle;text-align:right;color: red;">0</td>

        </tr>
      </tbody>
    </table>
  </div>
</ng-container>