
    <div class="my-auto p-0">
        <div class="input-group" id="searchmargin" attr.data-tip="{{toolTip}}" >
            <input type="text" class="form-control searchInput" [(ngModel)]="searchText" name="jobName"
                placeholder="Search" >
            <div class="input-group-append">
                <button class="btn btn-secondary" (click)="searchRecords()" type="button" id="searchicon">
                    <i class="fa fa-search" id="searchicon"></i>
                </button>
            </div>
        </div>
    </div>
