/* projects/wtaf/src/app/features/start_execution/start-execution/start-execution.component.css */
.titleLableCss {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #012f60;
}
.dataLableCss {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}
.dropLableCss {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  color: #012f60;
}
.dropLableCss1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  color: #012f60;
}
.linlLableCss {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-decoration-line: underline;
  color: #1f83fe;
}
.list-group-item {
  border: none;
  padding: 0px !important;
  padding-left: 5px !important;
  margin-right: 10px !important;
  background-color: #e8f2fe;
}
.list-group-item.active {
  background: #e8f2fe;
}
.col-md-5-5 {
  flex: 0 0 45%;
  max-width: 45%;
}
.custom-file-label::after {
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #ffffff;
  border-left: inherit;
  border-radius: 0px;
}
.btn-add_clr {
  height: 30px !important;
  padding: 5px !important;
  font-size: 12px !important;
}
.btn-add_clr1 {
  height: 30px !important;
  padding: 0px !important;
  font-size: 12px !important;
}
.listGroupLableCss {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-decoration-line: underline;
  color: #1f83fe !important;
}
.listGroupLableCss_noUnderline {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #1f83fe !important;
}
.clearBtnWidth {
  width: 120px !important;
}
.saveBtnWidth {
  width: 120px !important;
}
.capabilityTxt {
  font-weight: 600;
  font-family: Open Sans;
  font-size: 14px;
}
.no-pad {
  padding-left: 0px;
  padding-right: 0px;
}
.btn-add {
  height: 32px !important;
}
.marginTop23 {
  margin-top: 23px;
}
.stepper_active {
  background-color: #007bff !important;
}
.outer {
  overflow-x: scroll;
  overflow-y: visible;
  width: 100%;
}
.headcol1 {
  position: sticky;
  left: 0;
  background-color: ghostwhite;
  text-align: center;
  min-width: 90px;
  max-width: 90px;
}
.headcol2 {
  position: sticky;
  left: 89px;
  background-color: ghostwhite;
  text-align: left;
  min-width: 350px;
}
.btnIteration {
  border: 1px solid #1f83fe !important;
  width: 180px !important;
}
.dt {
  min-width: 100%;
  overflow: auto;
}
.fixed {
  position: absolute;
  background: #bdb7b7dd;
}
.ds-row {
  width: 100%;
}
.cursor {
  cursor: pointer;
}
.out {
  display: none;
}
.in {
  display: table-row;
}
.tree-sidebar {
  min-height: 67vh;
  max-height: 100vh;
  word-break: break-all;
  overflow-y: scroll;
}
ul,
#myUL {
  list-style-type: none;
}
#myUL {
  margin: 0;
  padding: 10px;
}
.inlineCase {
  display: inline;
}
.caret {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}
.caret::before {
  content: url("./media/expand.png");
  color: black;
  display: inline-block;
  margin-right: 6px;
}
.ico-brand::before {
  content: url("./media/brand.png");
  color: black;
  display: inline-block;
  margin-right: 6px;
}
.ico-case::before {
  content: url("./media/test_case.png");
  color: black;
  display: inline-block;
  margin-right: 6px;
}
.ico-suit::before {
  content: url("./media/test_suite.png");
  color: black;
  display: inline-block;
  margin-right: 6px;
}
.caret-down::before {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
ul,
#myUL li {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #012f60;
}
.myUL1 ul {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #012f60;
}
.nested {
  display: none;
}
.active {
  display: block;
}
.addBtnPopupCss {
  border: 2px solid #1f83fe !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 74px !important;
  height: 35px !important;
  color: #1f83fe !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  background-color: #ffffff !important;
}
.tblexec-row {
  background-color: white !important;
  border-bottom: 1px solid #dee2e6 !important;
}
.tblexec-td {
  border: none !important;
}
.col-delete {
  width: 70px;
  text-align: center;
}
.col-delete-nested {
  width: 59px;
  text-align: center;
}
.btn-secondary {
  color: black;
  background-color: white;
  border-color: darkgray;
}
.redio {
  align-self: center;
  margin-left: 8px;
}
.titleLableCss1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  align-self: center;
  font-size: 15px;
  color: #012f60;
}
.headermsg {
  margin-bottom: 15px;
}
.border-grey {
  border: 3px solid #8291a5;
  border-radius: 5px;
}
.txt-color {
  color: #8291a5;
}
.table-responsive {
  display: table;
}
.modal-body {
  overflow-x: auto;
}
.highlight {
  background-color: royalblue;
}
.lbl {
  color: #012f60;
  font-weight: 700;
}
.disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
  color: #989795 !important;
}
.highlight_li {
  border-radius: 1px;
  border: 1px solid #1f83fe;
  padding: 2px;
  background-color: #1f83fe;
  min-width: 176px;
}
.highlight_li > span {
  padding-right: 15px !important;
  color: white;
}
.ico_before {
  margin-right: 7px;
  color: #8fc1ff;
  font-size: 13.5px;
}
#thFont {
  font-size: 0.9rem;
}
#osversionwidth {
  width: 50px;
}
#tbody {
  overflow-y: scroll;
}
.ico {
  color: white;
}
.selected {
  background-color: red;
}
.platformstyle {
  background: #ffffff !important;
  border: 1px solid #8291a5 !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  height: 30px !important;
  width: 80px;
}
.txtControlCss4 {
  background: #ffffff !important;
  border: 1px solid #8291a5 !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  height: 35px !important;
  border-left: none;
  width: 10px !important;
}
.txtControlCss3 {
  background: #ffffff !important;
  border: 1px solid #1f83fe !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  height: 35px !important;
  color: #1f83fe;
}
.pad-2 {
  padding-left: 2px;
  padding-right: 2px;
}
.pad-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.suite-case {
  min-height: 137px;
}
.bs-stepper-content {
  margin-top: 20px;
  padding: 0px;
  min-height: 330px;
}
.bs-stepper-header {
  background-color: white;
}
.bs-stepper {
  padding-left: 20px;
  padding-right: 20px;
}
.executionTxtCss {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #012f60;
  padding-top: 0px !important;
}
.clearBtnPopupCss2 {
  border: 2px solid #1f83fe !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 50% !important;
  height: 35px !important;
  color: #1f83fe !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  margin-right: 20px;
  background-color: #ffffff !important;
}
.nextBtnCss {
  border: 2px solid #1f83fe !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 140px !important;
  height: 35px !important;
  color: white !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  background-color: #1f83fe !important;
}
.previousBtnCss {
  border: 2px solid #1f83fe !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 140px !important;
  height: 35px !important;
  color: #1f83fe !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  background-color: #ffffff !important;
}
.clearBtnCss1 {
  border: 2px solid #1f83fe !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 50% !important;
  height: 35px !important;
  color: #1f83fe !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  background-color: #ffffff !important;
}
.container_ {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container_ .checkBoxClass {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  height: 20px;
  width: 20px;
  border: 2px solid #a7a7a7;
}
.container_:hover .checkBoxClass ~ .checkmark {
  background-color: #ccc;
}
.container_ .checkBoxClass:checked ~ .checkmark {
  background-color: #1f83fe;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.container_ .checkBoxClass:checked ~ .checkmark:after {
  display: block;
}
.container_ .checkmark:after {
  left: 5px;
  width: 7px;
  height: 14px;
  border: solid white;
  border-width: 0px 3px 3px 0px;
  transform: rotate(45deg);
}
.pagination {
  display: inline-block;
}
.pagination a {
  color: #1f83fe;
  float: left;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 50px;
  background-color: white;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid;
  margin-left: 7px;
}
.pagination a.active {
  background-color: #1f83fe;
  color: white;
  border-radius: 50px;
}
.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 50px;
}
.bs-stepper .step-trigger {
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  .col-md-3_custom {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
}
.executionDiv {
  padding-top: 30px;
  margin: 0px;
}
.environmentDiv {
  margin-left: 32px;
}
.machineDiv {
  padding-top: 20px;
}
.selectnodeDiv {
  padding-left: 8px;
}
.selectDeviceTxt {
  margin-left: 10px;
}
.devicePoolDiv {
  margin-right: 20px;
}
.selectDevicePoolDiv {
  padding-right: 6px;
  padding-left: 10px;
}
.loadingDeviceDiv {
  margin-right: -20px;
}
.selectApkTxt {
  margin-left: -10px;
}
.selectApkDiv {
  padding-right: 8px;
}
.capTemplateCss {
  margin-top: 24px;
}
.capTempDivCss {
  margin-left: -70px;
}
.createCapDivCss {
  margin-right: 94px;
}
.addCapTempDivCss {
  max-height: 80px;
  overflow-y: auto;
}
.checkth {
  width: 130px;
}
.align-right_ex {
  text-align: right;
}
@media (max-width: 768px) {
  .align-right_ex {
    text-align: left;
  }
}
.exc_mr-10 {
  margin-top: 7px;
}
.p_in {
  display: block;
}
.p_out {
  display: none;
}
.disabled-steps {
  cursor: not-allowed;
}
.enabled-steps {
  cursor: pointer;
  pointer-events: all !important;
}
#casetableExecution tr:last-of-type {
  border: 0 !important;
}
.pointer-events-none {
  pointer-events: none !important;
}
.lbl_exec {
  padding-right: 17px !important;
}
.txtTemplate-name:disabled {
  background-color: #e9ecef !important;
}
.required-template-in {
  display: none !important;
}
.required-template-out {
  display: block !important;
}
.devicePoolNameCss {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  color: #012f60;
}
.openaccord,
.closeaccord {
  cursor: pointer;
}
/*# sourceMappingURL=start-execution.component.css.map */
