/* projects/wtaf/src/app/shared/big-input/big-input/big-input.component.scss */
.big-input {
  width: 100%;
  transition: all 0.5s;
  display: flex;
  padding: 10px 10px 10px 20px;
}
.big-input input {
  flex-grow: 1;
  border: 0;
  font-size: 20px;
  min-width: 100px;
}
/*# sourceMappingURL=big-input.component.css.map */
