<div class="container">
  <!-- Modal -->
  <div class="modal fade" id="scheduleModal" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" style="width: 600px;">
      <div class="modal-content">
        <div class="commonBorderBottom marginLeftRightCss row mt-2">
          <div class="ml-3">
            <h4 class="PopupTitleTxt">Schedule</h4>
          </div>
          <div class="ml-auto mr-3">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
        </div>
        <div class="ml-3">
          <span class="frequncyTxt">Timeline</span>
        </div>

        <div class="col-md-12 mt-2">
          <div class="row">
            <div class="col-md-6">
              <div class="exmp-wrp">
                <div class="input-group btn-wrp">
                  <input type="date" class="inputCss" class="btn-clck " [(ngModel)]="StartDate" (change)="onChange($event)"  id="StartDate" name="StartDate" placeholder="Start Date">
                </div>
                <span class="input-group-btn">
                  <button class="btn1  " type="text"><span><img class=""
                        src="assets/images/calenderr.png"></span></button>
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="exmp-wrp">
                <div class="input-group btn-wrp">
                  <input type="time" class="inputCss" class="btn-clck " [(ngModel)]="scheduleTime"  id="scheduleTime" name="scheduleTime" placeholder="00:00">
                </div>
                <span class="input-group-btn">
                  <button class="btn1  " type="text"><span><img class=""
                        src="assets/images/watch.png"></span></button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="ml-3 mt-2">
          <span class="frequncyTxt">Recurrence</span>
        </div>

        <div class="col-md-12 mt-2">
          <div class="row">
            <div class="col-md-12">
            
              <div class="form-group">
                <select [(ngModel)]="Frequancy" class="form-control" id="Frequancy" (change)="onFrequency_Change($event.target.value)">
                  <option *ngFor="let froption of frequancy_option" [value]="froption.id">{{froption.name}}</option>
                </select>
              </div>
            </div> 
          </div>
        </div>
        <div class="custom_picker_section container" [ngClass]="(hide_custom==true)?'show_custom':'hidecustom'">
          <div class="row">
            <div class="col-md-12">
              <label class="frequncyTxt">Repeat every</label>
            </div>
            <div class="col-md-4 mt-2">
              <div class="form-group">
              <input type="number"  min="0" oninput="this.value = Math.abs(this.value)" value="1" [(ngModel)]="repeatCount" class="form-control"/>
            </div>
            </div>
            <div class="col-md-8 mt-2">
              <div class="form-group">
              <select class="form-control" [(ngModel)]="repeatFrequancy" (change)="onOccurance_Change($event.target.value)">
                <option *ngFor="let ocroption of ocuurance_option" [value]="ocroption.id">{{ocroption.name}}</option>
              </select>
            </div>
            </div>
          <div class="col-md-12" [ngClass]="(hide_weekdays==true)?'showday':'hideday'">
            <div class="mt-2">
              <label class="frequncyTxt">Repeat On</label>
            </div>
          <div class="weekDays-selector">
            <input type="checkbox" (click)='selectDays($event)' id="weekday-mon" value="monday"  class="weekday" />
            <label for="weekday-mon">M</label>
            <input type="checkbox" (click)='selectDays($event)' id="weekday-tue" value="tuesday" class="weekday" />
            <label for="weekday-tue">T</label>
            <input type="checkbox" (click)='selectDays($event)' id="weekday-wed" value="wednesday"  class="weekday" />
            <label for="weekday-wed">W</label>
            <input type="checkbox" (click)='selectDays($event)' id="weekday-thu" value="thursday"  class="weekday" />
            <label for="weekday-thu">T</label>
            <input type="checkbox" (click)='selectDays($event)'  id="weekday-fri" value="friday"  class="weekday" />
            <label for="weekday-fri">F</label>
            <input type="checkbox" (click)='selectDays($event)' id="weekday-sat"  value="saturday" class="weekday" />
            <label for="weekday-sat">S</label>
            <input type="checkbox" (click)='selectDays($event)' id="weekday-sun"  value="sunday"  class="weekday" />
            <label for="weekday-sun">S</label>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-12">
          <label class="frequncyTxt">Ends on</label>
        </div>
        <div class="col-md-12 mt-2">
          <div class="exmp-wrp">
            <div class="input-group btn-wrp">
              <input type="date" class="inputCss" class="btn-clck " [(ngModel)]="endDate" id="EndDate" name="EndDate" placeholder="End Date">
            </div>
            <span class="input-group-btn">
              <button class="btn1  " type="text"><span><img class=""
                    src="assets/images/calenderr.png"></span></button>
            </span>
          </div>
        </div>
      </div>
    </div>
        <div class="mt-3 pb-2 ml-auto mr-3">

          <button type="button" (click)="hideModel()" class="btn closebtn">Cancel</button>
          <button type="button" (click)="ScheduleDetails()" id="schedule_btn" class="btn btn-primary saveBtnPopupCss">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
