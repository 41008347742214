import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../shared/services/data.service';
import { SharedDataService } from '../../../shared/services/shared-data.service';
import { orderBy } from 'lodash';
import { LoaderService } from '../../../shared/services/loader.service';
// import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';
import * as Highcharts from 'highcharts';
// import * as moment from 'moment';
import moment from 'moment';
// import * as $ from 'jquery';
import $ from 'jquery';
import { Constvar } from '../../../models/constVar';
import { DatePipe } from '@angular/common';
import {
  FormArray,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { element } from 'protractor';

interface testLevel {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'wtaf-monthly-dashboard-execution-report',
  templateUrl: './monthly-dashboard-test-case-report.component.html',
  styleUrls: ['./monthly-dashboard-test-case-report.component.css']
})
export class MonthlyDashboardExecutionReportComponent implements OnInit {
  testExecutionForm: UntypedFormGroup;
  load = false;
  regionList: any = [];
  regionName: string = '';
  brandList: any = [];
  regionId: Number = 0;
  fromDate: any;
  projecttypeId: any;
  brandId: Number = 0;
  isfromOninit: boolean = false;
  projectTypeList: any = [];
  platformList: any = [];
  environmentList = ['STG', 'Dev', 'Prod', 'QA'];
  caseSuiteList = ['TestCase', 'TestSuite'];
  highcharts: any = Highcharts;
  updateFlag = true;
  toDate: any;
  error: any = { isError: false, errorMessage: '' };
  exe_status_fail = [];
  exe_status_pass = [];
  exe_status_total = [];
  barChartLabels = [];
  exe_status_unknown = [];
  pass_android = [];
  count_fail = [];
  pass_ios = [];
  fail_ios = [];
  count_pass = [];
  selectOption = 'Smoke';
  selectedDate1: any;
  projectTypeId: Number = 0;
  projectNameList: any = [];
  projectTypeName: String = '';
  apiUiFlag: boolean;
  selectedProjectType: number;
  selectedRegion: any;
  regionsListShared: string[];
  selectedBrand: Number;
  selectedPlatform: Number;
  selectedEnviornment: string;
  selectedStartDate: any;
  selectedEndDate: any;
  maximumDate: Date;
  testlevellist: testLevel[] = [
    { value: 'Smoke', viewValue: 'Smoke' },
    { value: 'CICD', viewValue: 'CICD' },
    { value: 'Regression', viewValue: 'Regression' },
    { value: 'Compatibility', viewValue: 'Compatibility' },
    { value: 'Localization', viewValue: 'Localization' },
    { value: 'Trial-Run', viewValue: 'Trial-Run' }
  ];
  chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'column'
    },
    xAxis: {
      categories: ['Whirlpool', 'Jennair', 'KitchenAid']
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Test Case Execution Count'
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: 'bold',
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            'gray'
        }
      }
    },

    mapNavigation: {
      enableMouseWheelZoom: true
    },
    title: {
      text: ''
    },

    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true
        }
      }
    },

    series: []
  };

  constructor(
    private dataservice: DataService,
    public loaderservice: LoaderService,
    public toastr: ToastrService,
    public fb: UntypedFormBuilder,
    public datePipe: DatePipe,
    private sharedDataService: SharedDataService
  ) {
    this.sharedDataService.selectedProject$.subscribe(projectType => {
      this.selectedProjectType = projectType;
    });
    this.sharedDataService.selectedRegionList$.subscribe(regions => {
      this.regionList = regions;
    });
    this.sharedDataService.selectedRegion$.subscribe(region => {
      this.selectedRegion = region;
    });
    this.sharedDataService.selectedBrandList$.subscribe(brands => {
      this.brandList = brands;
    });
    this.sharedDataService.selectedBrand$.subscribe(brand => {
      this.selectedBrand = brand;
    });
    this.sharedDataService.selectedPlatformList$.subscribe(
      platformListSelected => {
        this.platformList = platformListSelected;
      }
    );
    this.sharedDataService.selectedPlatform$.subscribe(platform => {
      this.selectedPlatform = platform;
    });
    this.sharedDataService.selectedEnvironment$.subscribe(env => {
      this.selectedEnviornment = env || 'STG';
    });
    this.sharedDataService.selectedTestType$.subscribe(testType => {
      this.selectOption = testType || 'Smoke';
    });
    let nowDate = new Date();
    const maxDate = new Date();
    maxDate.setDate(nowDate.getDate() - 1);
    this.toDate = this.datePipe.transform(maxDate, 'yyyy-MM-dd');
    this.fromDate = moment()
      .subtract('days', 7)
      .format('YYYY-MM-DD');
    this.sharedDataService.selectedStartDate$.subscribe(startdate => {
      this.selectedStartDate =
        this.datePipe.transform(startdate, 'yyyy-MM-dd') || this.fromDate;
    });
    this.sharedDataService.selectedEndDate$.subscribe(endDate => {
      this.selectedEndDate =
        this.datePipe.transform(endDate, 'yyyy-MM-dd') || this.toDate;
    });
    this.testExecutionForm = this.fb.group({
      projectTypeId: ['', [Validators.required]],
      regionId: ['', [Validators.required]],
      brandId: ['', [Validators.required]],
      platformId: ['', [Validators.required]],
      environment: ['STG', [Validators.required]],
      startDate: [this.fromDate, [Validators.required]],
      endDate: [this.toDate, [Validators.required]],
      testLevel: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.loaderservice.show();

    setTimeout(() => {
      this.loaderservice.hide();
    }, 15000);

    $('#exe_StartDate').attr('max', this.toDate);
    $('#toDate').attr('max', this.toDate);
    this.getAllProjectType(true);
    this.testExecutionForm.controls['brandId'].setValue(0);
    this.testExecutionForm.controls['platformId'].setValue(0);
    Highcharts.setOptions({
      colors: ['#007bff', '#dc3545', '#ffc107']
    });
    this.chartOptions.series = [
      {
        name: 'Passed',
        data: [0, 0, 0],
        color: '#1b7730'
      },
      {
        name: 'Failed',
        data: [0, 0, 0],
        color: '#dc3545'
      }
    ];
    this.updateFlag = true;
  }

  onFormSubmit() {
    this.testCaseExecutionReport();
  }

  changeRegion(e) {
    this.dataservice.isfromWidget = false;
    if (!(e.target.value == '0')) {
      let regionName = this.regionList.filter(region => {
        return region.regionId == parseInt(e.target.value);
      });
      this.regionName = regionName[0].regionName;
      console.log(this.regionName);
      this.getBrandsByRegionId(parseInt(e.target.value));
      this.sharedDataService.setRegion(parseInt(e.target.value));
    } else {
      this.getBrandsByRegionId(0);
      this.sharedDataService.setRegion(parseInt(e.target.value));
    }
  }

  changeBrand(e) {
    this.dataservice.isfromWidget = false;
    this.brandId = parseInt(e.target.value);
  }

  onEnviornmentChange(e) {
    this.dataservice.isfromWidget = false;
  }

  changePlatform(e) {
    this.dataservice.isfromWidget = false;
    let platformId = parseInt(e.target.value);
    this.testExecutionForm.controls['platformId'].setValue(
      parseInt(e.target.value)
    );
  }

  onToDateChange(e) {
    this.toDate = this.datePipe.transform(e.target.value, 'yyyy-MM-dd');
  }

  onFromDateChange(e) {
    this.fromDate = this.datePipe.transform(e.target.value, 'yyyy-MM-dd');
    let fromDate = this.datePipe.transform(e.target.value, 'yyyy-MM-dd');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(fromDate).getMonth();
    let currentyear1 = new Date(fromDate).getFullYear();
    if (
      new Date(this.datePipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ==
      new Date(this.datePipe.transform(fromDate, 'yyyy-MM-dd')).getTime()
    ) {
      let maxDate = fromDate;
      // fromDate = moment(fromDate).subtract(1, 'months').format('YYYY-MM-DD');
      $('#toDateTestCase').attr(
        'min',
        this.datePipe.transform(fromDate, 'yyyy-MM-dd')
      );
      $('#toDateTestCase').attr(
        'max',
        this.datePipe.transform(maxDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        let maxDate = fromDate;
        // fromDate = moment(fromDate).subtract(1, 'months').format('YYYY-MM-DD');
        $('#toDateTestCase').attr(
          'min',
          this.datePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#toDateTestCase').attr(
          'max',
          this.datePipe.transform(currentDate, 'yyyy-MM-dd')
        );
      } else {
        // fromDate = moment(fromDate).subtract(1, 'months').format('YYYY-MM-DD');
        let toDate = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        let date_1 = new Date(
          this.datePipe.transform(currentDate, 'yyyy-MM-dd')
        ).getTime();
        let date_2 = new Date(
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        ).getTime();

        if (date_2 > date_1) {
          toDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
        }
        $('#toDateTestCase').attr(
          'min',
          this.datePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#toDateTestCase').attr(
          'max',
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        );
        this.testExecutionForm.controls['endDate'].setValue(toDate);
      }
    }
  }

  testCaseExecutionReport() {
    this.isfromOninit = false;
    if (this.testExecutionForm.invalid) {
      return;
    } else {
      // this.loaderservice.hide();

      setTimeout(() => {
        this.loaderservice.hide();
      }, 3000);
      this.dataservice
        .getTestCasePassFailCountDashboard(this.testExecutionForm.value)
        .subscribe(
          (exeRes: any) => {
            this.loaderservice.show();
            this.load = false;
            let startDate = new Date(this.selectedStartDate);
            let endDate = new Date(this.selectedEndDate);
            let totalDates = this.getDates(startDate, endDate);
            console.log('exeRes,,.....', exeRes);
            // this.load=false;
            this.error = { isError: false, errorMessage: '' };
            this.exe_status_fail = [];
            this.exe_status_pass = [];
            this.exe_status_total = [];
            this.barChartLabels = [];
            this.exe_status_unknown = [];
            this.pass_android = [];
            this.count_fail = [];
            this.pass_ios = [];
            this.fail_ios = [];
            this.count_pass = [];
            var currBrand = '';
            var currentDevice = '';

            totalDates.forEach(element => {
              this.barChartLabels.push(element);
            });
            if (exeRes.length > 0) {
              this.barChartLabels.forEach(element => {
                var pass_count = exeRes.filter(item => item.date == element);
                let totalPassCount = 0;
                for (let i = 0; i < pass_count.length; i++) {
                  totalPassCount = totalPassCount + pass_count[i].passCount;
                }
                this.count_pass.push(totalPassCount);
                var fail_count = exeRes.filter(item => item.date == element);
                let totalFailCount = 0;
                for (let i = 0; i < fail_count.length; i++) {
                  totalFailCount = totalFailCount + fail_count[i].failCount;
                }
                this.count_fail.push(totalFailCount);
              });
            } else {
              this.toastr.info('No data found');
            }

            (this.chartOptions.xAxis = {
              categories: this.barChartLabels
            }),
              (this.chartOptions.series = [
                {
                  name: 'Passed',
                  data: this.count_pass,
                  color: '#1b7730'
                },
                {
                  name: 'Failed',
                  data: this.count_fail,
                  color: '#dc3545'
                }
              ]);
            this.updateFlag = true;
          },
          error => {
            this.toastr.error('Something went Wrong');
          }
        );

      console.log('success');
    }
  }

  async getAllProjectType(isfromOninit) {
    this.isfromOninit = isfromOninit;
    await this.dataservice
      .getAllProjectType()
      .toPromise()
      .then(async response => {
        // Success
        this.projectTypeList = response;
        console.log('this.projectTypeList.length');
        console.log(this.projectTypeList.length);
        if (response != null) {
          this.testExecutionForm.controls['projectTypeId'].setValue(
            this.projectTypeList[0].projectTypeId
          );
          this.getPlatformByProjectTypeId(
            this.projectTypeList[0].projectTypeId
          );
          await this.getAllRegion(this.projectTypeList[0].projectTypeId);
        }
      });
  }

  async getPlatformByProjectTypeId(projectTypeId) {
    await this.dataservice.getPlatformByProjectTypeId(2).subscribe(
      (response: {}) => {
        this.platformList = response;
        console.log(this.platformList);

        if (this.platformList != null) {
          if (this.platformList.length == 1) {
            this.testExecutionForm.controls['platformId'].setValue(
              this.platformList[0].platformId
            );
          } else {
            this.testExecutionForm.controls['platformId'].setValue(0);
          }
        }
      },
      error => {
        console.log(error.message);
        this.toastr.error('Something went Wrong ');
      }
    );
  }
  getAllRegion(Projecttypeid) {
    this.dataservice
      .getAllRegionByProjectType(Projecttypeid)
      .subscribe(async (response: {}) => {
        this.regionList = response;
        this.regionList = orderBy(this.regionList, ['regionCode'], ['asc']);
        let index = this.regionList.findIndex(
          item => item.regionName === 'NAR'
        );
        if (index < 0) {
          index = index + 1;
        }
        console.log('index=====', index);
        if (response != null) {
          console.log('region id' + this.regionList[index].regionId);
          this.testExecutionForm.controls['regionId'].setValue(
            this.regionList[index].regionId
          );
          this.regionName = this.regionList[0].regionName;
          this.getBrandsByRegionId(
            this.testExecutionForm.get('regionId').value
          );
        }
      });
  }

  async getBrandsByRegionId(regionId) {
    await this.dataservice
      .getBrandsByRegionId(regionId)
      .toPromise()
      .then((response: {}) => {
        this.brandList = response;
        this.brandList = orderBy(this.brandList, ['brandName'], ['asc']);
        if (response != null) {
          if (this.brandList) {
            this.regionId = this.testExecutionForm.get('regionId').value;
            this.projecttypeId = this.testExecutionForm.get(
              'projectTypeId'
            ).value;
            this.brandId = this.brandList[0].brandId;
            this.testExecutionForm.controls['brandId'].setValue(0);
            console.log('this.isfromOninit.............', this.isfromOninit);
            this.loaderservice.show();
            if (this.isfromOninit) {
              this.testExecutionForm.controls['brandId'].setValue(0);
              this.testCaseExecutionReport();
            }
          }
        } else {
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }

  getMinFromDate(): string {
    const currentDate = new Date();
    const minDate = new Date();
    minDate.setDate(currentDate.getDate() - 30);
    return minDate.toISOString().split('T')[0];
  }

  getMaxFromDate(): string {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }

  getMinToDate() {
    const currentDate = new Date();
    const minDate = new Date();
    minDate.setDate(currentDate.getDate() - 30);
    return minDate.toISOString().split('T')[0];
  }

  getMaxToDate() {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }

  changeProjectType(e) {
    this.dataservice.isfromWidget = false;
    console.log(e);

    this.projecttypeId = e.target.value;
    console.log(this.projecttypeId);

    this.regionList = [];
    this.brandList = [];
    this.brandId = 0;
    this.projectNameList = [];
    this.testExecutionForm.controls['regionId'].setValue('');
    let objProjectType = this.projectTypeList.filter(
      pl => pl.projectTypeId == this.projecttypeId
    );

    this.projectTypeName = objProjectType[0].projectTypeName;
    console.log('project type -->', this.projectTypeName);
    if (this.projectTypeName == Constvar.API) {
      this.apiUiFlag = true;
      this.getAllRegion(parseInt(e.target.value));
    } else {
      this.apiUiFlag = false;
      this.sharedDataService.setSelectedProjectType(e.target.value);
      this.getPlatformByProjectTypeId(parseInt(e.target.value));
      this.getAllRegion(parseInt(e.target.value));
    }
  }

  getDates(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(endDate);
    while (currentDate >= startDate) {
      let datetoadd = moment(currentDate).format('YYYY-MM-DD');
      dates.push(datetoadd);
      currentDate.setDate(currentDate.getDate() - 1);
    }
    return dates;
  }

  isEndDateSelectable = (date: Date): boolean => {
    return !this.fromDate || (date >= this.fromDate && date <= new Date());
  };
}
