<div>
    <form [formGroup]="weeklyTSCountStatusform" (ngSubmit)="weeklyTSCountStatusformSubmit()">
        <div class="row">
            <div class="form-group col-md-6">
                <select class="txtControlCss col-md-12" id="regionId" formControlName="regionId"
                    (change)="changeRegion($event)">
                    <option value="" disabled selected>Region</option>
                    <option [value]="region.regionId" *ngFor="let region of this.regionList">
                        {{region.regionCode}}</option>
                </select>
                <span class="error" *ngIf=" this.isSubmitted && !weeklyTSCountStatusform.controls['regionId'].valid">
                    Region is required</span>
            </div>



            <div class="form-group col-md-6">
                <select class="txtControlCss  col-md-12" id="Brand" (change)="changeBrand($event)"
                    formControlName="brandId">
                    <option value="0" selected>All</option>
                    <option [value]="brand.brandId" *ngFor="let brand of this.brandList">
                        {{brand.brandName}}</option>
                </select>
                <span class="error" *ngIf=" this.isSubmitted && !weeklyTSCountStatusform.controls['brandId'].valid">
                    Brand is required</span>
            </div>

            <div class="form-group col-md-6" *ngIf="!this.apiUiFlag">
                <select class="txtControlCss col-md-12" id="PLATFORM"  (change)="changePlatform($event)"
                    style="font-size: 12px;" formControlName="platformId">
                    <option *ngIf="this.platformList.length > 1" value="0" selected>All</option>
                    <option [value]="platform.platformId" *ngFor="let platform of platformList">
                        {{platform.platformName}}</option>
                </select>
                <span class="error" *ngIf=" this.isSubmitted && !weeklyTSCountStatusform.controls['platformId'].valid">
                    Platform is required</span>
            </div>


            <div class="col-md-6">
                <select class="txtControlCss  col-md-12" id="ENV" (change)="changeEnviornment($event)" style="font-size: 12px;"
                    formControlName="environment">
                    <option value="" disabled selected>ENV</option>
                    <option [value]="env" *ngFor="let env of environmentList">
                        {{env}}
                    </option>
                </select>
                <span class="error" *ngIf=" this.isSubmitted && !weeklyTSCountStatusform.controls['environment'].valid">
                    Environment is required</span>
            </div>

            <!-- modified code -->
            <div class="col-md-6">
                <select class="txtControlCss col-md-12" id="testLevel" (change)="changeTestLevel($event)" style="font-size: 12px;"
                    formControlName="testLevel" [(ngModel)]="selectOption">

                    <option *ngFor="let testLevel of testlevellist" [value]="testLevel.value">
                        {{testLevel.viewValue}}
                    </option>
                </select>
                <span class="error" *ngIf=" this.isSubmitted && !weeklyTSCountStatusform.controls['testLevel'].valid">
                    Test Level is required</span>

            </div>

            <div class="col-md-6">
                <select class="txtControlCss col-md-12" id="appName" (change)="onAppSelection($event)"  style="font-size: 12px;"
                    formControlName="appName" [(ngModel)]="selectedValueApps">
                    <option value="All" selected>All Apps</option>
                    <option *ngFor="let app of appList" >{{ app }}</option>
                </select>
                 <span class="error" 
                    *ngIf=" this.isSubmitted && !weeklyTSCountStatusform.controls['appName'].valid">
                     App Name is required</span>
            </div>
              <!-- Modified code -->
              <div class="col-md-6" style="margin-top:5px;">
                <div *ngIf="selectOption === 'Compatibility'">
                  <select class="txtControlCss col-md-12" id="deviceName" style="font-size: 12px;" 
                  formControlName="deviceName" [(ngModel)]="selectedDevice">
                      <option value="All" selected>All Devices</option>
                      <option *ngFor="let device of deviceList" >{{ device }}</option>
                  </select>
                </div>
                  <span class="error"
                  *ngIf=" this.isSubmitted && !weeklyTSCountStatusform.controls['deviceName'].valid">
                  Device Name is required</span>
              </div>
             
            
              
          
            <div class="form-group col-md-6" style="margin-top:5px;">
              <div *ngIf="selectOption === 'Compatibility'">
                    <select class="txtControlCss col-md-12" id="OSversion"
                        style="font-size: 12px;" formControlName="osVersion" [(ngModel)]="selectedValue">
                        <option value="All" selected>All OS</option>
                        <option *ngFor="let item of dataVariable" >{{ item }}</option>
                    </select>
                  </div>
                <span class="error"
                    *ngIf=" this.isSubmitted && !weeklyTSCountStatusform.controls['osVersion'].valid">
                     OS VERSION is required</span>
                </div>
          

            <div class="col-md-6">
                <label for="startDate" class="txtControlLblCss">From<span>*</span></label>
                <div class="exmp-wrp " style="margin-left:2px;">
                    <div class="input-group btn-wrp">
                        <input type="date" class="btn-clck" formControlName="startDate"
                            (change)="onFromDateChange($event)" [min]="fromDate" [max]="getMaxDate()" id="exe_StartDate"
                            name="widget_From_date" placeholder="From">
                    </div>
                    <span class="input-group-btn ">
                        <button class="btn1  " type="text"><span class="paddingLefiRightCalender"><img
                                    src="assets/images/calendarr.png"></span></button>
                    </span>
                </div>
            </div>
            <div class="col-md-6">
                <label for="endDate" class="txtControlLblCss">To<span>*</span></label>

                <div class="exmp-wrp " style="margin-left:2px;">
                    <div class="input-group btn-wrp">
                        <input type="date" class="btn-clck " formControlName="endDate" id="toDate"
                            (change)="onToDateChange($event)" name="endDate" placeholder="To" [min]="getMinDate()"
                            [max]="getMaxDate()" [disabled]="!fromDate">
                    </div>
                    <span class="input-group-btn ">
                        <button class="btn1  " type="text"><span class="paddingLefiRightCalender"><img
                                    src="assets/images/calendarr.png"></span></button>
                    </span>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end col-md-12 mt-4 p-0">
            <button type="submit" class="btn runBtnCss">Run</button>
        </div>
    </form>
    <div class="row mt-2">
        <div class="col-md-8 txt-color" style="font-weight: 600;">
            Test Case Execution Count {{selectedTestLevel}}{{selectedDate1}}{{selectedDate2}}
        </div>
        <div class="chart-container  col-md-12" id="barchart">
        </div>
    </div>

    <div class="d-flex overlay " *ngIf="this.load==true">
        <div class="monthlyloading">Loading&#8230;</div>
    </div>

    <div class="chart-container  col-md-12">
        <highcharts-chart [(update)]="updateFlag" [Highcharts]="highcharts" [options]="chartOptions"
            style="width: 100%; height: 350px; display: block;">
        </highcharts-chart>
    </div>
</div>