<div class="container">
    <!-- Modal -->
    <div class="modal fade" id="viewStatusModal" role="dialog">
        <div class="modal-dialog  modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="commonBorderBottom marginLeftRightCss row mt-2">
                    <div class="ml-3">
                       
                       <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                        <a id="txtExtendedReportTitle" class="active nav-link  TabTxt txtExtendedReportTitle" href="#TestCasePassFailReport" role="tab"
                            data-toggle="tab"><b>Test-Case Execution Console</b></a>
                        </li>

                        <li class="nav-item" *ngIf="this.pop.Job_arn ==undefined || this.pop.Job_arn == null">
                        <a class="nav-link TabTxt jenkinsBuildlog" (click) = "check_isJenkinsLog()" href="#JenkinsBuildLogReport" role="tab"
                            data-toggle="tab"><b>Jenkins Build log Console</b></a>
                        </li>

                    </ul>
                    </div>
                    <div class="ml-auto mr-3">
                        <button type="button" class="close" (click)="closeModal()">&times;</button>
                        <button type="button" class="close hide" data-dismiss="modal" >&times;</button>

                    </div>
                </div>

                <div class="tab-content">
                    <div role="tabpanel" class="tab-pane active" id="TestCasePassFailReport"> 
                        <div class="row marginCss paddingCss whiteBackgroundCss mt-2 modal-body"
                             style="height: 500px; overflow-y:auto;">
                                <div class="col-md-12 paddingRightCss paddingLeftCss">
                                    <table class="table table-striped table-bordered">
                                        <colgroup>
                                            <col>
                                            <col>
                                            <col>
                                        </colgroup>
                                        <tbody>
                                            <ng-container
                                                *ngFor="let listdata of this.pop.viewStatusData , let j=index;let index=index; let odd=odd; let even=even;">
                                                    <tr [ngClass]="{ odd: odd, even: even }" style="background-color: #f2f2f2;">
                                                        <td class="sheduletablestyle">
                                                             <div>
                                                            <span  class="ml-2"
                                                            (click)="expandCollapse(listdata.testSuiteId +''+ j, 'r','ico', listdata.devices.length)"><i
                                                            [id]="'ico'+listdata.testSuiteId+''+j"
                                                            class="caret caret-down"></i></span>
                                                            {{listdata.testSuiteName}}
                                                             </div>
                                                        </td>
                                                     </tr>
                                                    <tr style="background-color: white;"
                                                         *ngFor="let devices of listdata.devices,let i=index;let index=index;"
                                                        [id]="'r'+listdata.testSuiteId+''+j+''+i" class="in">
                                                        <td style="border-left: none;">
                                                            <table class="childTable  "
                                                                 style="margin-left: 20px !important; width:92% !important;">
                                                                     <tbody >
                                                                            <tr [ngClass]="{ odd: odd, even: even }">
                                                                                <td class="sheduletablestyle">
                                                                                    <div>
                                                                                     <span class="ml-2"
                                                                                        (click)="expandCollapse(listdata.testSuiteId +''+ i, 'n_r','n_ico', listdata.devices.length)"><i
                                                                                        [id]="'n_ico'+listdata.testSuiteId+''+i"
                                                                                        class="caret caret-down"></i></span>
                                                                                        {{devices.templateName}}
                                                                                     </div>
                                                                                </td>
                                                                            </tr>
                                                                             <table class="table-responsive">
                                                                                <tbody [id]="'n_r'+listdata.testSuiteId+''+i" class="in"
                                                                                        style="width: 100% !important;" class="table-responsive">
                                                                                        <tr class="tblexec-row" style="width: 100% !important;"
                                                                                             *ngFor="let case of devices.cases ,let i=index;let index=index;">
                                                                                             <td style="min-width: 387px !important;">
                                                                                                 {{case.testCaseName}}</td>
                                                                                            <td style="min-width: 187px !important;">
                                                                                            <span class="statusCss1" *ngIf="case.caseStatus==1">
                                                                                                  In
                                                                                                 Progress</span>
                                                                                            <span class="statusCss1" *ngIf="case.caseStatus==2">
                                                                                                Passed</span>
                                                                                            <span class="statusCss1" *ngIf="case.caseStatus==3">
                                                                                                 Failed
                                                                                             </span>
                                                                                        </td>
                                                                                        <td style="text-align: center;min-width: 87px !important; ">
                                                                                        <div class="spinner-grow spinner-grow-sm text-primary "
                                                                                             *ngIf="case.caseStatus==1"></div>
                                                                                            <span *ngIf="case.caseStatus==2"
                                                                                            class="passedCss "></span>
                                                                                            <span *ngIf="case.caseStatus==3"
                                                                                            class="failedCss "></span>
                                                                                        </td>
                                                                                    </tr>
                                                                            </tbody>
                                                                         </table>
                                                                    </tbody>
                                                             </table>
                                                        </td>
                                                    </tr>
                                            </ng-container>
                                        </tbody>
                                     </table>
                                </div>
                         </div>   
                    </div>
                    <div role="tabpanel" class="tab-pane" id="JenkinsBuildLogReport">
                        <div class="mr-3" style="text-align: right;">
                            <a data-toggle="modal" class="text-right" 
                           title="Re-execute job"><span class="font-weight-bold mr-2">Refresh Logs</span><img (click)="this.pop.refreshJenkinsBuildLogs(this.pop.jobData,this.pop.strJobName)" src="assets/images/refresh-ccw.png"></a>
                       </div>
                        <div class="row marginCss paddingCss whiteBackgroundCss mt-2 modal-body"
                        style="height: 800px; overflow-y:auto;">
                        
                        <div class="col-md-20 paddingRightCss paddingLeftCss mt-2">
                                <table class="table table-striped">
                                     <div *ngFor="let item of this.pop.viewJenkinsStatusData | keyvalue">
                                         <pre> {{item.value}} </pre> 
                                        </div>
                            
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
        </div>
    </div>
</div>
    
