import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../shared/services/data.service';
import { orderBy } from 'lodash';
import { LoaderService } from '../../../shared/services/loader.service';
// import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';
import * as Highcharts from 'highcharts';
// import * as moment from 'moment';
import moment from 'moment';
// import * as $ from 'jquery';
import $ from 'jquery';
import { DatePipe } from '@angular/common';
import {
  FormArray,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
@Component({
  selector: 'wtaf-device-test-cases-report',
  templateUrl: './device-test-cases-report.component.html',
  styleUrls: ['./device-test-cases-report.component.css']
})
export class DeviceTestCasesReportComponent implements OnInit {
  testCaseDeviceComparionForm: UntypedFormGroup;
  regionList: any = [];
  regionName: string = '';
  brandList: any = [];
  regionId: Number = 0;
  fromDate: any;
  projecttypeId: any;
  brandId: Number = 0;
  isfromOninit: boolean = false;
  projectTypeList: any = [];
  platformList: any = [];
  environmentList = ['STG', 'Dev', 'Prod', 'QA'];
  caseSuiteList = ['TestCase', 'TestSuite'];
  highcharts: any = Highcharts;
  updateFlag = true;
  toDate: any;
  error: any = { isError: false, errorMessage: '' };
  exe_status_fail = [];
  exe_status_pass = [];
  exe_status_total = [];
  barChartLabels = [];
  exe_status_unknown = [];
  pass_android = [];
  count_fail = [];
  pass_ios = [];
  fail_ios = [];
  count_pass=[];
  chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'column'
    },
    xAxis: {
      categories: ['Whirlpool', 'Jennair', 'KitchenAid']
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Test Case Count'
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: 'bold',
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            'gray'
        }
      }
    },

    mapNavigation: {
      enableMouseWheelZoom: true
    },
    title: {
      text: ''
    },

    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true
        }
      }
    },

    series: []
  };

  constructor(
    private dataservice: DataService,
    public loaderservice: LoaderService,
    public toastr: ToastrService,
    public fb: UntypedFormBuilder,
    public datePipe: DatePipe,
  ) {
    let nowDate = new Date();
    this.toDate = this.datePipe.transform(nowDate, 'yyyy-MM-dd');
    this.fromDate = moment()
      .subtract('months', 1)
      .format('YYYY-MM-DD'); 
    this.testCaseDeviceComparionForm = this.fb.group({
      projectTypeId: ['', [Validators.required]],
      regionId: ['', [Validators.required]],
      brandId: ['', [Validators.required]],
      platformId: ['', [Validators.required]],
      environment: ['STG', [Validators.required]],
      tableName:['TestCase',[Validators.required]],
      startDate: [this.fromDate, [Validators.required]],
      endDate: [this.toDate, [Validators.required]]
    });

  }

  ngOnInit() {

    
    this.loaderservice.show() 

    setTimeout(()=>{
      this.loaderservice.hide()
    },15000)



    $('#exe_StartDate').attr('max', this.toDate);
    $('#toDateID').attr('max', this.toDate);
    this.getAllProjectType(true);
    this.testCaseDeviceComparionForm.controls['brandId'].setValue(0);
    this.testCaseDeviceComparionForm.controls['platformId'].setValue(0);
    Highcharts.setOptions({
      colors: ['#007bff', '#dc3545', '#ffc107']
    });
    this.chartOptions.series = [
      {
        name: 'Passed',
        data: [0, 0, 0],
        color: '#1b7730'
      },
      {
        name: 'Failed',
        data: [0, 0, 0],
        color: '#AF4E3F'
      }
    ];
    this.updateFlag = true;
  }

  changeRegion(e) {
    this.dataservice.isfromWidget = false;
    if (!(e.target.value == '0')){
      let regionName = this.regionList.filter(region => {
        return region.regionId == parseInt(e.target.value);
      });
      this.regionName = regionName[0].regionName;
      console.log(this.regionName);
      this.getBrandsByRegionId(parseInt(e.target.value));

    }else{
      this.getBrandsByRegionId(0);
    }
    this.deviceExecutionComparionSubmit();


  }

  changeBrand(e) {
    this.dataservice.isfromWidget = false;
    this.brandId = parseInt(e.target.value);
    this.deviceExecutionComparionSubmit();
  }

  onEnviornmentChange(e) {
    this.dataservice.isfromWidget = false;
    this.deviceExecutionComparionSubmit();
  }

  onCaseChange(e) {
    this.dataservice.isfromWidget = false;
    this.deviceExecutionComparionSubmit();


  }

  changePlatform(e) {
    this.dataservice.isfromWidget = false;
    let platformId = parseInt(e.target.value);
    this.testCaseDeviceComparionForm.controls['platformId'].setValue(
      parseInt(e.target.value)
    );
    this.deviceExecutionComparionSubmit();
  }

  onToDateChange(e) {
    this.toDate=this.datePipe.transform(e.target.value, 'yyyy-MM-dd');
    this.deviceExecutionComparionSubmit();
  }

  onFromDateChange(e) {
    let fromDate = this.datePipe.transform(e.target.value, 'yyyy-MM-dd');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(fromDate).getMonth();
    let currentyear1 = new Date(fromDate).getFullYear();
    if (
      new Date(this.datePipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ==
      new Date(this.datePipe.transform(fromDate, 'yyyy-MM-dd')).getTime()
    ) {
      let maxDate = fromDate;
      $('#toDateID').attr(
        'min',
        this.datePipe.transform(fromDate, 'yyyy-MM-dd')
      );
      $('#toDateID').attr(
        'max',
        this.datePipe.transform(maxDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        let maxDate = fromDate;
        $('#toDateID').attr(
          'min',
          this.datePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#toDateID').attr(
          'max',
          this.datePipe.transform(currentDate, 'yyyy-MM-dd')
        );
      } else {
        let toDate = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        let date_1 = new Date(
          this.datePipe.transform(currentDate, 'yyyy-MM-dd')
        ).getTime();
        let date_2 = new Date(
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        ).getTime();

        if (date_2 > date_1) {
          toDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
        }
        $('#toDateID').attr(
          'min',
          this.datePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#toDateID').attr(
          'max',
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        );
        this.testCaseDeviceComparionForm.controls['endDate'].setValue(toDate);
      }
    }
    this.deviceExecutionComparionSubmit();
  }

  deviceExecutionComparionSubmit(){
    this.isfromOninit = false;
    if (this.testCaseDeviceComparionForm.invalid) {
      return;
    } else {
      // this.loaderservice.hide();
      setTimeout(() => {
        this.loaderservice.hide();
    }, 3000);
      this.dataservice
        .deviceTestCaseStatus(this.testCaseDeviceComparionForm.value)
        .subscribe(
          (exeRes: any) => {
            console.log('exeRes,,.....', exeRes);
            // this.load=false;
            this.error = { isError: false, errorMessage: '' };
            this.exe_status_fail = [];
            this.exe_status_pass = [];
            this.exe_status_total = [];
            this.barChartLabels = [];
            this.exe_status_unknown = [];
            this.pass_android = [];
            this.count_fail = [];
            this.pass_ios = [];
            this.fail_ios = [];
            this.count_pass=[];
            var currBrand="";
            var currentDevice="";

            if (exeRes.length > 0) {
              this.barChartLabels = exeRes.reduce(function(acc, curr) {
             if (!acc.includes(curr.deviceName)) acc.push(curr.deviceName);
                return acc;
              }, []);

              this.barChartLabels.forEach(element => {
                var pass_count = exeRes.filter(
                  item => item.deviceName == element
                );
                let totalPassCount=0;
                for(let i=0;i<pass_count.length;i++){
                  totalPassCount=totalPassCount+pass_count[i].passCount;
                }
                this.count_pass.push(totalPassCount);
                var fail_count = exeRes.filter(
                  item =>
                    item.deviceName == element
                );
                let totalFailCount=0;
                for(let i=0;i<fail_count.length;i++){
                  totalFailCount=totalFailCount+fail_count[i].failCount;
                }
                this.count_fail.push(totalFailCount);
              });
            } else {
              this.toastr.info('No data found');
            }

            (this.chartOptions.xAxis = {
              categories: this.barChartLabels
            }),
              (this.chartOptions.series = [
                {
                  name: 'Passed',
                  data: this.count_pass,
                  color: '#1b7730'
                },
                {
                  name: 'Failed',
                  data: this.count_fail,
                  color: '#AF4E3F'
                }
              ]);
            this.updateFlag = true;
          },
          error => {
            this.toastr.error('Something went Wrong');
          }
        );

      console.log('success');
    }
  }

  async getAllProjectType(isfromOninit) {
    this.isfromOninit = isfromOninit;
    await this.dataservice
      .getAllProjectType()
      .toPromise()
      .then(async response => {
        // Success
        this.projectTypeList = response;
        console.log('this.projectTypeList.length');
        console.log(this.projectTypeList.length);
        if (response != null) {
          this.testCaseDeviceComparionForm.controls['projectTypeId'].setValue(
            this.projectTypeList[0].projectTypeId
          );
          this.getPlatformByProjectTypeId(
            this.projectTypeList[0].projectTypeId
          );
          await this.getAllRegion(this.projectTypeList[0].projectTypeId);
        }
      });
  }

  async getPlatformByProjectTypeId(projectTypeId) {
    await this.dataservice.getPlatformByProjectTypeId(2).subscribe(
      (response: {}) => {
        this.platformList = response;
        console.log(this.platformList);

        if (this.platformList != null) {
          if (this.platformList.length == 1) {
            this.testCaseDeviceComparionForm.controls['platformId'].setValue(
              this.platformList[0].platformId
            );
          } else {
            this.testCaseDeviceComparionForm.controls['platformId'].setValue(0);
          }
        }
      },
      error => {
        console.log(error.message);
        this.toastr.error('Something went Wrong ');
      }
    );
  }
  getAllRegion(Projecttypeid) {
    this.dataservice
      .getAllRegionByProjectType(Projecttypeid)
      .subscribe(async (response: {}) => {
        this.regionList = response;
        this.regionList = orderBy(this.regionList, ['regionCode'], ['asc']);
        let index = this.regionList.findIndex(
          item => item.regionName === 'NAR'
        );
        if (index < 0) {
          index = index + 1;
        }
        console.log('index=====', index);
        if (response != null) {
          console.log('region id' + this.regionList[index].regionId);
          this.testCaseDeviceComparionForm.controls['regionId'].setValue(
            this.regionList[index].regionId
          );
          this.regionName = this.regionList[0].regionName;
          this.getBrandsByRegionId(
            this.testCaseDeviceComparionForm.get('regionId').value
          );
        }
      });
  }

  async getBrandsByRegionId(regionId) {
    await this.dataservice
      .getBrandsByRegionId(regionId)
      .toPromise()
      .then((response: {}) => {
        this.brandList = response;
        this.brandList = orderBy(this.brandList, ['brandName'], ['asc']);
        if (response != null) {
          if (this.brandList) {
            this.regionId = this.testCaseDeviceComparionForm.get('regionId').value;
            this.projecttypeId = this.testCaseDeviceComparionForm.get(
              'projectTypeId'
            ).value;
            this.brandId = this.brandList[0].brandId;
            console.log('this.isfromOninit.............', this.isfromOninit);
            this.loaderservice.show();
            if (this.isfromOninit) {
              this.deviceExecutionComparionSubmit();
            }
          }
        } else {
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }



  getMinDate(): string {
    const currentDate = new Date();
    const minDate = new Date();
    minDate.setDate(currentDate.getDate() - 30);
    return minDate.toISOString().split('T')[0];
  }

  getMaxDate():string{
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }


}