import { Component, OnInit } from '@angular/core';

import {
  FormArray,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { DataService } from '../../../shared/services/data.service';
import { DatePipe } from '@angular/common';
// import * as moment from 'moment';
import moment from 'moment';
// import * as $ from 'jquery';
import $ from 'jquery';
// import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';
import * as Highcharts from 'highcharts';
import { orderBy } from 'lodash';
import { Constvar } from '../../../models/constVar';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { element } from 'protractor';
import { Console } from 'console';
import { LoaderService } from '../../../shared/services/loader.service';
import { HttpClient } from '@angular/common/http';

interface testLevel {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'wtaf-report-test-cases',
  templateUrl: './report-test-cases.component.html',
  styleUrls: ['./report-test-cases.component.css']
})
export class ReportTestCasesComponent implements OnInit {
  weeklyReportStatusform: UntypedFormGroup;
  load = false;
  isSubmitted = false;
  apiUiFlag: boolean;
  toDate: any;
  fromDate: any;
  month: any = [];
  month_index: any;
  year: any = [];
  cm: any = [];
  monthlyExeStatus_date_labels: any = [];
  projectTypeList: any = [];
  isfromOninit: boolean = false;
  platformList: any = [];
  regionList: any = [];
  brandList: any = [];
  regionId: Number = 0;
  regionName: string = '';
  projectTypeName: String = '';
  projecttypeId: any;
  brandId: Number = 0;
  dataVariable: any = [];
  appList: any = [];
  deviceList: any = [];
  ossVersion = 'All';
  ddeviceName = 'All d';
  enviornment: string = 'STG';
  testLevel: string = 'Smoke';
  selectedValue: string = 'All';
  selectedValueApps: string = 'All';
  selectedDevice: string = 'All';
  environmentList = ['STG', 'Dev', 'Prod', 'QA'];
  selectOption = 'Smoke';
  selectedDate1: any;
  selectedDate2: any;
  selectedTestLevel: any = '- Smoke :';
  testlevellist: testLevel[] = [
    { value: 'Smoke', viewValue: 'Smoke' },
    { value: 'CICD', viewValue: 'CICD' },
    { value: 'Regression', viewValue: 'Regression' },
    { value: 'Compatibility', viewValue: 'Compatibility' },
    { value: 'Localization', viewValue: 'Localization' },
    { value: 'Trial-Run', viewValue: 'Trial-Run' }
  ];
  yearsMonth = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  error: any = { isError: false, errorMessage: '' };
  isValidDate: any;
  environment_type = 'STG';
  updateFlag = true;
  exe_status_fail = [];
  exe_status_pass = [];
  exe_status_total = [];
  barChartLabels = [];
  exe_status_unknown = [];
  pass_android = [];
  fail_android = [];
  pass_ios = [];
  fail_ios = [];

  highcharts: any = Highcharts;
  chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'column'
    },
    xAxis: {
      categories: ['Whirlpool', 'Jennair', 'KitchenAid']
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Test Case Count'
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: 'bold',
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            'gray'
        }
      }
    },

    mapNavigation: {
      enableMouseWheelZoom: true
    },
    title: {
      text: ''
    },

    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true
        }
      }
    },

    series: []
  };
  constructor(
    public fb: UntypedFormBuilder,
    private dataservice: DataService,
    public datePipe: DatePipe,
    public toastr: ToastrService,
    public loaderservice: LoaderService,
    private http: HttpClient
  ) {
    let nowDate = new Date();
    this.toDate = this.datePipe.transform(nowDate, 'yyyy-MM-dd');
    let toUIDate = this.datePipe.transform(nowDate, 'd MMM yyyy');
    this.selectedDate2 = ' - ' + toUIDate;
    this.fromDate = moment()
      .subtract('months', 1)
      .format('YYYY-MM-DD');
    let fromUIDate = moment()
      .subtract('months', 1)
      .format('DD MMM');
    this.selectedDate1 = ' ' + fromUIDate;
    this.weeklyReportStatusform = this.fb.group({
      projectTypeId: ['', [Validators.required]],
      regionId: ['', [Validators.required]],
      brandId: ['', [Validators.required]],
      platformId: ['', [Validators.required]],
      environment: ['STG', [Validators.required]],
      testLevel: ['', [Validators.required]],
      osVersion: [this.ossVersion, [Validators.required]],
      //  osversion:['',[Validators.required]],
      // month: ['', [Validators.required]]
      startDate: [this.fromDate, [Validators.required]],
      endDate: [this.toDate, [Validators.required]],
      appName: ['All', [Validators.required]],
      deviceName: [this.ddeviceName, [Validators.required]]
    });
  }

  ngOnInit() {
    this.loaderservice.show();

    setTimeout(() => {
      this.loaderservice.hide();
    }, 15000);

    //  this.load=true;
    // this.dataservice.isfromWidget = true;
    $('#monthlyExecStatusFromDate').attr('max', this.toDate);
    $('#monthlyExecStatusToDate').attr('max', this.toDate);
    // this.http.get<number[]>('https://85cv10xbk7.execute-api.us-east-1.amazonaws.com/uat/dashBoard/findAllOsVersion').subscribe(response => {
    // this.dataVariable = response.map(dataVariable=>dataVariable.toString());
    //   });

    console.log(
      'this.monthlyExeStatus_date_labels',
      this.monthlyExeStatus_date_labels
    );
    this.getAllProjectType(true);
    this.weeklyReportStatusform.controls['brandId'].setValue(0);
    this.weeklyReportStatusform.controls['platformId'].setValue(0);
    this.getAllAppName(
      this.weeklyReportStatusform.get('platformId').value,
      this.brandId,
      this.enviornment,
      this.testLevel
    );
    Highcharts.setOptions({
      colors: ['#007bff', '#dc3545', '#ffc107']
    });
    this.chartOptions.series = [
      {
        name: 'Passed Android',
        data: [0, 0, 0],
        color: '#4285F4'
      },
      {
        name: 'Failed Android',
        data: [0, 0, 0],
        color: '#ED4242'
      },
      {
        name: 'Passed IOS',
        data: [0, 0, 0],
        color: '#34A853'
      },
      {
        name: 'Failed IOS',
        data: [0, 0, 0],
        color: '#FBBC04'
      }
      // {
      //   name: 'Unknown',
      //   data: [2, 2, 3]
      // }
    ];
    this.updateFlag = true;
  }

  async getAllProjectType(isfromOninit) {
    this.isfromOninit = isfromOninit;
    await this.dataservice
      .getAllProjectType()
      .toPromise()
      .then(async response => {
        // Success
        this.projectTypeList = response;
        console.log('this.projectTypeList.length');
        console.log(this.projectTypeList.length);
        if (response != null) {
          this.weeklyReportStatusform.controls['projectTypeId'].setValue(
            this.projectTypeList[0].projectTypeId
          );
          this.getPlatformByProjectTypeId(
            this.projectTypeList[0].projectTypeId
          );
          await this.getAllRegion(this.projectTypeList[0].projectTypeId);

          //Modified Code
          // this.getAllOSversion(0);
        }
      });
  }

  getAllRegion(Projecttypeid) {
    this.dataservice
      .getAllRegionByProjectType(Projecttypeid)
      .subscribe(async (response: {}) => {
        this.regionList = response;
        this.regionList = orderBy(this.regionList, ['regionCode'], ['asc']);
        // this.regionList.unshift(
        //   this.regionList.splice(
        let index = this.regionList.findIndex(
          item => item.regionName === 'NAR'
        );
        //     1
        //   )[0]
        // );
        if (index < 0) {
          index = index + 1;
        }
        console.log('index=====', index);
        if (response != null) {
          console.log('region id' + this.regionList[index].regionId);
          this.weeklyReportStatusform.controls['regionId'].setValue(
            this.regionList[index].regionId
          );
          this.regionName = this.regionList[0].regionName;
          this.getBrandsByRegionId(
            this.weeklyReportStatusform.get('regionId').value
          );
          // await this.getBrandsByRegionId(this.regionList[0].regionId);
        }
      });
  }
  async getPlatformByProjectTypeId(projectTypeId) {
    await this.dataservice.getPlatformByProjectTypeId(2).subscribe(
      (response: {}) => {
        this.platformList = response;
        console.log(this.platformList);

        if (this.platformList != null) {
          if (this.platformList.length == 1) {
            // this.weeklyReportStatusform.controls["platformId"].
            this.weeklyReportStatusform.controls['platformId'].setValue(
              this.platformList[0].platformId
            );
          } else {
            this.weeklyReportStatusform.controls['platformId'].setValue(0);
          }
        }
      },
      error => {
        console.log(error.message);
        this.toastr.error('Something went Wrong ');
      }
    );
  }

  async getBrandsByRegionId(regionId) {
    await this.dataservice
      .getBrandsByRegionId(regionId)
      .toPromise()
      .then((response: {}) => {
        this.brandList = response;
        this.brandList = orderBy(this.brandList, ['brandName'], ['asc']);
        if (response != null) {
          if (this.brandList) {
            this.regionId = this.weeklyReportStatusform.get('regionId').value;
            this.projecttypeId = this.weeklyReportStatusform.get(
              'projectTypeId'
            ).value;
            // this.brandList.unshift(
            //   this.brandList.splice(
            //     this.brandList.findIndex(
            //       item => item.brandName.toLowerCase() === 'whirlpool'
            //     ),
            //     1
            //   )[0]
            // );
            this.brandId = this.brandList[0].brandId;
            console.log('this.isfromOninit.............', this.isfromOninit);
            this.loaderservice.show();
            if (this.isfromOninit) {
              this.weeklyReportStatusFormSubmit();
            }
          }
          // this.getAllProjectName(this.regionId, this.brandId, this.projecttypeId)
        } else {
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }

  //Modified Code
  getAllOSversion(platformIdd, brandId, enviornment, regionId, appName) {
    this.dataservice
      .getAllOSversionByIDn(
        platformIdd,
        brandId,
        enviornment,
        regionId,
        appName
      )
      .toPromise()
      .then(async response => {
        this.dataVariable = response;
        console.log(
          'Congratulations you got the os version' + this.dataVariable
        );
      });
  }

  getAllAppName(platformIdd, brandid, enviornment, testLevel) {
    this.dataservice
      .getAllAppNamesByID(platformIdd, brandid, enviornment, testLevel)
      .toPromise()
      .then(async response => {
        this.appList = response;
        console.log(
          'Congratulations you got the app name version' + this.appList
        );
      });
  }

  getAllDeviceNames(platformIdd, brandid, enviornment, testLevel, appName) {
    this.dataservice
      .getAllDeviceNamesByID(
        platformIdd,
        brandid,
        enviornment,
        testLevel,
        appName
      )
      .toPromise()
      .then(async response => {
        this.deviceList = response;
        console.log(
          'Congratulations you got the device list' + this.deviceList
        );
      });
  }

  changeProjectType(e) {
    this.dataservice.isfromWidget = false;
    console.log(
      'this.isfromWidget_ from change',
      this.dataservice.isfromWidget
    );

    console.log(e);
    this.projecttypeId = e.target.value;
    console.log(this.projecttypeId);
    this.regionList = [];
    this.brandList = [];
    this.brandId = 0;
    this.weeklyReportStatusform.controls['regionId'].setValue('');
    let objProjectType = this.projectTypeList.filter(
      pl => pl.projectTypeId == this.projecttypeId
    );

    this.projectTypeName = objProjectType[0].projectTypeName;
    console.log('project type -->', this.projectTypeName);
    if (this.projectTypeName == Constvar.API) {
      this.apiUiFlag = true;
      this.getAllRegion(parseInt(e.target.value));
    } else {
      this.apiUiFlag = false;
      this.getPlatformByProjectTypeId(parseInt(e.target.value));
      this.getAllRegion(parseInt(e.target.value));
    }
  }

  changeRegion(e) {
    this.dataservice.isfromWidget = false;
    let regionName = this.regionList.filter(region => {
      return region.regionId == parseInt(e.target.value);
    });
    this.regionName = regionName[0].regionName;
    console.log(this.regionName);
    this.getBrandsByRegionId(parseInt(e.target.value));
  }

  changeTestLevel(e) {
    this.selectedTestLevel = ' - ' + e.target.value + ' : ';
    this.testLevel = e.target.value;
    if (this.testLevel == 'Compatibility') {
      this.getAllDeviceNames(
        this.weeklyReportStatusform.get('platformId').value,
        this.weeklyReportStatusform.get('brandId').value,
        this.enviornment,
        'Compatibility',
        this.weeklyReportStatusform.get('appName').value
      );

      this.getAllOSversion(
        this.weeklyReportStatusform.get('platformId').value,
        this.weeklyReportStatusform.get('brandId').value,
        this.enviornment,
        this.weeklyReportStatusform.get('regionId').value,
        this.weeklyReportStatusform.get('appName').value
      );
    }

    this.getAllAppName(
      this.weeklyReportStatusform.get('platformId').value,
      this.weeklyReportStatusform.get('brandId').value,
      this.enviornment,
      this.testLevel
    );
    this.weeklyReportStatusform.controls['appName'].setValue('All');
    this.weeklyReportStatusform.controls['deviceName'].setValue('All');
  }

  changeBrand(e) {
    this.selectedTestLevel = '- Smoke :';
    this.dataservice.isfromWidget = false;
    this.brandId = parseInt(e.target.value);
    this.weeklyReportStatusform.controls['appName'].setValue('All');
    this.weeklyReportStatusform.controls['testLevel'].setValue('Smoke');
    this.weeklyReportStatusform.controls['deviceName'].setValue('All');
    this.getAllAppName(
      this.weeklyReportStatusform.get('platformId').value,
      this.brandId,
      this.enviornment,
      'Smoke'
    );
  }

  changeEnviornment(e) {
    this.selectedTestLevel = '- Smoke :';
    this.dataservice.isfromWidget = false;
    this.enviornment = e.target.value;
    this.getAllAppName(
      this.weeklyReportStatusform.get('platformId').value,
      this.weeklyReportStatusform.get('brandId').value,
      this.enviornment,
      this.testLevel
    );
    this.weeklyReportStatusform.controls['appName'].setValue('All');
    this.weeklyReportStatusform.controls['deviceName'].setValue('All');
    this.weeklyReportStatusform.controls['testLevel'].setValue('Smoke');
  }

  changePlatform(e) {
    this.selectedTestLevel = '- Smoke :';
    this.dataservice.isfromWidget = false;
    let platformId = parseInt(e.target.value);
    this.weeklyReportStatusform.controls['platformId'].setValue(
      parseInt(e.target.value)
    );

    console.log('platformId', platformId);

    // this.getAllOSversion(e.target.value);
    this.weeklyReportStatusform.controls['testLevel'].setValue('Smoke');
    this.getAllAppName(
      platformId,
      this.weeklyReportStatusform.get('brandId').value,
      this.enviornment,
      'Smoke'
    );
    this.weeklyReportStatusform.controls['appName'].setValue('All');
    this.weeklyReportStatusform.controls['deviceName'].setValue('All');
  }

  onAppSelection(e) {
    if (this.testLevel == 'Compatibility') {
      this.getAllDeviceNames(
        this.weeklyReportStatusform.get('platformId').value,
        this.weeklyReportStatusform.get('brandId').value,
        this.enviornment,
        this.testLevel,
        this.weeklyReportStatusform.get('appName').value
      );

      this.getAllOSversion(
        this.weeklyReportStatusform.get('platformId').value,
        this.weeklyReportStatusform.get('brandId').value,
        this.enviornment,
        this.weeklyReportStatusform.get('regionId').value,
        this.weeklyReportStatusform.get('appName').value
      );
      this.weeklyReportStatusform.controls['osVersion'].setValue('All');
      this.weeklyReportStatusform.controls['deviceName'].setValue('All');
    }
  }

  onFromDateChange(e) {
    let fromDate = this.datePipe.transform(e.target.value, 'yyyy-MM-dd');
    let fromUIDate = this.datePipe.transform(e.target.value, 'd MMM');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(fromDate).getMonth();
    let currentyear1 = new Date(fromDate).getFullYear();
    this.selectedDate1 = ' ' + fromUIDate;
    if (
      new Date(this.datePipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ===
      new Date(this.datePipe.transform(fromDate, 'yyyy-MM-dd')).getTime()
    ) {
      let maxDate = fromDate;
      fromDate = moment(fromDate)
        .subtract(1, 'months')
        .format('YYYY-MM-DD');
      $('#exe_EndDate').attr(
        'min',
        this.datePipe.transform(fromDate, 'yyyy-MM-dd')
      );
      $('#exe_EndDate').attr(
        'max',
        this.datePipe.transform(maxDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        let maxDate = fromDate;
        fromDate = moment(fromDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let toDate = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#exe_EndDate').attr(
          'min',
          this.datePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#exe_EndDate').attr(
          'max',
          this.datePipe.transform(currentDate, 'yyyy-MM-dd')
        );
        this.weeklyReportStatusform.controls['exe_EndDate'].setValue(
          this.datePipe.transform(currentDate, 'yyyy-MM-dd')
        );
      } else {
        let toDate = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');

        let date_1 = new Date(
          this.datePipe.transform(currentDate, 'yyyy-MM-dd')
        ).getTime();
        let date_2 = new Date(
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        ).getTime();

        if (date_2 > date_1) {
          toDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
        }
        $('#exe_EndDate').attr(
          'min',
          this.datePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#exe_EndDate').attr(
          'max',
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        );
        this.weeklyReportStatusform.controls['exe_EndDate'].setValue(toDate);
      }
    }
  }
  onToDateChange(e) {
    let toDate = this.datePipe.transform(e.target.value, 'yyyy-MM-dd');
    let toUIDate = this.datePipe.transform(e.target.value, 'd MMM yyyy');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(toDate).getMonth();
    let currentyear1 = new Date(toDate).getFullYear();
    this.selectedDate2 = ' - ' + toUIDate;
    if (
      new Date(this.datePipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ===
      new Date(this.datePipe.transform(toDate, 'yyyy-MM-dd')).getTime()
    ) {
      toDate = moment(toDate)
        .subtract(1, 'months')
        .format('YYYY-MM-DD');
      $('#exe_StartDate').attr(
        'min',
        this.datePipe.transform(toDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        toDate = moment(toDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let fromDate = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#exe_StartDate').attr(
          'min',
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        );
        $('#exe_StartDate').attr(
          'max',
          this.datePipe.transform(currentDate, 'yyyy-MM-dd')
        );
      } else {
        toDate = moment(toDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let fromDate = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#exe_StartDate').attr(
          'min',
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        );
        $('#exe_StartDate').attr(
          'max',
          this.datePipe.transform(fromDate, 'yyyy-MM-dd')
        );
      }
    }
  }

  getMaxDate(): string {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }
  getMinDate(): string {
    return this.weeklyReportStatusform.get('startDate').value;
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if (sDate == null || eDate == null) {
      this.error = {
        isError: true,
        errorMessage: 'Start date and end date are required.'
      };
      this.isValidDate = false;
    }

    if (sDate != null && eDate != null && eDate < sDate) {
      this.error = {
        isError: true,
        errorMessage: 'To date should be greater than from date.'
      };
      this.isValidDate = false;
    }
    let currenDate = new Date();
    let curr_date = this.datePipe.transform(currenDate, 'yyyy-MM-dd');
    console.log('curr_date', curr_date);
    if (sDate != null && eDate != null && curr_date < eDate) {
      this.error = {
        isError: true,
        errorMessage:
          'To date should not be greater than today' + "'" + 's date.'
      };
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  GetEnv_type(e) {
    this.environment_type = e.target.value;
  }

  weeklyReportStatusFormSubmit() {
    this.isfromOninit = false;
    if (this.weeklyReportStatusform.invalid) {
      return;
    } else {
      // this.loaderservice.hide();
      setTimeout(() => {
        this.loaderservice.hide();
      }, 3000);
      this.dataservice
        .weeklyTestCaseStatus(this.weeklyReportStatusform.value)
        .subscribe(
          (exeRes: any) => {
            console.log('exeRes,,.....', exeRes);
            // this.load=false;
            let mindate = moment(this.toDate)
              .subtract(24, 'months')
              .format('YYYY-MM-DD');
            this.error = { isError: false, errorMessage: '' };
            this.exe_status_fail = [];
            this.exe_status_pass = [];
            this.exe_status_total = [];
            this.barChartLabels = [];
            this.exe_status_unknown = [];
            this.pass_android = [];
            this.fail_android = [];
            this.pass_ios = [];
            this.fail_ios = [];
            if (exeRes.length > 0) {
              this.barChartLabels = exeRes.reduce(function(acc, curr) {
                if (!acc.includes(curr.brand)) acc.push(curr.brand);
                return acc;
              }, []);
              this.barChartLabels.forEach(element => {
                var android_pass = exeRes.filter(
                  item => item.brand == element && item.status == 'Android Pass'
                );
                if (android_pass.length > 0) {
                  this.pass_android.push(android_pass[0].count);
                } else {
                  this.pass_android.push(0);
                }
                var android_fail = exeRes.filter(
                  item =>
                    item.brand == element && item.status == 'Android failed'
                );
                if (android_fail.length > 0) {
                  this.fail_android.push(android_fail[0].count);
                } else {
                  this.fail_android.push(0);
                }
                var ios_pass = exeRes.filter(
                  item => item.brand == element && item.status == 'IOS Pass'
                );
                if (ios_pass.length > 0) {
                  this.pass_ios.push(ios_pass[0].count);
                } else {
                  this.pass_ios.push(0);
                }
                var ios_fail = exeRes.filter(
                  item => item.brand == element && item.status == 'IOS failed'
                );
                if (ios_fail.length > 0) {
                  this.fail_ios.push(ios_fail[0].count);
                } else {
                  this.fail_ios.push(0);
                }
              });
            } else {
              this.toastr.info('No data found');
            }

            (this.chartOptions.xAxis = {
              categories: this.barChartLabels
            }),
              (this.chartOptions.series = [
                {
                  name: 'Passed Android',
                  data: this.pass_android,
                  color: '#4285F4'
                },
                {
                  name: 'Failed Android',
                  data: this.fail_android,
                  color: '#ED4242'
                },
                {
                  name: 'Passed IOS',
                  data: this.pass_ios,
                  color: '#34A853'
                },
                {
                  name: 'Failed IOS',
                  data: this.fail_ios,
                  color: '#FBBC04'
                }
              ]);
            this.updateFlag = true;
          },
          error => {
            // this.load=false;
            this.toastr.error('Something went Wrong');
          }
        );

      console.log('success');
    }
  }
}
