import { Injectable } from '@angular/core';
// import { EmailValidator } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../models';

import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  uid: string;
  refreshToken: String;
  authToken: String = '1';
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    // this.authToken =this.currentUserSubject.value.token;
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  // Sign up with email/password
  SignUp(email, password) {
    //return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
  }

  // logUserIn(email, pass) {
  //   //return this.afAuth.auth.signInWithEmailAndPassword(email, pass)
  //   return this.http.post<any>(`/users/authenticate`, { email, pass })
  //           .pipe(map(user => {
  //               // login successful if there's a jwt token in the response
  //               if (user && user.token) {
  //                   // store user details and jwt token in local storage to keep user logged in between page refreshes
  //                   localStorage.setItem('currentUser', JSON.stringify(user));
  //                   this.currentUserSubject.next(user);
  //               }

  //               return user;
  //           }));
  // }

  getToken() {
    //return this.afAuth.auth.currentUser.getIdToken(true);
  }

  getCurrentUser() {
    //var loggedUser = this.afAuth.auth.currentUser;
    //console.log('getCurrentUser-->', loggedUser);
    //return loggedUser;
  }

  emailIdExistsCheck(email) {
    // return this.afAuth.auth.fetchSignInMethodsForEmail(email);
  }

  //   login(username: string, password: string) {
  //     const apiURL =
  //     environment.urlConfig.baseUrl + 'authenticates';
  //
  //     return this.http.post<any>(apiURL, { username, password })
  //         .pipe(map(user => {
  //             // login successful if there's a jwt token in the response
  //             if (user && user.token) {
  //                 // store user details and jwt token in local storage to keep user logged in between page refreshes
  //                 localStorage.setItem('currentUser', JSON.stringify(user));
  //                 this.currentUserSubject.next(user);
  //             }

  //             return user;
  //         }));
  // }
  login(user) {
    // console.log("USER DETAILS",user)
    // login successful if there's a jwt token in the response
    if (user.token) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('currentUser', JSON.stringify(user));
      this.currentUserSubject.next(user);
    }
    return user;
  }
  logout() {
    localStorage.removeItem('currentUser');
    window.localStorage.clear();
    this.currentUserSubject.next(null);
    // remove user from local storage to log user out
    // localStorage.removeItem('currentUser');
    // this.currentUserSubject.next(null);
  }
}
