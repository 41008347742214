import { Component, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DataService } from '../../../../shared/services/data.service';
import { DatePipe } from '@angular/common';
// import * as moment from 'moment';
import moment from 'moment';
// import * as $ from 'jquery';
import $ from 'jquery';
// import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';
import * as Highcharts from 'highcharts';
import {orderBy} from 'lodash';
import { Constvar } from '../../../../models/constVar';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { element } from 'protractor';
import { Console } from 'console';
import { LoaderService } from '../../../../shared/services/loader.service';

@Component({
  selector: 'wtaf-monthly-execution-status',
  templateUrl: './monthly-execution-status.component.html',
  styleUrls: ['./monthly-execution-status.component.css']
})
export class MonthlyExecutionStatusComponent implements OnInit {
  monthExecStatusform: UntypedFormGroup;
  load=false;
  isSubmitted = false;
  apiUiFlag: boolean;
  toDate: any;
  fromDate: any;
  month: any = [];
  month_index:any;
  year: any = [];
  cm: any = [];
  monthlyExeStatus_date_labels: any = [];
  projectTypeList: any = [];
  isfromOninit:boolean= false;
  platformList: any = [];
  regionList: any = [];
  brandList: any = [];
  regionId: Number = 0;
  regionName: string = '';
  projectTypeName: String = '';
  projecttypeId: any;
  brandId: Number = 0;
  environmentList = ['STG', 'Dev', 'Prod', 'QA'];
  yearsMonth = ['Jan', 'Feb', 'Mar', 'Apr','May', 'Jun', 'Jul', 'Aug','Sep','Oct','Nov','Dec'];
  error: any = { isError: false, errorMessage: '' };
  isValidDate: any;
  environment_type = 'STG';
  updateFlag = true;
  exe_status_fail = [];
  exe_status_pass = [];
  exe_status_total = [];
  barChartLabels = [];
  exe_status_unknown = [];
  highcharts: any = Highcharts;
  chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'column'
    },
    xAxis: {
      categories: ['Whirlpool', 'Jennair', 'KitchenAid']
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Test Suite Execution Count'
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: 'bold',
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            'gray'
        }
      }
    },

    mapNavigation: {
      enableMouseWheelZoom: true
    },
    title: {
      text: ''
    },

    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true
        }
      }
    },

    series: []
  };
  constructor(
    public fb: UntypedFormBuilder,
    private dataservice: DataService,
    public datePipe: DatePipe,
    public toastr: ToastrService,
    public loaderservice: LoaderService
  ) {
    let nowDate = new Date();
    this.toDate = this.datePipe.transform(nowDate, 'yyyy-MM-dd');
    this.fromDate = 
    moment()
      .subtract('months', 1)
      .format('YYYY-MM-DD');
    this.monthExecStatusform = this.fb.group({
      projectTypeId: ['', [Validators.required]],
      regionId: ['', [Validators.required]],
      brandId: ['', [Validators.required]],
      platformId: ['', [Validators.required]],
      environment: ['STG', [Validators.required]],
      month: ['', [Validators.required]]
     // startDate: [this.fromDate, [Validators.required]],
      //endDate: [this.toDate, [Validators.required]]
    });
  }

  ngOnInit() {

    this.loaderservice.show()
    setTimeout(()=>{
       this.loaderservice.hide()
 
     },15000)


     this.prevMonth();
    //  this.load=true;
    // this.dataservice.isfromWidget = true;
    $('#monthlyExecStatusFromDate').attr('max', this.toDate);
    $('#monthlyExecStatusToDate').attr('max', this.toDate);

    console.log(
      'this.monthlyExeStatus_date_labels',
      this.monthlyExeStatus_date_labels
    );
    this.getAllProjectType(true);
    this.monthExecStatusform.controls['brandId'].setValue(0);
    this.monthExecStatusform.controls['platformId'].setValue(0);
    Highcharts.setOptions({
      colors: ['#007bff', '#dc3545', '#ffc107']
    });
    this.chartOptions.series = [
      {
        name: 'Passed',
        data: [0, 0, 0],
        color:'#1b7730'
      },
      {
        name: 'Failed',
        data: [0, 0, 0],
        color : '#dc3545'
      },
      // {
      //   name: 'Unknown',
      //   data: [2, 2, 3]
      // }
    ];
    this.updateFlag = true;
  }

  async getAllProjectType(isfromOninit) {
    this.isfromOninit=isfromOninit;
    await this.dataservice
      .getAllProjectType()
      .toPromise()
      .then(async response => {
        // Success
        this.projectTypeList = response;
        console.log('this.projectTypeList.length');
        console.log(this.projectTypeList.length);
        if (response != null) {
          this.monthExecStatusform.controls['projectTypeId'].setValue(
            this.projectTypeList[0].projectTypeId
          );
          this.getPlatformByProjectTypeId(
            this.projectTypeList[0].projectTypeId
          );
          await this.getAllRegion(this.projectTypeList[0].projectTypeId);
        }
      });
  }

  getAllRegion(Projecttypeid) {
    this.dataservice
      .getAllRegionByProjectType(Projecttypeid)
      .subscribe(async (response: {}) => {
        this.regionList = response;
        this.regionList = orderBy(this.regionList, ['regionCode'],['asc']);
        // this.regionList.unshift(
        //   this.regionList.splice(
            let index=this.regionList.findIndex(item => item.regionName === 'NAR');
        //     1
        //   )[0]
        // );
        if(index<0)
        {
          index=index+1;
        }
        console.log("index=====",index);
        if (response != null) {
          console.log('region id' + this.regionList[index].regionId);
          this.monthExecStatusform.controls['regionId'].setValue(
            this.regionList[index].regionId
          );
          this.regionName = this.regionList[0].regionName;
          this.getBrandsByRegionId(this.monthExecStatusform.get('regionId').value);
          // await this.getBrandsByRegionId(this.regionList[0].regionId);
        }
      });
  }
  async getPlatformByProjectTypeId(projectTypeId) {
    await this.dataservice.getPlatformByProjectTypeId(projectTypeId).subscribe(
      (response: {}) => {
        this.platformList = response;
        console.log(this.platformList);

        if (this.platformList != null) {
          if (this.platformList.length == 1) {
            // this.monthExecStatusform.controls["platformId"].
            this.monthExecStatusform.controls['platformId'].setValue(
              this.platformList[0].platformId
            );
          } else {
            this.monthExecStatusform.controls['platformId'].setValue(0);
          }
        }
      },
      error => {
        console.log(error.message);
        this.toastr.error('Something went Wrong ');
      }
    );
  }

  async getBrandsByRegionId(regionId) {
     
    await this.dataservice
      .getBrandsByRegionId(regionId)
      .toPromise()
      .then((response: {}) => {
        this.brandList = response;
        this.brandList = orderBy(this.brandList, ['brandName'],['asc']);
        if (response != null) {
          if (this.brandList) {
            this.regionId = this.monthExecStatusform.get('regionId').value;
            this.projecttypeId = this.monthExecStatusform.get(
              'projectTypeId'
            ).value;
            // this.brandList.unshift(
            //   this.brandList.splice(
            //     this.brandList.findIndex(
            //       item => item.brandName.toLowerCase() === 'whirlpool'
            //     ),
            //     1
            //   )[0]
            // );
            this.brandId = this.brandList[0].brandId;
            console.log('this.isfromOninit.............',this.isfromOninit);
            this.loaderservice.show();
            if(this.isfromOninit)
            {
             this.monthExecStatusFormSubmit();
            }
          }
          // this.getAllProjectName(this.regionId, this.brandId, this.projecttypeId)
        } else {
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }

  changeProjectType(e) {
    this.dataservice.isfromWidget = false;
    console.log(
      'this.isfromWidget_ from change',
      this.dataservice.isfromWidget
    );

    console.log(e);
    this.projecttypeId = e.target.value;
    console.log(this.projecttypeId);
    this.regionList = [];
    this.brandList = [];
    this.brandId = 0;
    this.monthExecStatusform.controls['regionId'].setValue('');
    let objProjectType = this.projectTypeList.filter(
      pl => pl.projectTypeId == this.projecttypeId
    );

    this.projectTypeName = objProjectType[0].projectTypeName;
    console.log('project type -->', this.projectTypeName);
    if(this.projectTypeName == Constvar.API)
    {
      this.apiUiFlag = true;
      this.getAllRegion(parseInt(e.target.value));
    }
    else{
      this.apiUiFlag = false;
    this.getPlatformByProjectTypeId(parseInt(e.target.value));
    this.getAllRegion(parseInt(e.target.value));
  }
  }

  changeRegion(e) {
    this.dataservice.isfromWidget = false;
    let regionName = this.regionList.filter(region => {
      return region.regionId == parseInt(e.target.value);
    });
    this.regionName = regionName[0].regionName;
    console.log(this.regionName);
    this.getBrandsByRegionId(parseInt(e.target.value));
  }

  changeBrand(e) {
    this.dataservice.isfromWidget = false;
    this.brandId = parseInt(e.target.value);
    // this.regionId = this.monthExecStatusform.get('regionId').value;
    // this.projecttypeId = this.monthExecStatusform.get('projectTypeId').value;
  }

  changePlatform(e) {
    this.dataservice.isfromWidget = false;
    let platformId = parseInt(e.target.value);
    this.monthExecStatusform.controls['platformId'].setValue(
      parseInt(e.target.value)
    );
    console.log('platformId', platformId);
  }

  changeMonth(e){
     
    let monthName=e.target.value;
    console.log("current month",monthName);
    this.yearsMonth.map(element => {
      if(monthName == element){
       this.month_index=this.yearsMonth.indexOf(element);
       this.month_index= this.month_index + 1 ;
        console.log("month index",this.month_index);
        console.log("current month.....",element);
        this.monthExecStatusform.controls['month'].setValue(this.month_index);
      }else{

      }
    });
  }
  prevMonth(){
    for (let i = 0; i < 6; i++) {
      let month = moment()
        .subtract(i + 1, 'month')
        .startOf('month')
        .format('MMM');
      this.month.push(month);
      console.log("Display previous 6 months :",this.month);
      }

      this.yearsMonth.map(element => {
        if(this.month[0] == element){
         this.month_index=this.yearsMonth.indexOf(element);
         this.month_index= this.month_index + 1 ;
          console.log("month index",this.month_index);
          console.log("current month.....",element);
          this.monthExecStatusform.controls['month'].setValue(this.month_index);
        }else{
  
        }
      });
      // this.monthExecStatusform.controls['month'].setValue(1);

  }

  onFromDateChange(e) {
    this.dataservice.isfromWidget = false;
    let fromDate = this.datePipe.transform(e.target.value, 'yyyy-MM-dd');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(fromDate).getMonth();
    let currentyear1 = new Date(fromDate).getFullYear();
    if (
      new Date(this.datePipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ==
      new Date(this.datePipe.transform(fromDate, 'yyyy-MM-dd')).getTime()
    ) {
      let maxDate = fromDate;
      // fromDate = moment(fromDate).subtract(1, 'months').format('YYYY-MM-DD');
      $('#monthlyExecStatusToDate').attr(
        'min',
        this.datePipe.transform(fromDate, 'yyyy-MM-dd')
      );
      $('#monthlyExecStatusToDate').attr(
        'max',
        this.datePipe.transform(maxDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        let maxDate = fromDate;
        // fromDate = moment(fromDate).subtract(1, 'months').format('YYYY-MM-DD');
        $('#monthlyExecStatusToDate').attr(
          'min',
          this.datePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#monthlyExecStatusToDate').attr(
          'max',
          this.datePipe.transform(currentDate, 'yyyy-MM-dd')
        );
        let currenDate1 = new Date();
        let curr_date = this.datePipe.transform(currenDate1, 'yyyy-MM-dd');
        console.log('curr_date', curr_date);
        this.monthExecStatusform.controls['endDate'].setValue(curr_date);
      } else {
        // fromDate = moment(fromDate).subtract(1, 'months').format('YYYY-MM-DD');
        let currentyear = currentDate.getFullYear();
        // let currentmonth1 =  new Date(fromDate).getMonth();
        let currentyear1 = new Date(fromDate).getFullYear();
        let year_1 = new Date(currentyear.toString());
        let year_2 = new Date(currentyear1.toString());
        let toDate;
        let date_1;
        let date_2;
        if (year_1.getTime() === year_2.getTime()) {
          let maxDate = fromDate;

          // fromDate = moment(fromDate).subtract(1, 'months').format('YYYY-MM-DD');
          $('#monthlyExecStatusToDate').attr(
            'min',
            this.datePipe.transform(fromDate, 'yyyy-MM-dd')
          );
          $('#monthlyExecStatusToDate').attr(
            'max',
            this.datePipe.transform(currentDate, 'yyyy-MM-dd')
          );
        } else {
          toDate = moment(this.datePipe.transform(e.target.value, 'yyyy-MM-dd'))
            .add(1, 'months')
            .format('YYYY-MM-DD');
          date_1 = new Date(
            this.datePipe.transform(currentDate, 'yyyy-MM-dd')
          ).getTime();
          date_2 = new Date(
            this.datePipe.transform(toDate, 'yyyy-MM-dd')
          ).getTime();
        }

        if (date_1 > date_2) {
          toDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
        }
        let todate_year = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'years')
          .format('YYYY-MM-DD');
        let todate_year_month = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        if (new Date(todate_year).getTime() > date_1) {
          if (new Date(todate_year_month).getTime() > date_1) {
            $('#monthlyExecStatusToDate').attr(
              'min',
              this.datePipe.transform(fromDate, 'yyyy-MM-dd')
            );
            $('#monthlyExecStatusToDate').attr(
              'max',
              this.datePipe.transform(currentDate, 'yyyy-MM-dd')
            );
            this.monthExecStatusform.controls['endDate'].setValue(
              this.datePipe.transform(currentDate, 'yyyy-MM-dd')
            );
          } else {
            $('#monthlyExecStatusToDate').attr(
              'min',
              this.datePipe.transform(fromDate, 'yyyy-MM-dd')
            );
            if (new Date(todate_year).getTime() > date_1) {
              $('#monthlyExecStatusToDate').attr(
                'max',
                this.datePipe.transform(toDate, 'yyyy-MM-dd')
              );
            } else {
              $('#monthlyExecStatusToDate').attr(
                'max',
                this.datePipe.transform(todate_year, 'yyyy-MM-dd')
              );
            }

            this.monthExecStatusform.controls['endDate'].setValue(
              todate_year_month
            );
          }
        } else {
          $('#monthlyExecStatusToDate').attr(
            'min',
            this.datePipe.transform(fromDate, 'yyyy-MM-dd')
          );
          if (new Date(todate_year).getTime() > date_1) {
            $('#monthlyExecStatusToDate').attr(
              'max',
              this.datePipe.transform(toDate, 'yyyy-MM-dd')
            );
          } else {
            $('#monthlyExecStatusToDate').attr(
              'max',
              this.datePipe.transform(todate_year, 'yyyy-MM-dd')
            );
          }
          this.monthExecStatusform.controls['endDate'].setValue(
            todate_year_month
          );
        }
      }
    }
  }
  onToDateChange(e) {
    this.dataservice.isfromWidget = false;
    let toDate = this.datePipe.transform(e.target.value, 'yyyy-MM-dd');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(toDate).getMonth();
    let currentyear1 = new Date(toDate).getFullYear();
    if (
      new Date(this.datePipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ===
      new Date(this.datePipe.transform(toDate, 'yyyy-MM-dd')).getTime()
    ) {
      let maxDate = toDate;
      toDate = moment(toDate)
        .subtract(1, 'years')
        .format('YYYY-MM-DD');
      $('#monthlyExecStatusFromDate').attr(
        'min',
        this.datePipe.transform(toDate, 'yyyy-MM-dd')
      );
      $('#monthlyExecStatusFromDate').attr(
        'max',
        this.datePipe.transform(maxDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        toDate = moment(toDate)
          .subtract(1, 'years')
          .format('YYYY-MM-DD');
        let fromDate = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'years')
          .format('YYYY-MM-DD');
        $('#monthlyExecStatusFromDate').attr(
          'min',
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        );
        // $('#monthlyExecStatusFromDate').attr('max', this.datePipe.transform(currentDate, 'yyyy-MM-dd'));
      } else {
        toDate = moment(toDate)
          .subtract(1, 'years')
          .format('YYYY-MM-DD');
        let fromDate = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'years')
          .format('YYYY-MM-DD');
        $('#monthlyExecStatusFromDate').attr(
          'min',
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        );
        $('#monthlyExecStatusFromDate').attr(
          'max',
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        );
      }
    }
  }

  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if (sDate == null || eDate == null) {
      this.error = {
        isError: true,
        errorMessage: 'Start date and end date are required.'
      };
      this.isValidDate = false;
    }

    if (sDate != null && eDate != null && eDate < sDate) {
      this.error = {
        isError: true,
        errorMessage: 'To date should be greater than from date.'
      };
      this.isValidDate = false;
    }
    let currenDate = new Date();
    let curr_date = this.datePipe.transform(currenDate, 'yyyy-MM-dd');
    console.log('curr_date', curr_date);
    if (sDate != null && eDate != null && curr_date < eDate) {
      this.error = {
        isError: true,
        errorMessage:
          'To date should not be greater than today' + "'" + 's date.'
      };
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  GetEnv_type(e) {
    this.environment_type = e.target.value;
  }

  monthExecStatusFormSubmit() {
    // this.isValidDate = this.validateDates(
    //   this.datePipe.transform(
    //     this.monthExecStatusform.controls['startDate'].value,
    //     'yyyy-MM-dd'
    //   ),
    //   this.datePipe.transform(
    //     this.monthExecStatusform.controls['endDate'].value,
    //     'yyyy-MM-dd'
    //   )
    // );
    this.isfromOninit=false;
    if(this.projectTypeName == Constvar.API){
      let formControl: UntypedFormControl = this.monthExecStatusform.get(
        'platformId'
      ) as UntypedFormControl;
      formControl.clearValidators();
      formControl.setValidators(null);
      formControl.updateValueAndValidity();
      this.monthExecStatusform.get('platformId').setValue('');
      }
    if (this.monthExecStatusform.invalid) {
      return;
    } else {
      // this.load=true;
      // if(this.load)
      // {
      //   this.chartOptions.series = [
      //     {
      //       name: 'Passed',
      //       data: [0, 0, 0]
         
      //     },
      //     {
      //       name: 'Failed',
      //       data: [0, 0, 0]
           
      //     }
      //   ];
      // }
      // this.loaderservice.hide();

      setTimeout(() => {
        this.loaderservice.hide();
    }, 3000);


      this.dataservice
        .monthlyExestatus(this.monthExecStatusform.value)
        .subscribe((exeRes: any) => {
          console.log('exeRes,,.....', exeRes.suiteMonthly);
          // this.load=false;
          let mindate = moment(this.toDate)
            .subtract(24, 'months')
            .format('YYYY-MM-DD');
          $('#monthlyExecStatusFromDate').attr('max', this.toDate);
          $('#monthlyExecStatusToDate').attr('max', this.toDate);
          $('#monthlyExecStatusFromDate').attr('min', mindate);
          $('#monthlyExecStatusToDate').attr('min', mindate);
          this.error = { isError: false, errorMessage: '' };
          this.exe_status_fail = [];
          this.exe_status_pass = [];
          this.exe_status_total = [];
          this.barChartLabels = [];
          this.exe_status_unknown = [];
          if (exeRes.suiteMonthly.length > 0) {
            if (exeRes.suiteMonthly.length > 0) {
              exeRes.suiteMonthly.forEach(element => {
                this.barChartLabels.push(element.brandName);
                element.regionName;
                this.exe_status_fail.push(element.failed);
                this.exe_status_pass.push(element.passed);
                this.exe_status_total.push(element.total);
                this.exe_status_unknown.push(element.unknown);
              });
            } else {
              this.toastr.info('No data found');
            }
          }

          (this.chartOptions.xAxis = {
            categories: this.barChartLabels
          }),
            (this.chartOptions.series = [
              {
                name: 'Passed',
                data: this.exe_status_pass
              },
              {
                name: 'Failed',
                data: this.exe_status_fail
              },
              // {
              //   name: 'unknown',
              //   data: this.exe_status_unknown
              // }
            ]);
          this.updateFlag = true;
        },
        error=>{
            // this.load=false;
            this.toastr.error("Something went Wrong");
        });

      console.log('success');
    }
  }
}
