<ng-container>
  <form [formGroup]="widgetFilterform" (ngSubmit)="onFormSubmit()">
  <div class="row">
    <div class="form-group col-md-6">
      <select class="txtControlCss col-md-12" id="4" formControlName="projectTypeId" (change)="changeProjectType($event)" >
        <option value="" disabled >Project Type</option>
        <option [value]="projectType.projectTypeId" *ngFor="let projectType of this.projectTypeList">
          {{projectType.projectTypeName}}</option>
      </select>
      <span class="error"
      *ngIf=" this.isSubmitted && !widgetFilterform.controls['projectTypeId'].valid">
      Project type is required</span>
    </div>
    <div class="form-group col-md-6">
      <select class="txtControlCss col-md-12" id="regionId" formControlName="regionId" (change)="changeRegion($event)" >
        <option value="" disabled selected>Region</option>
        <option [value]="region.regionId" *ngFor="let region of this.regionList">
          {{region.regionCode}}</option>
      </select>
      <span class="error"
      *ngIf=" this.isSubmitted && !widgetFilterform.controls['regionId'].valid">
      Region is required</span>
    </div>
    <div class="form-group col-md-6">
      <select class="txtControlCss  col-md-12" id="Brand" (change)="changeBrand($event)" formControlName="brandId" >
        <option value="" disabled selected>Brand</option>
        <option [value]="brand.brandId" *ngFor="let brand of this.brandList">
          {{brand.brandName}}</option>
      </select>
      <span class="error"
      *ngIf=" this.isSubmitted && !widgetFilterform.controls['brandId'].valid">
      Brand is required</span>
    </div>
    
    <div class="col-md-6">
      <!-- <input class="form-control txtControlCss" formControlName="projectName" placeholder="Product Name" autocomplete="off" name="projectName"
        id="projectNamewidget" type="text" list="projectNamewidget2"
        (change)="changeProjectName($event)">
      <datalist id="projectNamewidget2">
        <option *ngFor="let projectName of this.projectNameList; let i=index">{{projectName.projectName}}
        </option>
      </datalist> -->

      <ng-multiselect-dropdown id="multiselectdropdownId" formControlName="productName" name="productName" [placeholder]="'Product Name'"
      [data]="projectNameList" [(ngModel)]="selectedItems" [settings]="dropdownSettings" 
      (onSelect)="onItemSelectProductName($event)">
     </ng-multiselect-dropdown>
      <span class="error"
      *ngIf=" this.isSubmitted && !widgetFilterform.controls['productName'].valid">
      Product Name is required</span>
    </div>
    <div class="form-group col-md-6">
      <!-- <input type="hidden" formControlName="testSuiteId" >
      <input class="form-control txtControlCss" placeholder="Test Suite" autocomplete="off" name="testSuiteId"
        id="testSuiteIdwidget" type="text" formControlName="testSuiteName" (change)="changeTestsuite($event)"  list="testSuiteIdwidget2"
        >
      <datalist id="testSuiteIdwidget2">
        <option *ngFor="let testSuite of this.testSuiteList; let i=index"> {{testSuite.testSuiteName}}
        </option>
      </datalist> -->

      <ng-multiselect-dropdown id="multiselectdropdownId2" formControlName="testSuiteName" name="testSuiteName" [placeholder]="'Test Suite'"
      [data]="testSuiteList" [(ngModel)]="selectedItemsSuite" [settings]="dropdownSettingsTestSuite" 
      (onSelect)="onItemSelectSuite($event)">
     </ng-multiselect-dropdown>
      <span class="error"
      *ngIf=" this.isSubmitted && !widgetFilterform.controls['testSuiteName'].valid">
      Suite is required</span>
    </div>
    <div class="form-group col-md-6">
      <select class="txtControlCss col-md-12" id="PLATFORM" style="font-size: 12px;" formControlName="platformId" >
        <option value="" disabled selected>PLATFORM</option>
        <option [value]="platform.platformId" *ngFor="let platform of platformList">{{platform.platformName}}</option>
      </select>
      <span class="error"
      *ngIf=" this.isSubmitted && !widgetFilterform.controls['platformId'].valid">
      Platform is required</span>
    </div>
    <div class="col-md-6">
      <select class="txtControlCss  col-md-12" id="ENV" style="font-size: 12px;" formControlName="environment" >
        <option value="" disabled selected>ENV</option>
        <option [value]="env" *ngFor="let env of environmentList">
          {{env}}
        </option>
      </select>
      <span class="error"
      *ngIf=" this.isSubmitted && !widgetFilterform.controls['environment'].valid">
      Platform is required</span>
    </div>
    <div class="col-md-6">
      <select class="txtControlCss  col-md-12" (change)="changeUser($event)" id="userId" style="font-size: 12px;" formControlName="profileId" >
        <option value="0" selected>All</option>
        <option [value]="users.profileId" *ngFor="let users of this.userList">
          {{users.userName}}
        </option>
      </select>
      <span class="error"
      *ngIf=" this.isSubmitted && !widgetFilterform.controls['profileId'].valid">
      UserName is required</span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <label for="regionId" class="txtControlLblCss">From<span>*</span></label>

      <div class="exmp-wrp " style="margin-left:2px;">
        <div class="input-group btn-wrp">
          <input type="date" class="btn-clck" formControlName="startDate"  (change)="onFromDateChange($event)" id="testSuiteExecutionTimeTrendFromDate" name="testSuiteExecutionTimeToDate"
             placeholder="From">
        </div>
        <span class="input-group-btn ">
          <button class="btn1  " type="text"><span class="paddingLefiRightCalender"><img
                src="assets/images/calendarr.png"></span></button>
        </span>
      </div>
    </div>

    <div class="col-md-6">
      <label for="regionId" class="txtControlLblCss">To<span>*</span></label>

      <div class="exmp-wrp " style="margin-left:2px;">
        <div class="input-group btn-wrp">
          <input type="date" class="btn-clck " (change)="onToDateChange($event)" formControlName="endDate"  id="testSuiteExecutionTimeTrendToDate"
            name="testSuiteExecutionTimeFromDate"  placeholder="From">
        </div>
        <span class="input-group-btn ">
          <button class="btn1  " type="text"><span class="paddingLefiRightCalender"><img
                src="assets/images/calendarr.png"></span></button>
        </span>
      </div>
    </div>
  </div>
  <div class="text-danger" *ngIf="error.isError">{{error.errorMessage}}</div>
  <div class="d-flex justify-content-end col-md-12 mt-4 p-0">
    <button type="submit" class="btn runBtnCss">Run</button>
  </div>
</form>

  <div class="row mt-2">
    <div class="col-md-6 txt-color" style="font-weight: 600;">
      Test Suite Execution Time Trend
    </div>
    <div class="txt-color col-md-6">
      <span style="margin: 5px;"> {{regionName}}  : {{testSuiteName}}</span>
    </div>

  </div>
  <div id="InLinechart">
    <canvas id="InLine" width="50%"></canvas>
  </div>
</ng-container>