<div class="row marginCss mt-2"> <span class="breadcrumCss">Home / Execution /In Progress</span></div>

<div class="row marginCss paddingCss whiteBackgroundCss mt-2">

    <div class="row col-md-12 paddingRightCss paddingLeftCss">
        <div class="text-center successMessage"><b></b></div>
        <div class="text-center errorMessage"></div><br>
        <div class="col-md-9">
        </div>
        <div class="col-md-3  text-right paddingRightCss">
            <div class="input-group" id="searchmargin">
                <button (click)="getSchedulelist()" type="submit" class="btn refreshBtnCss">Refresh Jobs</button>
                <input type="text" class="form-control" [(ngModel)]="txtSearch" name="jobName"
                    placeholder="Search Job Name">
                <div class="input-group-append">
                    <button class="btn btn-secondary" type="button" id="searchicon">
                        <i class="fa fa-search" id="searchicon"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div style="width: 100% !important; height:500px !important; overflow:auto;">
        <table class="table table-hover table-sticky table-striped table-bordered">
            <colgroup>
                <col>
                <col>
                <col>
            </colgroup>
            <thead>
                <tr>
                    <th width="100px">
                        <span>Test Execution Id &nbsp;</span>
                    </th>
                    <th width="500px">
                        <span>Job Name &nbsp;</span>
                        <div class="inlineImages my-auto">
                        </div>
                    </th>
                    <th width="100px">
                        <span>Execution Mode &nbsp;</span>
                        <div class="inlineImages my-auto">
                        </div>
                    </th>
                    <th width="75px">
                        <span>Platform &nbsp;</span>
                        <div class="inlineImages my-auto">
                        </div>
                    </th>
                    <th width="125px">
                        <span>Created By &nbsp;</span>
                        <div class="inlineImages my-auto">
                        </div>
                    </th>
                    <th width="115px">
                        <span>Time &nbsp;</span>
                        <div class="inlineImages my-auto">

                        </div>
                    </th>
                    <th width="100px">
                        <span>Action &nbsp;</span>

                    </th>
                </tr>
            </thead>
            <tbody *ngIf="( this.Execution_list | filter : txtSearch) as result">
                <ng-container *ngFor="let listdata of result;let index=index; let odd=odd; let even=even ;">
                    <tr [ngClass]="{ odd: odd, even: even }">
                        <td class="sheduletablestyle">
                            {{listdata.exeHeaderId}} </td>
                        <td class="sheduletablestyle">
                            <div>
                                {{listdata.jobName}}
                            </div>
                        </td>
                        <td class="sheduletablestyle">
                            <div>
                                {{listdata.executionMode}}
                            </div>
                        </td>
                        <td class="sheduletablestyle">
                            <div>
                                {{listdata.platformName}}
                            </div>
                        </td>
                        <td class="sheduletablestyle">
                            <div>
                                {{listdata.createdBy}}
                            </div>
                        </td>
                        <td class="sheduletablestyle">
                            <div>
                                     <span class="datestyle">{{listdata.startDate1}}
                                         &nbsp;{{listdata.startTime1}}&nbsp;&nbsp;
                                 </span>
                                 </div>
                           </td>
                        <td class="sheduletablestyle">
                            <div>
                                <div class="spinner-grow spinner-grow-sm text-primary" data-toggle="tooltip"
                                    data-placement="left" title="Inprogress" role="status"
                                    *ngIf="listdata.jobStatus==1">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <span class="dot" *ngIf="listdata.jobStatus==0"></span>
                                &nbsp;
                               <a (click)="viewStatus(listdata)"><img src="assets/images/eye.png" height="15px"
                                        width="15px" title="View Execution Console"></a>&nbsp;
                                <a (click)="stopJenkinsJOb(listdata)"><img src="assets/images/stopIcon.png"
                                        height="15px" width="15px" title="Stop Jenkins Job"></a>&nbsp;
                                
                            </div>
                        </td>
                    </tr>
                    <tr id="{{listdata.exeHeaderId}}" class="out" colspan="2">
                        <td style="border-right: none;" width="70px"></td>
                        <td colspan="3" style="border-left: none;">
                            <table class="childTable" *ngIf=" this.Execution_details[listdata.exeHeaderId]  != null">
                                <thead>
                                    <th>Test Suite</th>
                                    <th>Test Case</th>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let listdata of this.Execution_details[listdata.exeHeaderId] ,let i=index">
                                        <tr class="tblexec-row" *ngFor="let lstCases of  listdata.cases, let j=index">
                                            <td *ngIf="j==0">{{listdata.testSuiteName}}</td>
                                            <td *ngIf="j!=0"></td>
                                            <td>{{lstCases.testCaseName}}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <div *ngIf="this.Execution_details[listdata.exeHeaderId] == null">
                                <p id="nodata">No Record Found...</p>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                <tr id="notFoundDiv" *ngIf="result.length === 0" style=" font-weight: bold">
                    <td colspan="4" style="text-align: center;">No Records Found</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<wtaf-view-status [changing]="changingValue"></wtaf-view-status>