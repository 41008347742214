/* projects/wtaf/src/app/features/schedule_popup/schedule-popup/schedule-popup.component.css */
.statusTxt {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #012f60;
}
.incativeTxt {
  font-family: Open Sans;
  font-style: italic;
  font-weight: normal;
  margin-top: 85px;
  font-size: 13px;
  color: #012f60;
}
.switch {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 26px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 20px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid #1f83fe;
}
.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 20px;
  border: 1px solid #1f83fe;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #2196f3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 35px;
}
.slider.round:before {
  border-radius: 50%;
}
.frequncyTxt {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #012f60;
}
.checkDiv {
  padding-right: 0px !important;
  padding-left: 128px !important;
}
.paddingLeftRightCss {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.timeInput {
  padding-right: 28px;
}
.headerModal {
  border-bottom: 1px solid #8291a5 !important;
}
.bsDatepicker {
  height: 10px;
  width: 10px;
  font-size: 5px;
}
.exmp-wrp {
  position: relative;
  border: 1px solid #ced4da !important;
  border: 1px solid #ced4da;
}
.container .inputCss {
  opacity: unset !important;
}
.btn-wrp {
  display: inline-block;
  position: relative;
  z-index: 10;
}
.btn-clck {
  background: transparent;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 20px;
  display: block;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-clck::-webkit-calendar-picker-indicator {
  right: -10px;
  position: absolute;
  width: 78px;
  height: 40px;
  color: rgba(204, 204, 204, 0);
  opacity: 0;
}
.btn-clck::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.btn-clck::-webkit-clear-button {
  margin-right: 75px;
}
.btn1 {
  height: 100%;
  background-color: #1f83fe !important;
  position: absolute;
  right: 0;
}
#StartDate,
#scheduleTime,
#EndDate {
  font-size: 14px;
}
.saveBtnPopupCss {
  margin-left: 5px;
  border: 2px solid #1f83fe !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 100px !important;
  height: 35px !important;
  color: white !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  background-color: #1f83fe !important;
}
.closebtn {
  border: 2px solid #1F83FE !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 100px !important;
  height: 35px !important;
  color: #1F83FE !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  background-color: #FFFFFF !important;
}
.weekDays-selector input {
  display: none !important;
}
.weekDays-selector input[type=checkbox] + label {
  display: inline-block;
  border-radius: 50%;
  background: #dddddd;
  height: 30px;
  width: 30px;
  margin-right: 3px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}
.weekDays-selector input[type=checkbox]:checked + label {
  background: #007bff;
  color: #ffffff;
}
.hidecustom {
  display: none;
}
.hideday {
  display: none;
}
/*# sourceMappingURL=schedule-popup.component.css.map */
