/* projects/wtaf/src/app/features/api_automation/api-automation/api-automation.component.css */
.api_container,
.api_group_box,
.initial_data {
  border: 2px solid #ccd3d8;
  padding: 10px;
  margin: 15px;
  border-radius: 5px;
}
.api_group_box,
.initial_data {
  border-left-color: #1f83fe;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 6%);
}
.invalid_form {
  border-left-color: #ff9494;
}
.pl-15 {
  padding-left: 15px;
}
.request_header p {
  font-size: 13px;
}
.request_header i,
.last_reponse_box i,
.Inti_hbox i {
  color: #007bff;
  cursor: pointer;
  padding-right: 5px;
}
.apisubbox,
.intial_box {
  display: none;
  border-top: 1px dashed #ccc;
  margin: 10px 0px;
}
.intital_setupbox {
  border-bottom: 1px dashed #ccc;
  margin-bottom: 10px;
}
.init_title {
  font-weight: 300;
}
.variable_box {
  border-bottom: 1px dashed #ccc;
  margin: 10px 0px;
}
.prev_apidata {
  display: none;
}
.open {
  display: block;
}
.addBtnCss {
  background-color: #1f83fe;
  border-radius: 3px;
  width: 100px;
  height: 35px;
  color: #ffffff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}
.btnaddmore {
  background-color: #1f83fe;
  border-radius: 3px;
  height: 35px;
  margin-left: 15px;
  color: #ffffff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}
.localvarbtn {
  margin-left: 0px;
}
.apiTabsection {
  padding: 15px;
  border: 1px solid #cdcdcd;
}
.deleteIcon {
  cursor: pointer;
}
#tabs {
  background: #007b5e;
  color: #eee;
}
#tabs h6.section-title {
  color: #eee;
}
#tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #007bff;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  border-bottom: 4px solid;
  font-size: 16px;
  font-weight: bold;
}
#tabs .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #eee;
  font-size: 20px;
}
.apiTabsection .control-box ul {
  list-style-type: none;
}
#btnSaveCase {
  margin-left: 10px;
}
.intial_box_title_form {
  border-bottom: 1px dashed #ccc;
  margin-bottom: 10px;
}
.intial_box_title_form .env_title_box,
.intial_box_title_form .env_select_box {
  width: 45%;
  display: inline-block;
  margin: 10px;
}
.form_box {
  display: flex;
}
.form_index {
  flex: 2%;
  margin: 15px 0px;
}
.form_data {
  flex: 97%;
}
.form_index div {
  text-align: center;
  border: 1px dashed #1f83fe;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-top: 50%;
}
.app-ace-editor {
  height: 500px;
  width: 100%;
}
.ace_print-margin {
  visibility: hidden !important;
  background: transparent !important;
}
.bb-2 {
  border-bottom: 2px solid #cdcdcd;
}
/*# sourceMappingURL=api-automation.component.css.map */
