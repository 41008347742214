/* projects/wtaf/src/app/layout/loader/loader/loader.component.css */
.overlay {
  position: fixed;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(74, 74, 74, .8);
  z-index: 99999;
}
.spinner {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.loading:not(:required) {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow:
    rgba(66, 139, 203, 1) 1.5em 0 0 0,
    rgba(66, 139, 203, 1) 1.1em 1.1em 0 0,
    rgba(66, 139, 203, 1) 0 1.5em 0 0,
    rgba(66, 139, 203, 1) -1.1em 1.1em 0 0,
    rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0,
    rgba(66, 139, 203, 1) 0 -1.5em 0 0,
    rgba(66, 139, 203, 1) 1.1em -1.1em 0 0;
  box-shadow:
    rgba(66, 139, 203, 1) 1.5em 0 0 0,
    rgba(66, 139, 203, 1) 1.1em 1.1em 0 0,
    rgba(66, 139, 203, 1) 0 1.5em 0 0,
    rgba(66, 139, 203, 1) -1.1em 1.1em 0 0,
    rgba(66, 139, 203, 1) -1.5em 0 0 0,
    rgba(66, 139, 203, 1) -1.1em -1.1em 0 0,
    rgba(66, 139, 203, 1) 0 -1.5em 0 0,
    rgba(66, 139, 203, 1) 1.1em -1.1em 0 0;
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=loader.component.css.map */
