/* projects/wtaf/src/app/features/widgets/test_suite_execution_time_trend/test-suite-execution-time-trend/test-suite-execution-time-trend.component.css */
.paddingRightOpx {
  padding-right: 0px;
}
.paddingLefiRightCalender {
  padding-left: 5px;
  padding-right: 5px;
}
.runBtnCss {
  background-color: #1F83FE !important;
  border-radius: 3px !important;
  width: 100px !important;
  height: 35px !important;
  color: #FFFFFF !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.exmp-wrp {
  position: relative;
}
.btn-wrp {
  display: inline-block;
  position: relative;
  z-index: 10;
}
.btn-clck {
  background: transparent;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 100%;
  padding-right: 0.50rem;
  padding-left: 0.30rem;
  display: block;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: .10rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.btn-clck::-webkit-calendar-picker-indicator {
  right: -0px;
  position: absolute;
  color: rgba(204, 204, 204, 0);
  opacity: 0;
}
.btn-clck::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.btn-clck::-webkit-clear-button {
  margin-right: 20px;
}
.btn1 {
  height: 100%;
  padding: 0px;
  position: absolute;
  right: 0;
}
#InLine {
  width: 100%;
  height: 100%;
}
.error {
  color: #ff0033;
  padding-left: 5px;
}
/*# sourceMappingURL=test-suite-execution-time-trend.component.css.map */
