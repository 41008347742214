import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private selectedProjectTypeSubject = new BehaviorSubject<number>(0);
  private regionListSubject= new BehaviorSubject<string[]>([]);
  private regionSelectedSubject= new BehaviorSubject<number>(null);
  private brandListSubject= new BehaviorSubject<string[]>([]);
  private brandSelectedSubject= new BehaviorSubject<Number>(0);
  private platformListSubject= new BehaviorSubject<string[]>([]);
  private platformSelectedSubject= new BehaviorSubject<Number>(0);
  private envSelectedSubject= new BehaviorSubject<string>('');
  private testTypeSelectedSubject= new BehaviorSubject<string>('');
  private startDateSubject= new BehaviorSubject<Date>(null);
  private endDateSubject= new BehaviorSubject<Date>(null);
  selectedProject$=this.selectedProjectTypeSubject.asObservable();
  selectedRegionList$=this.regionListSubject.asObservable();
  selectedRegion$= this.regionSelectedSubject.asObservable();
  selectedBrandList$=this.brandListSubject.asObservable();
  selectedBrand$= this.brandSelectedSubject.asObservable();
  selectedPlatformList$=this.platformListSubject.asObservable();
  selectedPlatform$= this.platformSelectedSubject.asObservable();
  selectedEnvironment$= this.envSelectedSubject.asObservable();
  selectedTestType$= this.testTypeSelectedSubject.asObservable();
  selectedStartDate$= this.startDateSubject.asObservable();
  selectedEndDate$= this.endDateSubject.asObservable();


  constructor() { }

  setSelectedProjectType(country: number){
    this.selectedProjectTypeSubject.next(country)
  }

  getSelectedProjectType(){
    return this.selectedProjectTypeSubject.getValue();
  }

  getRegionSelected(){
    return this.regionSelectedSubject.getValue();
  }

  setRegions(regions:string[]){
    this.regionListSubject.next(regions);
  }
  setRegion(region:number){
    this.regionSelectedSubject.next(region);
  }
  setBrandList(brands:string[]){
    this.brandListSubject.next(brands);
  }

  setBrand(brand: Number){
    this.brandSelectedSubject.next(brand);
  }

  setPlatformList(platforms:string[]){
    this.platformListSubject.next(platforms);
  }

  setPlatform(platform:Number){
    this.platformSelectedSubject.next(platform);
  }

  setEnvironment(env:string){
    this.envSelectedSubject.next(env);
  }

  setTestType(testType:string){
    this.testTypeSelectedSubject.next(testType);
  }

  setSelectedStartDate(date:Date){
    this.startDateSubject.next(date);
  }

  setSelectedEndDate(date:Date){
    this.endDateSubject.next(date);
  }
}