import { Injectable, ChangeDetectorRef } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';
//import {  AuthenticationService } from '../../../shared/services/authentication.service';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { DatePipe } from '@angular/common';
import { Type } from '../../features/models/type';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
// import { ToastrManager } from 'ng6-toastr-notifications';
// import { ToastrService } from 'ngx-toastr';
import { LoaderService } from './loader.service';
// const jenkinsapi = require('jenkins-api');
import { InterceptorSkipLoader } from '../../core/loader-interceptors/loader.interceptor';
import { InterceptorSkipLoader_fromWidget } from '../../core/loader-interceptors/loader.interceptor';
import {
  headSpinToken,
  InterceptorSkipHeader
} from './../../core/auth/jwt.interceptor';
import { env } from 'process';
import { Constvar } from '../../models/constVar';

// const jenkins = jenkinsapi.init(environment.urlConfig.jenkinsUrl);
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class DataService {
  errorWhenInUse: String = '';
  errorUnauthorized: string = '';
  errorMsg: string = '';
  errorUsedregionBrand: string = '';
  isDevicesLoading: boolean = false;
  error_500_status: string = '';
  //for Mobile and Web
  commonJobName = environment.framework_Exe;
  interval: any;
  IsjobName_error = '';
  arrJenkinsSlave: any = [];
  user = JSON.stringify(localStorage.getItem('currentUser'));
  token = `Bearer ` + this.user['token'];
  customFilter = {};
  userName: string;
  platform: string;
  isconsoleOut: boolean = false;
  slaveOS: string;
  serverDevices: any = [];
  isfromWidget: boolean = false;
  consoleOut_datares: any;
  loadarFlag: boolean = false;
  androidGrepCommand = 'pm list packages | grep';
  constructor(
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
    public authService: AuthenticationService, // public ref: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
    // public toastr: ToastrService,
    public loaderService: LoaderService
  ) {}

  //Number Validation
  onlyNumberValidation(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 12 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  // =====================================Region API's==============================================================
  saveRegion(details) {
    const apiURL = environment.urlConfig.baseUrl + 'region/addRegion';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // getAllRegion() {
  //   const url = environment.urlConfig.baseUrl + 'region/getAllRegion';
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getAllRegion() {
    const url = environment.urlConfig.baseUrl + '/masterData/getAllRegion';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  getAllRegions() {
    const url = environment.urlConfig.baseUrl + '/masterData/getAllRegions';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getAllRegionByProjectType(typeId) {
  //   let httpOptions;
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'region/getAllRegionByProjectType?projectTypeId=' +
  //     typeId;
  //   if (this.isfromWidget) {
  //     httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json;charset=UTF-8'
  //       }).set(InterceptorSkipLoader_fromWidget, '')
  //     };
  //   } else {
  //     httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json;charset=UTF-8'
  //       })
  //     };
  //   }

  //   return this.http.get(url, httpOptions);
  // }
  getAllRegionByProjectType(typeId) {
    let httpOptions;
    const url =
      environment.urlConfig.baseUrl +
      '/masterData/getRegionByProjectType?projectTypeId=' +
      typeId;
    if (this.isfromWidget) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${this.authService.authToken}`
        })
        // .set(InterceptorSkipLoader_fromWidget, '')
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${this.authService.authToken}`
        })
      };
    }

    return this.http.get(url, httpOptions);
  }
  // getRegionById(regionId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'region/getRegionById?regionId=' +
  //     regionId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getRegionById(regionId) {
    const url =
      environment.urlConfig.baseUrl +
      '/masterData/getRegionById?regionId=' +
      regionId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  deleteRegion(regionId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      'region/deleteRegion?regionId=' +
      regionId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }

  // =====================================Appliance Category API's==============================================================

  // saveApplianceCategory(details) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'appliance/addApplianceCategory';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }
  saveApplianceCategory(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/masterData/addApplianceCategory';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // getAllApplianceCategory(sortColumn, sortOrder) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'appliance/getAllApplianceCategory?sortColumn=' +
  //     sortColumn +
  //     '&sortOrder=' +
  //     sortOrder;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getAllApplianceCategory(sortColumn, sortOrder) {
    const url =
      environment.urlConfig.baseUrl +
      '/masterData/getAllApplianceCategory?sortColumn=' +
      sortColumn +
      '&sortOrder=' +
      sortOrder;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getApplianceCategoryById(applianceCategoryId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'appliance/getApplianceCategoryById?applianceCategoryId=' +
  //     applianceCategoryId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getApplianceCategoryById(applianceCategoryId) {
    const url =
      environment.urlConfig.baseUrl +
      '/masterData/getApplianceCategoryById?applianceCategoryId=' +
      applianceCategoryId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // deleteapplianceCategory(applianceCategoryId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'appliance/deleteApplianceCategory?applianceCategoryId=' +
  //     applianceCategoryId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }
  deleteapplianceCategory(applianceCategoryId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/masterData/deleteApplianceCategory?applianceCategoryId=' +
      applianceCategoryId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }
  // =====================================Brand API's==============================================================

  // saveBrand(details) {
  //   const apiURL = environment.urlConfig.baseUrl + 'brand/addBrand';

  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };

  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }
  saveBrand(details) {
    // console.log("SON.stringify(details)....." , JSON.parse(JSON.stringify(details)));

    const apiURL = environment.urlConfig.baseUrl + '/masterData/addBrand';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };

    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // getAllBrand() {
  //   const url = environment.urlConfig.baseUrl + 'brand/getAllBrand';
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getAllBrand() {
    const url = environment.urlConfig.baseUrl + '/masterData/getAllBrand';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  // getBrandsByRegionId(regionId) {
  //   let httpOptions;
  //   const url =
  //     environment.urlConfig.baseUrl + 'brand/getAllBrand?regionId=' + regionId;
  //   if (this.isfromWidget) {
  //     httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json;charset=UTF-8',
  //         authToken: `${this.authService.authToken}`
  //       }).set(InterceptorSkipLoader_fromWidget, '')
  //     };
  //   } else {
  //     httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json;charset=UTF-8',
  //         authToken: `${this.authService.authToken}`
  //       })
  //     };
  //   }
  //   return this.http.get(url, httpOptions);
  // }

  getBrandsByRegionId(regionId) {
    let httpOptions;
    const url =
      environment.urlConfig.baseUrl +
      '/masterData/getAllBrand?regionId=' +
      regionId;
    if (this.isfromWidget) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${this.authService.authToken}`
        })
        // .set(InterceptorSkipLoader_fromWidget, '')
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${this.authService.authToken}`
        })
      };
    }
    return this.http.get(url, httpOptions);
  }

  // getByBrandId(brandId) {
  //   const url =
  //     environment.urlConfig.baseUrl + 'brand/getBrandById?barndId=' + brandId;

  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getByBrandId(brandId) {
    const url =
      environment.urlConfig.baseUrl +
      '/masterData/getBrandById?brandId=' +
      brandId;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  // deleteBrandById(brandId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'brand/deleteBrand?barndId=' + brandId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }

  deleteBrandById(brandId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/masterData/deleteBrand?brandId=' +
      brandId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }
  // =====================================Brandwise Capability API's==============================================================
  // saveBrandWiseCapability(details) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'capability/addBrandWiseCapability';

  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };

  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  saveBrandWiseCapability(details) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/addBrandWiseCapability';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };

    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // getAllBrandWiseCapability() {
  //   const url =
  //     environment.urlConfig.baseUrl + 'capability/getAllBrandWiseCapability';
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getAllBrandWiseCapability() {
    const url =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/getAllBrandWiseCapability';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  // deleteBrandwisecapabilityById(brandCapId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'capability/deleteBrandWiseCapability?brandCapId=' +
  //     brandCapId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }

  deleteBrandwisecapabilityById(brandCapId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/deleteBrandWiseCapability?brandCapId=' +
      brandCapId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }

  // getByBrandCapId(brandCapId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'capability/getBrandWiseCapabilityById?brandCapId=' +
  //     brandCapId;

  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getByBrandCapId(brandCapId) {
    const url =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/getBrandWiseCapabilityById?brandCapId=' +
      brandCapId;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // checkForDuplicateInbrandWiseCapability(details) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'capability/checkForDuplicateInbrandWiseCapability?brandId=' +
  //     details.brandId +
  //     '&capabilityId=' +
  //     details.capabilityId +
  //     '&platformId=' +
  //     details.platformId +
  //     '&env=' +
  //     details.env;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  checkForDuplicateInbrandWiseCapability(details) {
    const url =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/checkForDuplicateInbrandWiseCapability?brandId=' +
      details.brandId +
      '&capabilityId=' +
      details.capabilityId +
      '&platformId=' +
      details.platformId +
      '&env=' +
      details.env;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getAppCapabilitiesByBrand(brandId, env, platformId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'capability/getBrandWiseCapabilityBybrandIdPlatformIdAndEnvironment?brandId=' +
  //     brandId +
  //     '&environment=' +
  //     env +
  //     '&platformId=' +
  //     platformId;
  //   brandId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }

  getAppCapabilitiesByBrand(brandId, env, platformId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/getBrandWiseCapabilityBybrandIdPlatformIdAndEnvironment?brandId=' +
      brandId +
      '&environment=' +
      env +
      '&platformId=' +
      platformId;
    brandId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }
  // =====================================Appliance API's==============================================================

  // saveAppliance(details) {
  //   const apiURL = environment.urlConfig.baseUrl + 'appliance/addAppliance';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };

  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }
  saveAppliance(details) {
    const apiURL = environment.urlConfig.baseUrl + '/masterData/addAppliance';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };

    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }
  // getAllAppliance() {
  //   const url = environment.urlConfig.baseUrl + 'appliance/getAllAppliance';

  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions).toPromise();
  // }
  getAllAppliance() {
    const url = environment.urlConfig.baseUrl + '/masterData/getAllAppliances';

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions).toPromise();
  }
  // getByApplianceId(applianceId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'appliance/getApplianceById?applianceId=' +
  //     applianceId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getByApplianceId(applianceId) {
    const url =
      environment.urlConfig.baseUrl +
      '/masterData/getApplianceById?applianceId=' +
      applianceId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // deleteApplianceById(applianceId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'appliance/deleteAppliance?applianceId=' +
  //     applianceId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }
  deleteApplianceById(applianceId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/masterData/deleteAppliance?applianceId=' +
      applianceId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }
  // =====================================Role API's==============================================================
  // saveRole(details) {
  //   const apiURL = environment.urlConfig.baseUrl + 'user/addUserRole';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }
  saveRole(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/userManagement/addUserRole';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // getAllRole(sortColumn, sortOrder) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'user/getAllUserRole?sortColumn=' +
  //     sortColumn +
  //     '&sortOrder=' +
  //     sortOrder;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getAllRole(sortColumn, sortOrder) {
    const url =
      environment.urlConfig.baseUrl +
      '/userManagement/getAllRoles?sortColumn=' +
      sortColumn +
      '&sortOrder=' +
      sortOrder;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getRoleById(roleId) {
  //   const url =
  //     environment.urlConfig.baseUrl + 'user/getUserRoleById?roleId=' + roleId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getRoleById(roleId) {
    const url =
      environment.urlConfig.baseUrl +
      '/userManagement/getUserRoleById?roleId=' +
      roleId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // deleteRole(roleId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'user/deleteRole?roleId=' + roleId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }
  deleteRole(roleId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/userManagement/deleteUserRole?roleId=' +
      roleId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }

  // =====================================User API's==============================================================
  // saveUser(details) {
  //   console.log('details', details);
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'user/addUser?loggedInUserId=' +
  //     details.loggedInUserId +
  //     '&profileId=' +
  //     details.profileId +
  //     '&userId=' +
  //     details.userId +
  //     '&userName=' +
  //     details.userName +
  //     '&userRoleId=' +
  //     details.userRoleId;

  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, httpOptions);
  // }
  saveUser(details) {
    const apiURL = environment.urlConfig.baseUrl + '/userManagement/addUser';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // getAllUser() {
  //   const url = environment.urlConfig.baseUrl + 'user/getAllUser';
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getAllUser() {
    const url = environment.urlConfig.baseUrl + '/userManagement/getAllUsers';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getUserById(profileId) {
  //   const url =
  //     environment.urlConfig.baseUrl + 'user/getUserById?profileId=' + profileId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getUserById(profileId) {
    const url =
      environment.urlConfig.baseUrl +
      '/userManagement/getUserById?profileId=' +
      profileId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // deleteUser(profileId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'user/deleteUser?profileId=' + profileId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }
  deleteUser(profileId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/userManagement/deleteUser?profileId=' +
      profileId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }

  downloadImage(profileId) {
    var time = Date.now();
    let authToken = 1 + '&timeStamp=' + time;
    const url =
      environment.urlConfig.baseUrl +
      'user/downloadUserImg?profileId=' +
      profileId +
      '&authToken=' +
      authToken;
    let httpOptions = {
      headers: new HttpHeaders({
        responseType: 'blob'
      })
    };
    return url;
  }

  // =====================================Keyword API's==============================================================

  // saveKeyword(details) {
  //   console.log('saveKeyword-->', details);
  //   const apiURL = environment.urlConfig.baseUrl + 'keyword/addKeyword';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };

  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }
  saveKeyword(details) {
    console.log('saveKeyword-->', details);
    const apiURL =
      environment.urlConfig.baseUrl + '/frameworkManagement/addKeyword';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }
  // getAllKeyword() {
  //   const url = environment.urlConfig.baseUrl + 'keyword/getAllKeywords';
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions).toPromise();
  // }
  getAllKeyword() {
    const url =
      environment.urlConfig.baseUrl + '/frameworkManagement/getAllKeywords/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions).toPromise();
  }
  //Get all keyword by projectTypeId

  // getKeywordByProjectTypeId(projectTypeId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'keyword/getAllKeywords?projectTypeId=' +
  //     projectTypeId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions).toPromise();
  // }
  getKeywordByProjectTypeId(projectTypeId) {
    const url =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/getAllKeywords?projectTypeId=' +
      projectTypeId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions).toPromise();
  }

  // getKeywordById(keywordId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'keyword/getKeywordById?keywordId=' +
  //     keywordId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getKeywordById(keywordId) {
    const url =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/getKeywordById?keywordId=' +
      keywordId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  // deleteKeyword(keywordId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'keyword/deleteKeyword?keywordId=' +
  //     keywordId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }
  deleteKeyword(keywordId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/deleteKeyword?keywordId=' +
      keywordId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }

  // =====================================Document API's==============================================================

  saveDocument(details) {
    console.log('saveDocument-->', details);
    const apiURL = environment.urlConfig.baseUrl + 'document/addDocument';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
        //Authorization: `${this.authService.authToken}`
      })
    };

    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  saveDocument_micro(details) {
    console.log('saveDocument-->', details);
    const apiURL = environment.urlConfig.baseUrl + '/masterData/addDocument';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };

    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  getAllDocument() {
    const url = environment.urlConfig.baseUrl + 'document/getAllDocuments';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
        //Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions).toPromise();
  }

  getAllDocument_micro() {
    const url = environment.urlConfig.baseUrl + '/masterData/getAllDocument ';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions).toPromise();
  }

  getDocumentById(documentId) {
    const url =
      environment.urlConfig.baseUrl +
      'document/getDocumentById?documentId=' +
      documentId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
        //Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  getDocumentById_micro(documentId) {
    const url =
      environment.urlConfig.baseUrl +
      '/masterData/getDocumentById?documentId=' +
      documentId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  deleteDocument(documentId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      'document/deleteDocument?documentId=' +
      documentId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
        //Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }

  deleteDocument_micro(documentId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/masterData/deleteDocument?documentId=' +
      documentId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }

  downloadGoggleDriveDocument(fileId): Observable<HttpResponse<Blob>> {
    const url =
      environment.urlConfig.baseUrl +
      'document/downloadFileFromGoogleDrive?fileId=' +
      fileId;
    return this.http.get<Blob>(url, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  downloadGoggleDriveDocument_micro(fileId) {
    const url =
      environment.urlConfig.baseUrl +
      '/masterData/downloadFileFromGoogleDrive?fileId=' +
      fileId;
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        //Accept: 'application/octet-stream',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  downloadConfluenceDocument(title): Observable<HttpResponse<Blob>> {
    const url =
      environment.urlConfig.baseUrl +
      'document/downloadFileFromConfluence?title=' +
      title;
    return this.http.get<Blob>(url, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  downloadConfluenceDocument_micro(title): Observable<HttpResponse<Blob>> {
    const url =
      environment.urlConfig.baseUrl +
      '/masterData/downloadFileFromConfluence?title=' +
      title;
    return this.http.get<Blob>(url, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }
  // =====================================Element API's==============================================================
  // saveElement(details) {
  //   const apiURL = environment.urlConfig.baseUrl + 'element/addElement';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }
  saveElement(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/frameworkManagement/addElement';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // getAllElement(sortColumn, sortOrder) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'element/getAllElement?sortColumn=' +
  //     sortColumn +
  //     '&sortOrder=' +
  //     sortOrder;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  //   // .toPromise();
  // }

  getAllElement(sortColumn, sortOrder) {
    const url =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/getAllElements?sortColumn=' +
      sortColumn +
      '&sortOrder=' +
      sortOrder;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
    // .toPromise();
  }
  getAllElementByBrandAndPlatform(brandId, PlatformId) {
    const url =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/getAllElementByBrandAndPlatform?brandId=' +
      brandId +
      '&PlatformId=' +
      PlatformId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
    // .toPromise();
  }

  getAllElementBySearch(pageNo, pageSize, searchBy) {
    const url =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/getAllElementBySearch?pageNo=' +
      pageNo +
      '&pageSize=' +
      pageSize +
      '&searchBy=' +
      encodeURIComponent(searchBy);
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
    // .toPromise();
  }
  // getElementById(elementId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'element/getElementById?elementId=' +
  //     elementId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getElementById(elementId) {
    const url =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/getElementById?elementId=' +
      elementId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // deleteElement(elementId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'element/deleteElement?elementId=' +
  //     elementId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }
  deleteElement(elementId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/deleteElement?elementId=' +
      elementId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }
  // =====================================Test Suite API's==============================================================
  // saveTestSuite(details) {
  //   const apiURL = environment.urlConfig.baseUrl + 'testsuite/addTestSuite';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }
  saveTestSuite(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/testCaseManagement/addTestSuite';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // saveTestSuiteAfterImport(details) {
  //   const apiURL = environment.urlConfig.baseUrl + 'testsuite/importTestSuite';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }
  checkDuplicateElementAndKeyword(projectTypeId, brandId, platformId, details) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/checkDuplicateElementAndKeyword?projectTypeId=' +
      projectTypeId +
      '&brandId=' +
      brandId +
      '&platformId=' +
      platformId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  saveTestSuiteAfterImport(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/testCaseManagement/importTestSuite';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // getAllTestSuite(
  //   sortColumn,
  //   sortOrder,
  //   regionId,
  //   brandId,
  //   projectTypeId,
  //   projectName,
  //   pageNo,
  //   pageSize
  // ) {
  //   let url = '';
  //   if (projectName != '') {
  //     url =
  //       environment.urlConfig.baseUrl +
  //       'testsuite/getAllTestSuite?regionId=' +
  //       regionId +
  //       '&brandId=' +
  //       brandId +
  //       '&projectTypeId=' +
  //       projectTypeId +
  //       '&projectName=' +
  //       projectName +
  //       '&pageNo=' +
  //       pageNo +
  //       '&pageSize=' +
  //       pageSize +
  //       '&sortColumn=' +
  //       sortColumn +
  //       '&sortOrder=' +
  //       sortOrder;
  //   } else {
  //     url =
  //       environment.urlConfig.baseUrl +
  //       'testsuite/getAllTestSuite?regionId=' +
  //       regionId +
  //       '&brandId=' +
  //       brandId +
  //       '&projectTypeId=' +
  //       projectTypeId +
  //       '&pageNo=' +
  //       pageNo +
  //       '&pageSize=' +
  //       pageSize +
  //       '&sortColumn=' +
  //       sortColumn +
  //       '&sortOrder=' +
  //       sortOrder;
  //   }

  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getAllTestSuite(
    sortColumn,
    sortOrder,
    regionId,
    brandId,
    projectTypeId,
    projectName,
    pageNo,
    pageSize
  ) {
    let url = '';
    if (projectName != '') {
      url =
        environment.urlConfig.baseUrl +
        '/testCaseManagement/getAllTestSuite?regionId=' +
        regionId +
        '&brandId=' +
        brandId +
        '&projectTypeId=' +
        projectTypeId +
        '&projectName=' +
        projectName +
        '&pageNo=' +
        pageNo +
        '&pageSize=' +
        pageSize +
        '&sortColumn=' +
        sortColumn +
        '&sortOrder=' +
        sortOrder;
    } else {
      url =
        environment.urlConfig.baseUrl +
        '/testCaseManagement/getAllTestSuite?regionId=' +
        regionId +
        '&brandId=' +
        brandId +
        '&projectTypeId=' +
        projectTypeId +
        '&pageNo=' +
        pageNo +
        '&pageSize=' +
        pageSize +
        '&sortColumn=' +
        sortColumn +
        '&sortOrder=' +
        sortOrder;
    }

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getAllTestSuiteBySearch(serachValue, pageNo, pageSize) {
  //   let url = '';
  //   url =
  //     environment.urlConfig.baseUrl +
  //     'testsuite/getAllTestSuiteBySearch?searchBy=' +
  //     serachValue +
  //     '&pageNo=' +
  //     pageNo +
  //     '&pageSize=' +
  //     pageSize;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getAllTestSuiteBySearch(serachValue, pageNo, pageSize, projectName) {
    let url = '';
    url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getAllTestSuiteBySearch?searchBy=' +
      serachValue +
      '&pageNo=' +
      pageNo +
      '&pageSize=' +
      pageSize +
      '&projectName=' +
      projectName;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  getAllTestCaseBySearch(
    projectName,
    searchValue,
    pageNo,
    pageSize,
    isShared,
    testsuiteId
  ) {
    let url = '';
    url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getAllTestCaseBySearch?pageNo=' +
      pageNo +
      '&pageSize=' +
      pageSize +
      '&searchBy=' +
      searchValue +
      '&projectName=' +
      projectName +
      '&isShared=' +
      isShared +
      '&testSuiteId=' +
      testsuiteId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getAllTestSuiteByPlatformId(
  //   sortColumn,
  //   sortOrder,
  //   regionId,
  //   brandId,
  //   projectTypeId,
  //   projectName,
  //   platformId
  // ) {
  //   let url =
  //     environment.urlConfig.baseUrl +
  //     'testsuite/getAllTestSuite?regionId=' +
  //     regionId +
  //     '&brandId=' +
  //     brandId +
  //     '&projectTypeId=' +
  //     projectTypeId +
  //     '&projectName=' +
  //     projectName +
  //     '&platformId=' +
  //     platformId +
  //     '&sortColumn=' +
  //     sortColumn +
  //     '&sortOrder=' +
  //     sortOrder;

  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getAllTestSuiteByPlatformId(
    sortColumn,
    sortOrder,
    regionId,
    brandId,
    projectTypeId,
    projectName,
    platformId
  ) {
    let url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getAllTestSuite?regionId=' +
      regionId +
      '&brandId=' +
      brandId +
      '&projectTypeId=' +
      projectTypeId +
      '&projectName=' +
      projectName +
      '&platformId=' +
      platformId +
      '&sortColumn=' +
      sortColumn +
      '&sortOrder=' +
      sortOrder;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getAllTestSuite_withoutProject(
  //   regionId,
  //   brandId,
  //   projectTypeId,
  //   projectName
  // ) {
  //   let url = '';

  //   url =
  //     environment.urlConfig.baseUrl +
  //     'testsuite/getTestSuiteNameAndId?brandId=' +
  //     brandId;

  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getAllTestSuite_withoutProject(
    regionId,
    brandId,
    projectTypeId,
    projectName
  ) {
    let url = '';

    url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getTestSuiteNameAndId?brandId=' +
      brandId;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getTestSuiteById(testSuiteId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'testsuite/getTestSuiteById?testSuiteId=' +
  //     testSuiteId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getTestSuiteById(testSuiteId) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getTestSuiteById?testSuiteId=' +
      testSuiteId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // deleteTestSuite(testSuiteId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'testsuite/deleteTestSuite?testSuiteId=' +
  //     testSuiteId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }
  deleteTestSuite(testSuiteId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/deleteTestSuite?testSuiteId=' +
      testSuiteId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }

  deleteTestSuiteByUserIdAndSuiteId(testSuiteId, userId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/deleteTestSuiteByUserIdAndSuiteId?testSuiteId=' +
      testSuiteId +
      '&userId=' +
      userId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }

  // getProjectTypeByBrandId(brandId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'testsuite/getProjectType?brandId=' +
  //     brandId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }

  getProjectTypeByBrandId(brandId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      'testsuite/getProjectType?brandId=' +
      brandId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  // getProjectNameByTypeId(brandId, projectTypeId, regionId) {
  //   let httpOptions;
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'testsuite/getProjectName?brandId=' +
  //     brandId +
  //     '&projectTypeId=' +
  //     projectTypeId +
  //     '&regionId=' +
  //     regionId;
  //   if (this.isfromWidget) {
  //     httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json;charset=UTF-8'
  //       }).set(InterceptorSkipLoader_fromWidget, '')
  //     };
  //   } else {
  //     httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json;charset=UTF-8'
  //       })
  //     };
  //   }

  //   return this.http.get(apiURL, httpOptions);
  // }

  getProjectNameByTypeId(brandId, projectTypeId, regionId) {
    let httpOptions;
    const apiURL =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getProjectName?brandId=' +
      brandId +
      '&projectTypeId=' +
      projectTypeId +
      '&regionId=' +
      regionId;
    if (this.isfromWidget) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8'
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8'
        })
      };
    }

    return this.http.get(apiURL, httpOptions);
  }

  // getTestSuiteNameByProjectName(projectName) {
  //   let httpOptions;
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'testsuite/getTestSuiteNameByProjectName?projectName=' +
  //     projectName;
  //   if (this.isfromWidget) {
  //     httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json;charset=UTF-8',
  //         authToken: `${this.authService.authToken}`
  //       }).set(InterceptorSkipLoader_fromWidget, '')
  //     };
  //   } else {
  //     httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json;charset=UTF-8',
  //         authToken: `${this.authService.authToken}`
  //       })
  //     };
  //   }

  //   return this.http.get(apiURL, httpOptions);
  // }
  getTestSuiteNameByProjectName(projectName) {
    let httpOptions;
    const apiURL =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getTestSuiteNameByProjectName?projectName=' +
      projectName;
    if (this.isfromWidget) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${this.authService.authToken}`
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${this.authService.authToken}`
        })
      };
    }

    return this.http.get(apiURL, httpOptions);
  }

  // =====================================Test Case API's==============================================================
  // saveTestCase(details) {
  //   const apiURL = environment.urlConfig.baseUrl + 'testcase/addTestCase';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  saveTestCase(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/testCaseManagement/testCaseSave';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // getAllTestCase(sortColumn, sortOrder, regionId, brandId, testSuiteId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'testcase/getAllTestCases?regionId=' +
  //     regionId +
  //     '&brandId=' +
  //     brandId +
  //     '&sortColumn=' +
  //     sortColumn +
  //     '&sortOrder=' +
  //     sortOrder +
  //     '&testSuiteId=' +
  //     testSuiteId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getAllTestCase(sortColumn, sortOrder, regionId, brandId, testSuiteId) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getAllTestCases?regionId=' +
      regionId +
      '&brandId=' +
      brandId +
      '&sortColumn=' +
      sortColumn +
      '&sortOrder=' +
      sortOrder +
      '&testSuiteId=' +
      testSuiteId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getTestCaseByTestSuiteId(sortColumn, sortOrder, testSuiteId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'testcase/getTestCaseByTestSuiteId?testSuiteId=' +
  //     testSuiteId +
  //     '&sortColumn=' +
  //     sortColumn +
  //     '&sortOrder=' +
  //     sortOrder;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getTestCaseByTestSuiteId(sortColumn, sortOrder, testSuiteId) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getTestCaseByTestSuiteId?testSuiteId=' +
      testSuiteId +
      '&sortColumn=' +
      sortColumn +
      '&sortOrder=' +
      sortOrder;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getAllTestCasebyProjects(
  //   sortColumn,
  //   sortOrder,
  //   regionId,
  //   brandId,
  //   projectTypeId,
  //   projectName,
  //   testSuiteId
  // ) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'testcase/getAllTestCases?regionId=' +
  //     regionId +
  //     '&brandId=' +
  //     brandId +
  //     '&projectTypeId=' +
  //     projectTypeId +
  //     '&projectName=' +
  //     projectName +
  //     '&testSuiteId=' +
  //     testSuiteId +
  //     '&sortColumn=' +
  //     sortColumn +
  //     '&sortOrder=' +
  //     sortOrder;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     }).set(InterceptorSkipLoader_fromWidget, this.loadarFlag.toString())
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getAllTestCasebyProjects(
    sortColumn,
    sortOrder,
    regionId,
    brandId,
    projectTypeId,
    projectName,
    testSuiteId
  ) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getAllTestCases?regionId=' +
      regionId +
      '&brandId=' +
      brandId +
      '&projectTypeId=' +
      projectTypeId +
      '&projectName=' +
      projectName +
      '&testSuiteId=' +
      testSuiteId +
      '&sortColumn=' +
      sortColumn +
      '&sortOrder=' +
      sortOrder;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
      // .set(InterceptorSkipLoader_fromWidget, this.loadarFlag.toString())
    };
    return this.http.get(url, httpOptions);
  }

  // getTestCaseById(testCaseId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'testcase/getTestCaseById?testCaseId=' +
  //     testCaseId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getTestCaseById(testCaseId) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getTestCaseById?testCaseId=' +
      testCaseId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // deleteTestCase(testCaseId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'testcase/deleteTestCase?testCaseId=' +
  //     testCaseId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }
  // =====================================End Test Case API's==============================================================
  // =====================================All Shared Test Case API's ==============================================================
  saveSharedTestCase(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/testCaseManagement/testCaseSave';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // saveSharedTestCase(details) {
  //   const apiURL = environment.urlConfig.baseUrl + 'testcase/testCaseSave';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  // getAllSharedTestCasebyProjects(
  //   sortColumn,
  //   sortOrder,
  //   regionId,
  //   brandId,
  //   projectTypeId,
  //   projectName,
  //   isShared
  // ) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'testcase/getAllTestCases?regionId=' +
  //     regionId +
  //     '&brandId=' +
  //     brandId +
  //     '&projectTypeId=' +
  //     projectTypeId +
  //     '&projectName=' +
  //     projectName +
  //     '&isShared=' +
  //     isShared +
  //     '&sortColumn=' +
  //     sortColumn +
  //     '&sortOrder=' +
  //     sortOrder;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getAllSharedTestCasebyProjects_micro(
    sortColumn,
    sortOrder,
    regionId,
    brandId,
    projectTypeId,
    projectName,
    isShared
  ) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getAllTestCases?regionId=' +
      regionId +
      '&brandId=' +
      brandId +
      '&projectTypeId=' +
      projectTypeId +
      '&projectName=' +
      projectName +
      '&isShared=' +
      isShared +
      '&sortColumn=' +
      sortColumn +
      '&sortOrder=' +
      sortOrder;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getSharedTestCaseById(testCaseId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'testcase/getTestCaseById?testCaseId=' +
  //     testCaseId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  // deleteShareTestCase(testCaseId, testSuiteId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'testcase/deleteTestCase?testCaseId=' +
  //     testCaseId +
  //     '&testSuiteId=' +
  //     testSuiteId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }

  // addSharedTestcaseToSuite(details) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'testcase/addSharedTestCaseToSuite';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  deleteTestCase(testCaseId, testSuiteId, userId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/deleteTestCase?TestCaseId=' +
      testCaseId +
      '&testSuiteId=' +
      testSuiteId +
      '&userId=' +
      userId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }

  addSharedTestcaseToSuite(details) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/addSharedTestCaseToSuite';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // clonesharedTestCase(details) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'testcase/cloneSharedTestCase';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }
  clonesharedTestCase(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/testCaseManagement/cloneSharedTestCase';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
        // Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }
  //Import File Data
  // addImportSharedCaseFileData(caseDetails) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'util/importSharedCaseFileData';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http
  //     .post(apiURL, JSON.stringify(caseDetails), httpOptions)
  //     .toPromise();
  // }

  addImportSharedCaseFileData(caseDetails) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/importSharedCaseFileData';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http
      .post(apiURL, JSON.stringify(caseDetails), httpOptions)
      .toPromise();
  }
  checkDuplicationwithOptionalId(details) {
    const url =
      environment.urlConfig.baseUrl +
      '/masterData/checkForDuplicate?id=' +
      details.id +
      '&optionalId=' +
      details.optionalId +
      '&resource=' +
      encodeURIComponent(details.resource) +
      '&type=' +
      details.type +
      (details.type == Type.Appliance
        ? '&optionalId=' + details.platformId
        : '');
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
      // .set(InterceptorSkipLoader, '')
    };
    return this.http.get(url, httpOptions);
  }
  getAllSharedTestCasebyProjects(
    sortColumn,
    sortOrder,
    regionId,
    brandId,
    projectTypeId,
    projectName,
    isShared,
    testSuiteId,
    pageNo,
    pageSize
  ) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getAllTestCases?regionId=' +
      regionId +
      '&brandId=' +
      brandId +
      '&projectTypeId=' +
      projectTypeId +
      '&projectName=' +
      projectName +
      '&pageNo=' +
      pageNo +
      '&pageSize=' +
      pageSize +
      '&isShared=' +
      isShared +
      '&sortColumn=' +
      sortColumn +
      '&sortOrder=' +
      sortOrder +
      '&testSuiteId=' +
      testSuiteId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  getSharedTestCaseById(testCaseId) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getSharedTestCaseById?sharedTestCaseId=' +
      testCaseId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  // =====================================End shared Test Case API's==============================================================
  // saveTestStep(details) {
  //   const apiURL = environment.urlConfig.baseUrl + 'teststep/addTestStep';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }
  saveTestStep(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/testCaseManagement/addTestStep';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // getAllTestStep(regionId, brandId, testSuiteId, testCaseId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'teststep/getAllTestStep?regionId=' +
  //     regionId +
  //     '&brandId=' +
  //     brandId +
  //     '&testSuiteId=' +
  //     testSuiteId +
  //     '&testCaseId=' +
  //     testCaseId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getAllTestStep(regionId, brandId, testSuiteId, testCaseId) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getAllTestStep?regionId=' +
      regionId +
      '&brandId=' +
      brandId +
      '&testSuiteId=' +
      testSuiteId +
      '&testCaseId=' +
      testCaseId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  // getTestStepById(testStepId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'teststep/getTestStepById?testStepId=' +
  //     testStepId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getTestStepById(testStepId) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getTestStepById?testStepId=' +
      testStepId;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
      // .set(InterceptorSkipLoader, '')
    };
    return this.http.get(url, httpOptions);
  }
  // deleteTestStep(testStepId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'teststep/deleteTestStep?testStepId=' +
  //     testStepId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }
  deleteTestStep(testStepId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/deleteTestStep?testStepId=' +
      testStepId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
      // .set(InterceptorSkipLoader, '')
    };
    return this.http.delete(apiURL, httpOptions);
  }
  // deleteSharedTestStep(testStepId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'teststep/deleteTestStep?testStepId=' +
  //     testStepId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }
  // =====================================Utilities API's==============================================================
  checkForDuplicate_usermanagement(details) {
    const url =
      environment.urlConfig.baseUrl +
      '/userManagement/checkForDuplicate?id=' +
      details.id +
      '&resource=' +
      encodeURIComponent(details.resource) +
      '&type=' +
      details.type +
      (details.type == Type.Appliance ? '&optionalId=' + details.brandId : '');
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  checkForDuplicate_mastermanagement(details) {
    const url =
      environment.urlConfig.baseUrl +
      '/masterData/checkForDuplicate?id=' +
      details.id +
      '&resource=' +
      encodeURIComponent(details.resource) +
      '&type=' +
      details.type +
      (details.type == Type.Appliance ? '&optionalId=' + details.brandId : '');
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  checkDuplication_document(details) {
    const url =
      environment.urlConfig.baseUrl +
      '/masterData/checkForDuplicate?id=' +
      details.id +
      '&resource=' +
      encodeURIComponent(details.resource) +
      '&type=' +
      details.type +
      (details.type == Type.Appliance ? '&optionalId=' + details.brandId : '');
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  checkDuplication_Doc(details) {
    const url =
      environment.urlConfig.baseUrl +
      '/masterData/checkForDuplicate?id=' +
      details.id +
      '&resource=' +
      encodeURIComponent(details.resource) +
      '&type=' +
      details.type +
      (details.type == Type.Appliance ? '&optionalId=' + details.brandId : '');
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  checkDuplication_Execution(details) {
    const url =
      environment.urlConfig.baseUrl +
      '/execution/checkForDuplicate?id=' +
      details.id +
      '&resource=' +
      encodeURIComponent(details.resource) +
      '&type=' +
      details.type +
      (details.type == Type.Appliance ? '&optionalId=' + details.brandId : '');
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  checkDuplicationwithOptionalId_fmicro(details) {
    const url =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/checkForDuplicate?id=' +
      details.id +
      '&resource=' +
      encodeURIComponent(details.resource) +
      '&type=' +
      details.type;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
      // .set(InterceptorSkipLoader, '')
    };
    return this.http.get(url, httpOptions);
  }

  checkDuplicationwithOptionalId_framemicro(details) {
    let url;
    if (details.type == Type.Keyword) {
      url =
        environment.urlConfig.baseUrl +
        '/frameworkManagement/checkForDuplicate?id=' +
        details.id +
        '&resource=' +
        encodeURIComponent(details.resource) +
        '&type=' +
        details.type;
    } else {
      url =
        environment.urlConfig.baseUrl +
        '/frameworkManagement/checkForDuplicate?id=' +
        details.id +
        '&optionalId=' +
        details.optionalId +
        '&resource=' +
        encodeURIComponent(details.resource) +
        '&type=' +
        details.type;
    }

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // checkDuplication(details) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'util/checkForDuplicate?id=' +
  //     details.id +
  //     '&resource=' +
  //     encodeURIComponent(details.resource) +
  //     '&type=' +
  //     details.type +
  //     (details.type == Type.Appliance ? '&optionalId=' + details.brandId : '');
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  checkDuplication(details) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/checkForDuplicate?id=' +
      details.id +
      '&resource=' +
      encodeURIComponent(details.resource) +
      '&type=' +
      details.type +
      (details.type == Type.Appliance ? '&optionalId=' + details.brandId : '');
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // checkDuplicationwithOptionalId(details) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'util/checkForDuplicate?id=' +
  //     details.id +
  //     '&optionalId=' +
  //     details.optionalId +
  //     '&resource=' +
  //     encodeURIComponent(details.resource) +
  //     '&type=' +
  //     details.type +
  //     (details.type == Type.Appliance
  //       ? '&optionalId=' + details.platformId
  //       : '');
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     }).set(InterceptorSkipLoader, '')
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  // getAllProjectType() {
  //   console.log(
  //     'this.isfromWidget_ from monthly execution status',
  //     this.isfromWidget
  //   );

  //   let httpOptions;
  //   const url = environment.urlConfig.baseUrl + 'util/getAllProjectType';

  //   if (this.isfromWidget) {
  //     httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json;charset=UTF-8',
  //         authToken: `${this.authService.authToken}`
  //       }).set(InterceptorSkipLoader_fromWidget, '')
  //     };
  //   } else {
  //     httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json;charset=UTF-8',
  //         authToken: `${this.authService.authToken}`
  //       })
  //     };
  //   }

  //   return this.http.get(url, httpOptions);
  // }
  getAllProjectType() {
    console.log(
      'this.isfromWidget_ from monthly execution status',
      this.isfromWidget
    );

    let httpOptions;
    const url = environment.urlConfig.baseUrl + '/masterData/getAllProjectType';

    if (this.isfromWidget) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${this.authService.authToken}`
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${this.authService.authToken}`
        })
      };
    }

    return this.http.get(url, httpOptions);
  }
  // getPlatformByProjectTypeId(projectTypeId) {
  //   let httpOptions;
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'util/getAllPlatform?projectTypeId=' +
  //     projectTypeId;
  //   if (this.isfromWidget) {
  //     httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json;charset=UTF-8',
  //         authToken: `${this.authService.authToken}`
  //       }).set(InterceptorSkipLoader_fromWidget, '')
  //     };
  //   } else {
  //     httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json;charset=UTF-8',
  //         authToken: `${this.authService.authToken}`
  //       })
  //     };
  //   }
  //   return this.http.get(url, httpOptions);
  // }
  getPlatformByProjectTypeId(projectTypeId) {
    let httpOptions;
    const url =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/getAllPlatform?projectTypeId=' +
      projectTypeId;
    if (this.isfromWidget) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${this.authService.authToken}`
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${this.authService.authToken}`
        })
      };
    }
    return this.http.get(url, httpOptions);
  }

  exportTestSuiteData(testSuiteId) {
    // const url =
    //   environment.urlConfig.baseUrl +
    //   'util/exportTestSuiteData?testSuiteId=' +
    //   testSuiteId;
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/exportTestSuiteData?testSuiteId=' +
      testSuiteId;
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // syncWithJira(testSuiteId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'util/synchWithJira?testSuiteId=' +
  //     testSuiteId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'text/plain;charset=UTF-8 ',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, { responseType: 'text' });
  // }

  syncWithJira(testSuiteId) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/synchWithJira?testSuiteId=' +
      testSuiteId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain;charset=UTF-8 ',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, { responseType: 'text' });
  }

  // ===================== Capability API's=============================

  // saveCapability(details) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'capability/addCapabilityMaster';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  saveCapability(details) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/addCapabilityMaster';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // getAllCapability() {
  //   const url = environment.urlConfig.baseUrl + 'capability/getAllCapability';
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  //get capability by Project type Id

  // getCapabilitiesByProjectTypeId(platformId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'capability/getAllCapability?platformId=' +
  //     platformId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     }).set(InterceptorSkipLoader, '')
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  // getCapabilityById(capabilityId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'capability/getCapabilityById?capabilityId=' +
  //     capabilityId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  // deleteCapability(capabilityId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'capability/deleteCapability?capabilityId=' +
  //     capabilityId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }
  // saveCapability_copy(details) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl_keyword_capability + 'addCapabilityMaster';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8'
  //       // authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }
  getAllCapability() {
    const url =
      environment.urlConfig.baseUrl + '/frameworkManagement/getAllCapability';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  getCapabilitiesByProjectTypeId(platformId) {
    const url =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/getAllCapability?platformId=' +
      platformId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  getCapabilityById(capabilityId) {
    const url =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/getCapabilityById?capabilityId=' +
      capabilityId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  deleteCapability(capabilityId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/deleteCapabilityById?capabilityId=' +
      capabilityId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }
  // ===================== Test Data API's=============================
  // getTestStepByCase(testCaseId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     '/teststep/getTestStepByCase?testCaseId=' +
  //     testCaseId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getTestStepByCase(testCaseId) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getTestStepByCase?testCaseId=' +
      testCaseId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getTestStepBysuite(testsuiteId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'teststep/getTestStepBySuite?testSuiteId=' +
  //     testsuiteId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getTestStepBysuite(testsuiteId) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getTestStepBySuite?testSuiteId=' +
      testsuiteId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  // saveDataset(details) {
  //   const apiURL = environment.urlConfig.baseUrl + 'teststep/addDataSet';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  saveDataset(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/testCaseManagement/addDataSet';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // DeleteIterations(dataSetName, iterationId, testsuiteId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'teststep/deleteDataSet?dataSetName=' +
  //     dataSetName +
  //     '&iterationId=' +
  //     iterationId +
  //     '&testSuiteId=' +
  //     testsuiteId +
  //     '';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }

  DeleteIterations(dataSetName, iterationId, testsuiteId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/deleteDataSet?dataSetName=' +
      dataSetName +
      '&iterationId=' +
      iterationId +
      '&testSuiteId=' +
      testsuiteId +
      '';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }
  // getAllDataSetByCaseId(testCaseId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'teststep/getDataSetName?testCaseId=' +
  //     testCaseId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getAllDataSetByCaseId(testCaseId) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getDataSetName?testCaseId=' +
      testCaseId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getAllDataSetBySuiteId(testSuiteId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'teststep/getDataSetName?testSuiteId=' +
  //     testSuiteId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     }).set(InterceptorSkipLoader_fromWidget, this.loadarFlag.toString())
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  //called in test Data
  getAllDataSetBySuiteId(testSuiteId) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getDataSetName?testSuiteId=' +
      testSuiteId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getAllDataSetIterationsByDsName(dsName, testSuiteId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'teststep/getAllDataSet?dataSetName=' +
  //     dsName +
  //     '&testSuiteId=' +
  //     testSuiteId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getAllDataSetIterationsByDsName(dsName, testSuiteId) {
    const url =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getAllDataSet?dataSetName=' +
      encodeURIComponent(dsName) +
      '&testSuiteId=' +
      testSuiteId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  // API for Get list of dataset for selected suites

  // getDataSetNameList(suitedetails) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'teststep/getDataSetNameList';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, suitedetails, httpOptions);
  // }

  getDataSetNameList(suitedetails) {
    const apiURL =
      environment.urlConfig.baseUrl + '/testCaseManagement/getDataSetNameList';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, suitedetails, httpOptions);
  }

  // getAllTestCasesByMultipleSuite(suitedetails) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'testcase/getAllTestCasesByMultipleSuite';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, suitedetails, httpOptions);
  // }
  getAllTestCasesByMultipleSuite(suitedetails) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getAllTestCasesByMultipleSuite';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, suitedetails, httpOptions);
  }
  // End of API's for get Testcases and datasets for selected suites
  // getcapabilityTemplateByName(templateName) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'exection/getCapabilityTemplates?usrTemplateName=' +
  //     encodeURIComponent(templateName);

  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     }).set(InterceptorSkipLoader, '')
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  // getcapabilityTemplateByNameHeadspin(templateType, usrTemplateName, userId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'exection/getHsCapabilityTemplates?templateType=' +
  //     templateType +
  //     '&usrTemplateName=' +
  //     usrTemplateName +
  //     '&userId=' +
  //     userId;

  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     }).set(InterceptorSkipLoader, '')
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getcapabilityTemplateByNameHeadspin(templateType, usrTemplateName, userId) {
    const url =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/getHsCapabilityTemplates?templateType=' +
      templateType +
      '&usrTemplateName=' +
      usrTemplateName +
      '&userId=' +
      userId;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  getAllHeadspinDevicesAppiumUrl() {
    const apiURL =
      environment.urlConfig.headspinBaseUrl + 'devices/automation-config';
    const httpOptions = {
      headers: new HttpHeaders({}).set(headSpinToken, 'true')
    };
    return this.http.get(apiURL, httpOptions);
  }
  //Add  Device Capability
  addDeviceCapability(details) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/addDeviceCapability';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details[0]), httpOptions);
    // JSON.stringify(details)
  }
  getcapabilityTemplateByName(templateName) {
    const url =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/getCapabilityTemplates?usrTemplateName=' +
      encodeURIComponent(templateName);

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  // ===============================================Capability Template===================================================
  // AddCapabilityTemplate(details) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'exection/addCapabilityTemplate';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  // addDeviceFarmCapabilityTemplate(details) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/addDeviceFarmCapabilityTemplate';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }
  addDeviceFarmCapabilityTemplate(details) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/addDeviceFarmCapabilityTemplate';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // getcapabilityTemplateName(userId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'exection/getCapabilityTemplateName?userId=' +
  //     userId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  // deleteCapabilityTemplateById(capTemplateId, userId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/deleteCapabilityTemplate?capTemplateId=' +
  //     capTemplateId +
  //     '&userId=' +
  //     userId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  // addDeviceFarmCapabilityTemplate(details) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/addDeviceFarmCapabilityTemplate';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }

  // addDeviceFarmCapabilityTemplate_micro(details) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     '/frameworkManagement/addDeviceFarmCapabilityTemplate';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8'
  //       // authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }
  // getCapabilityTemplates_micro() {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     '/frameworkManagement/getCapabilityTemplates';
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8'
  //       // authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }
  getcapabilityTemplateName(userId) {
    const url =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/getCapabilityTemplateName?userId=' +
      userId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  deleteCapabilityTemplateById(capTemplateId, userId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/deleteCapabilityTemplate?capTemplateId=' +
      capTemplateId +
      '&modifyBy=' +
      userId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }
  deleted_dfCapability(df_capabilityId, userTemplateName, userId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/deleteDeviceFarmCapability?capabilityId=' +
      df_capabilityId +
      '&usrTemplateName=' +
      userTemplateName +
      '&userId=' +
      userId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }
  deleteEntireCapabilityTemplate(capabilityTemplateName, userId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/deleteEntireCapabilityTemplate?usrTemplateName=' +
      capabilityTemplateName +
      '&userId=' +
      userId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }
  AddCapabilityTemplate(details) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/frameworkManagement/addCapabilityTemplate';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }
  // ========================= Save Execution Details ========================================
  // addExecutionHeader(details) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'exection/addExecutionHeader';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  addExecutionHeader(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/execution/addExecutionHeader';
    const httpOptions = {
      // headers: new HttpHeaders({
      //   'Content-Type': 'application/json;charset=UTF-8',
      //   // Authorization: `${this.authService.authToken}`
      // })
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
      // headers: new HttpHeaders({
      //   'Content-Type': 'application/json; charset=utf-8'
      // })
    };
    console.log('response of start-Execution ---', JSON.stringify(details));
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // addExecutionDetails(details) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'exection/addExecutionDetails';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  addExecutionDetails(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/execution/addExecutionDetails';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }
  //=================================Execution_Schedule_list_parent===================================
  // getExecutionSchedule() {
  //   //
  //   const apiURL = environment.urlConfig.baseUrl + 'exection/getRunningJobs';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       Authorization: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }
  //=================================Execution_Schedule_list_parent===================================
  // getExecutionSchedule() {
  //   //
  //   const apiURL = environment.urlConfig.baseUrl + 'exection/getRunningJobs';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }

  getExecutionSchedule() {
    //
    const apiURL = environment.urlConfig.baseUrl + '/execution/getRunningJobs';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }
  // getScheduleListDetails(exeHeaderId, exeHistoryId) {
  //   const apiURL1 =
  //     environment.urlConfig.baseUrl +
  //     'exection/getScheduleListDeatils?exeHeaderId=' +
  //     exeHeaderId +
  //     '&exeHistoryId=' +
  //     exeHistoryId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL1, httpOptions);
  // }

  getScheduleListDetails(exeHeaderId, exeHistoryId) {
    const apiURL1 =
      environment.urlConfig.baseUrl +
      '/execution/getScheduleListDeatils?exeHeaderId=' +
      exeHeaderId +
      '&exeHistoryId=' +
      exeHistoryId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL1, httpOptions);
  }

  // jobReExecution(exeHeaderId, exeHistoryId, userId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/reExecuteJob?exeHeaderId=' +
  //     exeHeaderId +
  //     '&exeHistoryId=' +
  //     exeHistoryId +
  //     '&userId=' +
  //     userId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }

  jobReExecution(exeHeaderId, exeHistoryId, userId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/reExecuteJob?exeHeaderId=' +
      exeHeaderId +
      '&exeHistoryId=' +
      exeHistoryId +
      '&userId=' +
      userId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }
  // Prashant Chnages - getScheduleDetails function added
  getScheduleDetails(searchBy, pageNo, pageSize) {
    // get all scheduled data         'Accept':'text/plain',
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/getScheduleList?pageNo=' +
      pageNo +
      '&pageSize=' +
      pageSize +
      '&searchBy=' +
      searchBy;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  // updateScheduleDetails(details) {
  //   const apiURL = environment.urlConfig.baseUrl + 'exection/updateSchedule';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  updateScheduleDetails(details) {
    const apiURL = environment.urlConfig.baseUrl + '/execution/updateSchedule';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }
  // deleteSchedule(exeHeaderId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/deleteSchedule?exeHeaderId=' +
  //     exeHeaderId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }
  deleteSchedule(exeHeaderId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/deleteSchedule?exeHeaderId=' +
      exeHeaderId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }
  //===========================================History Api======================================
  // getHistoryDetails() {
  //   const getUrl = environment.urlConfig.baseUrl + 'exection/getHistoryDetails';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8,'
  //     })
  //   };
  //   return this.http.get(getUrl, httpOptions);
  // }
  // getHistoryDetails_micro() {
  //   const getUrl = environment.urlConfig.baseUrl + '/execution/getHistoryDetails';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8,'
  //     })
  //   };
  //   return this.http.get(getUrl, httpOptions);
  // }
  //===========================================API Automation Api======================================
  saveEnvironmentDetails(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/apiAutomation/addEnvironment';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }
  getAPIEnvironmentList(regionId, brandId, SuiteId) {
    const getUrl =
      environment.urlConfig.baseUrl +
      '/apiAutomation/getEnvironmentList?regionId=' +
      regionId +
      '&brandId=' +
      brandId +
      '&testSuiteId=' +
      SuiteId +
      '';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8,'
      })
    };
    return this.http.get(getUrl, httpOptions);
  }
  saveAPITestCase(details) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/addTestCaseForAPIRequest';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }
  saveAPIRequest(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/apiAutomation/addAPIRequest';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }
  saveAutomationAPIJson(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/testCaseManagement/importAPITestSuite';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }
  exportAPITestSuiteData(testSuiteId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/apiAutomation/exportAPIRequest?testSuiteId=' +
      testSuiteId;

    return this.http.get(apiURL);
  }

  getSourceList() {
    const apiURL =
      environment.urlConfig.baseUrl + '/apiAutomation/getSourceList';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8,'
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  getComparisonTypeList(sourceId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/apiAutomation/getComparisonTypeBySourceId?sourceId=' +
      sourceId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8,'
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  //===========================================PiaChartStatus_Details Api======================================
  // getStatusCountsForPieCharts() {
  //   const getUrl =
  //     environment.urlConfig.baseUrl + 'exection/getStatusCountsForPieCharts';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8,'
  //     })
  //   };
  //   return this.http.get(getUrl, httpOptions);
  // }

  getStatusCountsForPieCharts() {
    const getUrl =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getStatusCountsForPieCharts';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8,'
      })
    };
    return this.http.get(getUrl, httpOptions);
  }

  jira(details) {
    const apiURL1 = 'https://siliconstack.atlassian.net/rest/api/2/issue';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization:
          'Basic QW1iYWRhcy5rZWRhckBzaWxpY29uc3RhY2suY29tLmF1OlRxYkJ0NkR6Y1lRRTZ1UGJtRVFaM0Q5Mg=='
      })
    };
    return this.http.post(apiURL1, httpOptions);
  }
  // exportElementsDataAsObject(testSuiteId) {
  //   const urlasDataObject =
  //     environment.urlConfig.baseUrl +
  //     'util/exportElementsDataAsObject?testSuiteId=' +
  //     testSuiteId;
  //   return this.http.get(urlasDataObject, { responseType: 'blob' });
  // }

  exportElementsDataAsObject(testSuiteId) {
    const urlasDataObject =
      environment.urlConfig.baseUrl +
      '/execution/exportElementsDataAsObject?testSuiteId=' +
      testSuiteId;
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(urlasDataObject, httpOptions);
  }

  //Import File Data
  // addImportFileData(caseDetails) {
  //   const apiURL = environment.urlConfig.baseUrl + 'util/addImportFileData';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http
  //     .post(apiURL, JSON.stringify(caseDetails), httpOptions)
  //     .toPromise();
  // }

  addImportFileData(caseDetails) {
    const apiURL =
      environment.urlConfig.baseUrl + '/testCaseManagement/addImportFileData';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http
      .post(apiURL, JSON.stringify(caseDetails), httpOptions)
      .toPromise();
  }

  //Get All Jira Name
  // getAllJiraProjectsName() {
  //   const exportURL = environment.urlConfig.baseUrl + 'util/getJiraProject';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(exportURL, httpOptions);
  // }
  // readFile(path) {
  //   return this.http.get(path, { responseType: 'text' });
  // }

  getAllJiraProjectsName() {
    const exportURL =
      environment.urlConfig.baseUrl + '/testCaseManagement/getJiraProject';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(exportURL, httpOptions);
  }

  // exportData(executionHeaderId, HistoryID, languageId) {
  //   const serverIP = environment.urlConfig.baseUrl;
  //   const exportURL =
  //     environment.urlConfig.baseUrl +
  //     'util/exportData?executionHeaderId=' +
  //     executionHeaderId +
  //     '&executionHistoryId=' +
  //     HistoryID +
  //     '&serverIP=' +
  //     serverIP +
  //     '&languageId=' +
  //     languageId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(exportURL, httpOptions);
  // }

  startJobExecution(executionHeaderId, HistoryID, executionMode, node) {
    const exportURL =
      environment.urlConfig.baseUrl +
      '/execution/startJobExecution?executionHeaderId=' +
      executionHeaderId +
      '&executionHistoryId=' +
      HistoryID +
      '&executionMode=' +
      executionMode +
      '&node=' +
      node;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(exportURL, httpOptions);
  }

  exportData(executionHeaderId, HistoryID, languageId) {
    const serverIP = environment.urlConfig.baseUrl;
    const exportURL =
      environment.urlConfig.baseUrl +
      '/execution/exportData?executionHeaderId=' +
      executionHeaderId +
      '&executionHistoryId=' +
      HistoryID +
      '&serverIP=' +
      serverIP +
      '&languageId=' +
      languageId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(exportURL, httpOptions);
  }

  // getJenkinsBuildLog(jenkinsJobId, strJobName) {
  //   const url = environment.urlConfig.jenkinsUrl;
  //   const apiURL1 =
  //     environment.urlConfig.baseUrl +
  //     'util/getJenkinsBuildLog?jenkinsJobId=' +
  //     jenkinsJobId +
  //     '&url=' +
  //     url +
  //     '&jobType=' +
  //     strJobName;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL1, httpOptions);
  // }

  getJenkinsBuildLog(exeHistoryId, strJobName) {
    const url = environment.urlConfig.jenkinsUrl;
    const apiURL1 =
      environment.urlConfig.baseUrl +
      '/execution/getJenkinsBuildLog?exeHistoryId=' +
      exeHistoryId;
    // '&url=' +
    // url +
    // '&jobType=' +
    // strJobName;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL1, httpOptions);
  }

  // updateHistoryWithArn(historyId, jobarn, mobileAppFileName) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/updateHistoryDetailsARN?exeHistoryId=' +
  //     historyId +
  //     '&jobArn=' +
  //     jobarn +
  //     '&mobileAppFileName=' +
  //     mobileAppFileName;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8'
  //     })
  //   };
  //   return this.http.post(apiURL, httpOptions);
  // }

  updateHistoryWithArn(historyId, jobarn, mobileAppFileName) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/updateHistoryDetailsARN?exeHistoryId=' +
      historyId +
      '&jobArn=' +
      jobarn +
      '&mobileAppFileName=' +
      mobileAppFileName;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, httpOptions);
  }
  // updateHistorystatus(exeHistoryId, isStartTime, jobStatus, runStatus) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/updateHistoryStatus?exeHistoryId=' +
  //     exeHistoryId +
  //     '&isStartTime=' +
  //     isStartTime +
  //     '&jobStatus=' +
  //     jobStatus +
  //     '&runStatus=' +
  //     runStatus;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, httpOptions);
  // }

  updateHistorystatus(exeHistoryId, isStartTime, jobStatus, runStatus) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/updateHistoryStatus?exeHistoryId=' +
      exeHistoryId +
      '&isStartTime=' +
      isStartTime +
      '&jobStatus=' +
      jobStatus +
      '&runStatus=' +
      runStatus;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, httpOptions);
  }

  // addDeviceFarmExtentedReport(exeHeaderId, exeHistoryId, fileUrl, testSuiteId) {
  //   let details = {
  //     exeHeaderId: exeHeaderId,
  //     exeHistoryId: exeHistoryId,
  //     urls: fileUrl,
  //     testSuiteId: testSuiteId
  //   };
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'util/addDeviceFarmExtentedReport';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http
  //     .post(apiURL, JSON.stringify(details), httpOptions)
  //     .toPromise();
  // }

  addDeviceFarmExtentedReport(exeHeaderId, exeHistoryId, fileUrl, testSuiteId) {
    let details = {
      exeHeaderId: exeHeaderId,
      exeHistoryId: exeHistoryId,
      urls: fileUrl,
      testSuiteId: testSuiteId
    };
    const apiURL =
      environment.urlConfig.baseUrl + '/execution/addDeviceFarmExtentedReport';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http
      .post(apiURL, JSON.stringify(details), httpOptions)
      .toPromise();
  }

  //updated exended report
  // updateDeviceFarmExtentedReport(exeHeaderId, exeHistoryId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'util/updateReportPathForExtentReport?exeHeaderId=' +
  //     exeHeaderId +
  //     '&exeHistoryId=' +
  //     exeHistoryId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, httpOptions);
  // }

  getProjectNameByTypeId_exc(regionId, brandId, projectTypeId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/getProjectName?brandId=' +
      brandId +
      '&projectTypeId=' +
      projectTypeId +
      '&regionId=' +
      regionId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  filter(key, value) {
    this.customFilter = {};
    this.customFilter[key] = value;
    return this.customFilter;
  }

  // getApplianceByBrand(brandId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'appliance/getApplianceByBrand?brandId=' +
  //     brandId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions).toPromise();
  // }

  getApplianceByBrand(brandId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/masterData/getApplianceByBrand?brandId=' +
      brandId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions).toPromise();
  }

  // exportElementFile(brandId, platformId, projectTypeId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'util/exportElementFile?brandId=' +
  //     brandId +
  //     '&platformId=' +
  //     platformId +
  //     '&projectTypeId=' +
  //     projectTypeId;
  //   return this.http.get(apiURL, { responseType: 'blob' });
  // }

  exportElementFile(brandId, platformId, projectTypeId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/exportElementFile?brandId=' +
      brandId +
      '&platformId=' +
      platformId +
      '&projectTypeId=' +
      projectTypeId;
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  // cloneTestSuite(details) {
  //   const apiURL = environment.urlConfig.baseUrl + 'testsuite/cloneTestSuite';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }
  cloneTestSuite(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/testCaseManagement/cloneTestSuite';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',

        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // cloneTestCase(details) {
  //   const apiURL = environment.urlConfig.baseUrl + 'testcase/cloneTestCase';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  cloneTestCase(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/testCaseManagement/cloneTestCase';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // Added by akash===

  // getExtendedReportListById(exeHeaderId, exeHistoryId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/getExtendedReportList?exeHeaderId=' +
  //     exeHeaderId +
  //     '&exeHistoryId=' +
  //     exeHistoryId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions).toPromise();
  // }

  getExtendedReportListById(
    exeHeaderId,
    exeHistoryId,
    searchBySuite,
    searchBy
  ) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/getExtendedReportList?exeHeaderId=' +
      exeHeaderId +
      '&exeHistoryId=' +
      exeHistoryId +
      '&searchBySuite=' +
      searchBySuite +
      '&searchBy=' +
      searchBy;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions).toPromise();
  }

  // getDashboardCountByRegion() {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'dashboard/getDashboardCounts';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8'
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }

  getDashboardCountByRegion() {
    const apiURL =
      environment.urlConfig.baseUrl + '/dashBoard/getDashboardCounts';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  // getDashboardCountForTestSuiteTrends(
  //   endTime,
  //   platformId,
  //   projectId,
  //   projectTypeId,
  //   regionId,
  //   startDate,
  //   suiteId
  // ) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/getDashboardCountsForTestExecutionTrends?endDate=' +
  //     endTime +
  //     'platformId=' +
  //     platformId +
  //     'projectId=' +
  //     projectId +
  //     'projectTypeId=' +
  //     projectTypeId +
  //     'regionId=' +
  //     regionId +
  //     'startDate=' +
  //     startDate +
  //     'suiteId=' +
  //     suiteId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }
  /*---------add jenkins Job id Api-----------------*/
  // addJenkinsJobId(exeHistoryId, jenkinsJobId) {
  //   let details = {
  //     exeHistoryId: exeHistoryId,
  //     jenkinsJobId: jenkinsJobId
  //   };
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/addJenkinsJobId?exeHistoryId=' +
  //     exeHistoryId +
  //     '&jenkinsJobId=' +
  //     jenkinsJobId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  addJenkinsJobId(exeHistoryId, jenkinsJobId) {
    let details = {
      exeHistoryId: exeHistoryId,
      jenkinsJobId: jenkinsJobId
    };
    const apiURL =
      environment.urlConfig.baseUrl +
      '/exection/addJenkinsJobId?exeHistoryId=' +
      exeHistoryId +
      '&jenkinsJobId=' +
      jenkinsJobId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // downloadAppiumLog(exeHeaderId, exeHistoryId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'util/downloadAppiumLogFile?exeHeaderId=' +
  //     exeHeaderId +
  //     '&exeHisotryId=' +
  //     exeHistoryId;
  //   return this.http.get(url, { responseType: 'text' });
  // }

  downloadAppiumLog(exeHeaderId, exeHistoryId) {
    const url =
      environment.urlConfig.baseUrl +
      '/execution/downloadAppiumLogFile?exeHeaderId=' +
      exeHeaderId +
      '&exeHistoryId=' +
      exeHistoryId;
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        Accept: 'application/text',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  uploadAppiumLog(exeHeaderId, exeHistoryId) {
    const url =
      environment.urlConfig.baseUrl +
      '/execution/uploadAppiumLog?exeHeaderId=' +
      exeHeaderId +
      '&exeHistoryId=' +
      exeHistoryId;
    const httpOptions = {
      // responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(url, httpOptions);
  }

  downloadDeviceFarmExecutionLog(exeHeaderId, exeHistoryId) {
    const url =
      environment.urlConfig.baseUrl +
      '/execution/downloadDeviceFarmExecutionLog?exeHeaderId=' +
      exeHeaderId +
      '&exeHistoryId=' +
      exeHistoryId;
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        Accept: 'application/text',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  findIsExists(sourceArr, destinationArr) {
    var result = sourceArr.filter(function(o1) {
      // filter out (!) items in result2
      return destinationArr.some(function(o2) {
        return (
          o1.name === o2.name &&
          o1.os === o2.os &&
          o1.formFactor === o2.formFactor &&
          o1.availability === o2.availability &&
          o1.platform === o2.platform &&
          o1.udid === o2.udid &&
          o1.deviceType === o2.deviceType &&
          o1.selected === o2.selected &&
          o1.id === o2.id
        ); // assumes unique id
      });
    });
    return result.length == destinationArr.length;
  }

  // getJobHistory(searchBy, pageNo, pageSize) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/getJobHistory?pageNo=' +
  //     pageNo +
  //     '&pageSize=' +
  //     pageSize +
  //     '&searchBy=' +
  //     searchBy;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }

  getJobHistory(searchBy, pageNo, pageSize, searchBySuite) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/getJobHistory?pageNo=' +
      pageNo +
      '&pageSize=' +
      pageSize +
      '&searchBy=' +
      searchBy +
      '&searchBySuite=' +
      searchBySuite;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  // getJobHistoryDetails(exeHeaderId, exeHistoryId, seeMore) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/getHistoryDetails?exeHeaderId=' +
  //     exeHeaderId +
  //     '&exeHistoryId=' +
  //     exeHistoryId +
  //     '&seeMore=' +
  //     seeMore;

  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       Authorization: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }

  // getJobHistoryDetails(exeHeaderId, exeHistoryId, seeMore) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/getHistoryDetails?exeHeaderId=' +
  //     exeHeaderId +
  //     '&exeHistoryId=' +
  //     exeHistoryId +
  //     '&seeMore=' +
  //     seeMore;

  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }
  getJobHistoryDetails(
    exeHeaderId,
    exeHistoryId,
    seeMore,
    searchBySuite,
    searchBy
  ) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/getHistoryDetails?exeHeaderId=' +
      exeHeaderId +
      '&exeHistoryId=' +
      exeHistoryId +
      '&seeMore=' +
      seeMore +
      '&searchBySuite=' +
      searchBySuite +
      '&searchBy=' +
      searchBy;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }
  // addReorderTestcases(details) {
  //   const apiURL = environment.urlConfig.baseUrl + 'testcase/re_orderTestCase';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   console.log('response of reorder ---', JSON.stringify(details));
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  addReorderTestcases(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/testCaseManagement/re_orderTestCase';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    console.log('response of reorder ---', JSON.stringify(details));
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // addReorderTestStep(details) {
  //   const apiURL = environment.urlConfig.baseUrl + 'teststep/re_orderTestStep';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   console.log('response of reorder ---', JSON.stringify(details));
  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  addReorderTestStep(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/testCaseManagement/re_orderTestStep';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    console.log('response of reorder ---', JSON.stringify(details));
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  saveJenkinsJob(details) {
    const apiURL = environment.urlConfig.baseUrl + '/execution/saveJenkinsLog';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    console.log('response of reorder ---', JSON.stringify(details));
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // downloadJenkinsLog(jenkinsLogFilePath) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'util/downloadJenkinsLog?fileurl=' +
  //     jenkinsLogFilePath;
  //   return this.http.get(url, { responseType: 'text' });
  // }

  downloadJenkinsLog(jenkinsLogFilePath) {
    const url =
      environment.urlConfig.baseUrl +
      '/execution/downloadJenkinsLog?fileurl=' +
      jenkinsLogFilePath;
    const httpOptions = {
      responseType: 'blob' as 'text',
      headers: new HttpHeaders({
        // Accept: 'application/octet-stream',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(url, JSON.stringify(jenkinsLogFilePath), httpOptions);
  }

  /*login API*/
  login(password, username) {
    let details = {
      password: password,
      username: username
    };
    const apiURL = environment.urlConfig.baseUrl + 'authenticate';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  login_micro(password, username) {
    let encoded: string = btoa(password);
    console.log(encoded);
    let details = {
      password: encoded,
      username: username
    };
    const apiURL = environment.urlConfig.baseUrl + '/Token-Generator';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
      })
    };
    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // showExtendReport(path) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'util/downloadExtendReportFile?fileurl=' +
  //     encodeURIComponent(path);
  //   return this.http.get(url);
  // }

  showExtendReport(path) {
    const url =
      environment.urlConfig.baseUrl +
      '/execution/downloadExtendReportFile?fileurl=' +
      encodeURIComponent(path);
    return this.http.get(url);
  }
  //<============================get template name by header id=====================================>
  // getTemplateNamebyHeaderId(headerId) {
  //   const baseUrl =
  //     environment.urlConfig.baseUrl +
  //     'exection/getTemplateNameByHeaderId?exeHeaderId=' +
  //     headerId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(baseUrl, httpOptions);
  // }

  getTemplateNamebyHeaderId(headerId) {
    const baseUrl =
      environment.urlConfig.baseUrl +
      '/execution/getTemplateNameByHeaderId?exeHeaderId=' +
      headerId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(baseUrl, httpOptions);
  }

  // checkTestSuiteUsedInExecution(testSuiteId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'testsuite/checkTestSuiteUsedInExecution?testSuiteId=' +
  //     testSuiteId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }

  checkTestSuiteUsedInExecution(testSuiteId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/testCaseManagement/checkTestSuiteUsedInExecution?testSuiteId=' +
      testSuiteId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, httpOptions);
  }

  // =====================================Language API's==============================================================

  // saveLanguage(details) {
  //   const apiURL = environment.urlConfig.baseUrl + 'language/addLanguage';

  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };

  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  saveLanguage(details) {
    const apiURL = environment.urlConfig.baseUrl + '/masterData/addLanguage';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };

    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // getAllLanguage() {
  //   const url = environment.urlConfig.baseUrl + 'language/getAllLanguage';
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getAllLanguage() {
    const url = environment.urlConfig.baseUrl + '/masterData/getAllLanguage';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getByLanguageId(languageId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'language/getLanguageById?languageId=' +
  //     languageId;

  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getByLanguageId(languageId) {
    const url =
      environment.urlConfig.baseUrl +
      '/masterData/getLanguageById?languageId=' +
      languageId;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // deleteLanguageById(languageId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'language/deleteLanguage?languageId=' +
  //     languageId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }

  deleteLanguageById(languageId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/masterData/deleteLanguage?languageId=' +
      languageId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }

  // =====================================Label API's==============================================================

  // saveLabel(details) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'languageLabel/addlanguageLabel';

  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };

  //   return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  // }

  saveLabel(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/masterData/addlanguageLabel';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };

    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  // getAllLabel() {
  //   const url = environment.urlConfig.baseUrl + 'languageLabel/getAllLabel';
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getAllLabel() {
    const url = environment.urlConfig.baseUrl + '/masterData/getAllLabel';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getByLabelId(labelId) {
  //   const url =
  //     environment.urlConfig.baseUrl +
  //     'languageLabel/getLabelById?labelId=' +
  //     labelId;

  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  getByLabelId(labelId) {
    const url =
      environment.urlConfig.baseUrl +
      '/masterData/getLabelById?labelId=' +
      labelId;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }
  // deleteLabelById(labelId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'languageLabel/deleteLabel?labelId=' +
  //     labelId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }

  deleteLabelById(labelId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/masterData/deleteLabel?labelId=' +
      labelId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }

  // exportLabelFile() {
  //   const apiURL = environment.urlConfig.baseUrl + 'util/exportLabelFile';
  //   return this.http.get(apiURL, { responseType: 'blob' });
  // }

  exportLabelFile() {
    const apiURL =
      environment.urlConfig.baseUrl + '/masterData/exportLabelFile';
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }
  // =============================Get Template List by HistoryId==============================
  // getTemplateNameByHistoryId(historyId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/getTemplateNameByHistoryId?exeHistoryId=' +
  //     historyId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }

  getTemplateNameByHistoryId(historyId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/getTemplateNameByHistoryId?exeHistoryId=' +
      historyId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  // =============================Get Selected Suite cases data set List by HeaderId HistoryId==============================
  // getSelectedSuitedata(headerId, historyId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/getSelectedTestSuites?exeHeaderId=' +
  //     headerId +
  //     '&exeHistoryId=' +
  //     historyId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }
  getSelectedSuitedata(headerId, historyId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/getSelectedTestSuites?exeHeaderId=' +
      headerId +
      '&exeHistoryId=' +
      historyId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  // =============================Get Environment details by HeaderId HistoryId==============================

  // getEnvironmentdetails(headerId, historyId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/getEnvDetailsForTestSuites?exeHeaderId=' +
  //     headerId +
  //     '&exeHistoryId=' +
  //     historyId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }

  getEnvironmentdetails(headerId, historyId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/getEnvDetailsForTestSuites?exeHeaderId=' +
      headerId +
      '&exeHistoryId=' +
      historyId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }
  //  ===============================Update Execution Name ===========================
  // updateExecutionName(exeHeaderId, executionName) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/?exeHeaderId=' +
  //     exeHeaderId +
  //     '&executionName=' +
  //     executionName;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.put(apiURL, httpOptions);
  // }

  updateExecutionName(exeHeaderId, executionName) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/changeTestExecutionName?exeHeaderId=' +
      exeHeaderId +
      '&executionName=' +
      executionName;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, httpOptions);
  }

  //====================================== Delete All Job History By HeaderID=================
  // DeleteAllJob_by_HeaderID(headerID) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/deleteJobHistoryDetails?exeHeaderId=' +
  //     headerID;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };

  //   return this.http.post(apiURL, JSON.stringify(headerID), httpOptions);
  // }

  DeleteAllJob_by_HeaderID(headerID) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/deleteJobHistoryDetails?exeHeaderId=' +
      headerID;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };

    return this.http.post(apiURL, JSON.stringify(headerID), httpOptions);
  }
  //====================================== Delete  Job History By HistoryID===================
  // DeleteAllJob_by_historyID(historyID) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/deleteHistoryById?exeHistoryId=' +
  //     historyID;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };

  //   return this.http.post(apiURL, JSON.stringify(historyID), httpOptions);
  // }

  DeleteAllJob_by_historyID(historyID) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/exection/deleteHistoryById?exeHistoryId=' +
      historyID;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };

    return this.http.post(apiURL, JSON.stringify(historyID), httpOptions);
  }

  //===========================================Applications tab================================//

  saveAppName_copy(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/execution/addBrandWiseApps';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
        // authToken: `${this.authService.authToken}`
      })
    };

    return this.http.post(apiURL, JSON.stringify(details), httpOptions);
  }

  deleteAppName(AppNameId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/deleteBrandWiseApps?appId=' +
      AppNameId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
        // authToken: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }

  getAllBrandWiseAppName(pageNo, pageSize) {
    const url =
      environment.urlConfig.baseUrl +
      '/execution/getAllBrandWiseApps?pageNo=' +
      pageNo +
      '&pagesize=' +
      pageSize;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
        // authToken: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  // getHeadSpinAppArn(AppNameId) {
  //   const url =
  //   environment.urlConfig.baseUrl +
  //     '/execution/getHeadSpinAppArn?appId=' +
  //     AppNameId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8'
  //       // authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(url, httpOptions);
  // }

  FileUpload(formdetails) {
    const url =
      environment.urlConfig.baseUrl +
      '/execution/getPresignedUrlForApkUpload?brandId=' +
      formdetails.brandId +
      '&env=' +
      formdetails.env +
      '&platformId=' +
      formdetails.platformId +
      '&fileName=' +
      formdetails.fileName;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
        // authToken: `${this.authService.authToken}`
      })
    };
    return this.http.post(url, JSON.stringify(formdetails), httpOptions);
  }

  getAllBrandWiseAppsbysearch(searchValue, pageNo, pageSize) {
    let url = '';
    url =
      environment.urlConfig.baseUrl +
      '/execution/getAllBrandWiseApps?searchBy=' +
      searchValue +
      '&pageNo=' +
      pageNo +
      '&pageSize=' +
      pageSize;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
        // authToken: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  PreSignedUrl(presignedUrl, body) {
    // const httpOptions = {
    //   headers: new HttpHeaders({}).set(InterceptorSkipHeader,'')
    // };
    return this.http.post(presignedUrl, body);
  }

  //==================================Test suite execution trend (widget 1)========================
  // testExeTrend(dashboardDetails) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'dashboard/getDashboardCountsForTestExecutionTrends';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };

  //   return this.http.post(
  //     apiURL,
  //     JSON.stringify(dashboardDetails),
  //     httpOptions
  //   );
  // }

  testExeTrend(dashboardDetails) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/getDashboardCountsForTestExecutionTrend';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };

    return this.http.post(
      apiURL,
      JSON.stringify(dashboardDetails),
      httpOptions
    );
  }

  //==================================Test suite execution time trend (widget 2)========================

  // testExeTimeTrend(dashboardDetails) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'dashboard/getDashboardCountsForTestExecutionTimeTrends';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };

  //   return this.http.post(
  //     apiURL,
  //     JSON.stringify(dashboardDetails),
  //     httpOptions
  //   );
  // }

  testExeTimeTrend(dashboardDetails) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/dashBoard/getDashboardCountsForTestExecutionTimeTrends';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };

    return this.http.post(
      apiURL,
      JSON.stringify(dashboardDetails),
      httpOptions
    );
  }
  //==================================Test suite execution count trend (widget 3)========================

  // testSuiteExeStatusTrend(dashboardSuiteStatusDetails) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl + 'dashboard/getDashboardSuiteCountTrend';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };

  //   return this.http.post(
  //     apiURL,
  //     JSON.stringify(dashboardSuiteStatusDetails),
  //     httpOptions
  //   );
  // }

  testSuiteExeStatusTrend(dashboardSuiteStatusDetails) {
    const apiURL =
      environment.urlConfig.baseUrl + '/execution/getDashboardCaseCountTrend';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };

    return this.http.post(
      apiURL,
      JSON.stringify(dashboardSuiteStatusDetails),
      httpOptions
    );
  }

  //==================================Monthly execution status (widget 4)========================

  // monthlyExestatus(dashboardSuiteStatusDetails) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'dashboard/getDashboardSuiteMonthlyExeStatus';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(
  //     apiURL,
  //     JSON.stringify(dashboardSuiteStatusDetails),
  //     httpOptions
  //   );
  // }

  monthlyExestatus(dashboardSuiteStatusDetails) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/dashBoard/getDashboardSuiteMonthlyExeStatus';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(
      apiURL,
      JSON.stringify(dashboardSuiteStatusDetails),
      httpOptions
    );
  }

  //==================================Count for Test suite execution time trend (widget 6)========================

  // getDashboardSuiteExeTimeProjectWise(dashboardSuiteStatusDetails) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'dashboard/getDashboardSuiteExeTimeProjectWise';
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.post(
  //     apiURL,
  //     JSON.stringify(dashboardSuiteStatusDetails),
  //     httpOptions
  //   );
  // }

  getDashboardSuiteExeTimeProjectWise(dashboardSuiteStatusDetails) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/dashBoard/getDashboardSuiteExeTimeProjectWise';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(
      apiURL,
      JSON.stringify(dashboardSuiteStatusDetails),
      httpOptions
    );
  }
  //==================================delete device farm capability ========================
  // deleted_dfCapability(capabilityId, userTemplateName, userId) {
  //   const apiURL =
  //     environment.urlConfig.baseUrl +
  //     'exection/deleteDeviceFarmCapability?capabilityId=' +
  //     capabilityId +
  //     '&usrTemplateName=' +
  //     userTemplateName +
  //     '&userId=' +
  //     userId;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       authToken: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.delete(apiURL, httpOptions);
  // }

  // getAllHeadspinDevices() {
  //   const apiURL = environment.urlConfig.headspinBaseUrl + 'devices';
  //   const httpOptions = {
  //     headers: new HttpHeaders({}).set(headSpinToken, 'true')
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }

  getAllHeadspinDevices() {
    const apiURL =
      environment.urlConfig.baseUrl + '/execution/getHeadSpinDevices';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  getHeadSpinAppArn(appId) {
    // let appId=3;
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/getHeadSpinAppArn?appId=' +
      appId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }
  getAllDevicesAvailability() {
    let postText = {};
    const apiURL = environment.urlConfig.headspinBaseUrl + 'devices/unlock';
    const httpOptions = {
      headers: new HttpHeaders({}).set(headSpinToken, 'true')
    };
    return this.http.post(apiURL, postText, httpOptions);
  }

  // getUploadedAppFile(platform) {
  //   let apiURL;
  //   if (platform == Constvar.Android) {
  //     apiURL = environment.urlConfig.headspinBaseUrl + 'apps/apks';
  //   } else {
  //     apiURL = environment.urlConfig.headspinBaseUrl + 'apps/ipas';
  //   }
  //   const httpOptions = {
  //     headers: new HttpHeaders({}).set(headSpinToken, 'true')
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }

  // getUploadedAppFile(platform) {
  //   let apiURL;
  //   if (platform == Constvar.Android) {
  //     apiURL =
  //       environment.urlConfig.baseUrl +
  //       '/execution/getHeadSpinFilesList?whichFile=apk';
  //   } else {
  //     apiURL =
  //       environment.urlConfig.baseUrl +
  //       '/execution/getHeadSpinFilesList?whichFile=ipk';
  //   }
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json;charset=UTF-8',
  //       Authorization: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }

  getAllUploadedAppListOnDevices(deviceId) {
    let apiURL =
      environment.urlConfig.headspinBaseUrl +
      'idevice/' +
      deviceId +
      '/installer/list?json';
    const httpOptions = {
      headers: new HttpHeaders({}).set(headSpinToken, 'true')
    };
    return this.http.get(apiURL, httpOptions);
  }

  getAllUploadedAppListOnAndroidDevices(deviceId, appPackage) {
    let reqData = this.androidGrepCommand + ' ' + appPackage;
    let apiURL =
      environment.urlConfig.headspinBaseUrl + 'adb/' + deviceId + '/shell';
    const httpOptions = {
      headers: new HttpHeaders({}).set(headSpinToken, 'true')
    };
    return this.http.post(apiURL, reqData, httpOptions);
  }

  uploadImg_for_Comparison(testsuiteId, testCaseId, testStepId, imageFile) {
    const apiURL =
      environment.urlConfig.baseUrl +
      'teststep/uploadCompareImage?testCaseId=' +
      testCaseId +
      '&testStepId=' +
      testStepId +
      '&testSuiteId=' +
      testsuiteId;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, imageFile, httpOptions);
  }

  updatePackageArn(historyID, appPackageArn) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/updateTestPackageARN?exeHistoryId=' +
      historyID +
      '&testPackageARN=' +
      appPackageArn;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      })
    };
    return this.http.post(apiURL, httpOptions);
  }

  scheduleRun(scheduleObj) {
    const apiURL = environment.urlConfig.baseUrl + '/execution/scheduleRun';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      })
    };
    return this.http.post(apiURL, JSON.stringify(scheduleObj), httpOptions);
  }

  getDevice(details) {
    const apiURL =
      environment.urlConfig.baseUrl + '/execution/getDeviceListByDeviceArn';
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; charset=utf-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, details, httpOptions);
  }

  getDevicePool(arn) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/getDevicePoolByDevicePoolArn?devicePoolArn=' +
      arn;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  listDevicePools() {
    const apiURL =
      environment.urlConfig.baseUrl + '/execution/getDevicePoolList';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  getUploadedAppFile(brandId, env, platformId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/getAppsByBrandAndEnv?brandId=' +
      brandId +
      '&env=' +
      env +
      '&platformId=' +
      platformId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  // stopRun(historyId){
  //   const apiURL = environment.urlConfig.baseUrl +
  //   '/execution/terminateJobOnAWSDeviceFarm?historyId=' +
  //   historyId ;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       // 'Content-Type': 'application/json; charset=utf-8',
  //       Authorization: `${this.authService.authToken}`
  //     })
  //   };
  //   return this.http.get(apiURL, httpOptions);
  // }

  getNodeList() {
    const apiURL = environment.urlConfig.baseUrl + '/execution/getNodeList';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  getDeviceListFromNode(platformName, nodeName) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/getDeviceListFromNode?platformName=' +
      platformName +
      '&nodeName=' +
      nodeName;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  listUploads(brandId, env, platformId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/getAppsByBrandAndEnv?brandId=' +
      brandId +
      '&env=' +
      env +
      '&platformId=' +
      platformId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  startDeviceNodeJob(nodeName, platform) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/startDeviceNodeJob?nodeName=' +
      nodeName +
      '&platformName=' +
      platform;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  getBuildNumberForDeviceList(nodeName, platform) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/getBuildNumberForDeviceList?nodeName=' +
      nodeName +
      '&platformName=' +
      platform;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  getConsoleText(buildNumber, platform) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/getConsoleText?buildNumber=' +
      buildNumber +
      '&platformName=' +
      platform;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  stopRun(exeHistoryId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/stopJob?exeHistoryId=' +
      exeHistoryId;
    const httpOptions = {
      responseType: 'text' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  checkIfCaseIsDeleted(executionHeaderId, executionHistoryId) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/checkSelectedTestSuites?exeHeaderId=' +
      executionHeaderId +
      '&exeHistoryId=' +
      executionHistoryId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  //======================================================================================================================
  // async getLocalDevices(
  //   slaveOS,
  //   selectedSlave,
  //   platform,
  //   isFromStartExe
  // ): Promise<any> {
  //   if (!isFromStartExe) {
  //     this.loaderService.show();
  //   } else {
  //     this.loaderService.hide();
  //   }

  //   this.platform = platform;
  //   this.slaveOS = slaveOS;

  //   let jobName = '';
  //   if (slaveOS != null && slaveOS != undefined && slaveOS != '') {
  //     console.log('slaveOS in if', slaveOS);

  //     if (slaveOS.toString().indexOf('Windows') > -1) {
  //       //  For Android
  //       jobName = 'getandroiddevices_node';
  //     }
  //     // For IOS
  //     else {
  //       jobName = 'getiosdevices';
  //     }
  //   } else {
  //     this.loaderService.hide();
  //     console.log('slaveOS in else', slaveOS);
  //     return this.toastr.errorToastr('Something went wrong.Please try again.');
  //   }
  //   let doc = this;
  //   let statusCode;
  //   await new Promise(async (resolve, reject) => {
  //     await jenkins.build_with_params(
  //       jobName,
  //       // Akash added Platform
  //       {
  //         mode: 'no-cors',
  //         RunJobNode: selectedSlave,
  //         Platform: platform,
  //         delay: 0
  //       }, //Platform: doc.platform,
  //       async function(err, data) {
  //         if (err) {
  //           doc.IsjobName_error = err;
  //           doc.isDevicesLoading = false;
  //           $('#btnSelectDevices').prop('disabled', false);
  //           setTimeout(() => {
  //             doc.IsjobName_error = '';
  //           }, 2500);
  //           doc.loaderService.hide();
  //           doc.toastr.errorToastr(
  //             'Please check whether your node has internet connection, selected node is online'
  //           );
  //           return console.log(
  //             'jenkins build error----build_with_params -->' + err
  //           );
  //         }
  //         console.log(data);
  //         resolve(data);
  //         doc.loaderService.hide();
  //       }
  //     );
  //   });
  //   return jobName;
  // }

  // async getJobInfo_by_jobName(jobName): Promise<any> {
  //   let statusCode;
  //   let getlocalDevicesData;
  //   let doc = this;
  //   await new Promise((resolve, reject) => {
  //     jenkins.job_info(jobName, async function(err, data) {
  //       if (err) {
  //         doc.IsjobName_error = err;
  //         doc.isDevicesLoading = false;
  //         $('#btnSelectDevices').prop('disabled', false);
  //         setTimeout(() => {
  //           doc.IsjobName_error = '';
  //           doc.toastr.errorToastr(
  //             'Please check whether your node has internet connection, selected node is online'
  //           );
  //         }, 2500);
  //         return console.log(err);
  //       }
  //       console.log(data);
  //       resolve(data);
  //       getlocalDevicesData = data;
  //       statusCode = data.statusCode;
  //     });
  //   });
  //   return getlocalDevicesData;
  // }

  // async readConsoleOutPut(jobName, data, jobType): Promise<any> {
  //   let doc = this;
  //   let consoleOutdata_res;
  //   await new Promise(async (resolve, reject) => {
  //     jenkins.console_output(jobName, data.builds[0].number, async function(
  //       err,
  //       data
  //     ) {
  //       if (err) {
  //         reject(err);
  //         doc.IsjobName_error = err;
  //         doc.isDevicesLoading = false;
  //         $('#btnSelectDevices').prop('disabled', false);
  //         setTimeout(() => {
  //           doc.IsjobName_error = '';
  //         }, 2500);
  //         return console.log(
  //           'jenkins console output error for job ' + jobName + ' -->' + err
  //         );
  //       } else {
  //         console.log('data============1', data);
  //         consoleOutdata_res = data;
  //         doc.consoleOut_datares = data;
  //         resolve(data);
  //       }
  //     });
  //   });
  //   if (consoleOutdata_res) {
  //     return { consoleOutdata_res, doc, jobType };
  //   }
  // }

  async parseAndroidFile(data, doc, active_step): Promise<any> {
    let file = data.ConsoleText;
    if (file != undefined) {
      try {
        file = file.split(': ').join(':');

        var startIndex,
          arrStartIndex = [];
        var endIndex,
          arrEndIndex = [];

        while (
          (startIndex = file.indexOf('[ro.boot.serialno]', startIndex + 1)) !=
          -1
        ) {
          arrStartIndex.push(startIndex);
        }
        while ((endIndex = file.indexOf('Next Device', endIndex + 1)) != -1) {
          arrEndIndex.push(endIndex + 1);
        }
        this.serverDevices = [];
        console.log('Start - ', arrStartIndex);
        console.log('End - ', arrEndIndex);

        for (let i = 0; i <= arrStartIndex.length - 1; i++) {
          let deviceProperty = {
            name: '',
            os: '',
            formFactor: '',
            availability: '',
            platform: '',
            udid: '',
            deviceType: ''
          };

          let fileValue = file.substring(arrStartIndex[i], arrEndIndex[i]);
          console.log('file value--------?', fileValue);

          let objFile = fileValue.split('\n');

          objFile.forEach(element => {
            let objVal = element.split(':');

            if (
              objVal[0]
                .toString()
                .replace('[', '')
                .replace(']', '') == 'ro.build.version.release'
            ) {
              console.log('objVal----version?', objVal);
              deviceProperty.os = objVal[1]
                .toString()
                .replace('[', '')
                .replace(']', '')
                .trim();
            } else if (
              objVal[0]
                .toString()
                .replace('[', '')
                .replace(']', '') == 'ro.product.model'
            ) {
              console.log('objVal-------model', objVal);
              deviceProperty.name =
                deviceProperty.name.toString().trim() +
                '_' +
                objVal[1]
                  .toString()
                  .replace('[', '')
                  .replace(']', '')
                  .toUpperCase()
                  .trim();
              console.log(
                'deviceProperty.name............>',
                deviceProperty.name
              );
            } else if (
              objVal[0]
                .toString()
                .replace('[', '')
                .replace(']', '') == 'ro.product.brand'
            ) {
              console.log('objVal------brand', objVal);
              deviceProperty.name = objVal[1]
                .toString()
                .replace('[', '')
                .replace(']', '')
                .toUpperCase()
                .trim();
              deviceProperty.formFactor = 'PHONE';
              deviceProperty.availability = 'AVAILABLE';
              deviceProperty.platform = 'ANDROID';
              deviceProperty.deviceType = 'Physical';
            } else if (
              objVal[0]
                .toString()
                .replace('[', '')
                .replace(']', '') == 'DEVICEUDID'
            ) {
              console.log('objVal------udid', objVal);
              deviceProperty.name =
                deviceProperty.name.toString().trim() +
                '_' +
                objVal[1].toString().toUpperCase();
              console.log('checkkkkkkkkkkkkkkkkkkkkkkkk', deviceProperty.name);

              deviceProperty.udid = objVal[1]
                .toString()
                .replace('[', '')
                .replace(']', '')
                .trim();
            }
          });
          await this.serverDevices.push(deviceProperty);
        }
        let index = 1;
        this.serverDevices.forEach(function(element) {
          element.selected = false;
          element.id = index;
          index = index + 1;
        });

        setTimeout(() => {
          //to open pop up only for select devices step(code by Mustayeed)
          if (active_step == 3) {
            $('#SelectDeviceTemplateModal').modal('show');
          } else {
            $('#SelectDeviceTemplateModal').modal('hide');
          }

          $('#btnSelectDevices').prop('disabled', false);
          $('#startExeBtnView').prop('disabled', false);
          this.isDevicesLoading = false;
        }, 1000);
        this.isconsoleOut = false;
      } catch (exception) {
        this.isconsoleOut = true;
        console.log('Fialed to load divices');
        $('#btnSelectDevices').prop('disabled', false);
        doc.isDevicesLoading = false;
        doc.IsjobName_error = exception;
        doc.ref.detectChanges();
        setTimeout(() => {
          doc.IsjobName_error = '';
          doc.ref.detectChanges();
        }, 2500);
      }
    }
    console.log('serverDevices length', this.serverDevices.length);
    return this.serverDevices;
  }

  async parseIosFile(data, doc, active_step): Promise<any> {
    this.serverDevices = [];
    let iOsFile = data.ConsoleText;
    if (iOsFile != undefined) {
      try {
        var objIosFile = iOsFile.split('Known Devices:');

        var objData = objIosFile[0].split('\n');
        console.log('obj data=====>', objData);

        objData.forEach(element => {
          if (element != '' && element.toString().indexOf('Finished') == -1) {
            let deviceProperty = {
              name: '',
              os: '',
              formFactor: '',
              availability: '',
              platform: '',
              udid: '',
              deviceType: ''
            };

            let objIosVal = element.split(' (');
            if (
              objIosVal[0]
                .toString()
                .replace('\n', '')
                .toString()
                .indexOf('iPhone') > -1
            ) {
              if (
                objIosVal[0]
                  .toString()
                  .replace('\n', '')
                  .toString()
                  .indexOf('(') > -1
              ) {
                let val = objIosVal[0].split('(');
                deviceProperty.name =
                  val[0]
                    .replace('\n', '')
                    .toString()
                    .replace('Simulator', '')
                    .trim() +
                  '_' +
                  objIosVal[0]
                    .replace(')', '')
                    .toString()
                    .replace('Simulator', '')
                    .trim();

                if (val.length - 1 == 1) {
                  deviceProperty.os = val[1].replace(')', '');
                } else if (val.length - 1 == 2) {
                  deviceProperty.os = val[2].replace(')', '');
                } else if (val.length - 1 == 3) {
                  deviceProperty.os = val[3].replace(')', '');
                } else {
                  deviceProperty.os = val[1].replace(')', '');
                }

                deviceProperty.udid = objIosVal[2]
                  .replace(')', '')
                  .toString()
                  .replace('Simulator', '')
                  .trim();
              } else {
                deviceProperty.name =
                  objIosVal[0]
                    .replace('\n', '')
                    .toString()
                    .replace('Simulator', '')
                    .trim() +
                  '_' +
                  objIosVal[objIosVal.length - 1]
                    .replace(')', '')
                    .toString()
                    .replace('Simulator', '')
                    .trim();
                deviceProperty.udid = objIosVal[objIosVal.length - 1]
                  .replace(')', '')
                  .toString()
                  .replace('Simulator', '')
                  .trim();
                if (objIosVal[1].includes('Simulator')) {
                  deviceProperty.os = objIosVal[2]
                    .split(')')[0]
                    .replace(')', '')
                    .toString();
                } else {
                  deviceProperty.os = objIosVal[1]
                    .split(')')[0]
                    .replace(')', '')
                    .toString();
                }
              }
            }
            if (
              objIosVal[0]
                .toString()
                .replace('\n', '')
                .toString()
                .indexOf('iPhone') > -1
            ) {
              deviceProperty.formFactor = 'iPhone';
            }
            deviceProperty.availability = 'AVAILABLE';
            deviceProperty.platform = 'IOS';

            if (element.toString().indexOf('Simulator') > -1) {
              deviceProperty.deviceType = 'Simulator';
            } else {
              deviceProperty.deviceType = 'Physical';
            }

            if (objIosVal[0].toString().indexOf('iPhone') > -1) {
              this.serverDevices.push(deviceProperty);
            }
          }
        });

        console.log(this.serverDevices);
        let index = 1;
        this.serverDevices.forEach(function(element) {
          element.selected = false;
          element.id = index;
          index = index + 1;
        });
        setTimeout(() => {
          //to open pop up only for select devices step(code by Mustayeed)
          if (active_step == 3) {
            $('#SelectDeviceTemplateModal').modal('show');
          } else {
            $('#SelectDeviceTemplateModal').modal('hide');
          }
          $('#btnSelectDevices').prop('disabled', false);
          $('#startExeBtnView').prop('disabled', false);
          this.isconsoleOut = false;
          this.isDevicesLoading = false;
          doc.ref.detectChanges();
        }, 5000);
        this.isconsoleOut = false;
      } catch (exception) {
        //flag checked for is console out
        doc.isconsoleOut = true;
        console.log('Fialed to load divices');
        $('#btnSelectDevices').prop('disabled', false);
        doc.isDevicesLoading = false;
        doc.IsjobName_error = exception;
        doc.ref.detectChanges();
        setTimeout(() => {
          doc.IsjobName_error = '';
          doc.ref.detectChanges();
        }, 2500);
      }
    }
    return this.serverDevices;
  }

  validate(event: any) {
    if (event.which === 32 && !event.target.value.length)
      event.preventDefault();

    var regex = new RegExp('^[a-zA-Z0-9 ]*$');
    var key = String.fromCharCode(
      event.charCode == event.which ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  checkIfCapabilityTemplateHasDuplicates(
    capabilityIdValuesViews_res: any
  ): string {
    const status = capabilityIdValuesViews_res.some(capabilityIdValuesView => {
      let counter = 0;
      for (const iterator of capabilityIdValuesViews_res) {
        if (iterator.capabilityName === capabilityIdValuesView.capabilityName) {
          counter += 1;
        }
      }
      return counter > 1;
    });
    if (status) {
      return 'Duplicate capabilities are present in capability template, please use another capability template for execution.';
    }
  }

  checkIfCapabilityTemplateIsCorrputed(
    capabilityIdValuesViews_res: any
  ): string {
    let counter = 0;
    for (const iterator of capabilityIdValuesViews_res) {
      if (iterator.capabilityName.trim() === iterator.capabilityValue.trim()) {
        counter += 1;
      }
    }
    if (counter > 0) {
      return 'Capability Template is corrupted, please use another capability template for execution.';
    }
  }

  getFailedTestSuiteCount(requestBody) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/dashBoard/getStatusCountsForFailedTestSuite';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, requestBody, httpOptions);
  }

  getFailedTestCasesCount(requestBody) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/dashBoard/getStatusCountsForFailedTestcases';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, requestBody, httpOptions);
  }

  getFailedTestExecutionCount() {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/dashBoard/getStatusCountsForFailedExecution';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  getFailedTestStepsCount(requestBody) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/dashBoard/getStatusCountsForFailedTestSteps';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, requestBody, httpOptions);
  }

  getBSDetails(sessionId) {
    const url =
      environment.urlConfig.baseUrl +
      '/execution/getBrowserStackSessionDetails?sessionId=' +
      sessionId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain; charset=ISO-8859-1',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  downloadBSAppiumlogs(logUrl) {
    const url =
      environment.urlConfig.baseUrl +
      '/execution/downloadBsAppiumlogs?appiumUrl=' +
      logUrl;
    const httpOptions = {
      responseType: 'blob' as 'text',
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  getAllBrowserStackDevices(platform) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/getDevicesForBrowserStack?platform=' +
      platform;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }

  uploadFileForBrowserStack(appId) {
    // let appId=3;
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/uploadFileForBrowserStack?appId=' +
      appId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
      })
    };
    // return this.http.get(apiURL, httpOptions);
    return this.http.post(apiURL, JSON.stringify(httpOptions), httpOptions);
  }

  weeklyTestSuiteStatus(dashboardSuiteStatusDetails) {
    const apiURL =
      environment.urlConfig.baseUrl + '/dashBoard/getWeeklyTestSuiteStatus';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(
      apiURL,
      JSON.stringify(dashboardSuiteStatusDetails),
      httpOptions
    );
  }

  testCaseTableStatus(requestBody) {
    const apiURL =
      environment.urlConfig.baseUrl + '/dashBoard/getTastCaseTableData';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(apiURL, requestBody, httpOptions);
  }

  exportTableDataasxls() {
    const urlasDataObject =
      environment.urlConfig.baseUrl + '/dashBoard/downloadTestCaseTable';

    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(urlasDataObject, httpOptions);
  }

  weeklyTestCaseStatus(dashboardSuiteStatusDetails) {
    const apiURL =
      environment.urlConfig.baseUrl + '/dashBoard/getWeeklyTestCaseStatus';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(
      apiURL,
      JSON.stringify(dashboardSuiteStatusDetails),
      httpOptions
    );
  }

  getAllOSversionByIDn(platformIdd, brandId, enviornment, regionId, appName) {
    let httpOptions;
    const url =
      environment.urlConfig.baseUrl +
      '/dashBoard/findAllOsVersion?platformId=' +
      platformIdd +
      '&brandId=' +
      brandId +
      '&enviornment=' +
      enviornment +
      '&regionId=' +
      regionId +
      '&appName=' +
      appName;
    if (this.isfromWidget) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${this.authService.authToken}`
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${this.authService.authToken}`
        })
      };
    }
    return this.http.get(url, httpOptions);
  }
  getAllAppNamesByID(platformId, brandId, enviornment, testLevel) {
    let httpOptions;
    const url =
      environment.urlConfig.baseUrl +
      '/dashBoard/findAllAppVersions?platformId=' +
      platformId +
      '&brandId=' +
      brandId +
      '&enviornment=' +
      enviornment +
      '&testLevel=' +
      testLevel;
    if (this.isfromWidget) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${this.authService.authToken}`
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${this.authService.authToken}`
        })
      };
    }
    return this.http.get(url, httpOptions);
  }
  getAllDeviceNamesByID(platformId, brandId, enviornment, testLevel, appName) {
    let httpOptions;
    const url =
      environment.urlConfig.baseUrl +
      '/dashBoard/findAllDeviceNames?platformId=' +
      platformId +
      '&brandId=' +
      brandId +
      '&enviornment=' +
      enviornment +
      '&testLevel=' +
      testLevel +
      '&appName=' +
      appName;
    if (this.isfromWidget) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${this.authService.authToken}`
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `${this.authService.authToken}`
        })
      };
    }
    return this.http.get(url, httpOptions);
  }

  deviceTestCaseStatus(dashboardDeviceStatusDetails) {
    const apiURL =
      environment.urlConfig.baseUrl + '/dashBoard/getDeviceExecutionStatus';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(
      apiURL,
      JSON.stringify(dashboardDeviceStatusDetails),
      httpOptions
    );
  }

  getTestCasePassFailCountDashboard(dashboardDetails) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/dashBoard/getTestCasePassFailCountDashboard';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(
      apiURL,
      JSON.stringify(dashboardDetails),
      httpOptions
    );
  }

  getTestSuitePassFailCountDashboard(dashboardDetails) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/dashBoard/getTestSuitePassFailCountDashboard';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(
      apiURL,
      JSON.stringify(dashboardDetails),
      httpOptions
    );
  }

  LCFileUpload(fileName: string, originalFileName: string, LCuserId: any) {
    const apiURL =
      environment.urlConfig.baseUrl + '/execution/uploadLocalizationFile';
    //const apiURL='http://localhost:8089/execution/uploadLocalizationFile';

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${this.authService.authToken}`
    }).set('Authorization', `${this.authService.authToken}`);
    const requestBody = {
      fileName: fileName,
      ogFileName: originalFileName,
      created_by: LCuserId
    };

    return this.http.post(apiURL, JSON.stringify(requestBody), {
      headers
    });
  }

  getLCTestSuites() {
    const apiURL =
      environment.urlConfig.baseUrl + '/execution/getAllLCTestSuites';
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get<any[]>(apiURL, httpOptions);
  }
  downloadLCExcelFile(url) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/downloadLCExcelFile?fileurl=' +
      url;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.get(apiURL, httpOptions);
  }
  deleteLCSuite(lc_suite_id) {
    const apiURL =
      environment.urlConfig.baseUrl +
      '/execution/deleteLCSuite?lc_suite_id=' +
      lc_suite_id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.delete(apiURL, httpOptions);
  }

  LCExcelFileUpload(fileName) {
    const url =
      environment.urlConfig.baseUrl +
      '/execution/getPresignedUrlForLCSuiteUpload?fileName=' +
      fileName;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `${this.authService.authToken}`
      })
    };
    return this.http.post(url, JSON.stringify(fileName), httpOptions);
  }
}
