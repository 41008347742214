import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../shared/services/authentication.service';
export const InterceptorSkipHeader = 'X-Skip-Interceptor';
export const headSpinToken = 'headSpinTokenInt';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser != null && currentUser.token) {
      if (request.body != null) {
        if (request.headers.has(InterceptorSkipHeader)) {
          const headers = request.headers.delete(InterceptorSkipHeader);
          return next.handle(request.clone({ headers }));
        }
        //If the headspin is getting called
        else if (request.headers.has(headSpinToken)) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ` + environment.urlConfig.headspinAPIToken
            }
          });
          const headers = request.headers.delete(headSpinToken);
          return next.handle(request.clone({ headers }));
        } else {
          request = request.clone({
            setHeaders: {
              Authorization: currentUser.token
            }
          });
        }
      }
      //If the headspin is getting called
      else if (request.headers.has(headSpinToken)) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ` + environment.urlConfig.headspinAPIToken
          }
        });
        const headers = request.headers.delete(headSpinToken);
        return next.handle(request.clone({ headers }));
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: currentUser.token
          }
        });
      }
    }
    return next.handle(request);
  }
}
