<ng-container>
   <form  [formGroup]="failedRCATestSuitesform" (ngSubmit)="failedRCATestSuitesformSubmit()">
    <div class="row">
      <div class="form-group col-md-6">
          <select class="txtControlCss col-md-12" disabled="true" id="regionId" (change)="changeRegion($event)" >
            <option [value]="region.regionId" *ngFor="let region of this.regionList">
              {{region.regionCode}}</option>
          </select>
      </div>
        <div class="form-group col-md-6">
          <select class="txtControlCss col-md-12" id="Brand" (change)="changeBrand($event)">
              <option *ngIf="this.brandLists.length > 1" value="0" selected>All</option>
              <option [value]="brand.brandId" *ngFor="let brand of brandLists">{{ brand.brandName }}</option>
          </select>
        </div>
  </div>

  
  <div class="row mt-2">
    <div class="col-md-6">
      <select class="txtControlCss col-md-12" id="testLevel" (change)="changeTestLevel($event)" style="font-size: 12px;"
          formControlName="testLevel" [(ngModel)]="selectOption">
          <option value="" disabled selected>Test Level</option>

          <option *ngFor="let testLevel of testlevellist" [value]="testLevel.value">
              {{testLevel.viewValue}}
          </option>
      </select>
       <span class="error" 
          *ngIf=" this.isSubmitted && !failedRCATestSuitesform.controls['testLevel'].valid">
           Test Level is required</span>
  
  </div>

  <div class="col-md-6">
    <select class="txtControlCss  col-md-12" id="ENV" style="font-size: 12px;" formControlName="environment" (change)="onEnvChange($event)" >
      <option value="" disabled selected>ENV</option>
      <option [value]="env" *ngFor="let env of environmentList">
        {{env}}
      </option>
    </select>
    <span class="error" *ngIf=" this.isSubmitted && !failedRCATestSuitesform.controls['environment'].valid"> 
      Environment is required</span>
  </div>

</div>
  <div class="row"> 
      <div class="col-md-6">
        <label for="regionId" class="txtControlLblCss">From<span>*</span></label>
        <div class="exmp-wrp " style="margin-left:2px;">
          <div class="input-group btn-wrp">
            <input type="date" class="btn-clck" (change)="onFromDateChange($event)"
               placeholder="From" [min]="getMinDate()" [max]="getMaxDate()"  formControlName="startDate" id="exe_StartDate" name="widget_From_date">
          </div>
          <span class="input-group-btn ">
            <button class="btn1  " type="text"><span class="paddingLefiRightCalender"><img
                  src="assets/images/calendarr.png"></span></button>
          </span>
        </div>
      </div>
      <div class="col-md-6">
        <label for="regionId" class="txtControlLblCss">To<span>*</span></label>
  
        <div class="exmp-wrp " style="margin-left:2px;">
          <div class="input-group btn-wrp">
            <input type="date" class="btn-clck " id = "toDate"(change)="onToDateChange($event)" 
              name="endDate"  placeholder="To"[min]="fromDate" [max]="getMaxDate()" formControlName="endDate" [disabled]="!fromDate">
          </div>
          <span class="input-group-btn ">
            <button class="btn1  " type="text"><span class="paddingLefiRightCalender"><img
                  src="assets/images/calendarr.png"></span></button>
          </span>
        </div>
      </div>
    </div>
   </form>
        <br>
        <div class="col-md-6 txt-color" style="text-align:right;font-weight: 500;">
            Test Suites
        </div>

        <div class="chart-container  col-md-12" id="barchart">
        </div>
        <div class="d-flex overlay " *ngIf="this.load==true">
            <div class="monthlyloading">Loading&#8230;</div>
        </div>

        <div class="chart-container  col-md-12">
            <div id="container"></div>
            <highcharts-chart [(update)]="updateFlag" [Highcharts]="highcharts" [options]="chartOptions"
                style="width: 100%; height: 350px; display: block;">
            </highcharts-chart>
        </div>
</ng-container>