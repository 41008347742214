/* projects/wtaf/src/app/features/widgets/failed-test-suites/failed-test-suites.component.css */
.exmp-wrp {
  position: relative;
}
.btn-wrp {
  display: inline-block;
  position: relative;
  z-index: 10;
}
.btn-clck {
  background: transparent;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.3rem;
  display: block;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-clck::-webkit-calendar-picker-indicator {
  right: -0px;
  position: absolute;
  color: rgba(204, 204, 204, 0);
  opacity: 0;
}
.btn-clck::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.btn-clck::-webkit-clear-button {
  margin-right: 20px;
}
.btn1 {
  height: 100%;
  padding: 0px;
  position: absolute;
  right: 0;
}
.runBtnCss {
  background-color: #1f83fe !important;
  border-radius: 3px !important;
  width: 100px !important;
  height: 35px !important;
  color: #ffffff !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.error {
  color: #ff0033;
  padding-left: 5px;
}
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.overlay {
  position: fixed;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(74, 74, 74, 0.8);
  z-index: 99999;
  opacity: 0.2;
}
.spinner {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.monthlyloading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.monthlyloading:not(:required) {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.monthlyloading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow:
    rgba(66, 139, 203, 1) 1.5em 0 0 0,
    rgba(66, 139, 203, 1) 1.1em 1.1em 0 0,
    rgba(66, 139, 203, 1) 0 1.5em 0 0,
    rgba(66, 139, 203, 1) -1.1em 1.1em 0 0,
    rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0,
    rgba(66, 139, 203, 1) 0 -1.5em 0 0,
    rgba(66, 139, 203, 1) 1.1em -1.1em 0 0;
  box-shadow:
    rgba(66, 139, 203, 1) 1.5em 0 0 0,
    rgba(66, 139, 203, 1) 1.1em 1.1em 0 0,
    rgba(66, 139, 203, 1) 0 1.5em 0 0,
    rgba(66, 139, 203, 1) -1.1em 1.1em 0 0,
    rgba(66, 139, 203, 1) -1.5em 0 0 0,
    rgba(66, 139, 203, 1) -1.1em -1.1em 0 0,
    rgba(66, 139, 203, 1) 0 -1.5em 0 0,
    rgba(66, 139, 203, 1) 1.1em -1.1em 0 0;
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=failed-test-suites.component.css.map */
