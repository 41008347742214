import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatSliderModule } from '@angular/material/slider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { LoaderComponent } from '../layout/loader/loader/loader.component';
import { SchedulePopupComponent } from '../features/schedule_popup/schedule-popup/schedule-popup.component';
import { ViewStatusComponent } from '../features/view_status/view-status/view-status.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
// import { HighchartsChartComponent } from 'highcharts-angular';
import { TestExecutionTrendComponent } from '../features/widgets/test_execution_trend/test-execution-trend/test-execution-trend.component';
import { TestSuiteExecutionTimeTrendComponent } from '../features/widgets/test_suite_execution_time_trend/test-suite-execution-time-trend/test-suite-execution-time-trend.component';
import { AllCountComponent } from '../features/widgets/all_count/all-count/all-count.component';
import { ExecutionTimeWidgetComponent } from '../features/widgets/execution-time-widget/execution-time-widget.component';
import { TestSuiteStatusComponent } from '../features/widgets/test_suite_status/test-suite-status/test-suite-status.component';
import { MonthlyExecutionStatusComponent } from '../features/widgets/monthly_execution_status/monthly-execution-status/monthly-execution-status.component';
// import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ApiAutomationComponent } from '../features/api_automation/api-automation/api-automation.component';
import { PaginationComponent } from '../layout/commonFeatures/pagination/pagination.component';
import { SearchboxComponent } from '../layout/commonFeatures/searchbox/searchbox.component';
import { FailedTestSuitesComponent } from '../features/widgets/failed-test-suites/failed-test-suites.component';
import { FailedTestCasesComponent } from '../features/widgets/failed-test-cases/failed-test-cases.component';
import { FailedExecutionComponent } from '../features/widgets/failed-execution/failed-execution.component';
import { FailedTestStepsComponent } from '../features/widgets/failed-test-steps/failed-test-steps.component';
import { ReportTestSuitesComponent } from '../features/widgets/report-test-suites/report-test-suites.component';
import { CountTestSuitesComponent } from '../features/widgets/count-test-suites/count-test-suites.component';
import { TestSuiteWeeklyCountComponent } from '../features/widgets/test-suite-weekly-count/test-suite-weekly-count.component'
import { ReportTestCasesComponent } from '../features/widgets/report-test-cases/report-test-cases.component';
import { CountTestCasesComponent } from '../features/widgets/count-test-cases/count-test-cases.component';
import { DeviceTestCasesReportComponent } from '../features/widgets/device-test-cases-report/device-test-cases-report.component';
import { MonthlyDashboardExecutionReportComponent } from '../features/widgets/monthly-dashboard-test-case-report/monthly-dashboard-test-case-report.component';
import { MonthlyDashboardTestSuiteReportComponent } from '../features/widgets/monthly-dashboard-test-suite-report/monthly-dashboard-test-suite-report.component';


import {
  faBars,
  faUserCircle,
  faPowerOff,
  faCog,
  faPlayCircle,
  faRocket,
  faPlus,
  faEdit,
  faTrash,
  faTimes,
  faCaretUp,
  faCaretDown,
  faExclamationTriangle,
  faFilter,
  faTasks,
  faCheck,
  faSquare,
  faLanguage,
  faPaintBrush,
  faLightbulb,
  faWindowMaximize,
  faStream,
  faBook
} from '@fortawesome/free-solid-svg-icons';
import {
  faGithub,
  faMediumM,
  faTwitter,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faBars,
  faUserCircle,
  faPowerOff,
  faCog,
  faRocket,
  faPlayCircle,
  faGithub,
  faMediumM,
  faTwitter,
  faInstagram,
  faYoutube,
  faPlus,
  faEdit,
  faTrash,
  faTimes,
  faCaretUp,
  faCaretDown,
  faExclamationTriangle,
  faFilter,
  faTasks,
  faCheck,
  faSquare,
  faLanguage,
  faPaintBrush,
  faLightbulb,
  faWindowMaximize,
  faStream,
  faBook
);

import { BigInputComponent } from './big-input/big-input/big-input.component';
import { BigInputActionComponent } from './big-input/big-input-action/big-input-action.component';
import { RtlSupportDirective } from './rtl-support/rtl-support.directive';
import { from } from 'rxjs';
import { SearchDataPipe } from './services/search-data.pipe';
import { TimeFormat } from './pipes/timeformat.pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HighchartsChartModule } from 'highcharts-angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatToolbarModule,
        MatSelectModule,
        MatTabsModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatCardModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatListModule,
        MatMenuModule,
        MatIconModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatDividerModule,
        DragDropModule,
        // FontAwesomeModule,
        InfiniteScrollModule,
        NgMultiSelectDropDownModule.forRoot(),
        HighchartsChartModule
    ],
    declarations: [
        BigInputComponent,
        BigInputActionComponent,
        RtlSupportDirective,
        LoaderComponent,
        SchedulePopupComponent,
        ViewStatusComponent,
        SearchDataPipe,
        TimeFormat,
        TestExecutionTrendComponent,
        TestSuiteExecutionTimeTrendComponent,
        TestSuiteStatusComponent,
        AllCountComponent,
        ExecutionTimeWidgetComponent,
        MonthlyExecutionStatusComponent,
        ApiAutomationComponent,
        PaginationComponent,
        SearchboxComponent,
        FailedTestSuitesComponent,
        FailedTestCasesComponent,
        FailedExecutionComponent,
        FailedTestStepsComponent,
        ReportTestSuitesComponent,
        CountTestSuitesComponent,
        TestSuiteWeeklyCountComponent,
        ReportTestCasesComponent,
        CountTestCasesComponent,
        DeviceTestCasesReportComponent,
        MonthlyDashboardExecutionReportComponent,
        MonthlyDashboardTestSuiteReportComponent
        // HighchartsChartModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatButtonModule,
        MatMenuModule,
        MatTabsModule,
        MatChipsModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatCardModule,
        MatSidenavModule,
        MatListModule,
        MatSelectModule,
        MatToolbarModule,
        MatIconModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatSliderModule,
        MatDatepickerModule,
        MatNativeDateModule,
        InfiniteScrollModule,
        // FontAwesomeModule,
        BigInputComponent,
        BigInputActionComponent,
        RtlSupportDirective,
        LoaderComponent,
        SchedulePopupComponent,
        ViewStatusComponent,
        SearchDataPipe,
        TimeFormat,
        TestExecutionTrendComponent,
        TestSuiteExecutionTimeTrendComponent,
        TestSuiteStatusComponent,
        MonthlyExecutionStatusComponent,
        ApiAutomationComponent,
        SearchboxComponent,
        PaginationComponent,
        AllCountComponent,
        ExecutionTimeWidgetComponent,
        // HighchartsChartModule,
        FailedTestSuitesComponent,
        FailedTestCasesComponent,
        FailedExecutionComponent,
        FailedTestStepsComponent,
        ReportTestSuitesComponent,
        CountTestSuitesComponent,
        TestSuiteWeeklyCountComponent,
        ReportTestCasesComponent,
        CountTestCasesComponent,
        DeviceTestCasesReportComponent,
        MonthlyDashboardExecutionReportComponent,
        MonthlyDashboardTestSuiteReportComponent
    ]
})
export class SharedModule {}
