
export enum Type {
    Appliance='APPLIANCE',
    ApplicanceCategory='APPLIANCE_CATEGORY',
    Brand = 'BRAND',
    Element='ELEMENT',
    Keyword='KEYWORD',
    TestSuite='TEST_SUITE',
    TestCase='TEST_CASE',
    TestStep='TEST_STEP',
    UserRole='USER_ROLE',
    User='USER',
    document ='DOCUMENT',
    Capability='CAPABILITY',
    CapabilityTemplate ='CAPABILITY_TEMPLATE',
    Execution ='EXECUTION_HEADER',
    ascending='asc',
    descending='desc',
    BrandName='Brand Name',
    BrandId='Brand Id',
    ApplicanceCategoryId="Appliance Category Id",
    ApplianceCategoryName='Appliance Category Name',
    ElementId='Element Id',
    ElementName='Element Name',
    RoleId='Role Id',
    RoleName='Role Name',
    TestSuiteId='Test Suite Id',
    TestSuiteName='Test Suite Name',
    TestCaseId='Test Case Id',
    TestCaseName='Test Case Name',
    TEST_SUITE_CAN_DELETE="1",
    TEST_SUITE_BEING_USED_CAN_NOT_DELETE="2",
    TEST_SUITE_BEING_USED_CAN_DELETE="3",
    Language='LANGUAGE',
    LanguageLabel="LANGUAGELABEL"
}