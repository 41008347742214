import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Chart } from 'chart.js';
import { DataService } from '../../../../app/shared/services/data.service';
// import * as $ from 'jquery';
import $ from 'jquery';
// import * as moment from 'moment';
import moment from 'moment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as Highcharts from 'highcharts';
// import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../../shared/services/loader.service';
@Component({
  selector: 'wtaf-execution-time-widget',
  templateUrl: './execution-time-widget.component.html',
  styleUrls: ['./execution-time-widget.component.css']
})
export class ExecutionTimeWidgetComponent implements OnInit {
  chart: any;
  projectTypeList: any = [];
  executionStatusform: UntypedFormGroup;
  toDate: any;
  fromDate: any;
  projecttypeId: any;
  platformList: any = [];
  regionList: any = [];
  regionName: any;
  brandList: any = [];
  regionId: number;
  environmentList = ['STG', 'Dev', 'Prod', 'QA'];
  isSubmitted = false;
  isValidDate: any;
  error: any = { isError: false, errorMessage: '' };

  brandId: number;
  projectNameList: any = [];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  updateFlag = true;
  exe_label_names: any = [];
  highcharts: any = Highcharts;
  chartOptions = {
    chart: {
      type: 'column'
    },
    title: {
      text: ''
    },

    xAxis: {
      type: 'category',
      labels: {
        rotation: '',
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif'
        }
      }
    },
    yAxis: {
      min: 0,
      minPadding: 0,
      maxPadding: 0,
      title: {
        text: 'Execution Time (min)'
      }
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:10px">{point.key}:<b>{point.y}</b></span><table>',
      pointFormat:
        '<tr><td style="padding:0">Passed: </td>' +
        '<td style="padding:0"><b>{point.countPass}</b></td></tr>' +
        '<tr><td style="padding:0">Failed: </td>' +
        '<td style="padding:0"><b>{point.countfail}</b></td></tr>' +
        '<tr><td style="padding:0">Unknown: </td>' +
        '<td style="padding:0"><b>{point.countUnknown}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
      backgroundColor: '#FFF'
    },
    series: [
      {
        name: 'Product',
        showInLegend: false,
        data: [
          {
            name: 'product1',
            y: 30,
            countPass: 18,
            countfail: 2,
            countUnknown: 20
          },
          {
            name: 'product12',
            y: 40,
            countPass: 15,
            countfail: 2,
            countUnknown: 20
          }
        ]
      }
    ]
  };
  constructor(
    private dataservice: DataService,
    public fb: UntypedFormBuilder,
    public DatePipe: DatePipe,
    public toastr: ToastrService,
    public loaderservice:LoaderService
  ) {
    let nowDate = new Date();
    this.toDate = this.DatePipe.transform(nowDate, 'yyyy-MM-dd');
    this.fromDate = moment()
      .subtract('days', 7)
      .format('YYYY-MM-DD');

    this.executionStatusform = this.fb.group({
      exe_ProjectTypeId: ['', [Validators.required]],
      exe_RegionId: ['', [Validators.required]],
      exe_BrandId: ['', [Validators.required]],
      exe_ProductNames: [[], [Validators.required]],
      exe_PlatformId: ['', [Validators.required]],
      exe_Environment: ['STG', [Validators.required]],
      exe_StartDate: [this.fromDate, [Validators.required]],
      exe_EndDate: [this.toDate, [Validators.required]],
      exe_ProjectNames: []
    });
  }

  ngOnInit() {

    
    this.loaderservice.show()

    setTimeout(()=>{
        this.loaderservice.hide()
  
      },15000)

    $('#exe_StartDate').attr('max', this.toDate);
    $('#exe_EndDate').attr('max', this.toDate);
    this.getAllProjectType();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'projectName',
      textField: 'projectName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }
  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }
  async getAllProjectType() {
    await this.dataservice
      .getAllProjectType()
      .toPromise()
      .then(async response => {
        // Success
        this.projectTypeList = response;
        console.log('this.projectTypeList.length_widget_3');
        console.log(
          'this.projectTypeList.length_widget_3',
          this.projectTypeList
        );
        if (response != null) {
          this.executionStatusform.controls['exe_ProjectTypeId'].setValue(
            this.projectTypeList[0].projectTypeId
          );
          this.getPlatformByProjectTypeId(
            this.projectTypeList[0].projectTypeId
          );
          await this.getAllRegion(this.projectTypeList[0].projectTypeId);
        }
      });
  }
  async getPlatformByProjectTypeId(projectTypeId) {
    await this.dataservice
      .getPlatformByProjectTypeId(projectTypeId)
      .subscribe(
        (response: {}) => {
          this.platformList = response;
          console.log(this.platformList);
          if (this.platformList != null) {
            if (this.platformList.length == 1) {
              this.executionStatusform.controls['exe_PlatformId'].setValue(
                this.platformList[0].platformId
              );
            } else {
              this.executionStatusform.controls['exe_PlatformId'].setValue(
                this.platformList[1].platformId
              );
            }
          }
        },
        error => {
          console.log(error.message);
        }
      );
  }
  getAllRegion(Projecttypeid) {
    this.dataservice
      .getAllRegionByProjectType(Projecttypeid)
      .subscribe(async (response: {}) => {
        this.regionList = response;
        this.regionList.unshift(
          this.regionList.splice(
            this.regionList.findIndex(item => item.regionName === 'NAR'),
            1
          )[0]
        );
        if (response != null) {
          this.executionStatusform.controls['exe_RegionId'].setValue(
            this.regionList[0].regionId
          );
          this.regionName = this.regionList[0].regionName;
          await this.getBrandsByRegionId(
            this.executionStatusform.get('exe_RegionId').value
          );
        }
      });
  }
  async getBrandsByRegionId(regionId) {
    await this.dataservice
      .getBrandsByRegionId(regionId)
      .toPromise()
      .then((response: {}) => {
        this.brandList = response;
        console.log('this.brandList.....>', this.brandList);

        if (response != null) {
          if (this.brandList) {
            this.regionId = this.executionStatusform.get('exe_RegionId').value;
            this.projecttypeId = this.executionStatusform.get(
              'exe_ProjectTypeId'
            ).value;

            this.brandList.unshift(
              this.brandList.splice(
                this.brandList.findIndex(
                  item => item.brandName.toLowerCase() === 'whirlpool'
                ),
                1
              )[0]
            );
            this.executionStatusform.controls['exe_BrandId'].setValue(
              this.brandList[0].brandId
            );
            this.brandId = this.brandList[0].brandId;
          }
          this.getAllProjectName(
            this.regionId,
            this.brandId,
            this.projecttypeId
          );
        } else {
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }
  async getAllProjectName(regionId, brandId, projecttypeId) {
    await this.dataservice
      .getProjectNameByTypeId(brandId, projecttypeId, regionId)
      .subscribe(allProjNameRes => {
        this.projectNameList = allProjNameRes;
        console.log('all product names', this.projectNameList);
        for (let i = 0; i < this.projectNameList.length; i++) {
          this.projectNameList.id = i;
        }
        this.selectedItems = [...this.projectNameList];
        console.log(this.selectedItems);
      });
  }
  // on dropdown changes methods
  changeProjectType(e) {
    this.dataservice.isfromWidget = false;
    console.log(e);

    this.projecttypeId = e.target.value;
    console.log(this.projecttypeId);

    this.projecttypeId = e.target.value;
    this.regionList = [];
    this.brandList = [];
    this.brandId = 0;
    this.projectNameList = [];
    // this.testSuiteList = [];
    this.executionStatusform.controls['exe_RegionId'].setValue('');
    // this.executionStatusform.controls['testSuiteId'].setValue('');
    this.executionStatusform.controls['exe_ProductNames'].setValue('');
    this.getPlatformByProjectTypeId(parseInt(e.target.value));
    this.getAllRegion(parseInt(e.target.value));
  }

  changeRegion(e) {
    this.dataservice.isfromWidget = false;
    this.projectNameList = [];
    // this.testSuiteList = [];
    // this.executionStatusform.controls['testSuiteId'].setValue('');
    this.executionStatusform.controls['exe_ProductNames'].setValue('');
    this.getBrandsByRegionId(parseInt(e.target.value));
  }

  changeBrand(e) {
    this.dataservice.isfromWidget = false;
    // this.executionStatusform.controls['testSuiteId'].setValue('');
    // this.executionStatusform.controls['testSuiteName'].setValue('');
    this.executionStatusform.controls['exe_ProductNames'].setValue('');
    this.brandId = parseInt(e.target.value);
    this.regionId = this.executionStatusform.get('exe_RegionId').value;
    this.projecttypeId = this.executionStatusform.get(
      'exe_ProjectTypeId'
    ).value;

    this.getAllProjectName(this.regionId, this.brandId, this.projecttypeId);
  }

  onFromDateChange(e) {
    let fromDate = this.DatePipe.transform(e.target.value, 'yyyy-MM-dd');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(fromDate).getMonth();
    let currentyear1 = new Date(fromDate).getFullYear();
    if (
      new Date(this.DatePipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ===
      new Date(this.DatePipe.transform(fromDate, 'yyyy-MM-dd')).getTime()
    ) {
      let maxDate = fromDate;
      fromDate = moment(fromDate)
        .subtract(1, 'months')
        .format('YYYY-MM-DD');
      $('#exe_EndDate').attr(
        'min',
        this.DatePipe.transform(fromDate, 'yyyy-MM-dd')
      );
      $('#exe_EndDate').attr(
        'max',
        this.DatePipe.transform(maxDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        let maxDate = fromDate;
        fromDate = moment(fromDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let toDate = moment(
          this.DatePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#exe_EndDate').attr(
          'min',
          this.DatePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#exe_EndDate').attr(
          'max',
          this.DatePipe.transform(currentDate, 'yyyy-MM-dd')
        );
        this.executionStatusform.controls['exe_EndDate'].setValue(
          this.DatePipe.transform(currentDate, 'yyyy-MM-dd')
        );
      } else {
        // fromDate = moment(fromDate)
        // .subtract(1, 'months')
        // .format('YYYY-MM-DD');
        let toDate = moment(
          this.DatePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');

        let date_1 = new Date(
          this.DatePipe.transform(currentDate, 'yyyy-MM-dd')
        ).getTime();
        let date_2 = new Date(
          this.DatePipe.transform(toDate, 'yyyy-MM-dd')
        ).getTime();

        if (date_2 > date_1) {
          toDate = this.DatePipe.transform(currentDate, 'yyyy-MM-dd');
        }
        $('#exe_EndDate').attr(
          'min',
          this.DatePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#exe_EndDate').attr(
          'max',
          this.DatePipe.transform(toDate, 'yyyy-MM-dd')
        );
        this.executionStatusform.controls['exe_EndDate'].setValue(toDate);
      }
    }
  }
  onToDateChange(e) {
    let toDate = this.DatePipe.transform(e.target.value, 'yyyy-MM-dd');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(toDate).getMonth();
    let currentyear1 = new Date(toDate).getFullYear();
    if (
      new Date(this.DatePipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ===
      new Date(this.DatePipe.transform(toDate, 'yyyy-MM-dd')).getTime()
    ) {
      toDate = moment(toDate)
        .subtract(1, 'months')
        .format('YYYY-MM-DD');
      $('#exe_StartDate').attr(
        'min',
        this.DatePipe.transform(toDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        toDate = moment(toDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let fromDate = moment(
          this.DatePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#exe_StartDate').attr(
          'min',
          this.DatePipe.transform(toDate, 'yyyy-MM-dd')
        );
        $('#exe_StartDate').attr(
          'max',
          this.DatePipe.transform(currentDate, 'yyyy-MM-dd')
        );
      } else {
        toDate = moment(toDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let fromDate = moment(
          this.DatePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#exe_StartDate').attr(
          'min',
          this.DatePipe.transform(toDate, 'yyyy-MM-dd')
        );
        $('#exe_StartDate').attr(
          'max',
          this.DatePipe.transform(fromDate, 'yyyy-MM-dd')
        );
      }
    }
  }

  //on submit
  onFormSubmit() {
    this.exe_label_names = [];
    this.isSubmitted = true;
    this.isValidDate = this.validateDates(
      this.DatePipe.transform(
        this.executionStatusform.controls['exe_StartDate'].value,
        'yyyy-MM-dd'
      ),
      this.DatePipe.transform(
        this.executionStatusform.controls['exe_EndDate'].value,
        'yyyy-MM-dd'
      )
    );
    if (!this.isValidDate || this.executionStatusform.invalid) {
      return;
    } else {
      if (this.projectNameList != null) {
        let temp_projectName_list = this.executionStatusform.value[
          'exe_ProductNames'
        ].map(element => {
          return element.projectName;
        });
        this.executionStatusform.controls['exe_ProjectNames'].setValue(
          temp_projectName_list
        );
        console.log(
          'temp_projectName_list....',
          this.executionStatusform.controls['exe_ProjectNames'].setValue(
            temp_projectName_list
          )
        );
      }
      const formdata = this.executionStatusform.value;
      const refineddata = {
        brandId: formdata.exe_BrandId,
        endDate: formdata.exe_EndDate,
        environment: formdata.exe_Environment,
        platformId: formdata.exe_PlatformId,
        projectNames: formdata.exe_ProjectNames,
        projectTypeId: formdata.exe_ProjectTypeId,
        regionId: formdata.exe_RegionId,
        startDate: formdata.exe_StartDate,
        testSuiteId: 0
      };

      this.dataservice
        .getDashboardSuiteExeTimeProjectWise(refineddata)
        .subscribe(
          (res: any) => {
            console.log('test suite exec status res', res);
            let mindate = moment(this.toDate)
              .subtract(24, 'months')
              .format('YYYY-MM-DD');
            $('#exe_StartDate').attr('max', this.toDate);
            $('#exe_EndDate').attr('max', this.toDate);
            $('#exe_StartDate').attr('min', mindate);
            $('#exe_EndDate').attr('min', mindate);
            this.error = { isError: false, errorMessage: '' };
            if (res.length === 0) {
              this.toastr.error('No records found');
            }
            if (res != null) {
              res.forEach(element => {
                this.exe_label_names.push({
                  name: element.projectName,
                  y: element.executionTime,
                  countPass: element.passed,
                  countfail: element.failed,
                  countUnknown: element.unknown
                });
              });
              // let maxy =  Math.max.apply(Math, this.exe_label_names.map(function(o) { return o.y; }));

              this.chartOptions.series = [
                {
                  name: 'Product',
                  showInLegend: false,
                  data: this.exe_label_names
                }
              ];
              this.updateFlag = true;
            }
          },
          error => {
            console.log(error);
          }
        );
    }
  }

  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if (sDate == null || eDate == null) {
      this.error = {
        isError: true,
        errorMessage: 'Start date and end date are required.'
      };
      this.isValidDate = false;
    }

    if (sDate != null && eDate != null && eDate < sDate) {
      this.error = {
        isError: true,
        errorMessage: 'To date should be greater than from date.'
      };
      this.isValidDate = false;
    }
    return this.isValidDate;
  }
}
export interface Formdetail {
  ProjectTypeId: number;
  regionId: number;
  brandId: number;
  productNames: string;
  platformId: number;
  environment: string;
  startDate: string;
  endDate: string;
  profileId: number;
  projectNames: [];
}
