import { Component, OnInit, Input } from '@angular/core';
import { InprogressComponent } from '../../inprogress/inprogress/inprogress.component';
// import * as $ from 'jquery';
import $ from 'jquery';
import { DataService } from '../../../shared/services/data.service';
import { FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
@Component({
  selector: 'wtaf-view-status',
  templateUrl: './view-status.component.html',
  styleUrls: ['./view-status.component.css']
})
export class ViewStatusComponent implements OnInit {
  executionHeaderId: Number = 0;
  Execution_list: any = {};
  isJenkinsLog : boolean = false;
  @Input() changing: Subject<boolean>;
  constructor(public pop:InprogressComponent, public dataservice: DataService) { }
  ngOnInit() {
   console.log(' this.pop.Job_arn' ,  this.pop.Job_arn);
      console.log(' this.pop.strJobName' ,  this.pop.strJobName);
 console.log(' this.pop.jobData' ,  this.pop.jobData);
  
   this.changing.subscribe(v => { 
   if(v){
     this.tabActive();
   }
 });
  }
  tabActive()
  {
    if(!this.isJenkinsLog)
    {
      $("#txtExtendedReportTitle").addClass('active');
      $("#TestCasePassFailReport").addClass('active');
      $("#JenkinsBuildLogReport").removeClass('active');
      $(".jenkinsBuildlog").removeClass('active');
      //  $('#txtExtendedReportTitle').click();
    }

  }

  check_isJenkinsLog()
  {
    this.isJenkinsLog = true;
  }
  expandCollapse(id, r, ico, len) {
    if (r == 'r') {
      for (let m = 0; m < len; m++) {
        if ($('#' + r + id + m).hasClass('in')) {
          $('#' + r + id + m).addClass('out');
          $('#' + r + id + m).removeClass('in');
          $('#' + ico + id).removeClass('caret caret-down');
          $('#' + ico + id).addClass('caret');
        } else {
          $('#' + r + id + m).addClass('in');
          $('#' + r + id + m).removeClass('out');
          $('#' + ico + id).removeClass('caret');
          $('#' + ico + id).addClass('caret caret-down');
        }
      }
    }
    else {
      if ($('#' + r + id).hasClass('in')) {
        $('#' + r + id).addClass('out');
        $('#' + r + id).removeClass('in');
        $('#' + ico + id).removeClass('caret caret-down');
        $('#' + ico + id).addClass('caret');
      } else {
        $('#' + r + id).addClass('in');
        $('#' + r + id).removeClass('out');
        $('#' + ico + id).removeClass('caret');
        $('#' + ico + id).addClass('caret caret-down');
      }
    }
  }
  closeModal() {
    $(".hide").click();
    this.isJenkinsLog = false;
    if (this.pop.interval) {
      clearInterval(this.pop.interval);
      if (this.pop.paused) {
        this.pop.paused = false;
        this.pop.getSchedulelist();
      }
    }
  }
  ngOnDestroy() {
    if (this.pop.interval) {
      clearInterval(this.pop.interval);
    }
  }
}