<div>
    <div class="intital_setupbox">
        <form [formGroup]="apiAutomationForm" method="post">
            <div class="row col-md-12" >
                <div class="initial_data col-md-12">
                    <div class="Inti_hbox">
                        <p>
                            <i class="fa fa-caret-right" id="iconI" aria-hidden="true" (click)="onInitailShow()"></i>
                            <span class="init_title">Initial Settings</span>
                        </p>
                        <p class="mb-0 pl-15">
                            <span>Variables <b>0</b> |</span>
                            <span> Headers <b>0</b> |</span>
                            <span> API <b>{{apiCount}}</b></span>
                        </p>
                    </div>
                    <div class="col-md-12 intial_box">
                        <div class=" intial_box_title_form">
                            <div class="env_title_box">
                                <label for="envName" class="txtControlLblCss">Environment Name<span>*</span></label>
                                <input class="form-control txtControlCss" autocomplete="off" name="envName">
                            </div>
                            <div class="env_select_box">
                                <label for="envName" class="txtControlLblCss">Environment Type<span>*</span></label>
                                <select class="form-control ">
                                    <option>Shared Environment</option>
                                    <option>Test Suite Environment</option>
                                </select>
                            </div>
                        </div>
                        <nav>
                            <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                <a class="nav-item nav-link active" id="nav-globalvar-tab" data-toggle="tab"
                                    href="#nav-globalvar" role="tab" aria-controls="nav-globalvar"
                                    aria-selected="true">Initial Variables</a>
                                <a class="nav-item nav-link" id="nav-globalhed-tab" data-toggle="tab"
                                    href="#nav-globalhed" role="tab" aria-controls="nav-globalhed"
                                    aria-selected="true">Initial Headers</a>
                            </div>
                        </nav>
                        <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-globalvar" role="tabpanel"
                                aria-labelledby="nav-globalvar">
                                <div class="queryparam_box apiTabsection">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h5> Initial Variables <button type="button" class="btn addBtnCss"
                                                    (click)="addMoreGvar()" id="btnSaveCase">Add</button>
                                            </h5>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Value</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody formArrayName="initGvaribale">
                                                    <tr
                                                        *ngFor="let globalvar of apiAutomationForm['controls'].initGvaribale['controls']; let igx=index">
                                                        <ng-container formGroupName="{{igx}}">
                                                            <td><input class="form-control txtControlCss"
                                                                    autocomplete="off" formControlName="gVarname"
                                                                    name="key" id="key" type="text"></td>
                                                            <td><input class="form-control txtControlCss"
                                                                    autocomplete="off" formControlName="gVarvalue"
                                                                    name="value" id="value" type="text">
                                                            </td>
                                                            <td class="text-center"><i
                                                                    class="fa fa-times-circle deleteIcon"
                                                                    (click)="deleteGVar(igx)"></i></td>
                                                        </ng-container>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade show " id="nav-globalhed" role="tabpanel"
                                aria-labelledby="nav-globalhed">
                                <div class="queryparam_box apiTabsection">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h5> Initial Headers <button type="button" class="btn addBtnCss"
                                                    (click)="addMoreGHead()" id="btnSaveCase">Add</button>
                                            </h5>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Value</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody formArrayName="initGheader">
                                                    <tr
                                                        *ngFor="let globalhed of apiAutomationForm['controls'].initGheader['controls']; let ighx=index">
                                                        <ng-container formGroupName="{{ighx}}">
                                                            <td><input class="form-control txtControlCss"
                                                                    autocomplete="off" formControlName="gHedname"
                                                                    name="key" id="key" type="text"></td>
                                                            <td><input class="form-control txtControlCss"
                                                                    autocomplete="off" formControlName="gHedvalue"
                                                                    name="value" id="value" type="text">
                                                            </td>
                                                            <td class="text-center"><i
                                                                    class="fa fa-times-circle deleteIcon"
                                                                    (click)="deleteGHed(ighx)"></i></td>
                                                        </ng-container>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
    <form [formGroup]="mainAPIAutomationform" method="post">
        <button type="button" (click)="addMoreRequest()" class="btn btnaddmore"> + Add Requests</button>
        <div class="api_main_box " formArrayName="steps">
            <div *ngFor="let X of mainAPIAutomationform['controls'].steps['controls']; let ix=index">
                <div formGroupName="{{ix}}" class="form_box">
                    <div class="form_index">
                        <div>{{ix+1}}</div>
                    </div>
                    <div class="api_group_box form_data" [ngClass]="{ 'invalid_form': mainAPIAutomationform.invalid }" attr.id="'api_group_box{{ix}}">

                        <div class="request_header">
                            <p> <i class="fa fa-caret-right" id="iconO{{ix}}" aria-hidden="true"
                                    (click)="onShow(ix)"></i>
                                    {{X['controls'].url.value}} <span
                                    class="float-right deleterequest"><i class="fa fa-times deleteIcon"
                                        (click)="deleteRequest(ix)"></i></span></p>
                            <p class="mb-0 pl-15"><b>{{X['controls'].method.value}}/</b></p>
                        </div>

                        <div class="apisubbox" attr.id="apisubbox{{ix}}">
                            <div class="variable_box">
                                <div class="row col-md-12">
                                    <div class="col-md-12 mt-2">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Source</th>
                                                    <th>Property</th>
                                                    <th>Variable Name</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="variables">
                                                <tr
                                                    *ngFor="let variables of X['controls'].variables['controls']; let ivx=index">
                                                    <ng-container formGroupName="{{iax}}">
                                                        <td>
                                                            <select class="form-control txtControlCss"
                                                                style="font-size: 12px;" formControlName="source">
                                                                <option hidden value="" disabled selected *ngFor ='let sources of this.this.sourceList'>Select
                                                                </option>
                                                                <option>{{sources.sourceName}}</option>
                                                                <!-- <option>JSON Body</option>
                                                                <option>Response Size (Bytes)</option>
                                                                <option>ResponseTime (ms)</option>
                                                                <option>Status Code</option>
                                                                <option>Text Body</option>
                                                                <option>XML Body</option> -->
                                                            </select>
                                                        </td>
                                                        <td><input class="form-control txtControlCss" autocomplete="off"
                                                                type="text" formControlName="property" [readonly]='this.isSource==true'></td>

                                                        <td><input class="form-control txtControlCss" autocomplete="off"
                                                                type="text" formControlName="value"></td>
                                                        <td class="text-center"><i class="fa fa-times-circle deleteIcon"
                                                                (click)="deleteLocalVar(ivx,ix)"></i></td>
                                                    </ng-container>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-12 mb-2">
                                        <button type="button" (click)="addMoreLocalVar(ix)"
                                            class="btn btnaddmore localvarbtn"> +
                                            Add Variables</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row col-md-12 mt-2">
                                <div class="form-group col-md-4">
                                    <label for="elementId" class="txtControlLblCss">API Methods<span>*</span></label>
                                    <select formControlName='method' class="form-control txtControlCss" [ngClass]="{ 'is-invalid': X['controls'].method.errors }"
                                        style="font-size: 12px;" id="methodId">
                                        <option hidden value="" disabled selected>Select</option>
                                        <option value='GET'>GET</option>
                                        <option value='POST'>POST</option>
                                        <option value='PUT'>PUT</option>
                                        <option value='DELETE'>DELETE</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-8">
                                    <label for="url" class="txtControlLblCss">URL<span>*</span></label>
                                    <input type="text" formControlName='url' (blur)="checkQueryParam($event.target.value,ix)" id="url" placeholder="URL"
                                        class="form-control txtControlCss" [ngClass]="{ 'is-invalid': X['controls'].url.errors }">
                                        <div *ngIf="X['controls'].url.errors" class="text-danger">
                                            <label *ngIf="X['controls'].url.errors?.pattern">Please enter valid URL</label>
                                            <label *ngIf="X['controls'].url.errors?.required">Please enter URL</label>
                                        </div>
                                </div>
                            </div>
                            <div class="row col-md-12">
                                <div class="col-md-12 ">
                                    <nav>
                                        <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                            <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab"
                                                href="#nav-home{{ix}}" role="tab" aria-controls="nav-home"
                                                aria-selected="true">Params</a>
                                            <a class="nav-item nav-link" id="nav-assert-tab" data-toggle="tab"
                                                href="#nav-assert{{ix}}" role="tab" aria-controls="nav-assert"
                                                aria-selected="true">Assertions</a>
                                            <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab"
                                                href="#nav-profile{{ix}}" role="tab" aria-controls="nav-profile"
                                                aria-selected="false">Authorization</a>
                                            <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab"
                                                href="#nav-contact{{ix}}" role="tab" aria-controls="nav-contact"
                                                aria-selected="false">Headers</a>
                                            <a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab"
                                                href="#nav-about{{ix}}" role="tab" aria-controls="nav-about"
                                                aria-selected="false">Body</a>
                                            <a class="nav-item nav-link" id="nav-pre-tab" data-toggle="tab"
                                                href="#nav-pre{{ix}}" role="tab" aria-controls="nav-pre"
                                                aria-selected="false">Pre-request script</a>
                                            <a class="nav-item nav-link" id="nav-post-tab" data-toggle="tab"
                                                href="#nav-post{{ix}}" role="tab" aria-controls="nav-post"
                                                aria-selected="false">Post-request script</a>
                                        </div>
                                    </nav>
                                    <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                                        <div class="tab-pane fade show active" id="nav-home{{ix}}" role="tabpanel"
                                            aria-labelledby="nav-home-tab">
                                            <div class="queryparam_box apiTabsection">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <h5> Query Params <button type="button" class="btn addBtnCss"
                                                                (click)="addMoreParams(ix)"
                                                                id="btnSaveCase">Add</button>
                                                        </h5>
                                                        <table class="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Value</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody formArrayName="queryParams">
                                                                <tr
                                                                    *ngFor="let apiparams of X['controls'].queryParams['controls']; let iqx=index">
                                                                    <ng-container formGroupName="{{iqx}}">
                                                                        <td><input class="form-control txtControlCss"
                                                                                autocomplete="off"
                                                                                formControlName="queryKey" (blur)="addQueryParamtoUrl($event.target.value,ix,iqx)" name="key"
                                                                                id="key" type="text"></td>
                                                                        <td><input class="form-control txtControlCss"
                                                                                autocomplete="off"
                                                                                formControlName="queryValue"
                                                                                name="value" (blur)="addQueryParamtoUrl($event.target.value,ix,iqx)" id="value" type="text">
                                                                        </td>
                                                                        <td class="text-center"><i
                                                                                class="fa fa-times-circle deleteIcon"
                                                                                (click)="deleteParams(iqx,ix)"></i></td>
                                                                    </ng-container>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade show " id="nav-assert{{ix}}" role="tabpanel"
                                            aria-labelledby="nav-assert-tab">
                                            <div class="queryparam_box apiTabsection">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <h5> Assertions <button type="button" class="btn addBtnCss"
                                                                (click)="addMoreAssertions(ix)"
                                                                id="btnSaveCase">Add</button></h5>
                                                        <table class="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Source</th>
                                                                    <th>Property</th>
                                                                    <th>Comparison</th>
                                                                    <th>Target Value</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody formArrayName="assertions">
                                                                <tr
                                                                    *ngFor="let assertions of X['controls'].assertions['controls']; let iax=index">
                                                                    <ng-container formGroupName="{{iax}}">
                                                                        <td>
                                                                            <select class="form-control txtControlCss"
                                                                                style="font-size: 12px;"
                                                                                formControlName="source" (change)="changeSource($event,ix,iax)">
                                                                                <!-- <option hidden value="" disabled
                                                                                    selected>Select
                                                                                </option> -->
                                                                                <option hidden value="" disabled
                                                                                selected >Select
                                                                            </option>
                                                                                <option [value]="sourceLists.sourceId" *ngFor = 'let sourceLists of this.sourceList'>{{sourceLists.sourceName}}</option>
                                                                                <!-- <option>JSON Body</option>
                                                                                <option>Response Size (Bytes)</option>
                                                                                <option>ResponseTime (ms)</option>
                                                                                <option>Status Code</option>
                                                                                <option>Text Body</option>
                                                                                <option>XML Body</option> -->
                                                                            </select>
                                                                        </td>
                                                                        <td><input id="txtProperty{{iax}}"    class="form-control txtControlCss"
                                                                                autocomplete="off" type="text"
                                                                                formControlName="property"></td>
                                                                        <td>
                                                                            <select class="form-control txtControlCss"
                                                                                style="font-size: 12px;"
                                                                                formControlName="comparison" (change)="changeComparison($event)">
                                                                                <!-- formControlName="comparison"> -->
                                                                                <option hidden value="" disabled
                                                                                    selected >Select
                                                                                </option>
                                                                                <option [value]="compList.compTypeId" *ngFor = 'let compList of this.comparisonList'>{{compList.compName}}</option>
                                                                                <!-- <option>does not equal</option>
                                                                                <option>is empty</option>
                                                                                <option>is not empty</option>
                                                                                <option>contains</option>
                                                                                <option>does not contain</option>
                                                                                <option>is a number</option>
                                                                                <option>equals (number)</option>
                                                                                <option>less than</option>
                                                                                <option>less than or equal</option>
                                                                                <option>greater than</option>
                                                                                <option>greater than or equal</option> -->
                                                                            </select>
                                                                        </td>
                                                                        <td><input class="form-control txtControlCss"
                                                                                autocomplete="off" type="text"
                                                                                formControlName="value"></td>
                                                                        <td class="text-center"><i
                                                                                class="fa fa-times-circle deleteIcon"
                                                                                (click)="deleteassertions(iax,ix)"></i>
                                                                        </td>
                                                                    </ng-container>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="nav-contact{{ix}}" role="tabpanel"
                                            aria-labelledby="nav-contact-tab">
                                            <div class="header_box apiTabsection">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <h5> Headers <button type="button" class="btn addBtnCss"
                                                                (click)="addMoreHeaders(ix)"
                                                                id="btnSaveCase">Add</button></h5>
                                                        <table class="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Value</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody formArrayName="headers">
                                                                <tr
                                                                    *ngFor="let apiheaders of X['controls'].headers['controls']; let ihx=index">
                                                                    <ng-container formGroupName="{{ihx}}">
                                                                        <td><input class="form-control txtControlCss"
                                                                                autocomplete="off"
                                                                                formControlName="headerKey" name="key"
                                                                                id="key" type="text"></td>
                                                                        <td><input class="form-control txtControlCss"
                                                                                autocomplete="off"
                                                                                formControlName="headerValue"
                                                                                name="value" id="value" type="text">
                                                                        </td>
                                                                        <td class="text-center"><i
                                                                                class="fa fa-times-circle deleteIcon"
                                                                                (click)="deleteHeadres(ihx,ix)"></i>
                                                                        </td>
                                                                    </ng-container>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="nav-profile{{ix}}" role="tabpanel"
                                            aria-labelledby="nav-profile-tab">
                                            <div class="auth_box apiTabsection">
                                                <div class="row" formGroupName="auth">
                                                    <div class="form-group col-md-4">
                                                        <label for="authType" class="txtControlLblCss">Auth
                                                            Type<span>*</span></label>
                                                        <select formControlName="auth_type"
                                                            class="form-control txtControlCss" style="font-size: 12px;"
                                                            id="authType" (change)="getAuthValue($event, ix)">
                                                            <option hidden value="" disabled selected>Select</option>
                                                            <option [value]="authtype.id"
                                                                *ngFor="let authtype of this.authType">
                                                                {{authtype.label}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6" [ngSwitch]="X['controls'].auth['controls']['auth_type'].value">
                                                        <div class="api_Key_box" *ngSwitchCase="'1'">
                                                            <ng-container>
                                                                <div>
                                                                    <div class="col-md-12 form-group">
                                                                        <label for="key"
                                                                            class="txtControlLblCss">Key<span>*</span></label>
                                                                        <input class="form-control txtControlCss"
                                                                            formControlName="username" autocomplete="off"
                                                                            name="key" id="key" type="text"
                                                                            [ngClass]="{ 'is-invalid': X['controls'].auth['controls'].username.errors }">
                                                                            <ng-container *ngIf="X['controls'].auth['controls'].username.invalid && X['controls'].auth['controls'].username.touched" class="text-danger">
                                                                            <label class="text-danger" *ngIf=" X['controls'].auth['controls'].username.hasError('whitespace') || X['controls'].auth['controls'].username.errors?.required">Please enter APIKey</label>
                                                                            </ng-container>
                                                                    </div>
                                                                    <div class="col-md-12">
                                                                        <label for="keyvalue"
                                                                            class="txtControlLblCss">Value<span>*</span></label>
                                                                        <input class="form-control txtControlCss"
                                                                            formControlName="password"
                                                                            autocomplete="off" name="keyvalue"
                                                                            id="keyvalue" type="text">
                                                                            <ng-container *ngIf="X['controls'].auth['controls'].password.invalid && X['controls'].auth['controls'].password.touched" class="text-danger">
                                                                                <label class="text-danger" *ngIf="X['controls'].auth['controls'].username.hasError('whitespace') || X['controls'].auth['controls'].password.errors?.required">Please enter APIValue</label>
                                                                                </ng-container>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                        <div class="basic_auth_box" *ngSwitchCase="'2'">
                                                            <div class="col-md-12">
                                                                <label for="username"
                                                                    class="txtControlLblCss">Username<span>*</span></label>
                                                                <input class="form-control txtControlCss"
                                                                    formControlName="username" autocomplete="off"
                                                                    name="username" id="username" type="text">
                                                                    <ng-container *ngIf="X['controls'].auth['controls'].username.invalid && X['controls'].auth['controls'].username.touched" class="text-danger">
                                                                        <label class="text-danger" *ngIf="X['controls'].auth['controls'].username.errors?.required">Please enter username</label>
                                                                        </ng-container>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label for="password"
                                                                    class="txtControlLblCss">Password<span>*</span></label>
                                                                <input class="form-control txtControlCss"
                                                                    formControlName="password" autocomplete="off"
                                                                    name="password" id="password" type="password">
                                                                    <ng-container *ngIf="X['controls'].auth['controls'].password.invalid && X['controls'].auth['controls'].password.touched" class="text-danger">
                                                                        <label class="text-danger" *ngIf="X['controls'].auth['controls'].password.errors?.required">Please enter password</label>
                                                                        </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="nav-about{{ix}}" role="tabpanel"
                                            aria-labelledby="nav-about-tab">
                                            <div class="body_box apiTabsection">
                                                <div class="row" formGroupName="body">
                                                    <div class="col-md-12 bb-2 mb-2 pb-3">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                formControlName="bodyDataFormat" id="inlineRadio1{{ix}}"
                                                                value="none" [checked]=true (change)="handleChange($event, ix,1)">
                                                            <label class="form-check-label"
                                                                for="inlineRadio1{{ix}}">none</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                formControlName="bodyDataFormat" id="inlineRadio2{{ix}}"
                                                                value="form-data" (change)="handleChange($event,ix,2)">
                                                            <label class="form-check-label"
                                                                for="inlineRadio2{{ix}}">form-data</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                formControlName="bodyDataFormat" id="inlineRadio3{{ix}}"
                                                                value="form-data1" (change)="handleChange($event, ix,2)">
                                                            <label class="form-check-label"
                                                                for="inlineRadio3{{ix}}">x-www-form-urlencoded</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                formControlName="bodyDataFormat" id="inlineRadio4{{ix}}"
                                                                value="raw" (change)="handleChange($event,ix,3)">
                                                            <label class="form-check-label"
                                                                for="inlineRadio4{{ix}}">raw</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                formControlName="bodyDataFormat" id="inlineRadio5{{ix}}"
                                                                value="binary" (change)="handleChange($event,ix,4)">
                                                            <label class="form-check-label"
                                                                for="inlineRadio5{{ix}}">binary</label>
                                                        </div>
                                                        <div class="form-check form-check-inline mb-0" *ngIf="X['controls'].body['controls']['bodyDataFormat'].value == 'raw'">
                                                            <div class="form-group mb-0">
                                                                <select class="form-group mb-0"
                                                                    formControlName="bodyDataType">
                                                                    <option hidden value="" disabled selected>Select
                                                                    </option>
                                                                    <option>JSON</option>
                                                                    <option>TEXT</option>
                                                                    <option>JAVASCRIPT</option>
                                                                    <option>XML</option>
                                                                    <option>HTML</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 mt-1">
                                                        
                                                        <div class="row mt-3" [ngSwitch]="X['controls'].body['controls']['bodyDataFormat'].value">
                                                            <div class="col-md-12" *ngSwitchCase="'form-data'">
                                                                <h5> Form <button type="button" class="btn addBtnCss"
                                                                        (click)="addMoreFormData(ix)"
                                                                        id="btnSaveCase">Add</button></h5>
                                                                <table class="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Name</th>
                                                                            <th>Value</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody formArrayName="form">
                                                                        <tr
                                                                            *ngFor="let apibody of X['controls'].body['controls'].form['controls']; let ibx=index">
                                                                            <ng-container formGroupName="{{ibx}}">
                                                                                <td><input class="form-control txtControlCss"
                                                                                        autocomplete="off"
                                                                                        formControlName="name" 
                                                                                        id="key" type="text"></td>
                                                                                <td><input class="form-control txtControlCss"
                                                                                        autocomplete="off"
                                                                                        formControlName="value"
                                                                                         type="text">
                                                                                </td>
                                                                                <td class="text-center"><i
                                                                                        class="fa fa-times-circle deleteIcon"
                                                                                        (click)="deleteMoreFormData(ibx,ix)"></i>
                                                                                </td>
                                                                            </ng-container>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-md-12" *ngSwitchCase="'form-data1'">
                                                                <h5> Form <button type="button" class="btn addBtnCss"
                                                                        (click)="addMoreFormData(ix)"
                                                                        id="btnSaveCase">Add</button></h5>
                                                                <table class="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Name</th>
                                                                            <th>Value</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody formArrayName="form">
                                                                        <tr
                                                                            *ngFor="let apibody of X['controls'].body['controls'].form['controls']; let ibx=index">
                                                                            <ng-container formGroupName="{{ibx}}">
                                                                                <td><input class="form-control txtControlCss"
                                                                                        autocomplete="off"
                                                                                        formControlName="name" 
                                                                                        id="key" type="text"></td>
                                                                                <td><input class="form-control txtControlCss"
                                                                                        autocomplete="off"
                                                                                        formControlName="value"
                                                                                         type="text">
                                                                                </td>
                                                                                <td class="text-center"><i
                                                                                        class="fa fa-times-circle deleteIcon"
                                                                                        (click)="deleteMoreFormData(ibx,ix)"></i>
                                                                                </td>
                                                                            </ng-container>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-md-12" *ngSwitchCase="'binary'">
                                                                <div class="form-group">
                                                                    <input type="file" formControlName="formBinary" class="form-control-file" id="formbinary">
                                                                  </div>
                                                            </div>
                                                            <div class="col-md-12" *ngSwitchCase="'raw'">
                                                                <div class="form-group" >
                                                                    <textarea class="form-control"
                                                                        formControlName="bodyData"></textarea>
        
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="nav-pre{{ix}}" role="tabpanel"
                                            aria-labelledby="nav-pre-tab">
                                            <div class="pretest_box apiTabsection">
                                                <div class="row">
                                                    <div class="col-md-12 variable-box">
                                                        <div class="form-group">
                                                            <div id="app-ace-editor{{ix}}" (keyup)="onChangePreScript($event,ix)" class="app-ace-editor" #editor
                                                                ></div>
                                                            <!-- <textarea class="app-ace-editor"
                                                            #editor formControlName="post_script" rows="10"
                                                                cols="70" class="form-control"></textarea> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="nav-post{{ix}}" role="tabpanel"
                                            aria-labelledby="nav-post-tab">
                                            <div class="posttest_box apiTabsection">
                                                <div class="row">
                                                    <div class="col-md-12 variable-box">
                                                        <div class="form-group">
                                                            <div id="app-ace-posteditor{{ix}}" (keyup)="onChangePostScript($event,ix)" class="app-ace-editor"
                                                                ></div>
                                                            <!-- <textarea formControlName="pre_scripts" rows="10" cols="70"
                                                                class="form-control"></textarea> -->
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                            <div class="row col-md-12">
                                <div class=" col-md-12 last_reponse_box">
                                    <p> <i class="fa fa-caret-right" id="iconR{{ix}}" aria-hidden="true"
                                            (click)="onLastRespShow(ix)"></i> Last Response Data</p>
                                    <div class="prev_apidata prev_response{{ix}}">
                                        <p>No previous results</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    </form>
</div>