/* projects/wtaf/src/app/features/inprogress/inprogress/inprogress.component.css */
#tblsylecld {
  position: absolute;
  width: 665px;
  height: 200px;
  left: 179px;
  top: 421px;
  border: 1px solid #c5c5c5;
  box-sizing: border-box;
}
.tblexec-row {
  background-color: white !important;
  border-bottom: 1px solid rgb(212, 221, 230) !important;
}
.nav-link.active {
  color: #012F60;
  border-bottom: #1F83FE 4px solid;
  border-bottom-left-radius: -10px;
  border-bottom-right-radius: -10px;
  border-color: white white #1F83FE;
}
.tblexec-td {
  border: none !important;
}
.timestyle {
  padding-top: 0px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: black;
  opacity: 0.5;
}
.datestyle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #192b49;
}
.out {
  display: none;
}
.in {
  display: table-row;
}
.caret {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.caret::before {
  content: url("./media/expand.png");
  color: black;
  display: inline-block;
  margin-right: 6px;
}
.caret-down::before {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.childTable {
  margin-bottom: 20px;
  width: 60%;
  height: 80px;
  left: 100px;
  top: 421px;
  border: 1px solid rgb(197, 197, 197);
}
.childTable td {
  width: 500px;
}
.even {
  background-color: #ffffff !important;
}
.odd {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.statusVericalCss {
  vertical-align: bottom;
}
.sheduletablestyle {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  vertical-align: middle !important;
}
.dot {
  height: 10px;
  width: 10px;
  vertical-align: middle;
  background-color: #ff5e00 !important;
  border-radius: 50%;
  display: inline-block;
}
.stopIcon {
  color: #007bff;
}
.refreshBtnCss {
  margin-left: 5px;
  margin-right: 5px;
  background-color: #1f83fe !important;
  border-radius: 3px !important;
  width: 110px !important;
  height: 38px !important;
  color: #ffffff !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}
/*# sourceMappingURL=inprogress.component.css.map */
