{
  "appName": "Prodigio",
  "baseUrl": "https://6kd2ewh4cb.execute-api.us-east-1.amazonaws.com/dev",
  "jenkinsUrl": "https://USERW:Pkm12345@prodigio.whirlpool.com/jenkins",
  "JenkinsWorkspace_Url": "https://USERW:Pkm12345@prodigio.whirlpool.com/jenkins",
  "headspinBaseUrl": "https://api-dev.headspin.io/v0/",
  "ProjectArn": "arn:aws:devicefarm:us-west-2:968307359860:project:5584828f-c01a-49a0-9dcc-5897b7404594",
  "IdentityPoolId": "us-west-2:79e5cbfa-6be3-4eab-972a-9e2ddbb7e8e5",
  "DF_region": "us-west-2",
  "testSpecArnAndroid": "arn:aws:devicefarm:us-west-2:968307359860:upload:5584828f-c01a-49a0-9dcc-5897b7404594/51e92bad-3323-49e6-9801-ea6aa7fc4d67",
  "testSpecArnIOS": "arn:aws:devicefarm:us-west-2:968307359860:upload:5584828f-c01a-49a0-9dcc-5897b7404594/f599598a-2a16-43e2-a514-ff0a1fb30451",
  "githubCheckout": "DeviceFarm Execution",
  "JenkinsCheckoutJobUrl": "execution/node/3/ws/framework/target/zip-with-dependencies.zip",
  "framework_Exe": "Framework_Execution_2",
  "wtafEnv": "true",
  "envValue": "prodigio",
  "headspinAPIToken": "1fbd4ab2208e444f8b39a94a8183a114",
  "BrowserStackBaseUrl": "https://api-cloud.browserstack.com/app-automate/"
}
