import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faPodcast } from '@fortawesome/free-solid-svg-icons';
import { range } from 'rxjs';

@Component({
  selector: 'wtaf-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  collection = [];
  totalPage: number;
  public totalPagesArr: number[] = [];
  startPage: number;
  endPage: number;
  pagesRange = [];
  recordRangeArr = [
    { name: 'Show 25 records', value: 25 },
    { name: 'Show 50 records', value: 50 },
    { name: 'Show All records', value: 0 }
  ];
  constructor() {}
  @Input() totalRecords = 0;
  @Input() recordsPerPage = 25;
  @Output() onPageChange: EventEmitter<{}> = new EventEmitter();
  @Input() activePage: number;

  ngOnInit() {}

  ngOnChanges(): any {
    const pageCount = this.getPageCount();
    this.totalPagesArr = this.getArrayOfPage(pageCount);
    console.log('this.pages ', this.totalPagesArr);
    // this.activePage = 1;
    const post = {
      pages: this.totalPagesArr,
      currentPage: this.activePage,
      totalPages: this.totalPage,
      recordsPerPage: this.recordsPerPage
    };
    this.onPageChange.emit(post);
    this.onChangeTotalRecords();
  }
  private getPageCount(): number {
    this.totalPage = 0;
    if (this.totalRecords > 0 && this.recordsPerPage > 0) {
      const pageCount = this.totalRecords / this.recordsPerPage;
      const roundedPageCount = Math.floor(pageCount);
      this.totalPage =
        roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;
    }

    return this.totalPage;
  }

  private getArrayOfPage(pageCount: number): number[] {
    const pageArray = [];

    if (pageCount > 0) {
      for (let i = 1; i <= pageCount; i++) {
        pageArray.push(i);
      }
    }
    return pageArray;
  }

  onClickPage(pageNumber: number): void {
    if (pageNumber < 1 || pageNumber > this.totalPagesArr.length) {
      return;
    }
    if (pageNumber === this.activePage) {
      return;
    }
    let currntPage;
    if (pageNumber >= 1 && pageNumber <= this.totalPagesArr.length) {
      this.activePage = pageNumber;
      const post = {
        pages: this.totalPagesArr,
        currentPage: this.activePage,
        totalPages: this.totalPage,
        recordsPerPage: this.recordsPerPage
      };
      this.onPageChange.emit(post);
      currntPage = this.activePage;
      this.onChangeTotalRecords();
    }
  }

  onChangeTotalRecords() {
    if (this.totalPage <= 5) {
      this.startPage = 1;
      this.endPage = this.totalPage;
    } else {
      if (this.activePage <= 3) {
        this.startPage = 1;
        this.endPage = 5;
      } else if (this.activePage + 1 >= this.totalPage) {
        this.startPage = this.totalPage - 4;
        this.endPage = this.totalPage;
      } else {
        if (this.totalPage - (this.activePage - 2) == 5) {
          this.startPage = this.activePage - 1;
          this.endPage = this.activePage + 3;
        } else {
          this.startPage = this.activePage - 2;
          this.endPage = this.activePage + 2;
        }
      }
    }
    // create an array of pages to ng-repeat in the pager control
    let rangePages = range(this.startPage, this.endPage + 1);
    this.pagesRange = [];
    rangePages.subscribe(res => {
      if (res <= this.endPage) {
        this.pagesRange.push(res);
      }
    });
  }
  getrecordsPerPage(event) {
    this.recordsPerPage = event.target.value;
    const paginationObj = {
      pages: this.totalPagesArr,
      currentPage: 1,
      totalPages: this.totalPage,
      recordsPerPage: this.recordsPerPage
    };
    this.onPageChange.emit(paginationObj);
  }
}
