/* projects/wtaf/src/app/shared/big-input/big-input-action/big-input-action.component.scss */
button {
  padding: 0;
  min-width: 36px;
  margin-left: 10px;
}
button mat-icon {
  position: relative;
  top: 2px;
  font-size: 18px;
}
button fa-icon {
  position: relative;
  font-size: 18px;
}
/*# sourceMappingURL=big-input-action.component.css.map */
