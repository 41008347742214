import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as ace from "ace-builds";
import { DataService } from '../../../shared/services/data.service';
import { LoaderService } from '../../../shared/services/loader.service';
declare var $: any;
@Component({
  selector: 'wtaf-api-automation',
  templateUrl: './api-automation.component.html',
  styleUrls: ['./api-automation.component.css']
})
export class ApiAutomationComponent implements OnInit, AfterViewInit {
authType = [{
  "id":0,
  "label":'No Auth'
},
{
  "id":1,
  "label":'API Key'
},

{
  "id":2,
  "label":'Basic Auth'
}
];
headerType = [{
  "id":1,
  "label":'Accept'
},
{
  "id":2,
  "label":'Accept-Charset'
},
{
  "id":3,
  "label":'Accept-Encoding'
},
{
  "id":4,
  "label":'Accept-Language'
},
{
  "id":5,
  "label":'Cache-Control'
},
{
  "id":6,
  "label":'Connection'
},
{
  "id":7,
  "label":'Content-Length'
},
{
  "id":7,
  "label":'Content-Type'
},
];

authTypeVal:number;
formTypeVal:number;
apiCount:number;
apiAutomationForm: UntypedFormGroup;
mainAPIAutomationform:UntypedFormGroup;
isRawForm:boolean =false;
sourceList :any =[];
comparisonList :any =[];
isSource=false;
ixs =0;
hidePropertiesList = 'Response Size (Bytes),ResponseTime (ms),Status Code,Text Body';
urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
@Input() formData: UntypedFormGroup;
@Output() onFormGroupChange: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
@ViewChildren("editor") private editor:  QueryList<ElementRef>;
ComparisonTypeList: any=[];

  constructor(private formBuilder: UntypedFormBuilder, private dataService: DataService, public loaderService: LoaderService) {
   this.apiCount =0;
   }

  ngOnInit() {
  

    
    this.mainAPIAutomationform = this.formBuilder.group({
      'steps':this.formBuilder.array([
this.initApiAutomationForm()
      ]),
   });
   this.apiAutomationForm = this.formBuilder.group({
    'initGvaribale':this.formBuilder.array([]),
    'initGheader':this.formBuilder.array([]),
 });
    this.addFormGroupToParent();
    // this.getSourceList();
    // this.getComparisonTypeList();
  }

  ngAfterViewInit(): void {
    ace.config.set("fontSize", "14px");
    ace.config.set(
      "basePath",
      "https://unpkg.com/ace-builds@1.4.12/src-noconflict"
    );
    const aceEditor = ace.edit("app-ace-editor0");
    aceEditor.session.setMode("ace/mode/javascript");
    aceEditor.session.setValue("var preScript = '';");
    const acePostEditor = ace.edit("app-ace-posteditor0");
    acePostEditor.session.setMode("ace/mode/javascript");
    acePostEditor.session.setValue("var postScript = '';");
  //   this.editor.forEach((div: ElementRef) => { 
  //   const aceEditor = ace.edit("app-ace-editor");
  //   aceEditor.session.setMode("ace/mode/javascript");
  //   aceEditor.session.setValue("var preScript = '';");
  // });
  aceEditor.on("change", () => {
    console.log(aceEditor.getValue());
  });

  aceEditor.on("change", () => {
    console.log(acePostEditor.getValue());
  });
  
  }

  onChangePreScript(e,ix)
  {
     
   let pre_Script= "var preScript = '';"
   
  console.log('changes for pre_Script',e.target.value);
  let preScriptData=e.target.value.trim();
  if(preScriptData == pre_Script)
  {
    console.log('changes for ix',ix);
    preScriptData="";
  }
  // else
  // {
    console.log('changes for ix',ix);
    const control = (<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('pre_scripts') as UntypedFormArray;
    control.setValue(preScriptData);  
    console.log(control.value);  
  // }
 
 

  }

  onChangePostScript(e,ix)
  {
   
  let post_Script= "var postScript = '';"
  console.log('changes for post_script',e.target.value);
  let postScriptData=e.target.value.trim();
  console.log('changes for ix',ix);
  if(postScriptData == post_Script)
  {
  postScriptData = "";
  }
  // else{  
  const control = (<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('post_script') as UntypedFormArray;
  control.setValue(postScriptData)  
  console.log(control.value);  
  // }
  }

  getSourceList(){
    this.loaderService.show();
  this.dataService
   .getSourceList().toPromise()
   .then((response: {}) => {
    this.sourceList = response;
    console.log("response sourcelist:",this.sourceList);
      })
      .catch(err => {
        console.log(err);
        this.loaderService.hide();
      });

  }

  getComparisonTypeList(sourceId){
    this.comparisonList=[];
    this.loaderService.show();
  this.dataService
   .getComparisonTypeList(sourceId).toPromise()
   .then((response: {}) => {
    this.comparisonList = response;
    console.log("response getComparisonTypeList:",this.comparisonList);
      })
      .catch(err => {
        console.log(err);
        this.loaderService.hide();
      });
  
  }

  addMainform(){
    const mainAPIControl = this.mainAPIAutomationform.controls['steps'] as UntypedFormArray;
    mainAPIControl.push(this.initApiAutomationForm());
  }
  initApiAutomationForm(){
    return this.formBuilder.group({
      step_type:new UntypedFormControl('request'),
      skipped: new UntypedFormControl('false'),
      note: new UntypedFormControl(''),
      method:new UntypedFormControl('GET', [Validators.required]),
      url:new UntypedFormControl('http://testwtaf-env.eba-3yzahsmz.us-east-2.elasticbeanstalk.com/wtafUI/',[Validators.required,Validators.pattern(this.urlRegex)]),
      queryParams: this.formBuilder.array([]),
      assertions:this.formBuilder.array([]),
      headers:this.formBuilder.array([]),
      variables:this.formBuilder.array([]),
      post_script:new UntypedFormControl(''),
      pre_scripts:new UntypedFormControl(''),
      body:this.formBuilder.group({
        bodyDataFormat: new UntypedFormControl(''),
        bodyDataType: new UntypedFormControl(''),
        bodyData: new UntypedFormControl(''),
      }),
      auth:this.formBuilder.group({
        auth_type: new UntypedFormControl(''),
      }),
    });
  };
  addFormGroupToParent() {
    this.formData.addControl('apiAutomationForm', this.mainAPIAutomationform);
    this.onFormGroupChange.emit(this.formData);
  }
  getAuthValue(authValue,ix){
    const enum authType {
      noAuth = 0,
      apiKey = 1,
      basicAuth = 2,
    }
    this.authTypeVal = authValue.target.value;
    switch (+this.authTypeVal) {
      case authType.noAuth: {
        ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as UntypedFormGroup).removeControl('apiKey');
        ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as UntypedFormGroup).removeControl('apiKeyvalue');
        ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as UntypedFormGroup).removeControl('apiAuthToken');
        ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as UntypedFormGroup).removeControl('username');
        ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as UntypedFormGroup).removeControl('password');
        break;
      }
      case authType.apiKey: {
        ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as UntypedFormGroup).removeControl('username');
        ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as UntypedFormGroup).removeControl('password');
        ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as UntypedFormGroup).addControl('username',this.formBuilder.control(null,[Validators.required,noWhitespaceValidator]));
        ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as UntypedFormGroup).addControl('password',this.formBuilder.control(null,[Validators.required,noWhitespaceValidator]));
        ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as UntypedFormGroup).removeControl('apiAuthToken');
   
        break;
      }
      // case authType.token: {
      //   ((<FormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as FormGroup).removeControl('apiKey');
      //   ((<FormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as FormGroup).removeControl('apiKeyvalue');
      //   ((<FormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as FormGroup).addControl('apiAuthToken',this.formBuilder.control(null));
      //   ((<FormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as FormGroup).removeControl('apiAuthUsername');
      //   ((<FormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as FormGroup).removeControl('apiAuthPassword');
      //   break;
      // }
      case authType.basicAuth: {
        ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as UntypedFormGroup).removeControl('username');
        ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as UntypedFormGroup).removeControl('password');
        ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as UntypedFormGroup).removeControl('apiAuthToken');
        ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as UntypedFormGroup).addControl('username',this.formBuilder.control(null,[Validators.required,noWhitespaceValidator]));
        ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('auth') as UntypedFormGroup).addControl('password',this.formBuilder.control(null,[Validators.required,noWhitespaceValidator]));
        break;
      }
    }
  }
  addMoreRequest(){  
       
    const control = <UntypedFormArray>this.mainAPIAutomationform.controls['steps'];
    const apiIndex = control.length;
    control.push(this.initApiAutomationForm());
    this.apiCount = control.length;
   
    
    setTimeout(() => {
      // this.editor.forEach((element:ElementRef, index) => {
      //   console.log(apiIndex + "===" + index);
        
      //   if(apiIndex === index) { 
      //   const aceEditor = ace.edit(element.nativeElement);
      //   aceEditor.session.setMode("ace/mode/javascript");
      //   aceEditor.session.setValue("var preScript = '';");
      //   }
      // }
      // );
      let preScriptId = 'app-ace-editor'+ apiIndex;
      const aceEditor = ace.edit(preScriptId);
      aceEditor.session.setMode("ace/mode/javascript");
      aceEditor.session.setValue("var preScript = '';");
      let postScriptId = 'app-ace-posteditor'+ apiIndex;
      const acePostEditor = ace.edit(postScriptId);
      acePostEditor.session.setMode("ace/mode/javascript");
      acePostEditor.session.setValue("var postScript = '';");
      console.log(aceEditor.getValue());
      
  }, 500);
  }
 
  deleteRequest(ix){
    const requestArray = (<UntypedFormArray>this.mainAPIAutomationform.controls['steps']) as UntypedFormArray;
    requestArray.removeAt(ix); 
    this.apiCount = requestArray.length;
  }
  addMoreParams(ix) {
    const control = (<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('queryParams') as UntypedFormArray;
    control.push(this.formBuilder.group({
      queryKey: new UntypedFormControl(''),
      queryValue: new UntypedFormControl(''),
    }));
}
deleteParams(index:number,ix){
    const queryparamArray = (<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('queryParams') as UntypedFormArray;
    queryparamArray.removeAt(index);
}
addMoreHeaders(ix){
  
  const controls = (<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('headers') as UntypedFormArray;
  controls.push(this.formBuilder.group({
    headerKey: new UntypedFormControl(''),
    headerValue: new UntypedFormControl(''),
  })); 
}
deleteHeadres(index:number,ix){
  const headersArray = (<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('headers') as UntypedFormArray;
  headersArray.removeAt(index);
}
addMoreAssertions(ix){
  
  const controls = (<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('assertions') as UntypedFormArray;
  this.ixs=ix;
  controls.push(this.formBuilder.group({
    source: new UntypedFormControl(''),
    property: new UntypedFormControl(''),
    comparison: new UntypedFormControl(''),
    value: new UntypedFormControl(''),
  })); 

  this.dataService.getSourceList().subscribe(res => {
    console.log('response....', res);
    this.sourceList = res;
  });
  // this.dataService.getComparisonList().subscribe(res => {
  //   console.log('comparison list response....', res);
  //   this.comparisonList = res;
  // });
}
deleteassertions(index:number,ix){
  const assertionArray = (<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('assertions') as UntypedFormArray;
  assertionArray.removeAt(index);
}
addMoreLocalVar(ix){
  const controls = (<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('variables') as UntypedFormArray;
  controls.push(this.formBuilder.group({
    source: new UntypedFormControl(''),
    property: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
  })); 
}
deleteLocalVar(index:number,ix){
  const assertionArray = (<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('variables') as UntypedFormArray;
  assertionArray.removeAt(index);
}
onShow(id){
  $('#apisubbox'+id).toggleClass('open');
  let classList = $('#iconO'+id).attr('class').split(/\s+/);
  $.each(classList, function(index, item) {
    if (item === 'fa-caret-right') {
      $('#iconO'+id).addClass('fa-caret-down').removeClass('fa-caret-right');
    }else{
      $('#iconO'+id).addClass('fa-caret-right').removeClass('fa-caret-down');

    }
});
}
onLastRespShow(id){
  $('.prev_response'+id).toggleClass('open');
  let classList = $('#iconR'+id).attr('class').split(/\s+/);
  $.each(classList, function(index, item) {
    if (item === 'fa-caret-right') {
      $('#iconR'+id).addClass('fa-caret-down').removeClass('fa-caret-right');
    }else{
      $('#iconR'+id).addClass('fa-caret-right').removeClass('fa-caret-down');

    }
});

}
onInitailShow(){
  $('.intial_box').toggleClass('open');
  let classList = $('#iconI').attr('class').split(/\s+/);
  $.each(classList, function(index, item) {
    if (item === 'fa-caret-right') {
      $('#iconI').addClass('fa-caret-down').removeClass('fa-caret-right');
    }else{
      $('#iconI').addClass('fa-caret-right').removeClass('fa-caret-down');

    }
}); 
}
addMoreGvar(){
  const controls = (<UntypedFormArray>this.apiAutomationForm.controls['initGvaribale']) as UntypedFormArray;
  controls.push(this.formBuilder.group({
    gVarname: new UntypedFormControl(''),
    gVarvalue: new UntypedFormControl(''),
  }));
}
deleteGVar(index){
  const assertionArray = (<UntypedFormArray>this.apiAutomationForm.controls['initGvaribale']) as UntypedFormArray;
  assertionArray.removeAt(index);
}
addMoreGHead(){
  const controls = (<UntypedFormArray>this.apiAutomationForm.controls['initGheader']) as UntypedFormArray;
  controls.push(this.formBuilder.group({
    gHedname: new UntypedFormControl(''),
    gHedvalue: new UntypedFormControl(''),
  }));
}
deleteGHed(index){
  const assertionArray = (<UntypedFormArray>this.apiAutomationForm.controls['initGheader']) as UntypedFormArray;
  assertionArray.removeAt(index);
}
handleChange(evt,ix,formTypeNumber){
  let target = evt.target;
  
        const enum formType {
          none = 1,
          form = 2,
          raw = 3,
          binary = 4,
        }
        this.formTypeVal = formTypeNumber;
        switch (+this.formTypeVal) {
          case formType.none: {
            ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('body') as UntypedFormGroup).removeControl('form');
            ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('body') as UntypedFormGroup).removeControl('formBinary');
            this.isRawForm =false;

            break;
          }
          case formType.form: {
            ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('body') as UntypedFormGroup).removeControl('form');
            ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('body') as UntypedFormGroup).removeControl('formBinary');
            ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('body') as UntypedFormGroup).addControl('form',this.formBuilder.array([]));
            const bodycontrols = ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('body') as UntypedFormGroup);
            const controls = (<UntypedFormArray>bodycontrols.controls['form']) as UntypedFormArray;
            controls.push(this.formBuilder.group({
              name: new UntypedFormControl(''),
              value: new UntypedFormControl(''),
            }));
            this.isRawForm =false;
            break;
          }
          case formType.raw: {
            this.isRawForm =true;
            ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('body') as UntypedFormGroup).removeControl('form');
            ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('body') as UntypedFormGroup).removeControl('formBinary');
            break;
          }
          case formType.binary: {
            this.isRawForm =false;
            ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('body') as UntypedFormGroup).removeControl('form');
            ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('body') as UntypedFormGroup).addControl('formBinary',this.formBuilder.control(null));
            break;
          }
        }
}
addMoreFormData(ix){
  ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('body') as UntypedFormGroup).addControl('form',this.formBuilder.array([]));
  const bodycontrols = ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('body') as UntypedFormGroup);
  const controls = (<UntypedFormArray>bodycontrols.controls['form']) as UntypedFormArray;
  controls.push(this.formBuilder.group({
    name: new UntypedFormControl(''),
    value: new UntypedFormControl(''),
  }));
}
deleteMoreFormData(index,ix){
  const bodycontrols = ((<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('body') as UntypedFormGroup);
  const bodyFormArray = (<UntypedFormArray>bodycontrols.controls['form']) as UntypedFormArray;
  bodyFormArray.removeAt(index);
}
checkQueryParam(urls, index){
  const url = new URL(urls);
  let query = url.search;
  let params = query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {};
  if(Object.keys(params).length != 0 && params.constructor === Object){
    const control = (<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(index).get('queryParams') as UntypedFormArray;
    if(control.length > 0){
      control.clear();
    }
    for (const property in params) {
      control.push(this.formBuilder.group({
        queryKey: new UntypedFormControl(property),
        queryValue: new UntypedFormControl(params[property]),
      }));
    }

  }
}
addUpdateUrlParam(uri, key, value){
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
}
addQueryParamtoUrl(key,ix,index){
  const control = (<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('queryParams') as UntypedFormArray;
  console.log(control);
  
   let queryKey = control.controls[index].get('queryKey').value;
   let queryValue = control.controls[index].get('queryValue').value;
  let apiUrl = (<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('url');
  let updatedUrl = this.addUpdateUrlParam(apiUrl.value,queryKey,queryValue);
  (<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(ix).get('url').setValue(updatedUrl);
}

changeSource(e,ix,ixa)
{
console.log('e_source......',e.target.value);
this.getComparisonTypeList(e.target.value);
let controls = (<UntypedFormArray>this.mainAPIAutomationform.controls['steps']).at(this.ixs).get('assertions') as UntypedFormArray;
controls.at(ixa).get('comparison').setValue('');
 let sourceName= e.target.options[e.target.options.selectedIndex].text;
console.log('sourceName......',sourceName);
 
 if(this.hidePropertiesList.includes(sourceName))
 {
  this.isSource = true;
  console.log('controls...',controls);
  $("#txtProperty"+ ixa).prop("disabled" , true);
  controls.at(ixa).get('property').setValue('')
  console.log('controls.value',controls.value);
  console.log('controls.controls',controls.controls);
  
 }
 else
 {
  $("#txtProperty"+ ixa).prop("disabled" , false);
  this.isSource = false;
 }

}

changeComparison(e)
{
console.log('e_comparison......',e.target.value);
}
ngOnDestroy() {
  this.mainAPIAutomationform.reset();
  this.formData.removeControl('apiAutomationForm');
}
}
export function noWhitespaceValidator(control: UntypedFormControl) {
  const isSpace = (control.value || '').trim().length === 0;
  return isSpace ? { whitespace: true } : null;
}
