<div>
    <form [formGroup]="testCaseDeviceComparionForm" (ngSubmit)="deviceExecutionComparionSubmit()"> 
        <div class="row">
            <div class="form-group col-md-3">
                <label for="select1" class="small-label" style="font-size: 12px;">Select Region</label>
                <select class="txtControlCss col-md-12" id="regionId" formControlName="regionId" (change)="changeRegion($event)">
                    <option value=0 selected>All Region</option>
                    <option [value]="region.regionId" *ngFor="let region of this.regionList">{{region.regionCode}}</option>        
                </select>
                <span class="error" *ngIf=" this.isSubmitted && !testCaseDeviceComparionForm.controls['regionId'].valid">Region is required</span>
            </div>
            <div class="form-group col-md-3">
                <label for="select1" class="small-label" style="font-size: 12px;">Select Brand</label>
                <select class="txtControlCss  col-md-12" id="Brand" (change)="changeBrand($event)" formControlName="brandId">
                    <option value=0  selected>All Brands</option>
                    <option [value]="brand.brandId" *ngFor="let brand of this.brandList">{{brand.brandName}}</option>
                </select>
            </div>
            <div class="form-group col-md-3" *ngIf="!this.apiUiFlag">
                <label for="select1" class="small-label" style="font-size: 12px;">Select Platform</label>
                <select class="txtControlCss  col-md-12" id="PLATFORM" (change)="changePlatform($event)" style="font-size: 12px;" formControlName="platformId">
                    <option *ngIf="this.platformList.length > 1" value="0"  selected>All Platforms</option>
                    <option [value]="platform.platformId" *ngFor="let platform of platformList">{{platform.platformName}}</option>
                </select>
                <span class="error" *ngIf=" this.isSubmitted && !testCaseDeviceComparionForm.controls['platformId'].valid">Platform is required</span>
            </div>
            <div class="form-group col-md-3">
                <label for="select1" class="small-label" style="font-size: 12px;">Select Enviornment</label>
                <select class="txtControlCss  col-md-12" id="ENV" style="font-size: 12px;" formControlName="environment" (change)="onEnviornmentChange($event)">
                    <option value="" disabled selected>ENV</option>
                    <option [value]="env" *ngFor="let env of environmentList">{{env}}</option>
                </select>
                <span class="error" *ngIf=" this.isSubmitted && !testCaseDeviceComparionForm.controls['environment'].valid">Environment is required</span>
            </div>
            <div class="form-group col-md-3">
                <label for="select2" class="small-label" style="font-size: 12px;">Select Case or Suite</label>
                <select class="txtControlCss  col-md-12" id="caseOrSuite" style="font-size: 12px;" formControlName="tableName" (change)="onCaseChange($event)">
                    <option value="" disabled selected>Select TestCase or Test Suite</option>
                    <option [value]="item" *ngFor="let item of caseSuiteList">{{item}}</option>
                </select>
                <span class="error" *ngIf=" this.isSubmitted && !testCaseDeviceComparionForm.controls['caseSelect'].valid">Case is Required</span>
            </div>
            <div class="col-md-3">
                <label for="startDate" class="small-label" style="font-size: 12px;">Select Start Date<span>*</span></label>
                <div class="exmp-wrp" style="margin-left:2px;">
                  <div class="input-group btn-wrp">
                    <input type="date" class="btn-clck" formControlName="startDate"  (change)="onFromDateChange($event)" 
                    id="exe_StartDate" name="widget_From_date" placeholder="From" [min]="getMinDate()" [max]="getMaxDate()">
                  </div>
                  <span class="input-group-btn ">
                    <button class="btn1  " type="text"><span class="paddingLefiRightCalender"><img src="assets/images/calendarr.png"></span></button>
                  </span>
               </div>
            </div>
            <div class="col-md-3">
                <label for="endDate" class="small-label" style="font-size: 12px;">Select End Date<span>*</span></label>
                <div class="exmp-wrp " style="margin-left:2px;">
                    <div class="input-group btn-wrp">
                        <input type="date" class="btn-clck "  formControlName="endDate"  id = "toDateID"(change)="onToDateChange($event)" 
                      name="endDate"  placeholder="To"[min]="getMinDate()" [max]="getMaxDate()" [disabled]="!fromDate">
                    </div>
                    <span class="input-group-btn ">
                        <button class="btn1  " type="text">
                            <span class="paddingLefiRightCalender"><img src="assets/images/calendarr.png"></span>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </form>
    <div class="row mt-2">
        <div class="col-md-7 txt-color" style="font-weight: 600;"></div>
        <div class="chart-container  col-md-12" id="barchart"></div>
    </div>
    <div class="d-flex overlay " *ngIf="this.load==true">
        <div class="monthlyloading">Loading&#8230;</div>
    </div>
    <div class="chart-container  col-md-12">
        <highcharts-chart
        [(update)]="updateFlag"
        [Highcharts] = "highcharts" 
        [options] = "chartOptions" 
        style = "width: 100%; height: 350px; display: block;">
        </highcharts-chart>
    </div>
</div>