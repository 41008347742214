import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DataService } from '../../../../shared/services/data.service';
// import * as $ from 'jquery';
import $ from 'jquery';
// import * as moment from 'moment';
import moment from 'moment';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as Highcharts from 'highcharts';
// import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';
import {orderBy} from 'lodash';
import { Constvar } from '../../../../models/constVar';
import { LoaderService } from '../../../../shared/services/loader.service';

@Component({
  selector: 'wtaf-test-suite-status',
  templateUrl: './test-suite-status.component.html',
  styleUrls: ['./test-suite-status.component.css']
})
export class TestSuiteStatusComponent implements OnInit {
  chart: any;
  projectTypeList: any = [];
  testSuiteStatusform: UntypedFormGroup;
  load=false;
  toDate: any;
  fromDate: any;
  projecttypeId: any;
  platformList: any = [];
  regionList: any = [];
  regionName: any;
  brandList: any = [];
  regionId: number;
  environmentList = ['Dev', 'QA','Prod','STG'];
  isSubmitted = false;
  isValidDate: any;
  projectTypeName: String = '';
  error: any = { isError: false, errorMessage: '' };
  date_labels: any = [];
  brandId: number;
  projectNameList: any = [];
  dropdownList = [];
  selectedItems = [];
  apiflag:boolean;
  dropdownSettings = {};
  updateFlag = true;
  highcharts: any = Highcharts;
  chartOptions: any ;
  barChartLabels: any=[];
  exe_status_fail: any=[];
  exe_status_pass: any=[];
  exe_status_total: any=[];
  exe_status_unknown: any=[];
 isfromOninit = false;
  
  constructor(
    private dataservice: DataService,
    public fb: UntypedFormBuilder,
    public DatePipe: DatePipe,
    public toastr: ToastrService,
    public loaderService: LoaderService,
  ) {
    let nowDate = new Date();
    this.toDate = this.DatePipe.transform(nowDate, 'yyyy-MM-dd');
    this.fromDate = moment()
      .subtract('days', 7)
      .format('YYYY-MM-DD');

    this.testSuiteStatusform = this.fb.group({
      projectTypeId: ['', [Validators.required]],
      regionId: ['', [Validators.required]],
      brandId: ['', [Validators.required]],
      platformId: ['', [Validators.required]],
      environment: ['STG', [Validators.required]],
      // startDate: [this.fromDate, [Validators.required]],
      // endDate: [this.toDate, [Validators.required]]
   
    });
  }

  async ngOnInit() {


    
    this.loaderService.show()
    setTimeout(()=>{
      this.loaderService.hide()

    },15000)



    this.load=true;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'projectName',
      textField: 'projectName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    for (let i = 1; i < 8; i++) {
      this.date_labels.push(
        moment()
          .subtract('days', i)
          .format('YYYY-MM-DD')
      );
    }
    console.log('this.date_labels', this.date_labels);
    $('#testSuiteStatusFromDate').attr('max', this.toDate);
    $('#testSuiteStatusToDate').attr('max', this.toDate);
    this.chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'column'
      },
      xAxis: {
        categories: this.date_labels
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Test Case Execution Count'
        },
        style: {with:"auto"},
        stackLabels: {
          enabled: false,
          style: {
            fontWeight: 'bold',
            color:
              // theme
              (Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color) ||
              'gray'
          }
        }
      },
  
      mapNavigation: {
        enableMouseWheelZoom: true
      },
      title: {
        text: ''
      },
  
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
  
      series: []
    };
    this.chartOptions.series = [
      {
        name: 'Passed',
        data: [0, 0, 0, 0, 0, 0, 0],
        color:'#1b7730'
      },
      {
        name: 'Failed',
        data: [0, 0, 0, 0, 0, 0, 0],
        color : '#dc3545'
      }
    ];
    this.updateFlag = true;
    $('#testSuiteStatusFromDate').attr('max', this.toDate);
    $('#testSuiteStatusToDate').attr('max', this.toDate);
   await this.getAllProjectType(true);
    let column = document.getElementById('column');
    
  }

  validateDates(sDate: string, eDate: string) {
     
    this.isValidDate = true;
    if (sDate == null || eDate == null) {
      this.error = {
        isError: true,
        errorMessage: 'Start date and end date are required.'
      };
      this.isValidDate = false;
    }

    if (sDate != null && eDate != null && eDate < sDate) {
      this.error = {
        isError: true,
        errorMessage: 'To date should be greater than from date.'
      };
      this.isValidDate = false;
    }
    return this.isValidDate;
  }
  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }

  onFormSubmit() {
     
    // this.testSuiteStatusform.controls['testSuiteId'].setValue(this.testSuiteid);
   console.log('adasdasdad');
   this.isfromOninit=false;
    this.isSubmitted = true;
    this.loaderService.show();
    // this.isValidDate = this.validateDates(
    //   this.DatePipe.transform(
    //     this.testSuiteStatusform.controls['startDate'].value,
    //     'yyyy-MM-dd'
    //   ),
    //   this.DatePipe.transform(
    //     this.testSuiteStatusform.controls['endDate'].value,
    //     'yyyy-MM-dd'
    //   )
    // );
    if(this.apiflag)
    {
      let formControl: UntypedFormControl = this.testSuiteStatusform.get(
        'platformId'
      ) as UntypedFormControl;
      formControl.clearValidators();
      formControl.setValidators(null);
      formControl.updateValueAndValidity();
      this.testSuiteStatusform.get('platformId').setValue('');
    }
    if (this.testSuiteStatusform.invalid) {
      // this.loaderService.hide();
      setTimeout(() => {
        this.loaderService.hide();
    }, 2000);
      return;
    } else {
      // if (this.projectNameList != null) {
      //   let temp_projectName_list = this.testSuiteStatusform.value[
      //     'productNames'
      //   ].map(element => {
      //     return element.projectName;
      //   });
      //   this.testSuiteStatusform.controls['projectNames'].setValue(
      //     temp_projectName_list
      //   );
      //   console.log(
      //     'temp_projectName_list....',
      //     this.testSuiteStatusform.controls['projectNames'].setValue(
      //       temp_projectName_list
      //     )
      //   );
      //   console.log(this.testSuiteStatusform.value);
      // }
     
      // setTimeout(() => {
      //   this.loaderService.show();
      // }, 3000);
      this.dataservice
        .testSuiteExeStatusTrend(this.testSuiteStatusform.value)
        .subscribe(
          (res: any) => {
            this.load=false;
            this.loaderService.show();
            console.log('test suite exec status res', res);
            let mindate = moment(this.toDate)
              .subtract(24, 'months')
              .format('YYYY-MM-DD');
            $('#testSuiteStatusFromDate').attr('max', this.toDate);
            $('#testSuiteStatusToDate').attr('max', this.toDate);
            $('#testSuiteStatusFromDate').attr('min', mindate);
            $('#testSuiteStatusToDate').attr('min', mindate);
            this.error = { isError: false, errorMessage: '' };
            if (res.length === 0) {
              this.toastr.error('No records found');
            }
            
            if (res != null) {
            this.chartOptions=[];
            this.chartOptions.xAxis=[];
            this.exe_status_fail=[];
            this.exe_status_pass=[];
            this.exe_status_total=[];
            this.exe_status_unknown=[];
            this.barChartLabels=[];
              if (res.length > 0) {
                res.map(element => {
                  this.exe_status_fail.push(element.failed);
                  this.exe_status_pass.push(element.passed);
                  this.exe_status_unknown.push(element.unknown);
                  this.exe_status_total.push(element.total);
                  this.barChartLabels.push(element.date);
                });
                
              } else {    
                this.toastr.info('No data found');
              }
              this.chartOptions={
                xAxis:{
                categories: this.barChartLabels
              }}
              this.chartOptions.series = [
                {
                  name: 'Passed',
                  data: this.exe_status_pass
                },
                {
                  name: 'Failed',
                  data: this.exe_status_fail
                }
              ]
              this.updateFlag = true;
            }
          },
          error => {
            // this.loaderService.hide();
            setTimeout(() => {
              this.loaderService.hide();
          }, 2000);
            this.load=false;
            this.toastr.error("something went wrong");
            console.log(error);
          }
        );
    }
  }
  
   getAllProjectType(isfromOninit) {
    this.isfromOninit=isfromOninit;
     this.dataservice
      .getAllProjectType()
      .toPromise()
      .then(async response => {
        // Success
        this.projectTypeList = response;
        console.log('this.projectTypeList.length_widget_3');
        console.log(
          'this.projectTypeList.length_widget_3',
          this.projectTypeList
        );
        // this.projectTypeList = orderBy(this.projectTypeList, ['projectTypeName'],['asc']);
        // console.log(
        //   'this.projectTypeList.length_widget_3',
        //   this.projectTypeList
        // );
        if (response != null) {
          this.testSuiteStatusform.controls['projectTypeId'].setValue(
            this.projectTypeList[0].projectTypeId
          );
        await  this.getPlatformByProjectTypeId(
            this.projectTypeList[0].projectTypeId
          );
          await this.getAllRegion(this.projectTypeList[0].projectTypeId);
        }
      });
  }

  async getPlatformByProjectTypeId(projectTypeId) {
    await this.dataservice.getPlatformByProjectTypeId(projectTypeId).subscribe(
      (response: {}) => {
        this.platformList = response;
        console.log(this.platformList);
        if (this.platformList != null) {
          if (this.platformList.length == 1) {
            this.testSuiteStatusform.controls['platformId'].setValue(
              this.platformList[0].platformId
            );
          } else {
            this.testSuiteStatusform.controls['platformId'].setValue(0);
            
          }
        }
      },
        error => {
          console.log(error.message);
        }
      );
  }

  async getAllRegion(Projecttypeid) {
   await this.dataservice
      .getAllRegionByProjectType(Projecttypeid)
      .subscribe(async (response: {}) => {
        this.regionList = response;
        this.regionList = orderBy(this.regionList, ['regionCode'],['asc']);
        // this.regionList.unshift(
        //   this.regionList.splice(
            let index=this.regionList.findIndex(item => item.regionName === 'NAR');
          //   1
          // )[0]
          
        if (response != null) {
         
          this.testSuiteStatusform.controls['regionId'].setValue(
            this.regionList[index].regionId
          );
          this.regionName = this.regionList[0].regionName;
          await this.getBrandsByRegionId(
            this.testSuiteStatusform.get('regionId').value
          );
        }
      });
  }

  async getBrandsByRegionId(regionId) {
    await this.dataservice
      .getBrandsByRegionId(regionId)
      .toPromise()
      .then(async (response: {}) => {
        this.brandList = response;
        console.log('this.brandList.....>', this.brandList);
        this.brandList = orderBy(this.brandList, ['brandName'],['asc']);

        if (response != null) {
          if (this.brandList) {
            this.regionId = this.testSuiteStatusform.get('regionId').value;
            this.projecttypeId = this.testSuiteStatusform.get(
              'projectTypeId'
            ).value;
            if (this.brandList.length == 1) {
              this.testSuiteStatusform.controls['brandId'].setValue(
                this.brandList[0].brandId
              );
            } else {
              this.testSuiteStatusform.controls['brandId'].setValue(0);
              
            }
            // this.brandList.unshift(
            //   this.brandList.splice(
            //     this.brandList.findIndex(
            //       item => item.brandName.toLowerCase() === 'whirlpool'
            //     ),
            //     1
            //   )[0]
            // );
          // let brandid= this.brandList.findIndex(
            //        item => item.brandName.toLowerCase() === 'whirlpool'
              //    );
            //this.testSuiteStatusform.controls['brandId'].setValue(
              //this.brandList[brandid].brandId
            //);
            this.brandId = this.brandList[0].brandId;
            console.log('this.isfromOninit.............',this.isfromOninit);
            this.loaderService.show();
            if(this.isfromOninit)
            {
             await this.onFormSubmit();
            }
          }
          // this.getAllProjectName(
          //   this.regionId,
          //   this.brandId,
          //   this.projecttypeId
          // );
        } else {
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }

  async getAllProjectName(regionId, brandId, projecttypeId) {
    await this.dataservice
      .getProjectNameByTypeId(brandId, projecttypeId, regionId)
      .subscribe(allProjNameRes => {
        this.projectNameList = allProjNameRes;
        console.log('all product names', this.projectNameList);
      });
  }

  changeProjectType(e) {
     
    this.dataservice.isfromWidget = false;
    console.log(e);
    this.projecttypeId = e.target.value;
    console.log(this.projecttypeId);

    this.projecttypeId = e.target.value;
    this.regionList = [];
    this.brandList = [];
    this.brandId = 0;
    this.projectNameList = [];
    // this.testSuiteList = [];
    this.testSuiteStatusform.controls['regionId'].setValue('');
    // this.testSuiteStatusform.controls['testSuiteId'].setValue('');
    // this.testSuiteStatusform.controls['productNames'].setValue('');
    let objProjectType = this.projectTypeList.filter(
      pl => pl.projectTypeId == this.projecttypeId
    );

    this.projectTypeName = objProjectType[0].projectTypeName;
    console.log('project type -->', this.projectTypeName);
    if(this.projectTypeName==Constvar.API)
    {
      this.apiflag=true;
      this.getAllRegion(parseInt(e.target.value));
    }
    else{
      this.apiflag=false;
      this.getPlatformByProjectTypeId(parseInt(e.target.value));
      this.getAllRegion(parseInt(e.target.value));}
  }

  changeRegion(e) {
    this.dataservice.isfromWidget = false;
    this.projectNameList = [];
    // this.testSuiteList = [];
    // this.testSuiteStatusform.controls['testSuiteId'].setValue('');
    // this.testSuiteStatusform.controls['productNames'].setValue('');
    this.getBrandsByRegionId(parseInt(e.target.value));
  }

  changeBrand(e) {
    this.dataservice.isfromWidget = false;
    // this.testSuiteStatusform.controls['testSuiteId'].setValue('');
    // this.testSuiteStatusform.controls['testSuiteName'].setValue('');
    // this.testSuiteStatusform.controls['productNames'].setValue('');
    this.brandId = parseInt(e.target.value);
    this.regionId = this.testSuiteStatusform.get('regionId').value;
    this.projecttypeId = this.testSuiteStatusform.get('projectTypeId').value;

    // this.getAllProjectName(this.regionId, this.brandId, this.projecttypeId);
  }

  onFromDateChange(e) {
    let fromDate = this.DatePipe.transform(e.target.value, 'yyyy-MM-dd');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(fromDate).getMonth();
    let currentyear1 = new Date(fromDate).getFullYear();
    if (
      new Date(this.DatePipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ===
      new Date(this.DatePipe.transform(fromDate, 'yyyy-MM-dd')).getTime()
    ) {
      let maxDate = fromDate;
      fromDate = moment(fromDate)
        .subtract(1, 'months')
        .format('YYYY-MM-DD');
      $('#testSuiteStatusToDate').attr(
        'min',
        this.DatePipe.transform(fromDate, 'yyyy-MM-dd')
      );
      $('#testSuiteStatusToDate').attr(
        'max',
        this.DatePipe.transform(maxDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        let maxDate = fromDate;
        fromDate = moment(fromDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let toDate = moment(
          this.DatePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#testSuiteStatusToDate').attr(
          'min',
          this.DatePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#testSuiteStatusToDate').attr(
          'max',
          this.DatePipe.transform(currentDate, 'yyyy-MM-dd')
        );
      } else {
        // fromDate = moment(fromDate)
        // .subtract(1, 'months')
        // .format('YYYY-MM-DD');
        let toDate = moment(
          this.DatePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');

        let date_1 = new Date(
          this.DatePipe.transform(currentDate, 'yyyy-MM-dd')
        ).getTime();
        let date_2 = new Date(
          this.DatePipe.transform(toDate, 'yyyy-MM-dd')
        ).getTime();

        if (date_2 > date_1) {
          toDate = this.DatePipe.transform(currentDate, 'yyyy-MM-dd');
        }
        $('#testSuiteStatusToDate').attr(
          'min',
          this.DatePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#testSuiteStatusToDate').attr(
          'max',
          this.DatePipe.transform(toDate, 'yyyy-MM-dd')
        );
        this.testSuiteStatusform.controls['endDate'].setValue(toDate);
      }
    }
  }
  onToDateChange(e) {
    let toDate = this.DatePipe.transform(e.target.value, 'yyyy-MM-dd');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(toDate).getMonth();
    let currentyear1 = new Date(toDate).getFullYear();
    if (
      new Date(this.DatePipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ===
      new Date(this.DatePipe.transform(toDate, 'yyyy-MM-dd')).getTime()
    ) {
      toDate = moment(toDate)
        .subtract(1, 'months')
        .format('YYYY-MM-DD');
      $('#testSuiteStatusFromDate').attr(
        'min',
        this.DatePipe.transform(toDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        toDate = moment(toDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let fromDate = moment(
          this.DatePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#testSuiteStatusFromDate').attr(
          'min',
          this.DatePipe.transform(toDate, 'yyyy-MM-dd')
        );
        $('#testSuiteStatusFromDate').attr(
          'max',
          this.DatePipe.transform(currentDate, 'yyyy-MM-dd')
        );
      } else {
        toDate = moment(toDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        let fromDate = moment(
          this.DatePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#testSuiteStatusFromDate').attr(
          'min',
          this.DatePipe.transform(toDate, 'yyyy-MM-dd')
        );
        $('#testSuiteStatusFromDate').attr(
          'max',
          this.DatePipe.transform(fromDate, 'yyyy-MM-dd')
        );
      }
    }
  }
}
