<ng-container>
    <form [formGroup]="monthExecStatusform" (ngSubmit)="monthExecStatusFormSubmit()">
    <div class="row">
      <div class="form-group col-md-6 ">
        <select class="txtControlCss col-md-12"  id="4" formControlName="projectTypeId" (change)="changeProjectType($event)" >
          <option value="" disabled >Project Type</option>
          <option [value]="projectType.projectTypeId" *ngFor="let projectType of this.projectTypeList">
            {{projectType.projectTypeName}}</option>
        </select>
        <span class="error"
        *ngIf=" this.isSubmitted && !monthExecStatusform.controls['projectTypeId'].valid">
        Project type is required</span>
      </div>
      <div class="form-group col-md-6">
        <select class="txtControlCss col-md-12" id="regionId" formControlName="regionId" (change)="changeRegion($event)" >
          <option value="" disabled selected>Region</option>
          <option [value]="region.regionId" *ngFor="let region of this.regionList">
            {{region.regionCode}}</option>
        </select>
        <span class="error"
        *ngIf=" this.isSubmitted && !monthExecStatusform.controls['regionId'].valid">
        Region is required</span>
      </div>
   
    
    
      <div class="form-group col-md-6">
        <select class="txtControlCss  col-md-12" id="Brand" (change)="changeBrand($event)" formControlName="brandId" >
          <option value="0"  selected>All</option>
          <option [value]="brand.brandId" *ngFor="let brand of this.brandList">
            {{brand.brandName}}</option>
        </select>
        <span class="error"
        *ngIf=" this.isSubmitted && !monthExecStatusform.controls['brandId'].valid">
        Brand is required</span>
      </div>
      
      <div class="form-group col-md-6" *ngIf="!this.apiUiFlag">
        <select class="txtControlCss col-md-12" id="PLATFORM" (change)="changePlatform($event)" style="font-size: 12px;" formControlName="platformId" >
          <option *ngIf="this.platformList.length > 1" value="0" selected>All</option>
          <option [value]="platform.platformId" *ngFor="let platform of platformList">{{platform.platformName}}</option>
        </select>
        <span class="error"
        *ngIf=" this.isSubmitted && !monthExecStatusform.controls['platformId'].valid">
        Platform is required</span>
      </div>
    
    
      <div class="col-md-6">
        <select class="txtControlCss  col-md-12" id="ENV" style="font-size: 12px;" formControlName="environment" >
          <option value="" disabled selected>ENV</option>
          <option [value]="env" *ngFor="let env of environmentList">
            {{env}}
          </option>
        </select>
        <span class="error"
        *ngIf=" this.isSubmitted && !monthExecStatusform.controls['environment'].valid">
        Platform is required</span>
      </div>

      <div class="col-md-6">
        <select class="txtControlCss  col-md-12" id="month_index"  style="font-size: 12px;" (change)="changeMonth($event)" formControlName="month_index">
          <option  *ngFor="let month of this.month" >{{month}}</option>
       </select>
      </div>
    </div>
    

    <!-- <div class="row">
      <div class="col-md-6">
        <label for="regionId" class="txtControlLblCss">From<span>*</span></label>
        <div class="exmp-wrp " style="margin-left:2px;">
          <div class="input-group btn-wrp">
            <input type="date" class="btn-clck" formControlName="startDate"  (change)="onFromDateChange($event)" id="monthlyExecStatusFromDate" name="monthlyExecStatusFromDate"
               placeholder="From">
          </div>
          <span class="input-group-btn ">
            <button class="btn1  " type="text"><span class="paddingLefiRightCalender"><img
                  src="assets/images/calendarr.png"></span></button>
          </span>
        </div>
      </div>
  
      <div class="col-md-6">
        <label for="regionId" class="txtControlLblCss">To<span>*</span></label>
  
        <div class="exmp-wrp " style="margin-left:2px;">
          <div class="input-group btn-wrp">
            <input type="date" class="btn-clck " (change)="onToDateChange($event)" formControlName="endDate"  id="monthlyExecStatusToDate"
              name="endDate"  placeholder="From">
          </div>
          <span class="input-group-btn">
            <button class="btn1" type="text"><span class="paddingLefiRightCalender"><img
                  src="assets/images/calendarr.png"></span></button>
          </span>
        </div>
      </div>
    </div> -->
    <!-- <div class="text-danger" *ngIf="error.isError">{{error.errorMessage}}</div> -->
    <div class="d-flex justify-content-end col-md-12 mt-4 p-0">
      <button type="submit" class="btn runBtnCss">Run</button>
    </div>
  </form>
    <div class="row mt-2">
      <div class="col-md-6 txt-color" style="font-weight: 600;">
       Monthly Execution Status
      </div>
      <div class="chart-container  col-md-12" id="barchart">
       </div>
    </div>
    <!-- <div class="col-md-12" *ngIf="this.load==true" style="text-align:center">
      
      <span title="Loading" style="text-align: center;font-size: 70px;">
          <span style="color: #1F83FE;" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span></span>
    </div> -->
    <div class="d-flex overlay " *ngIf="this.load==true">
      <div class="monthlyloading">Loading&#8230;</div>
    </div>
    
    <div class="chart-container  col-md-12">
     <highcharts-chart
      [(update)]="updateFlag"
      [Highcharts] = "highcharts" 
      [options] = "chartOptions" 
      style = "width: 100%; height: 350px; display: block;">
   </highcharts-chart>
    </div>
  </ng-container>