{
  "name": "wtaf",
  "version": "8.1.2",
  "license": "MIT",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --open",
    "start:prod": "npm run build:prod && npm run server",
    "build": "ng build",
    "build:prod": "ng build --configuration production",
    "lint": "ng lint",
    "test": "ng lint && ng test --configuration=test",
    "watch": "ng test --configuration=test --browsers ChromeHeadless --watch",
    "e2e": "ng e2e",
    "ci": "npm run format:test && ng lint && ng test --configuration=test --browsers ChromeTravisCi --code-coverage && npm run e2e && npm run build:prod -- --deploy-url /wtaf/ --base-href /wtaf",
    "format:write": "prettier projects/**/*.{ts,json,md,scss} --write",
    "format:test": "prettier projects/**/*.{ts,json,md,scss} --list-different",
    "release": "standard-version && git push --follow-tags origin master",
    "analyze": "npm run build:prod -- --stats-json && webpack-bundle-analyzer ./dist/wtaf/stats-es2015.json",
    "server": "node ./projects/server/server.js",
    "contributors:add": "all-contributors add",
    "contributors:generate": "all-contributors generate && node .all-contributors-html.js",
    "contributors:check": "all-contributors check"
  },
  "husky": {
    "hooks": {
      "pre-commit": "pretty-quick --staged",
      "commit-msg": "commitlint -E HUSKY_GIT_PARAMS"
    }
  },
  "commitlint": {
    "extends": [
      "@commitlint/config-conventional"
    ]
  },
  "private": true,
  "dependencies": {
    "@angular-devkit/schematics": "^17.3.8",
    "@angular/animations": "^17.3.12",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.3.12",
    "@angular/compiler": "^17.3.12",
    "@angular/core": "^17.3.12",
    "@angular/forms": "^17.3.12",
    "@angular/material": "^17.2.1",
    "@angular/platform-browser": "^17.3.12",
    "@angular/platform-browser-dynamic": "^17.3.12",
    "@angular/router": "^17.3.12",
    "@fortawesome/angular-fontawesome": "^0.14.1",
    "@fortawesome/fontawesome-free": "^5.15.4",
    "@fortawesome/fontawesome-svg-core": "^1.2.36",
    "@fortawesome/free-brands-svg-icons": "^5.15.4",
    "@fortawesome/free-solid-svg-icons": "^5.15.4",
    "@ng-bootstrap/ng-bootstrap": "^5.3.1",
    "@ngrx/effects": "^17.2.0",
    "@ngrx/entity": "^17.2.0",
    "@ngrx/router-store": "^17.2.0",
    "@ngrx/store": "^17.2.0",
    "@ngrx/store-devtools": "^17.2.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^4.0.0",
    "ace-builds": "^1.4.12",
    "angular-fixed-table-header": "^0.2.1",
    "angular2-multiselect-dropdown": "^4.6.9",
    "bootstrap": "^4.6.0",
    "browser-detect": "^0.2.28",
    "bs-stepper": "^1.7.0",
    "chart.js": "^4.4.2",
    "font-awesome": "^4.7.0",
    "highcharts": "^11.4.1",
    "highcharts-angular": "^4.0.0",
    "jquery": "^3.6.0",
    "module": "^1.2.5",
    "moment": "^2.26.0",
    "ng-chartjs": "^0.2.8",
    "ng-multiselect-dropdown": "^1.0.0",
    "ng2-search-filter": "^0.5.1",
    "ng2-tooltip-directive": "^2.10.3",
    "ngx-device-detector": "^1.5.2",
    "ngx-infinite-scroll": "^17.0.0",
    "ngx-pipes": "^3.2.2",
    "ngx-search-filter": "^17.0.0",
    "ngx-toastr": "^18.0.0",
    "popper": "^1.0.1",
    "popper.js": "^1.16.1",
    "rxjs": "^6.6.7",
    "tls": "0.0.1",
    "tslib": "^2.0.0",
    "uuid": "^3.4.0",
    "xlsx": "^0.17.3",
    "zone.js": "~0.14.8"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.12",
    "@angular/language-service": "^17.3.12",
    "@commitlint/cli": "^7.2.1",
    "@commitlint/config-conventional": "^7.1.2",
    "@types/jasmine": "^2.8.18",
    "@types/jasminewd2": "^2.0.10",
    "@types/node": "^10.17.60",
    "@types/uuid": "^3.4.10",
    "all-contributors-cli": "^5.4.1",
    "codelyzer": "^5.2.2",
    "express": "^4.16.4",
    "husky": "^1.1.3",
    "jasmine-core": "~3.5.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "karma-spec-reporter": "^0.0.32",
    "npm-run-all": "^4.1.5",
    "prettier": "^1.19.1",
    "pretty-quick": "^1.11.1",
    "protractor": "~7.0.0",
    "rimraf": "^2.7.1",
    "standard-version": "^6.0.1",
    "ts-node": "~7.0.1",
    "tslint": "~6.1.0",
    "typescript": "~5.4.5",
    "webpack-bundle-analyzer": "^3.9.0"
  }
}
