import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wtaf-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.css']
})
export class SearchboxComponent implements OnInit {
  searchText = '';
  constructor() {}
  @Input() toolTip: string;
  @Output() onSearchTxt: EventEmitter<string> = new EventEmitter();

  ngOnInit() {}
  searchRecords() {
    this.onSearchTxt.emit(this.searchText);
  }
}
