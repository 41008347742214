// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const packageJson = require('../../../../package.json');
const configJson = require('../assets/config/config.json');

export const environment = {
  appName: configJson.appName,
  envName: 'DEV',
  production: false,
  test: false,
  i18nPrefix: '',
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    ngrx: packageJson.dependencies['@ngrx/store'],
    material: packageJson.dependencies['@angular/material'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
    fontAwesome: packageJson.dependencies['@fortawesome/fontawesome-free'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript'],
    cypress: packageJson.devDependencies['cypress']
  },
  urlConfig: {
    baseUrl: configJson.baseUrl,
    jenkinsUrl: configJson.jenkinsUrl,
    JenkinsWorkspace_Url:configJson.JenkinsWorkspace_Url,
    envValue :configJson.envValue,
    headspinBaseUrl :configJson.headspinBaseUrl,
    headspinAPIToken : configJson.headspinAPIToken,
    browserStackBaseUrl : configJson.BrowserStackBaseUrl
    //baseUrl:'http://192.168.1.8:9000/',

    // baseUrl: 'http://wtaf-qa-env.d2x5c2tdv2.us-east-2.elasticbeanstalk.com/wtaf/'
    //baseUrl:'http://wtaf-portal.d2x5c2tdv2.us-east-2.elasticbeanstalk.com/wtaf/'
  },
  AwsConfig: {
    ProjectArn: configJson.ProjectArn,
    IdentityPoolId: configJson.IdentityPoolId,
    accessKeyId: configJson.accessKeyId,
    secretAccessKey: configJson.secretAccessKey,
    DF_region: configJson.DF_region,
    testSpecArnAndroid: configJson.testSpecArnAndroid,
    testSpecArnIOS: configJson.testSpecArnIOS
  },
  githubCheckout:configJson.githubCheckout,
  JenkinsCheckoutJobUrl:configJson.JenkinsCheckoutJobUrl,
  framework_Exe:configJson.framework_Exe,
  wtafEnv:configJson.wtafEnv
};
