<ng-container>
  <form [formGroup]="testExewidgetFilterform" (ngSubmit)="onFormSubmit()">
  <div class="row">
    <div class="form-group col-md-6 ">
      <select class="txtControlCss col-md-12"  id="4" formControlName="projectTypeId" (change)="changeProjectType($event)" >
        <option value="" disabled >Project Type</option>
        <option [value]="projectType.projectTypeId" *ngFor="let projectType of this.projectTypeList">
          {{projectType.projectTypeName}}</option>
      </select>
      <span class="error"
      *ngIf=" this.isSubmitted && !testExewidgetFilterform.controls['projectTypeId'].valid">
      Project type is required</span>
    </div>
    <div class="form-group col-md-6">
      <select class="txtControlCss col-md-12" id="regionId" formControlName="regionId" (change)="changeRegion($event)" >
        <option value="" disabled selected>Region</option>
        <option [value]="region.regionId" *ngFor="let region of this.regionList">
          {{region.regionCode}}</option>
      </select>
      <span class="error"
      *ngIf=" this.isSubmitted && !testExewidgetFilterform.controls['regionId'].valid">
      Region is required</span>
    </div>
  
  
  
    <div class="form-group col-md-6">
      <select class="txtControlCss  col-md-12" id="Brand" (change)="changeBrand($event)" formControlName="brandId" >
        <option value="" disabled selected>Brand</option>
        <option *ngIf="this.brandList.length > 1" value="0" selected>All</option>
        <option [value]="brand.brandId" *ngFor="let brand of this.brandList">
          {{brand.brandName}}</option>
      </select>
      <span class="error"
      *ngIf=" this.isSubmitted && !testExewidgetFilterform.controls['brandId'].valid">
      Brand is required</span>
    </div>
    
    <!-- <div class="form-group col-md-6">
      <ng-multiselect-dropdown id="multiselectdropdownId" formControlName="productName" name="productName" [placeholder]="'Product Name'"
       [data]="projectNameList" [(ngModel)]="selectedItems" [settings]="dropdownSettings" 
       (onSelect)="onItemSelectProductName($event)">
      </ng-multiselect-dropdown>
      <span class="error"
      *ngIf=" this.isSubmitted && !testExewidgetFilterform.controls['productName'].valid">
      Product Name is required</span>
    </div> -->
    <!-- <div class="col-md-6">
      <input class="form-control txtControlCss"  formControlName="projectName" placeholder="Product Name"  name="projectName"
        id="txtAutoCompletewid2" (keyup)='onkeyUP()' type="text" list="projectNamewid2"
        (change)="changeProjectName($event)">
      <datalist id="projectNamewid2"(keyup)='onkeyUP()' >
        <option  *ngFor="let projectName of this.projectNameList; let i=index">{{projectName.projectName}}
        </option>
      </datalist>
      <span class="error"
      *ngIf=" this.isSubmitted && !testExewidgetFilterform.controls['productName'].valid">
      Product Name is required</span>
    </div> -->

    <!-- <div class="form-group col-md-6"> -->
      <!-- <input type="hidden" formControlName="testSuiteId">
      <input class="form-control txtControlCss" placeholder="Test Suite" autocomplete="off" name="testSuiteName"
        id="txtAutoCompletewid2" type="text" formControlName="testSuiteName" list="testSuiteIdwid2"
        (change)="changeTestsuite($event)">
      <datalist id="testSuiteIdwid2">
        <option  *ngFor="let testSuite of this.testSuiteList; let i=index"> {{testSuite.testSuiteName}}
        </option>
      </datalist> -->
      <!-- <ng-multiselect-dropdown id="multiselectdropdownId2" formControlName="testSuiteName" name="testSuiteName" [placeholder]="'Test Suite'"
      [data]="testSuiteList" [(ngModel)]="selectedItemsSuite" [settings]="dropdownSettingsTestSuite" 
      (onSelect)="onItemSelectSuite($event)">
     </ng-multiselect-dropdown>
      <span class="error"
      *ngIf=" this.isSubmitted && !testExewidgetFilterform.controls['testSuiteId'].valid">
      Test suite is required</span>
    </div> -->
    <div class="form-group col-md-6" *ngIf="!this.apiUiFlag">
      <select class="txtControlCss col-md-12" id="PLATFORM" style="font-size: 12px;" formControlName="platformId" >
        <option value="" disabled selected>PLATFORM</option>
        <option *ngIf="this.platformList.length > 1" value="0" selected>All</option>
        <option [value]="platform.platformId" *ngFor="let platform of platformList">{{platform.platformName}}</option>
      </select>
      <span class="error"
      *ngIf=" this.isSubmitted && !testExewidgetFilterform.controls['platformId'].valid">
      Platform is required</span>
    </div>
 
    <div class="col-md-6">
      <select class="txtControlCss  col-md-12" id="ENV" style="font-size: 12px;" formControlName="environment" >
        <option value="" disabled selected>ENV</option>
        <option [value]="env" *ngFor="let env of environmentList">
          {{env}}
        </option>
      </select>
      <span class="error"
      *ngIf=" this.isSubmitted && !testExewidgetFilterform.controls['environment'].valid">
      Platform is required</span>
    </div>

    <!-- <div class="col-md-6">
      <select class="txtControlCss  col-md-12" (change)="changeUser($event)" id="userId" style="font-size: 12px;" formControlName="profileId" >
        <option value="0" selected>All</option>
        <option [value]="users.profileId" *ngFor="let users of this.userList">
          {{users.userName}}
        </option>
      </select>
      <span class="error"
      *ngIf=" this.isSubmitted && !testExewidgetFilterform.controls['profileId'].valid">
      UserName is required</span>
    </div> -->
  </div>
 
  <!-- <div class="row">
    <div class="col-md-6">
      <label for="regionId" class="txtControlLblCss">From<span>*</span></label>
      <div class="exmp-wrp " style="margin-left:2px;">
        <div class="input-group btn-wrp">
          <input type="date" class="btn-clck" formControlName="startDate"  (change)="onFromDateChange($event)" id="testSuiteExecutionTrendFromDate" name="testSuiteExecutionTrendFromDate"
             placeholder="From">
        </div>
        <span class="input-group-btn ">
          <button class="btn1  " type="text"><span class="paddingLefiRightCalender"><img
                src="assets/images/calendarr.png"></span></button>
        </span>
      </div>
    </div>

    <div class="col-md-6">
      <label for="regionId" class="txtControlLblCss">To<span>*</span></label>

      <div class="exmp-wrp " style="margin-left:2px;">
        <div class="input-group btn-wrp">
          <input type="date" class="btn-clck " (change)="onToDateChange($event)" formControlName="endDate"  id="testSuiteExecutionTrendToDate"
            name="endDate"  placeholder="From">
        </div>
        <span class="input-group-btn ">
          <button class="btn1  " type="text"><span class="paddingLefiRightCalender"><img
                src="assets/images/calendarr.png"></span></button>
        </span>
      </div>
    </div>
  </div> -->
  <!-- <div class="text-danger" *ngIf="error.isError">{{error.errorMessage}}</div> -->
  <div class="d-flex justify-content-end col-md-12 mt-4 p-0">
    <button type="submit" class="btn runBtnCss">Run</button>
  </div>
</form>
  <div class="row">
    <div class="col-md-6 txt-color" style="font-weight: 600;">
      Test Suite Execution Trend
    </div>
    <!-- <div class="txt-color col-md-6">
      <span style="margin: 5px;"> {{regionName}} : {{testSuiteName}}</span>
    </div> -->
    </div>
    <!-- <div class="col-md-12" *ngIf="this.load==false" style="text-align:center">
      
      <span title="Loading" style="text-align: center;font-size: 70px;">
          <span style="color: #1F83FE;" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span></span>
    </div> -->

    <div class="d-flex overlay" *ngIf="this.load">
      <div class="testExecutionloading">Loading&#8230;</div>
    </div>
    
    <div class="chart-container  col-md-12">
      <!-- <canvas id="Bar" ></canvas> -->
      <highcharts-chart
      [(update)]="updateFlag"
      [Highcharts] = "highcharts" 
      [options] = "chartOptions"
      style = "width: 100%; height: 450px; display: block;">
   </highcharts-chart>
    </div>
  <!-- <div class="chart-container  col-md-12">
    <canvas id="Bar" ></canvas>
  </div> -->
</ng-container>