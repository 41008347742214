<ng-container>
  <div style="width: 100% !important; height:500px !important; overflow:auto;">
<table class="table  table-bordered myTable table-sticky">
    <thead class="thead-light">
      <tr>
        <th scope="col">Project Type</th>
        <th scope="col">Region </th>
        <th scope="col">Brand</th>
        <th scope="col">Products</th>
        <th scope="col">Test Suites</th>
        <th scope="col">Test Cases</th>
        <th scope="col">Test Steps</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor='let alldata of allDetailsCount'>
        <td style=" vertical-align : middle;text-align:center;">{{alldata.projectType}}</td>
        <td>
          <ng-container *ngFor='let regions of alldata.regionView; let id = index'>
          <table style="width:100%;" [attr.id]="alldata.projectType +'region' + id">
            <tr  style="width: 100%;">
            <td [attr.rowspan]="regions.brandView.length+1" style="width: 100%; vertical-align : middle;text-align:center;">
              {{regions.regionName}}
              
            </td>
             
        </tr>
      </table>
    </ng-container>
    </td>
      <td>
        <ng-container *ngFor='let regions of alldata.regionView; let id = index'>
          <table style="width:100%;" [attr.id]="alldata.projectType+'region_brand' + id">
            <tr *ngFor='let brands of regions.brandView'>
            <td>{{brands.brandName}}</td>
        </tr>
      </table>
        </ng-container>

  </td>
        <td>
          <ng-container *ngFor='let regions of alldata.regionView;'>
            <table style="width:100%;">
              <tr *ngFor='let brands of regions.brandView'>
              <td>{{brands.projectCount}}</td>
          </tr>
        </table>
          </ng-container>
        </td>
        <td>
          <ng-container *ngFor='let regions of alldata.regionView;'>
            <table style="width:100%;">
              <tr *ngFor='let brands of regions.brandView'>
              <td>{{brands.suiteCount}}</td>
          </tr>
        </table>
          </ng-container>
        </td>
        <td>
          <ng-container *ngFor='let regions of alldata.regionView;'>
            <table style="width:100%;">
              <tr *ngFor='let brands of regions.brandView'>
              <td>{{brands.caseCount}}</td>
          </tr>
        </table>
          </ng-container>
        </td>
        <td>
          <ng-container *ngFor='let regions of alldata.regionView;'>
            <table style="width:100%;">
              <tr *ngFor='let brands of regions.brandView'>
              <td>{{brands.stepCount}}</td>
          </tr>
        </table>
          </ng-container>
        </td>
      </tr>

    </tbody>
  </table>
  </div>
</ng-container>