import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { DataService } from '../../../shared/services/data.service';
import { DatePipe } from '@angular/common';
// import * as moment from 'moment';
import moment from 'moment';
// import * as $ from 'jquery';
import $ from 'jquery';
// import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';
import * as Highcharts from 'highcharts';
import { Constvar } from '../../../models/constVar';
import { LoaderService } from '../../../shared/services/loader.service';

declare var require: any;

@Component({
  selector: 'wtaf-failed-execution',
  templateUrl: './failed-execution.component.html',
  styleUrls: ['./failed-execution.component.css']
})
export class FailedExecutionComponent implements OnInit {
  Fail_count = [];
  barChartLabels = [];
  updateFlag = true;
  exe_status_unknown = [];
  highcharts: any = Highcharts;

  chart: any;
  chartOptions: any = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'bar'
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      categories: [],
      // labels: {
      //   align: 'left'
      // },
      title: {
        text: ''
      }
    },

    yAxis: {
      min: 0,
      title: {
        text: '',
        align: 'high'
      },
      labels: {
        overflow: 'justify'
      }
    },

    colors: ['#AF4E3F'],
    plotOptions: {
      bar: {
        colorByPoint: true,
        dataLabels: {
          enabled: true,
          inside: true
        }
      }
    },

    credits: {
      enabled: false
    },
    series: [
      {
        datalabels: {
          enabled: true
        },
        data: []
      }
    ]
  };
  constructor(private dataservice: DataService,public loaderservice:LoaderService) {}

  ngOnInit() {

    this.loaderservice.show()
    setTimeout(()=>{
      this.loaderservice.hide()

    },15000)
    
    this.dataservice.getFailedTestExecutionCount().subscribe((Res: any) => {
      console.log('Failed Test Execution.....', Res);

      this.Fail_count = [];
      this.barChartLabels = [];
      if (Res.length > 0) {
        Res.forEach(element => {
          this.barChartLabels.push(element.executionName);
          this.Fail_count.push(element.count);
        });
      }
      (this.chartOptions.xAxis = {
        categories: this.barChartLabels
      }),
        (this.chartOptions.series = [
          {
            name: 'Fail Count',
            data: this.Fail_count,
            showInLegend: false
          }
        ]);
      this.updateFlag = true;
    });
  }
}
