/* projects/wtaf/src/app/layout/commonFeatures/pagination/pagination.component.css */
.pagination {
  display: flex !important;
  padding-left: 0 !important;
  list-style: none !important;
  border-radius: 0.25rem !important;
}
.mainNav {
  float: right;
}
.recordRangCss {
  float: right;
  font-size: 12px;
  width: 140px !important;
  height: 37px !important;
}
.page-item:first-child .page-link {
  margin-left: 0 !important;
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.pagination a {
  color: #1f83fe !important;
  float: left !important;
  font-weight: 700 !important;
  padding: 8px 16px !important;
  border-radius: 50px !important;
  background-color: #fff !important;
  text-decoration: none !important;
  cursor: pointer !important;
  border: 1px solid !important;
  margin-left: 7px !important;
}
.page-item.disabled .page-link {
  color: #6c757d !important;
  pointer-events: none !important;
  cursor: auto !important;
  background-color: #fff !important;
  border-color: #dee2e6 !important;
}
.page-item.active .page-link {
  z-index: 3 !important;
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
/*# sourceMappingURL=pagination.component.css.map */
