import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DataService } from '../../../shared/services/data.service';
// import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormControl,
  FormGroupDirective,
  RequiredValidator
} from '@angular/forms';
import { Type } from '../../models';
import { ChangeDetectorRef } from '@angular/core';
import { async } from '@angular/core/testing';
import { LoaderService } from '../../../shared/services/loader.service';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Constvar } from '../../../models/constVar';
import { environment } from '../../../../environments/environment';
// import * as moment from 'moment';
import moment from 'moment';
import { threadId } from 'worker_threads';
import { ActivatedRoute } from '@angular/router';
import { headSpinToken, InterceptorSkipHeader } from '../../../core/auth';
import { DatePipe } from '@angular/common';
// import * as $ from 'jquery';
import $ from 'jquery';

@Component({
  selector: 'test-suite-weekly-count',
  templateUrl: './test-suite-weekly-count.component.html',
  styleUrls: ['./test-suite-weekly-count.component.css']
})
export class TestSuiteWeeklyCountComponent implements OnInit, AfterViewInit {
  allDetailsCount: any = [];
  brand: any = [];

  AppReportStatusform: UntypedFormGroup;

  selectedDate1: any;
  selectedDate2: any;
  fromDate: string | null = null;
  toDate: string | null = null;
  txtSearch = '';
  appNameList: any = [];
  error: any = { isError: false, errorMessage: '' };
  isValidDate: any;
  isfromOninit: boolean;
  DataType: any;
  isNormal: boolean = true;
  constructor(
    private dataservice: DataService,
    public toastr: ToastrService,
    private ref: ChangeDetectorRef,
    public loaderService: LoaderService,
    public datePipe: DatePipe,
    public fb: UntypedFormBuilder
  ) {
    let nowDate = new Date();
    this.toDate = this.datePipe.transform(nowDate, 'yyyy-MM-dd');
    let toUIDate = this.datePipe.transform(nowDate, 'd MMM yyyy');
    this.selectedDate2 = ' - ' + toUIDate;
    this.fromDate = moment()
      .subtract('days', 7)
      .format('YYYY-MM-DD');
    let fromUIDate = moment()
      .subtract('days', 7)
      .format('DD MMM');
    this.selectedDate1 = ' ' + fromUIDate;
    this.AppReportStatusform = this.fb.group({
      startDate: [this.fromDate, [Validators.required]],
      endDate: [this.toDate, [Validators.required]],
      DataType: ['', Validators.required]
    });
    this.AppReportStatusform.controls['DataType'].setValue('Normal');
  }

  ngOnInit() {
    this.loaderService.show();

    setTimeout(() => {
      this.loaderService.hide();
    }, 15000);
    $('#monthlyExecStatusFromDate').attr('max', this.toDate);
    $('#monthlyExecStatusToDate').attr('max', this.toDate);
    this.getAlldetailsCount(this.fromDate, this.toDate);
  }

  getMinDate(): string {
    const currentDate = new Date();
    const minDate = new Date();
    minDate.setDate(currentDate.getDate() - 30);
    return minDate.toISOString().split('T')[0];
  }

  onFromDateChange(e, event: any) {
    let fromDate = this.datePipe.transform(e.target.value, 'yyyy-MM-dd');
    let fromUIDate = this.datePipe.transform(e.target.value, 'd MMM');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(fromDate).getMonth();
    let currentyear1 = new Date(fromDate).getFullYear();
    this.selectedDate1 = ' ' + fromUIDate;
    console.log('Selected Date1 ' + this.selectedDate1);

    if (
      new Date(this.datePipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ===
      new Date(this.datePipe.transform(fromDate, 'yyyy-MM-dd')).getTime()
    ) {
      let maxDate = fromDate;
      fromDate = moment(fromDate)
        .subtract(7, 'days')
        .format('YYYY-MM-DD');
      $('#exe_EndDate').attr(
        'min',
        this.datePipe.transform(fromDate, 'yyyy-MM-dd')
      );
      $('#exe_EndDate').attr(
        'max',
        this.datePipe.transform(maxDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        let maxDate = fromDate;
        fromDate = moment(fromDate)
          .subtract(7, 'days')
          .format('YYYY-MM-DD');
        let toDate = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(1, 'months')
          .format('YYYY-MM-DD');
        $('#exe_EndDate').attr(
          'min',
          this.datePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#exe_EndDate').attr(
          'max',
          this.datePipe.transform(currentDate, 'yyyy-MM-dd')
        );
        this.AppReportStatusform.controls['exe_EndDate'].setValue(
          this.datePipe.transform(currentDate, 'yyyy-MM-dd')
        );
      } else {
        let toDate = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(7, 'days')
          .format('YYYY-MM-DD');

        let date_1 = new Date(
          this.datePipe.transform(currentDate, 'yyyy-MM-dd')
        ).getTime();
        let date_2 = new Date(
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        ).getTime();

        if (date_2 > date_1) {
          toDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
        }
        $('#exe_EndDate').attr(
          'min',
          this.datePipe.transform(fromDate, 'yyyy-MM-dd')
        );
        $('#exe_EndDate').attr(
          'max',
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        );
        this.AppReportStatusform.controls['exe_EndDate'].setValue(toDate);
      }
      this.getAlldetailsCount(this.fromDate, this.toDate);
    }

    this.AppReportStatusFormSubmit();
  }
  onToDateChange(e, event: any) {
    let toDate = this.datePipe.transform(e.target.value, 'yyyy-MM-dd');
    let toUIDate = this.datePipe.transform(e.target.value, 'd MMM yyyy');
    let currentDate = new Date();
    let currentmonth = currentDate.getMonth();
    let currentyear = currentDate.getFullYear();
    let currentmonth1 = new Date(toDate).getMonth();
    let currentyear1 = new Date(toDate).getFullYear();
    this.selectedDate2 = ' - ' + toUIDate;
    console.log('Selected Date2 ' + this.selectedDate2);
    if (
      new Date(this.datePipe.transform(currentDate, 'yyyy-MM-dd')).getTime() ===
      new Date(this.datePipe.transform(toDate, 'yyyy-MM-dd')).getTime()
    ) {
      toDate = moment(toDate)
        .subtract(7, 'days')
        .format('YYYY-MM-DD');
      $('#exe_StartDate').attr(
        'min',
        this.datePipe.transform(toDate, 'yyyy-MM-dd')
      );
    } else {
      let date_1 = new Date(currentyear, currentmonth);
      let date_2 = new Date(currentyear1, currentmonth1);
      if (date_1.getTime() === date_2.getTime()) {
        toDate = moment(toDate)
          .subtract(7, 'days')
          .format('YYYY-MM-DD');
        let fromDate = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(7, 'days')
          .format('YYYY-MM-DD');
        $('#exe_StartDate').attr(
          'min',
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        );
        $('#exe_StartDate').attr(
          'max',
          this.datePipe.transform(currentDate, 'yyyy-MM-dd')
        );
      } else {
        toDate = moment(toDate)
          .subtract(7, 'days')
          .format('YYYY-MM-DD');
        let fromDate = moment(
          this.datePipe.transform(e.target.value, 'yyyy-MM-dd')
        )
          .add(7, 'days')
          .format('YYYY-MM-DD');
        $('#exe_StartDate').attr(
          'min',
          this.datePipe.transform(toDate, 'yyyy-MM-dd')
        );
        $('#exe_StartDate').attr(
          'max',
          this.datePipe.transform(fromDate, 'yyyy-MM-dd')
        );
      }
    }
  }
  changeReport(e) {
    if (this.isNormal) {
      this.isNormal = false;
    } else {
      this.isNormal = true;
    }
  }
  getMaxDate(): string {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if (sDate == null || eDate == null) {
      this.error = {
        isError: true,
        errorMessage: 'Start date and end date are required.'
      };
      this.isValidDate = false;
    }

    if (sDate != null && eDate != null && eDate < sDate) {
      this.error = {
        isError: true,
        errorMessage: 'To date should be greater than from date.'
      };
      this.isValidDate = false;
    }
    let currenDate = new Date();
    let curr_date = this.datePipe.transform(currenDate, 'yyyy-MM-dd');
    console.log('curr_date', curr_date);
    if (sDate != null && eDate != null && curr_date < eDate) {
      this.error = {
        isError: true,
        errorMessage:
          'To date should not be greater than today' + "'" + 's date.'
      };
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  getAlldetailsCount(fromDate: string | null, toDate: string | null) {
    const requestBody = {
      startDate: fromDate,
      endDate: toDate
    };
    this.dataservice.testCaseTableStatus(requestBody).subscribe(
      (response: []) => {
        console.log('test suite weekly report.....', response);
        if (response != null) {
          this.allDetailsCount = response;
        } else {
          this.allDetailsCount = [];
        }

        if (response.length == 0) {
          this.toastr.info('No data found');
        }
      },
      error => {
        console.log(error.message);
      }
    );
  }

  AppReportStatusFormSubmit() {
    this.isfromOninit = false;
    this.isNormal = true;

    if (this.AppReportStatusform.invalid) {
      return;
    } else {
      setTimeout(() => {
        this.loaderService.hide();
      }, 3000);
      const requestBody = {
        startDate: this.fromDate,
        endDate: this.toDate
      };
      this.dataservice
        .testCaseTableStatus(this.AppReportStatusform.value)
        .subscribe(
          (response: []) => {
            console.log('test suite weekly report.....', response);
            if (response != null) {
              if (
                this.AppReportStatusform.controls['DataType'].value != 'Normal'
              ) {
                this.isNormal = false;
              }
              this.allDetailsCount = response;
            } else {
              this.allDetailsCount = [];
            }

            if (response.length === 0) {
              this.toastr.info('No data found');
            }
          },
          error => {
            console.log(error.message);
          }
        );
    }
  }

  filter(key) {
    this.dataservice.filter(key, this.txtSearch);
  }

  orderedArray = [
    {
      brandName: 'ASC',
      appVersion: 'ASC',
      testLevel: 'ASC',
      environment: 'ASC',
      platformName: 'ASC'
    }
  ];

  sort_by_key(array, key, order) {
    if (order === 'ASC') {
      return array.sort(function(a, b) {
        var x = a[key];
        var y = b[key];

        if (
          (x != null && x != undefined && x != '') ||
          (y != null && y != undefined && y != '')
        ) {
          return String(x).toLowerCase() < String(y).toLowerCase()
            ? -1
            : String(x).toLowerCase() > String(y).toLowerCase()
            ? 1
            : 0;
        }
      });
    } else {
      return array.sort(function(a, b) {
        var x = a[key];
        var y = b[key];
        if (
          (x != null && x != undefined && x != '') ||
          (y != null && y != undefined && y != '')
        ) {
          return String(x).toLowerCase() > String(y).toLowerCase()
            ? -1
            : String(x).toLowerCase() < String(y).toLowerCase()
            ? 1
            : 0;
        }
      });
    }
  }
  sortTable(key) {
    this.loaderService.show();
    let order = this.orderedArray[0][key];
    this.orderedArray[0][key] = order == 'ASC' ? 'DESC' : 'ASC';
    this.allDetailsCount = this.sort_by_key(this.allDetailsCount, key, order);
    console.log(this.allDetailsCount);
    // this.loaderService.hide();
    //changes

    this.loaderService.hide();
  }

  exportTableData() {
    this.dataservice.exportTableDataasxls().subscribe((res: any) => {
      const blob = new Blob([res], { type: 'application/xls' });
      setTimeout(function() {
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = 'Download' + '.xls';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }, 1000);
    });
  }

  ngAfterViewInit() {}
}
