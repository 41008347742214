//import * as $ from 'jquery';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import {
  FormBuilder,
  FormGroupDirective,
  FormGroup,
  FormControl
} from '@angular/forms';
import { DataService } from '../../../shared/services/data.service';
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxSearchFilterModule } from 'ngx-search-filter';
// import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';
import { async } from '@angular/core/testing';
import { LocationStrategy, DatePipe } from '@angular/common';
// import * as moment from 'moment';
import moment from 'moment';
declare var $: any;
import { Type } from '../../models';
import { from, zip, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
// import { invalid } from '@angular/compiler/src/render3/view/util';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'wtaf-inprogress',
  templateUrl: './inprogress.component.html',
  styleUrls: ['./inprogress.component.css']
})
export class InprogressComponent implements OnInit {
  
  isCollapse1: Boolean = false;
  Execution_list: any = [];
  Execution_details: any = [];
  id: any = [];
  strJobName = '';
  jenKinsJobID: any;
  selectedSlave: any;
  slaveOs: any;
  errorMessage: String = '';
  projectTypeName = '';
  projectTypeId = 0;
  projectTypeList: any = [];
  jobName: any;
  viewStatusData: any = [];
  viewJenkinsStatusData: any = [];
  interval: any;
  intervalJenkins: any;
  parentInterval: any;
  parentTimeout: any;
  paused: Boolean = false;
  updatedjenkinJobId: any;
  Job_arn: '';
  startTime1: any;
  endTime1: any;
  txtSearch: string;
  jobData:any;
  
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Output() viewStatus_emitter = new EventEmitter<any>();
  changingValue: Subject<boolean> = new Subject();
  constructor(
    private dataservice: DataService,
    public toastr: ToastrService,
    public datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.parentTimeout = setTimeout(() => {
      this.getSchedulelist();
      this.getAllProjectType();
    }, 2500);
    console.log('this.parent time out', this.parentTimeout);
  }

  getSchedulelist() {
     
    this.dataservice
      .getExecutionSchedule()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (response: {}) => {
        this.Execution_list = response;
        console.log('Execution List', this.Execution_list);
        if (this.Execution_list == null) {
          this.Execution_list = [];
        }
        let startTime;
        var eventStartTime;
        var startDate1;
        this.Execution_list.forEach(element => {
          eventStartTime = element.time;
          if (
            eventStartTime != null &&
            eventStartTime != '' &&
            eventStartTime != undefined &&
            eventStartTime != 'Invalid date'
          ) {
            startDate1 = element.startDate;
            startDate1 = this.datePipe.transform(startDate1, 'yyyy-MM-dd');
            startTime = startDate1 + ' ' + eventStartTime;
            startTime = moment.utc(startTime).toDate();
            var new_startDate = new Date(startTime);
            var date1 = moment(new_startDate).format('hh:mm:ss');
            console.log('StartTime===' + startTime + 'time===' + date1);
            startTime = Date.parse(startTime);
            element.startTime1 = date1;
            element.startDate1 = startDate1;
            console.log('start date', element.startDate1);
          } else {
            startTime = ' -- : -- : --';
            date1 = startTime;
            element.startTime1 = date1;
          }
          return(
            (element.startDate1),
            (element.startTime1)
          );
        });
      });
  }

  getScheduledData() {
    // Added by Akash
 
    this.dataservice
      .getExecutionSchedule()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (response: {}) => {
        this.Execution_list = response;
        console.log('Execution List', this.Execution_list);
        if (this.Execution_list == null) {
          this.Execution_list = [];
        }
        //To get the Device farm job List
        let objJob_arn = this.Execution_list.filter(pl => {
          if (pl.jobArn != undefined && pl.jobArn != '' && pl.jobArn != null) {
            return pl;
          }
          // console.log('pl............' , pl);
        });
        console.log('objJob_arn............', objJob_arn);
        for (let i = 0; i < objJob_arn.length; i++) {
          let headerId = objJob_arn[i].exeHeaderId;
          let historyId = objJob_arn[i].exeHistoryId;
          let jobArn = objJob_arn[i].jobArn;
          console.log({ headerId, historyId, jobArn });

          if (jobArn != '' && jobArn != undefined && jobArn != null) {
            // await this.updateJobStatus(headerId, historyId, jobArn);
          }
        }

        let startTime;
        var eventStartTime;
        var startDate1;
        this.Execution_list.forEach(element => {
          eventStartTime = element.time;
          if (
            eventStartTime != null &&
            eventStartTime != '' &&
            eventStartTime != undefined &&
            eventStartTime != 'Invalid date'
          ) {
            startDate1 = element.startDate;
            startDate1 = this.datePipe.transform(startDate1, 'yyyy-MM-dd');
           
            startTime = startDate1 + ' ' + eventStartTime;
            startTime = moment.utc(startTime).toDate();
            var new_startDate = new Date(startTime);
            var date1 = moment(new_startDate).format('hh:mm:ss');
            console.log('StartTime===' + startTime + 'time===' + date1);
            startTime = Date.parse(startTime);
            element.startTime1 = date1;
            element.startDate1 = startDate1;
            console.log('start date', element.startDate1);
            
          } else {
            startTime = ' -- : -- : --';
            date1 = startTime;
            element.startTime1 = date1;
          }
          return(
            (element.startDate1),
            (element.startTime1)
          );
        });
      });
  }


  getScheduleDetails(id, exeHistoryId) {
    this.dataservice
      .getScheduleListDetails(id, exeHistoryId)
      .subscribe((response: {}) => {
        this.Execution_details = response;
        console.log('Execution Details', this.Execution_details);
      });
  }

  viewStatus(data) {
    console.log('view status data', data);
    $('#popupTitle').text(data.exeHeaderId + '-' + data.jobName);
    this.dataservice
      .getScheduleListDetails(data.exeHeaderId, data.exeHistoryId)
      .subscribe((response: {}) => {
        console.log('Execution Details', response);
        this.viewStatusData = response;
        $('#viewStatusModal').modal('show');
        this.changingValue.next(true);
       
        this.clearTimer();
        let suiteid = response[0].testSuiteId;
        this.Job_arn = data.jobArn;
        if (this.viewStatusData != null) {
          if (this.viewStatusData[0].jobStatus == 2) {
            if (this.interval) {
              clearInterval(this.interval);
            }
          } else {
            this.interval = setInterval(async () => {
              if (
                this.Job_arn != '' &&
                this.Job_arn != undefined &&
                this.Job_arn != null
              ) {
                // await this.getDevicefarmJobStatus(
                //   this.Job_arn,
                //   data.exeHeaderId,
                //   data.exeHistoryId,
                //   suiteid
                // );
              } else {
                this.getData(data.exeHeaderId, data.exeHistoryId);
              }
            }, 20000);
          }
        }
      });

    //jenkins build log
    this.viewJenkinsStatus(data);
  }

  viewJenkinsStatus(data) {
     
    console.log('view Jenkins status data', data);
    let objProjectType = this.projectTypeList.filter(
      pl => pl.projectTypeId == data.projectTypeId
    );

    this.projectTypeName = objProjectType[0].projectTypeName;
    console.log('project type -->', this.projectTypeName);
    if (this.projectTypeName == 'Mobile') {
      this.strJobName = this.dataservice.commonJobName;
    } else {
      this.strJobName = this.dataservice.commonJobName;
    }
    console.log('Job type to trigger --> ', this.strJobName);
    this.jenKinsJobID = data.jenkinsJobId;
    console.log('Jenkins Job Id --> ', data.jenkinsJobId);
    //$('#popupTitle1').text(data.exeHeaderId + '-' + data.jobName);
    this.dataservice
      .getJenkinsBuildLog(data.exeHistoryId, this.strJobName)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: {}) => {
        console.log('jenkins build log Details', response);
        this.viewJenkinsStatusData = response;
        $('#viewStatusModal').modal('show');
        this.changingValue.next(true);
        this.clearTimer();
        this.jobData = data;
        // this.intervalJenkins = setInterval(() => {
        //   this.refreshJenkinsBuildLogs(data, this.strJobName);
        // }, 10000);
      });
  }

  refreshJenkinsBuildLogs(data, strJobName) {
     
    this.dataservice
      .getJenkinsBuildLog(data.exeHistoryId, strJobName)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: {}) => {
        this.viewJenkinsStatusData = response;
        console.log('refreshed Build logs', this.viewJenkinsStatusData);
      });
  }

  getData(exeHeaderId, exeHistoryId) {
    this.dataservice
      .getScheduleListDetails(exeHeaderId, exeHistoryId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: {}) => {
        console.log('Execution Details', response);

        this.viewStatusData = response;
        console.log(
          '------------------data list----------',
          this.viewStatusData
        );
        if (this.viewStatusData != null) {
          if (this.viewStatusData[0].jobStatus == 2) {
            if (this.interval) {
              clearInterval(this.interval);
            }
          }
        }
        if (!this.paused) {
          this.paused = true;
          this.clearTimer();
        }
        $('#viewStatusModal').modal('show');
        this.changingValue.next(true);
      });
      
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.clearTimer();
    clearInterval(this.intervalJenkins);
  }

  clearTimer() {
    if (this.parentInterval) {
      clearInterval(this.parentInterval);
    }

    if (this.parentTimeout) {
      clearTimeout(this.parentTimeout);
    }
  }
  //code added by shafa-(for stop Jenkins job )
  async stopJenkinsJOb(data) {
    let doc = this;
    let jobArn = '';
    let historyId;
    historyId = data.exeHistoryId;
    this.jenKinsJobID = data.jenkinsJobId;
    this.selectedSlave = data.nodeName;
    this.slaveOs = data.nodeOs;
    jobArn = data.jobArn;
    console.log('job arn..............', jobArn);

    console.log(
      'selectedSlave----',
      this.selectedSlave,
      'slaveOs----',
      this.slaveOs
    );
    var isStop = confirm('Are you sure that you want to stop this job?');
    if (isStop) {
        doc.dataservice.stopRun(historyId).subscribe((data:any) => {
          console.log("stop run",data);
          // let err;
          // if (err) {
          //   console.log(err, err.stack); // an error occurred
          // }
          // else {
            doc.dataservice
              .updateHistorystatus(historyId, false, 4, 4)
              .subscribe(updateRes_ => {
                console.log('update statusof stopped build------', updateRes_);
              },
              error =>{
                console.log(error);
                
              });
            doc.toastr.success('Job has stopped successfully');
        //     console.log("AWS Device farm job stopped", data);   // successful response 
        //   // }
        },
        error =>{
          console.log(error);
          
        });
        await this.getSchedulelist();
      // }
    }
  }
  async getAllProjectType() {
    await this.dataservice
      .getAllProjectType()
      .toPromise()
      .then(response => {
        // Success

        this.projectTypeList = response;
        if (response != null) {
          if (this.projectTypeList.length == 1) {
            this.projectTypeId = this.projectTypeList[0].projectTypeId;
          } else {
            this.projectTypeId = 0;
          }
        }
      });
  }

  filter(key) {
    this.dataservice.filter(key, this.txtSearch);
  }
}
