<div>
    <form [formGroup]="executionStatusform" (ngSubmit)="onFormSubmit()">
        <div class="row">
          <div class="form-group col-md-6 ">
            <select class="txtControlCss col-md-12"  id="4" formControlName="exe_ProjectTypeId" (change)="changeProjectType($event)" >
              <option value="" disabled >Project Type</option>
              <option [value]="projectType.projectTypeId" *ngFor="let projectType of this.projectTypeList">
                {{projectType.projectTypeName}}</option>
            </select>
            <span class="error"
            *ngIf=" this.isSubmitted && !executionStatusform.controls['exe_ProjectTypeId'].valid">
            Project type is required</span>
          </div>
          <div class="form-group col-md-6">
            <select class="txtControlCss col-md-12" id="regionId" formControlName="exe_RegionId" (change)="changeRegion($event)" >
              <option value="" disabled selected>Region</option>
              <option [value]="region.regionId" *ngFor="let region of this.regionList">
                {{region.regionCode}}</option>
            </select>
            <span class="error"
            *ngIf=" this.isSubmitted && !executionStatusform.controls['exe_RegionId'].valid">
            Region is required</span>
          </div>
        </div>
        
        <div class="row">
          <div class="form-group col-md-6">
            <select class="txtControlCss  col-md-12" id="Brand" (change)="changeBrand($event)" formControlName="exe_BrandId" >
              <option value="" disabled selected>Brand</option>
              <option [value]="brand.brandId" *ngFor="let brand of this.brandList">
                {{brand.brandName}}</option>
            </select>
            <span class="error"
            *ngIf=" this.isSubmitted && !executionStatusform.controls['exe_BrandId'].valid">
            Brand is required</span>
          </div>
          
          <div class=" mt-1 col-md-6">
            <ng-multiselect-dropdown id="multiselectdropdownId" formControlName="exe_ProductNames" name="productNames" [placeholder]="'Product Name'"
             [data]="projectNameList" [(ngModel)]="selectedItems" [settings]="dropdownSettings" 
             (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
            </ng-multiselect-dropdown>
            <span class="error"
            *ngIf=" this.isSubmitted && !executionStatusform.controls['exe_ProductNames'].valid">
            Product Name is required</span>
          </div>
      
          <div class=" mt-1 col-md-6">
            <select class="txtControlCss col-md-12" id="PLATFORM" style="font-size: 12px;" formControlName="exe_PlatformId" >
              <option value="" disabled selected>PLATFORM</option>
              <option [value]="platform.platformId" *ngFor="let platform of platformList">{{platform.platformName}}</option>
            </select>
            <span class="error"
            *ngIf=" this.isSubmitted && !executionStatusform.controls['exe_PlatformId'].valid">
            Platform is required</span>
          </div>

          <div class="col-md-6">
            <select class="txtControlCss  col-md-12" id="ENV" style="font-size: 12px;" formControlName="exe_Environment" >
              <option value="" disabled selected>ENV</option>
              <option [value]="env" *ngFor="let env of environmentList">
                {{env}}
              </option>
            </select>
            <span class="error"
            *ngIf=" this.isSubmitted && !executionStatusform.controls['exe_Environment'].valid">
            Platform is required</span>
          </div>
        </div>
       
        <div class="row">
          <div class="col-md-6">
            <label for="regionId" class="txtControlLblCss">From<span>*</span></label>
            <div class="exmp-wrp " style="margin-left:2px;">
              <div class="input-group btn-wrp">
                <input type="date" class="btn-clck" formControlName="exe_StartDate"  (change)="onFromDateChange($event)" id="exe_StartDate" name="widget_From_date"
                   placeholder="From">
              </div>
              <span class="input-group-btn ">
                <button class="btn1  " type="text"><span class="paddingLefiRightCalender"><img
                      src="assets/images/calendarr.png"></span></button>
              </span>
            </div>
          </div>
      
          <div class="col-md-6">
            <label for="regionId" class="txtControlLblCss">To<span>*</span></label>
      
            <div class="exmp-wrp " style="margin-left:2px;">
              <div class="input-group btn-wrp">
                <input type="date" class="btn-clck " (change)="onToDateChange($event)" formControlName="exe_EndDate"  id="exe_EndDate" name="widget_To_date"
                  name="endDate"  placeholder="From">
              </div>
              <span class="input-group-btn ">
                <button class="btn1  " type="text"><span class="paddingLefiRightCalender"><img
                      src="assets/images/calendarr.png"></span></button>
              </span>
            </div>
          </div>
        </div>
        <div class="text-danger" *ngIf="error.isError">{{error.errorMessage}}</div>
        <div class="d-flex justify-content-end col-md-12 mt-4 p-0">
          <button type="submit" class="btn runBtnCss">Run</button>
        </div>
      </form>

  <div class=" row ">
    <div class="col-md-6 txt-color" style="font-weight: 600;">
      Project Execution Time Status
    </div>

  <div class="chart-container col-md-12 mt-1" >
    <highcharts-chart
          [(update)]="updateFlag"
          [Highcharts] = "highcharts" 
          [options] = "chartOptions" 
          style = "width: 100%; height: 300px; display: block;">
       </highcharts-chart>
  </div>
</div>