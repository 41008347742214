import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import * as $ from 'jquery';
import $ from 'jquery';
import { StartExecutionComponent } from '../../start_execution/start-execution/start-execution.component';
import { DatePipe } from '@angular/common';
// import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';
// import * as moment from 'moment';
import moment from 'moment';
@Component({
  selector: 'wtaf-schedule-popup',
  templateUrl: './schedule-popup.component.html',
  styleUrls: ['./schedule-popup.component.css']
})
export class SchedulePopupComponent implements OnInit {
  sd1_picker: any;
  sd2_picker: any;
  Ed1_picker: any;
  Ed2_picker: any;

  Frequancy: any;
  repeatFrequancy: any;
  scheduleTime: any;
  StartDate: any;
  endDate: any;
  active: boolean;
  hide_custom: boolean = false;
  hide_weekdays: boolean = false;
  frequancy_option = [
    { id: 0, name: 'Does not repeat' },
    { id: 1, name: 'Daily' },
    { id: 2, name: 'Weekly' },
    { id: 3, name: 'Custom' }
  ];
  ocuurance_option = [
    { id: 3, name: 'Weeks' },
    { id: 4, name: 'Months' }
  ];
  weekdays = [];
 dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  repeatCount: number;
  constructor(
    private router: Router,
    public pop: StartExecutionComponent,
    public datepipe: DatePipe,
    public toastr: ToastrService
  ) {}
  myDateValue: Date;
  ngOnInit() {
    this.active = true;
    let nowDate = new Date();
    this.StartDate = this.datepipe.transform(nowDate, 'yyyy-MM-dd');
    this.endDate = this.datepipe.transform(nowDate, 'yyyy-MM-dd');
    this.scheduleTime = this.datepipe.transform(nowDate, 'HH:mm');

    let dtToday = new Date();
    let month: any = dtToday.getMonth() + 1;
    let day: any = dtToday.getDate();
    let year = dtToday.getFullYear();
    if (month < 10) month = '0' + month.toString();
    if (day < 10) day = '0' + day.toString();

    var maxDate = year + '-' + month + '-' + day;
    $('#StartDate').attr('min', maxDate);
    $('#EndDate').attr('min', maxDate);
    this.repeatCount = 1;
    this.Frequancy = 0;
    this.repeatFrequancy = 4;
    $('#scheduleModal').modal({backdrop: 'static', keyboard: false});
    $('#schedule_btn').prop('disabled', false);
  }

  OpenModal(param1) {
    $('#scheduleModal').modal('show');
  }
  hideModel() {
    this.repeatCount = 1;
    this.Frequancy = 0;
    this.repeatFrequancy = 1;
    this.hide_custom = false;
    this.hide_weekdays = false;
    $('.weekday').prop('checked', false);
    $('#schedule_btn').prop('disabled', false);
    $('#scheduleModal .close').click();
  }
  onFrequency_Change(evalue) {
    evalue == 3 ? (this.hide_custom = true) : (this.hide_custom = false);
    this.endDate = this.datepipe.transform(this.StartDate, 'yyyy-MM-dd');
  }
  onOccurance_Change(ocuval) {
    ocuval == 3 ? (this.hide_weekdays = true) : (this.hide_weekdays = false);
  }
  onChange(e){
    this.endDate = this.datepipe.transform(e.target.value, 'yyyy-MM-dd');
    $('#EndDate').attr('min', e.target.value);
  }
  selectDays(e) {
    if (e.target.checked) {
      const resultArr = this.weekdays.filter(
        arrObj => arrObj !== e.target.value
      );
      if (resultArr.length === this.weekdays.length) {
        resultArr.push(e.target.value);
        this.weekdays = resultArr;
      }
    } else {
      const resultArr = this.weekdays.filter(
        arrObj => arrObj !== e.target.value
      );
      this.weekdays = resultArr;
    }
  }
  selectEnddate(e) {
    if (e.target.checked && e.target.value == 'on') {
      $('#EndDate').prop('disabled', false);
      this.endDate = '0000-00-00';
    } else {
      $('#EndDate').prop('disabled', true);
    }
  }
  ScheduleDetails() {
    let today = this.dayNames[new Date(this.StartDate.toString()).getDay()];
    if(this.repeatFrequancy ==3){
            const sorter = {
        "monday": 1,
        "tuesday": 2,
        "wednesday": 3,
        "thursday": 4,
        "friday": 5,
        "saturday": 6,
        "sunday": 7
      }

      this.weekdays.sort(function sortByDay(a, b) {
        let day1 = a.toLowerCase();
        let day2 = b.toLowerCase();
        return sorter[day1] - sorter[day2];
      });
    }
    let tdate = this.datepipe.transform(this.StartDate, 'yyyy-MM-dd');
    let commondate = tdate.toString() +" " +  this.scheduleTime;
    let convert_modifiedTime_format = moment(commondate).format('HH:mm');
    let var_ScheduleDetails = {
      active: this.active,
      startDate: this.StartDate,
      endDate: this.endDate,
      executeTime: convert_modifiedTime_format +':'+Intl.DateTimeFormat().resolvedOptions().timeZone,
      frequency: this.Frequancy == 3 ? this.repeatFrequancy:this.Frequancy,
      repeatSchedule: this.Frequancy == 3 ? this.repeatCount : 0,
      daysOfWeek:
        this.repeatFrequancy == 3
          ? this.weekdays.join()
          : this.Frequancy == 2
          ? today.toString()
          : '',
    };
      let currenttime = new Date();
      let scheduletime = this.scheduleTime;
      let nowtime = this.datepipe.transform(currenttime, 'HH:mm');
      let regExp = /(\d{1,2})\:(\d{1,2})/;
      if (new Date(this.datepipe.transform(currenttime, 'yyyy-MM-dd')).getTime() === new Date(this.datepipe.transform(this.StartDate, 'yyyy-MM-dd')).getTime()) {
        
        if(parseInt(nowtime.replace(regExp, '$1$2')) > parseInt(scheduletime.replace(regExp, '$1$2'))){
          this.toastr.error('Please select current or future time');
        }else{
          
          $('#schedule_btn').prop('disabled', true);
          this.pop.save_startExecution_Details(var_ScheduleDetails);
        }
        
      } else {
        $('#schedule_btn').prop('disabled', true);
        this.pop.save_startExecution_Details(var_ScheduleDetails);
      }

  }

}
