import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DataService } from '../../../../shared/services/data.service';
// import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../../../shared/services/loader.service';

@Component({
  selector: 'wtaf-all-count',
  templateUrl: './all-count.component.html',
  styleUrls: ['./all-count.component.css']
})
export class AllCountComponent implements OnInit, AfterViewInit {
  allDetailsCount: any = [];
  constructor(private dataservice: DataService, 
    public toastr: ToastrService,
    public loaderservice:LoaderService) {
    this.getAlldetailsCount();
  }

  ngOnInit() {
    this.loaderservice.show()

    setTimeout(()=>{
        this.loaderservice.hide()
      
  
      },15000)
  }
  getAlldetailsCount() {
    this.dataservice.getDashboardCountByRegion().subscribe(
      (response: {}) => {
        if (response != null) {
          this.allDetailsCount = response;
          setTimeout(() => {
            for (let i in this.allDetailsCount) {
              for (let j in this.allDetailsCount[i].regionView) {
                let brandtable = document.getElementById(
                  this.allDetailsCount[i].projectType + 'region_brand' + j
                ).offsetHeight;
                document.getElementById(
                  this.allDetailsCount[i].projectType + 'region' + j
                ).style.height = brandtable + 'px';
              }
            }
          }, 1000);
        } else {
          this.allDetailsCount = [];
        }
      },
      error => {
        console.log(error.message);
      }
    );
  }
  ngAfterViewInit() {}
}
