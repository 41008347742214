import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchData'
})
export class SearchDataPipe implements PipeTransform {

  transform(items: Array<any>, filter: { [key: string]: any }): Array<any> {
    var res = items.filter((item) => {
      return Object.keys(item).some((key) => {
        // console.log("filter==>",filter );
        // console.log("key==>",key );
        // console.log("filter[key]==>",filter[key]);
        if (filter[key] != undefined) {
          // console.log("item==>",item );
          // console.log("key==>",key );
          // console.log("item[key]==>",item[key]);
          let val = item[key];
          val = val.toString().toLowerCase().trim();
          console.log("val==>", val);

          let fltVal = filter[key];

          let dt = val.includes(fltVal.toString().toLowerCase().trim());
          // console.log("dt==>",dt);
          return dt
        }

      });
    });
    return res;
  }

}
