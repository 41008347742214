/* projects/wtaf/src/app/features/view_status/view-status/view-status.component.css */
.caret {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.caret::before {
  content: url("./media/expand.png");
  color: black;
  display: inline-block;
  margin-right: 6px;
}
.caret-down::before {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.childTable td {
  background-color: none !important;
}
.out {
  display: none;
}
.in {
  display: table-row;
}
.nav-link.active {
  color: #012F60;
  border-bottom: #1F83FE 4px solid;
  border-bottom-left-radius: -10px;
  border-bottom-right-radius: -10px;
  border-color: white white #1F83FE;
}
.passedCss {
  height: 12px;
  width: 12px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}
.failedCss {
  height: 12px;
  width: 12px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.table td {
  padding: 5px !important;
}
.tblexec-row {
  background-color: white !important;
  border-bottom: 1px solid rgb(212, 221, 230) !important;
}
.tblexec-td {
  border: none !important;
}
.modal-xl {
  max-width: 60% !important;
}
.statusCss {
  margin-left: 5px;
  float: right !important;
}
.statusIconCss {
  margin-top: 5px !important;
  float: right !important;
}
.statusCss1 {
  margin-left: 5px;
}
.hide {
  visibility: hidden;
}
/*# sourceMappingURL=view-status.component.css.map */
